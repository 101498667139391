import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import utils from '#/';

function TableHeader() {
  return (
    <div className='bg-grey-dark text-white grid'>
      <div className='p-md w-10'>
        <b className='mr-sm'>Id</b>
      </div>
      <div className='p-md w-30'>
        <b className='mr-sm'>URL</b>
      </div>
      <div className='p-md w-20'>
        <b className='mr-sm'>Top Content Before 'More'</b>
      </div>
      <div className='p-md w-10'>
        <b className='mr-sm'>Force to be included in sitemap?</b>
      </div>
      <div className='p-md w-10'>{/* spacer */}</div>
    </div>
  );
}

const tableClass = (i) => (i % 2 === 0 ? 'bg-grey-lightest' : 'bg-white');

export const SearchContentTable = ({ searchContentOverrides }) => {
  const [search, setSearch] = useState('');

  const filteredData = () => {
    // When searching also check parent tags so you can search for name of parent and see all children
    if (search && search.length) {
      return searchContentOverrides.filter((x) => _.includes(x.url.toUpperCase(), search.toUpperCase()));
    }

    return searchContentOverrides;
  };

  const handleSearch = ({ target }) => utils.debounce(() => setSearch(target.value));

  const TableRow = (props) => {
    const { row, i } = props;

    return (
      <>
        <div className={`${tableClass(i)} grid align-center data`}>
          <div className='p-md w-10'>{row.id}</div>
          <div className='p-md w-30'>{row.url}</div>
          <div className='p-md w-50'>{row.top_content_before_more}</div>
          <div className='p-md w-50'>{row.force_include_in_sitemap ? 'yes (will always be included)' : 'no (could still be auto-included)'}</div>
          <div className='p-md w-10'>
            <a href={`/search-content/edit/${row.id}`} target='_blank' rel='noopener noreferrer' className='button primary m-r-sm edit-rule-grid-button'>
              Edit
            </a>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className='border border-grey-lightest radius-xs scroll insurance-rules-table'>
        <input type='text' className='block w-100' placeholder='Filter by URL' onKeyUp={handleSearch} />
        <TableHeader />
        {filteredData().map((row, i) => (
          <TableRow row={row} i={i} key={row.id} />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  searchContentOverrides: state.searchContent.searchContentOverrides,
});

export default connect(mapStateToProps, actions)(SearchContentTable);
