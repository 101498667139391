// @ts-check
import React from 'react';
import { actions } from './sagaSlice';
import { useDispatch } from 'react-redux';

function ListRedirectsInRedis() {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    // @ts-ignore
    dispatch(actions.listRedirectsInRedis());
  };

  return (
    <button onClick={handleClick} className='primary'>
      List redirects in redis (check Network Tab of Chrome dev tools for result)
    </button>
  );
}

export default ListRedirectsInRedis;
