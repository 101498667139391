import React from 'react';
import Error from './Error';
import TUIEditor from './TUIEditor';
const chars = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];

const Editor = (props) => {
  const { input = {}, label, meta = {}, style, height } = props;
  // Generate Random Id for TUIEditor
  const id = [1, 2, 3, 4, 5].map(() => chars[(Math.random() * chars.length) | 0]).join``;

  return (
    <div className={`${style} field ${meta.error && meta.touched ? 'error' : ''}`}>
      <label>{label}</label>
      <TUIEditor {...input} id={id} height={height} value={input.value} />

      <Error {...meta} />
    </div>
  );
};

export default Editor;
