import DefaultLayout from '##/Default';
import DeveloperTools from './DeveloperTools';
import ManualReimbursifySSO from './ManualReimbursifySSO';

export default [
  {
    path: '/ppb-sso-tool',
    exact: true,
    layout: DefaultLayout,
    component: ManualReimbursifySSO,
    auth: true,
    title: 'PPB SSO Tool',
  },
  {
    path: '/developer-tools',
    exact: true,
    layout: DefaultLayout,
    component: DeveloperTools,
    auth: true,
    title: 'Zencare Software Developer Tools',
  },
];
