import { select, put, takeLatest } from 'redux-saga/effects';
import { crudSlice, noop, failReducer } from 'saga-slice-helpers';
import history from '#/history';
import { api, sagaApi } from '#/apis';
import { paginationReducer } from '#/sagaSlice';
import { flashError } from '+/flashes/redux';
import { selectScope } from '../auth/sagaSlice';

const sagaSliceModule = crudSlice({
  name: 'users',
  sagaApi,
  initialState: {
    providerToAssociate: null,
    providerToDissociate: null,
    providerAccounts: null,
    accounts: null,
    emails: [],
    unsubscribes: [],
  },
  reducers: {
    readAllSuccess: paginationReducer,

    associateUserProvider: noop,
    dissociateUserProvider: noop,
    selectProviderToAssociate: (state, payload) => {
      state.providerToAssociate = payload;
    },
    selectProviderToDissociate: (state, payload) => {
      state.providerToDissociate = payload;
    },

    getProviderAccountSummary: noop,
    getProviderAccountSummarySuccess: (state, payload) => {
      state.providerAccounts = payload;
    },
    getProviderAccountSummaryFail: noop,

    getAccountSummary: noop,
    getAccountSummarySuccess: (state, payload) => {
      state.accounts = payload;
    },
    getAccountSummaryFail: noop,

    lockUser: noop,
    unlockUser: noop,

    twoFactorEnroll: noop,
    twoFactorUnenroll: noop,

    requestPasswordReset: noop,
    requestPasswordResetSuccess: noop,
    requestPasswordResetFail: failReducer,

    confirmAccount: noop,
    confirmAccountSuccess: noop,
    confirmAccountFail: failReducer,

    batchCreate: noop,
    batchCreateSuccess: noop,
    batchCreateFail: failReducer,

    searchUsers: noop,
    searchUsersSuccess: paginationReducer,
    searchUsersFail: noop,

    getEmails: noop,
    getEmailsSuccess: (state, payload) => {
      state.emails = payload;
    },
    getEmailsFail: noop,

    getAllAppointments: noop,
    getAllAppointmentsSuccess: (state, payload) => {
      state.appointments = payload;
    },
    getAllAppointmentsFailure: failReducer,

    getUnsubscribes: noop,
    getUnsubscribesSuccess: (state, payload) => {
      state.unsubscribes = payload;
    },
    getUnsubscribesFail: noop,

    unsubscribeFromFlow: noop,
    unsubscribeFromFlowSuccess: noop,
    unsubscribeFromFlowFail: failReducer,

    resubscribeToFlow: noop,
    resubscribeToFlowSuccess: noop,
    resubscribeToFlowFail: failReducer,

    redact: noop,
    redactSuccess: (state, payload) => {
      alert(JSON.stringify(payload));
    },
    redactFail: failReducer,

    createUserAccountBlock: noop,
    createUserAccountBlockSuccess: noop,
    createUserAccountBlockFail: failReducer,

    deleteUserAccountBlock: noop,
    deleteUserAccountBlockSuccess: noop,
    deleteUserAccountBlockFail: failReducer,
  },
  takers: {
    readOne: takeLatest,
  },
  //eslint-disable-next-line
  sagas: (A) => ({
    [A.redact]: {
      *saga({ payload }) {
        //eslint-disable-next-line
        const { user_id } = payload;
        yield sagaApi.post(`/users/${user_id}/redact`, {}, A.redactSuccess, A.redactFail);
      },
    },
    [A.readAll]: {
      *saga({ payload = {} }) {
        const { page = 0, order = 'asc', orderBy = 'username', type = 'all' } = payload;
        window.scrollTo(0, 0);
        yield sagaApi.get(`/users?page=${page}&order=${order}&orderBy=${orderBy}&type=${type}`, A.readAllSuccess, A.readAllFail);
        yield put(A.resetCurrent());
      },
    },
    [A.readOneSuccess]: {
      *saga({ payload }) {
        yield put(A.setCurrent(payload.id));
        yield put({ type: 'accounts/readAll', payload: { limit: 200 } });
        yield put(A.getAccountSummary({ id: payload.id }));
      },
    },
    [A.getAccountSummary]: {
      *saga({ payload }) {
        yield sagaApi.get(`/accounts/user/${payload.id}`, A.getAccountSummarySuccess, A.getAccountSummaryFail);
      },
    },
    [A.selectProviderToAssociate]: {
      *saga({ payload }) {
        if (payload) {
          yield put(A.getProviderAccountSummary(payload));
        }
      },
    },
    [A.selectProviderToDissociate]: {
      *saga({ payload }) {
        if (payload) {
          yield put(A.getProviderAccountSummary(payload));
        }
      },
    },
    [A.getProviderAccountSummary]: {
      *saga({ payload }) {
        yield sagaApi.get(`/accounts/provider/${payload.id}`, A.getProviderAccountSummarySuccess, A.getProviderAccountSummaryFail);
      },
    },
    [A.associateUserProvider]: {
      *saga({ payload }) {
        //eslint-disable-next-line
        let { account_id, user_id, provider_id, onSuccess } = payload;
        // If no account id, create an owned account for the user.
        if (!account_id) {
          const response = yield api.post(`/account/user/${user_id}`, {
            name: 'Account',
          });

          account_id = response.data.id;
        }

        try {
          yield api.post(`/account/${account_id}/user-provider`, {
            user_id,
            provider_id,
          });
          yield put(A.selectProviderToAssociate());
          yield put(A.readOne(user_id));
          onSuccess && onSuccess();
        } catch (e) {
          yield put(flashError(e));
        }
      },
    },
    [A.dissociateUserProvider]: {
      *saga({ payload }) {
        //eslint-disable-next-line
        const { account_id, user_id, provider_id, onSuccess } = payload;
        try {
          yield api.delete(`/account/${account_id}/user-provider`, {
            data: {
              user_id,
              provider_id,
            },
          });
          yield put(A.selectProviderToDissociate());
          yield put(A.readOne(user_id));
          onSuccess && onSuccess();
        } catch (e) {
          yield put(flashError(e));
        }
      },
    },
    [A.batchCreate]: {
      *saga({ payload }) {
        yield sagaApi.post('/user/batch', payload, A.batchCreateSuccess, A.batchCreateFail);
      },
    },
    [A.batchCreateSuccess]: {
      *saga() {
        yield history.push('/users');
      },
    },
    [A.lockUser]: {
      *saga({ payload }) {
        const { id } = payload;
        yield api.get(`/users/${id}/lock`);
        yield put(A.readOne(id));
      },
    },
    [A.unlockUser]: {
      *saga({ payload }) {
        const { id } = payload;
        yield api.get(`/users/${id}/unlock`);
        yield put(A.readOne(id));
      },
    },
    [A.twoFactorEnroll]: {
      *saga({ payload }) {
        const { id } = payload;
        try {
          yield api.post(`/users/${id}/two-factor-enroll`, {});
        } catch (e) {
          // TODO someday but not during this ticket:
          // Figure out how to access the specific error message the server is returning.
          yield put(flashError(`Error enrolling. Did you forget to set a phone number first? : ${e.message}`));
        }
        yield put(A.readOne(id));
      },
    },
    [A.twoFactorUnenroll]: {
      *saga({ payload }) {
        const { id } = payload;
        try {
          yield api.post(`/users/${id}/two-factor-unenroll`, {});
        } catch (e) {
          yield put(flashError(e.message));
        }
        yield put(A.readOne(id));
      },
    },
    [A.requestPasswordReset]: {
      *saga({ payload }) {
        const { id } = payload;
        yield sagaApi.post(`/users/${id}/password/request-reset`, {}, A.requestPasswordResetSuccess, A.requestPasswordResetFail);
      },
    },
    [A.requestPasswordResetSuccess]: {
      *saga() {
        yield window.alert('Password reset successful.');
      },
    },
    [A.requestPasswordResetFail]: {
      *saga({ payload }) {
        yield window.alert(`Error resetting password. Error: ${payload}`);
      },
    },
    [A.confirmAccount]: {
      *saga({ payload }) {
        const { id } = payload;
        yield sagaApi.get(`/admin/confirm/${id}`, A.confirmAccountSuccess, A.confirmAccountFail);
      },
    },
    [A.confirmAccountFail]: {
      *saga({ payload }) {
        yield window.alert(`Error confirmed Account. Error: ${payload}`);
      },
    },
    [A.createSuccess]: {
      *saga({ payload }) {
        const { id } = payload;

        // Use the select effect to get the scope from the auth state
        const scope = yield select(selectScope);
        if (scope === 'admin-hipaa' || scope === 'super-admin') {
          yield history.push(`/users/view-with-mode/${id}/all`);
        } else {
          yield history.push(`/users/view-with-mode/${id}/provider`);
        }
      },
    },
    [A.deleteSuccess]: {
      *saga() {
        yield history.push('/users');
      },
    },
    [A.updateSuccess]: {
      *saga({ payload }) {
        yield put(A.readOne(payload.id));
      },
    },
    [A.searchUsers]: {
      *saga({ payload }) {
        const { q = '', type = 'all' } = payload;
        console.log(q, type);
        try {
          if (q.length < 1) {
            yield put(A.readAll());
          } else if (q.length > 0) {
            const { data } = yield api.get(`/users/search/${q}?type=${type}`);
            yield put(A.searchUsersSuccess(data));
          }
        } catch (error) {
          yield put(A.searchUsersFail(error));
        }
      },
    },
    [A.getEmails]: {
      *saga({ payload }) {
        yield sagaApi.get(`/users/${payload.id}/email-log/${payload.mode}`, A.getEmailsSuccess, A.getEmailsFail);
      },
    },
    [A.getAllAppointments]: {
      *saga({ payload }) {
        yield sagaApi.get(`/users/${payload.id}/appointments`, A.getAllAppointmentsSuccess, A.getAllAppointmentsFailure);
      },
    },
    [A.getUnsubscribes]: {
      *saga({ payload }) {
        yield sagaApi.get(`/users/${payload.id}/unsubscribes`, A.getUnsubscribesSuccess, A.getUnsubscribesFail);
      },
    },
    [A.unsubscribeFromFlow]: {
      *saga({ payload }) {
        const userId = payload.user_id;
        delete payload.user_id;

        yield sagaApi.post(`/users/${userId}/unsubscribes`, payload, A.unsubscribeFromFlowSuccess, A.unsubscribeFromFlowFail);
        yield put(A.getUnsubscribes({ id: userId }));
      },
    },
    [A.resubscribeToFlow]: {
      *saga({ payload }) {
        const userId = payload.user_id;
        delete payload.user_id;

        yield sagaApi.post(`/users/${userId}/remove-unsubscribe`, payload, A.resubscribeToFlowSuccess, A.resubscribeToFlowFail);
        yield put(A.getUnsubscribes({ id: userId }));
      },
    },
    [A.createUserAccountBlock]: {
      *saga({ payload }) {
        yield sagaApi.post(`/users/account-blocks`, payload, A.createUserAccountBlockSuccess, A.createUserAccountBlockFail);
      },
    },
    [A.createUserAccountBlockSuccess]: {
      *saga({ payload }) {
        yield put(A.readOne(payload.user_id));
      },
    },
    [A.deleteUserAccountBlock]: {
      *saga({ payload }) {
        yield sagaApi.put(`/users/account-blocks/delete`, payload, A.deleteUserAccountBlockSuccess, A.deleteUserAccountBlockFail);
      },
    },
    [A.deleteUserAccountBlockSuccess]: {
      *saga({ payload }) {
        yield put(A.readOne(payload.user_id));
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
