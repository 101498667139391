import React from 'react';
import EditableText from '+/forms/EditableText';
import utils from '#/';
const randKey = () => Math.floor(Math.random() * 100000);

const SessionInfoTable = (props) => {
  const { provider, updateFn, properties } = props;
  const { custom_content } = provider;
  const {
    session_fee_initial,
    session_fee_couples,
    session_fee_family,
    session_fee_group,
    session_fee_med_management_and_therapy,
    session_fee_med_management,
    session_length_ongoing,
    session_length_initial,
    session_length_couples,
    session_length_group,
    session_length_family,
    session_length_med_management_and_therapy,
    session_length_med_management,
  } = custom_content;

  // Huge JSON for table, pass name and value so that it can be updated
  const sessionInfo = [
    {
      session_type: 'Initial Session',
      session_fee: {
        name: 'session_fee_initial',
        value: session_fee_initial,
      },
      session_length: {
        name: 'session_length_initial',
        value: session_length_initial,
      },
    },
    {
      session_type: 'Individual Sessions',
      session_fee: {
        name: 'session_fee_ongoing',
        value: provider.session_fee_ongoing,
      },
      session_length: {
        name: 'session_length_ongoing',
        value: session_length_ongoing,
      },
    },
    {
      session_type: 'Couples Session',
      session_fee: {
        name: 'session_fee_couples',
        value: session_fee_couples,
      },
      session_length: {
        name: 'session_length_couples',
        value: session_length_couples,
      },
    },
    {
      session_type: 'Family Session',
      session_fee: {
        name: 'session_fee_family',
        value: session_fee_family,
      },
      session_length: {
        name: 'session_length_family',
        value: session_length_family,
      },
    },
    {
      session_type: 'Group Session',
      session_fee: {
        name: 'session_fee_group',
        value: session_fee_group,
      },
      session_length: {
        name: 'session_length_group',
        value: session_length_group,
      },
    },
    {
      session_type: 'Med Management Session',
      session_fee: {
        name: 'session_fee_med_management',
        value: session_fee_med_management,
      },
      session_length: {
        name: 'session_length_med_management',
        value: session_length_med_management,
      },
    },
    {
      session_type: 'Med Management and Therapy Session',
      session_fee: {
        name: 'session_fee_med_management_and_therapy',
        value: session_fee_med_management_and_therapy,
      },
      session_length: {
        name: 'session_length_med_management_and_therapy',
        value: session_length_med_management_and_therapy,
      },
    },
  ];

  return (
    <div>
      <h2>Session Info:</h2>
      <hr />
      <div className='bg-grey-dark text-white'>
        <div className='p-md grid align-center justify-between'>
          {properties.map((val, i) => (
            <div className='mr-sm w-30 sessionTitle' key={randKey()}>
              <b>{val.title}</b>
            </div>
          ))}
          <div className='w-5' />
        </div>
      </div>
      <div style={{ overflow: 'auto' }}>
        {sessionInfo.map((val, i) => (
          <div className={`${utils.tableClass(i)} grid align-center`} key={randKey()}>
            {properties.map((p, index) => {
              if (p.name === 'session_type') {
                return (
                  <div className='p-md w-33' key={randKey()}>
                    {val[p.name]}
                  </div>
                );
              }

              return (
                <div className='w-33 my-xxs' key={randKey()}>
                  <div className='w-50'>
                    <EditableText
                      name={val[p.name]['name']}
                      type={val[p.name]['name'].includes('fee') ? 'number' : 'text'}
                      updateFn={updateFn}
                      custom_content={
                        val[p.name]['name'] !== 'session_fee_ongoing' && custom_content
                      }
                      initialState={val[p.name]['value']}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SessionInfoTable;
