import React from 'react';
import history from '#/history';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import TherapyGroupForm from './Form';

export const AddTherapyGroup = (props) => {
  const onCancel = () => {
    history.push('/therapy-groups');
  };

  return (
    <TherapyGroupForm onSubmit={(values) => props.createTherapyGroup(values)} onCancel={onCancel} />
  );
};

export default connect(null, { createTherapyGroup: actions.create })(AddTherapyGroup);
