import uuid from 'uuid';

// This is the function that will be called in
// order to pass redux actions into React component.
// eslint-disable-next-line no-unused-vars
let interceptor;

export const setInterceptor = (fn) => {
  interceptor = fn;
};

export const flashAction = (payload) => {
  payload.id = uuid();
  payload.time = +new Date();

  payload.type = payload.type || 'info';

  return {
    type: `flash/${payload.type}`,
    payload,
  };
};

export const flashError = (message, permanent) =>
  flashAction({
    type: 'error',
    message,
    permanent,
  });
export const flashSuccess = (message, permanent) =>
  flashAction({
    type: 'success',
    message,
    permanent,
  });
export const flashWarn = (message, permanent) =>
  flashAction({
    type: 'warn',
    message,
    permanent,
  });
export const flashInfo = (message, permanent) =>
  flashAction({
    type: 'info',
    message,
    permanent,
  });
export const flashBrand = (message, permanent) =>
  flashAction({
    type: 'brand',
    message,
    permanent,
  });

const typesToIntercept = ['FLASH', 'ALERT', 'alert'];

export const flashMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (typesToIntercept.includes(action.type)) {
      dispatch(flashAction(action.payload));
    }

    if (/sagaApi\/\d+/.test(action.type)) {
      action.payload.type = 'error';
      dispatch(flashAction(action.payload));
    }

    if (/flash/i.test(action.type)) {
      interceptor && interceptor(action.payload);
    }
  };

export default flashMiddleware;
