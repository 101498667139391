/**
 * Formats a phone number
 * @example const formattedPhone = formatPhoneNumber('1234567890')
 * console.log(formattedPhone) // '+1 (234) 567-8901'
 * @param {string} phoneNumberString
 */
export function formatPhoneNumber(phoneNumberString) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';

    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return phoneNumberString;
}

/**
 * @description Formats a :tel link
 * @example const telLink = formatTelLink('+1 (234) 567-8901')
 * console.log(telLink) // '+12345678901'
 * @param {string} phoneNumberString
 */
export function formatTelLink(phoneNumberString) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1' : '';

    return [intlCode, match[2], match[3], match[4]].join('');
  }

  return phoneNumberString;
}
