import React from 'react';

// Recursive component to render each value based on its type
const RenderValue = ({ value }) => {
  if (Array.isArray(value)) {
    return (
      <ul>
        {value.map((item, index) => (
          <li key={index}>
            <RenderValue value={item} />
          </li>
        ))}
      </ul>
    );
  } else if (typeof value === 'object') {
    return <DynamicList data={value} />;
  } else {
    return <span>{value}</span>;
  }
};

// Main component to render the unknown shape object
const DynamicList = ({ data }) => {
  if (!data) return null;

  return (
    <div>
      {Object.entries(data).map(([key, value]) => (
        <div key={key} className='m-b-xs'>
          <b>{transformKey(key)}:</b> <RenderValue value={value} />
        </div>
      ))}
    </div>
  );
};

// removes underlines and capitalizes the first letter
const transformKey = (key) => {
  return key
    .replaceAll('_', ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export default DynamicList;
