import React, { useEffect, useState } from 'react';
import MappedTable from './MappedTable';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import { Redirect } from 'react-router-dom';

export const ProvidersByLicenseStatus = (props) => {
  const {
    verificationStatus,
    verificationStatusName,
    providersByLicenseStatus,
    getProvidersByLicenseStatus,
  } = props;
  const [redirectToProviderId, setRedirectToProviderId] = useState(null);

  useEffect(() => {
    getProvidersByLicenseStatus({ status: verificationStatus });
  }, [getProvidersByLicenseStatus, verificationStatus]);

  if (!providersByLicenseStatus) {
    return 'Loading providers...';
  }

  const tables = [
    {
      title: `Providers with license of ${verificationStatusName}`,
      data: providersByLicenseStatus,
      properties: [
        {
          name: 'name',
          title: 'Name',
        },
        {
          name: 'profile_status',
          title: 'Profile status',
        },
        {
          name: 'email',
          title: 'Email',
        },
        {
          name: 'created_at',
          title: 'Created at',
        },
      ],
    },
  ];

  const clickHandler = (value) => {
    setRedirectToProviderId(value.provider_id);
  };

  const renderTable = (table, i) => (
    <MappedTable clickHandler={clickHandler} {...table} key={table.title} />
  );

  if (redirectToProviderId) {
    return (
      <Redirect
        to={{
          pathname: `providers/view/${redirectToProviderId}/locations`,
        }}
      />
    );
  }

  return <div>{tables.map(renderTable)}</div>;
};
export default connect(
  (state) => ({
    providersByLicenseStatus: state.stats.providersByLicenseStatus,
  }),
  actions
)(ProvidersByLicenseStatus);
