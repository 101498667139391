import React, { useEffect, useState } from 'react';
import MappedTable from './MappedTable';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import ProvidersByLicenseStatus from './ProvidersByLicenseStatus';

export const ProviderLicenseStats = (props) => {
  const { providerLicenseStats, getProviderLicenseStats, auth } = props;
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [verificationStatusName, setVerificationStatusName] = useState(null);

  useEffect(() => {
    if (
      !providerLicenseStats &&
      auth &&
      ['license-checker', 'admin', 'staff', 'admin-hipaa', 'super-admin'].includes(auth.scope)
    ) {
      getProviderLicenseStats();
    }
  }, [auth, getProviderLicenseStats, providerLicenseStats]);

  if (!providerLicenseStats) {
    return 'Loading license stats...';
  }

  const totals = [
    {
      name: 'Verified',
      reservedName: 'Verified',
      total: providerLicenseStats.verified,
    },
    {
      name: 'Unverified (and provider has active subscription)',
      reservedName: 'Unverified',
      total: providerLicenseStats.unverifiedLicensesWithActiveSubscription,
    },
    {
      name: 'Not found',
      reservedName: 'Not found',
      total: providerLicenseStats.notFound,
    },
    {
      name: 'Legacy',
      reservedName: 'Legacy',
      total: providerLicenseStats.legacy,
    },
  ];
  const tables = [
    {
      title: 'Licenses by status',
      data: totals,
      properties: [
        {
          name: 'name',
          title: 'Name',
        },
        {
          name: 'total',
          title: 'Total',
        },
      ],
      height: '250px',
    },
  ];

  const clickHandler = (value) => {
    setVerificationStatus(value.reservedName);
    setVerificationStatusName(value.name);
  };

  const renderTable = (table, i) => (
    <MappedTable clickHandler={clickHandler} {...table} key={table.title} />
  );

  return (
    <div>
      {tables.map(renderTable)}

      {verificationStatus && (
        <ProvidersByLicenseStatus
          verificationStatus={verificationStatus}
          verificationStatusName={verificationStatusName}
        />
      )}
    </div>
  );
};
export default connect(
  (state) => ({
    providerLicenseStats: state.stats.providerLicenseStats,
    auth: state.auth,
  }),
  actions
)(ProviderLicenseStats);
