import React from 'react';
import Modal from '+/Modal';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';

export const UnsubscribeFromFlowModal = ({ user, showModal, toggleModal, unsubscribeFromFlow }) => {
  const options = [
    {
      userType: 'Provider',
      flowName: 'Account Creation',
      description: 'Pre-payment',
    },
    {
      userType: 'Provider',
      flowName: 'Onboarding Reminder',
      description: 'Post-payment',
    },
    {
      userType: 'Provider',
      flowName: 'Post Live',
      description: '',
    },
    {
      userType: 'Provider',
      flowName: 'Booking Tool Announcement',
      description: '',
    },
    {
      userType: 'Provider',
      flowName: 'Booking Tool Tips',
      description: 'booking-tool-now-offering-phone-consultations',
    },
    {
      userType: 'Provider',
      flowName: 'Booking Tool No Availability Reminders',
      description: 'booking-tool-no-availability-reminder',
    },
    {
      userType: 'Provider',
      flowName: 'Availability Updates',
      description: 'availability-updates',
    },
    {
      userType: 'Client',
      flowName: 'Provider Feedback',
      description: '',
    },
  ];

  const unsubscribeOptionChosen = ({ target: { value } }) => {
    unsubscribeFromFlow({
      user_id: user.id,
      flow_name: value,
    });
    toggleModal();
  };

  if (showModal) {
    return (
      <Modal title='Choose the flow to unsubscribe the user from' onDismiss={toggleModal}>
        <div className='text-grey-lighter grid align-center justify-around'>
          {/* eslint-disable-next-line jsx-a11y/no-onchange */}
          <select onChange={unsubscribeOptionChosen}>
            <option value=''>Choose an option.</option>
            {options.map((option) => (
              <option key={option.flowName} value={option.flowName}>
                {option.userType}: {option.flowName}{' '}
                {option.description ? `(${option.description})` : ''}
              </option>
            ))}
          </select>
        </div>
      </Modal>
    );
  }

  return null;
};

export default connect((state) => ({}), actions)(UnsubscribeFromFlowModal);
