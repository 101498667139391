import rgx from '#/regex';
const validate = (values) => {
  const errors = {};
  if (!values.name) {
    errors.name = 'You must enter a name';
  }

  if (!values.slug) {
    errors.slug = 'You must enter a slug';
  } else if (!rgx.slug.test(values.slug)) {
    errors.slug =
      'Slug must contain only lowercase letters and must not begin or end with special chars.';
  }
  if (values.short_description && values.short_description.length > 250) {
    errors.short_description = 'Short Description is greater then 250 Characters';
  }

  if (!values.costs) {
    errors.costs = 'You must enter costs';
  }
  if (!values.hours) {
    errors.hours = 'You must enter hours';
  }

  return errors;
};

export default validate;
