import React, { useEffect } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import ErrorLayout from '##/Error';
import Routes from './routes';

import { getState } from './store';
import { actions as authActions } from '@/auth/sagaSlice';

import './styles/index.scss';

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

const App = (props) => {
  const { isSignedIn, checkSessionStart, setShouldRefreshSession } = props;

  useEffect(() => {
    let timeoutFn;

    const activityFn = () => {
      setShouldRefreshSession(true);
    };

    const activityEvents = ['mousemove', 'mousedown', 'touchstart', 'click', 'scroll', 'keypress'];

    const debounceActivityFn = () => {
      clearTimeout(timeoutFn);
      timeoutFn = setTimeout(activityFn, 5000);
    };

    for (const event of activityEvents) {
      window.addEventListener(event, debounceActivityFn);
    }

    return () => {
      for (const event of activityEvents) {
        window.removeEventListener(event, debounceActivityFn);
      }
    };
  }, []);

  // Create a loop to check sessions every minute
  if (isSignedIn) {
    checkSessionStart();
  }

  return (
    <Switch>
      {Routes.map((route, key) => (
        <Route
          key={key}
          path={route.path}
          exact={route.exact}
          render={(props) => {
            if (route.auth && !getState().auth.isSignedIn) {
              return (
                <Redirect
                  to={{
                    pathname: '/login',
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            }

            if (!route.layout) {
              route.component = withRouter(route.component);

              return <route.component {...props} />;
            }

            route.layout = withRouter(route.layout);

            global.document.title = route.title || route.path;

            return (
              <route.layout {...props}>
                <route.component {...props} />
              </route.layout>
            );
          }}
        />
      ))}
      <Route render={ErrorLayout} />
    </Switch>
  );
};

export default connect(
  (state) => ({
    isSignedIn: state.auth.isSignedIn,
  }),
  authActions
)(App);
