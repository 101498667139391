import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { sideLinks } from './links';

const isProduction = process.env.ZENCARE_ENV === 'production';
const SideNav = (props) => (
  <nav
    className={`w-10xl bg-${
      isProduction ? 'secondary' : 'error'
    } full-m-height scroll-y ml-0 fixed`}
  >
    <ul className='none'>
      {sideLinks(props.scope).map((link, i) => (
        <Link key={i} {...link.link}>
          <li className='nav py-sm'>
            <p className='text-white px-xl pointer m-0'>{link.text}</p>
          </li>
        </Link>
      ))}
    </ul>
  </nav>
);

export default connect((state) => state.auth)(SideNav);
