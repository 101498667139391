import React, { useState } from 'react';
import PhotoSelectForm from './Form';
import { prefixImage } from '#/';
import _ from 'lodash';

export default (props) => {
  const { provider, updateFn, fetchFn } = props;
  const activePhotos = _.get(
    provider,
    'custom_content.images',
    _.get(provider, 'gallery.images', [])
  );
  const selectedPhotos = _.get(provider, 'custom_content.images', []);
  const allGalleryPhotos = _.get(provider, 'gallery.images', []).filter((p) => !p.profile_ar);
  const galleryPhotos = _.uniqBy(selectedPhotos.concat(allGalleryPhotos), 'key');

  const [editingGallery, setEditingGallery] = useState(false);

  const initValues = {
    images: galleryPhotos.map((p, i) => ({
      oldIdx: i,
      checked: !_.isNil(_.find(selectedPhotos, (s) => s.handle === p.handle)),
    })),
  };

  const activeGallery = activePhotos.map((p) => (
    <img
      className='gallery-photo border-primary m-b-xs'
      key={p.handle}
      alt='1'
      src={`${prefixImage(p.key)}?w=400&h=400&fit=crop&crop=faces`}
    ></img>
  ));

  function onSubmitGallery(form) {
    const customImages = form.images.filter((i) => i.checked).map((i) => galleryPhotos[i.oldIdx]);

    updateFn({
      custom_content: {
        images: customImages,
      },
      provider_id: provider.id,
      fetchFn,
    });

    setEditingGallery(false);
  }

  return (
    <div>
      <div>
        <div className='header'>
          <h3 className='m-b-sm'>Custom Content Photos</h3>
          <button
            onClick={() => setEditingGallery(!editingGallery)}
            className={`${editingGallery ? '' : 'primary'}`}
          >
            {editingGallery ? 'Cancel' : 'Edit'}
          </button>
        </div>
        <p className='instruction'>
          These are the photos that actually display on a provider's profile. Click edit to change
          the order of the photos or to add/remove gallery photos to the custom content photos.
        </p>

        {editingGallery ? (
          <PhotoSelectForm
            images={galleryPhotos}
            initialValues={initValues}
            onSubmit={onSubmitGallery}
          />
        ) : (
          <div className='gallery'>{activeGallery}</div>
        )}
      </div>
    </div>
  );
};
