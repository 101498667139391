import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { actions } from '../../sagaSlice';

const OverviewTab = (props) => {
  const { provider, getOverview, overview } = props;

  useEffect(() => {
    getOverview({ id: provider.id });
  }, [getOverview, provider]);

  if (!provider || !provider.custom_content || !overview) {
    return null;
  }

  const OverviewBox = ({ obj, name, children }) => (
    <div
      className='box'
      style={{
        minWidth: '200px',
        maxWidth: '500px',
        flex: '1 1 300px',
        marginRight: '10px',
      }}
    >
      <h5>{name}</h5>
      {Object.keys(obj).map((ok) => (
        <p
          style={{
            marginBottom: '4px',
            marginTop: '4px',
          }}
        >
          <span className={`text-${obj[ok] ? 'primary' : 'secondary'}`}>{ok}</span>
          {obj[ok] && <span className='fas fa-check-circle ml-xs text-primary'></span>}
        </p>
      ))}
      {children && (
        <>
          <hr></hr>
          {children}
        </>
      )}
    </div>
  );

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <OverviewBox obj={overview.onboardingStatus} name='Onboarding Status' />
      <OverviewBox obj={overview.subscriptionStatus} name='Subscription Status'>
        {overview.subscriptionId && (
          <a target='_blank' rel='noopener noreferrer' href={`https://dashboard.stripe.com/${process.env.ZENCARE_ENV === 'production' ? '' : 'test/'}subscriptions/${overview.subscriptionId}`}>
            Click to view subscription on Stripe here
          </a>
        )}
      </OverviewBox>
      <OverviewBox obj={overview.profileContentChecklist} name='Profile Content Checklist' />
    </div>
  );
};

export default connect(
  (state) => ({
    overview: _.get(state, 'providers.overview'),
  }),
  actions
)(OverviewTab);
