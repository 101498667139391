import React from 'react';

import UnregisteredProviders from '../practices/UnregisteredProviders';
import ProviderAssociation from '../practices/ProviderAssociation';
import TagsContainer from './tags';
import LocationsContainer from './locations';
import OverviewContainer from './overview';
import AccountsContainer from './accounts';
import ProfileContainer from './profile';
import ReferralContainer from './referrals';
import ContactContainer from './contact';
import SessionInfo from './sessionInfo';
import AppointmentsContainer from './appointments';
import SeoContainer from '+/seo/View';
import ViewPhotosContainer from '+/photos/View';
import VideoLinksContainer from '../VideoLinks';
import Testimonials from '../Testimonials';
import Publications from '../Publications';
import ClientFeedback from '../ClientFeedback';
import ReimbursifyContainer from './reimbursify';

const TabsContainer = (props) => {
  const renderTestimonials = () => (
    <div>
      <ClientFeedback provider={props.provider} />
      <Testimonials
        testimonialName='professional_testimonials'
        updateFn={props.updateFn}
        custom_content={props.provider.custom_content}
        initialValue={props.provider.custom_content.professional_testimonials}
      />
    </div>
  );

  if (props.auth && props.auth.scope === 'photo-uploader') {
    return {
      photos: () => (
        <div>
          <ViewPhotosContainer {...props} />
          <VideoLinksContainer {...props} />
        </div>
      ),
      locations: () => <LocationsContainer {...props} />,
      seo: () => <SeoContainer seo={props.provider.seo} fetchFn={props.readOne.bind({}, props.provider.id)} />,
    };
  }

  if (props.auth && props.auth.scope === 'license-checker') {
    return {
      locations: () => <LocationsContainer {...props} />,
    };
  }

  return {
    overview: () => <OverviewContainer {...props} />,
    'account Information': () => <AccountsContainer {...props} />,
    profile: () => <ProfileContainer {...props} />,
    referrals: () => <ReferralContainer {...props} />,
    appointments: () => <AppointmentsContainer {...props} />,
    'session Info': () => <SessionInfo {...props} />,
    contact: () => <ContactContainer {...props} />,
    photos: () => (
      <div>
        <ViewPhotosContainer {...props} />
        <VideoLinksContainer {...props} />
      </div>
    ),
    locations: () => <LocationsContainer {...props} />,
    tags: () => <TagsContainer {...props} />,
    seo: () => <SeoContainer seo={props.provider.seo} fetchFn={props.readOne.bind({}, props.provider.id)} />,
    reimbursify: () => <ReimbursifyContainer {...props} />,
    other: () => (
      <>
        {props.provider.type === 'practice' ? (
          <div>
            {renderTestimonials()}
            <div className='grid justify-between'>
              <ProviderAssociation {...props} />
              <UnregisteredProviders {...props} />
            </div>
          </div>
        ) : (
          <div>
            {renderTestimonials()}
            <Publications updateFn={props.updateFn} custom_content={props.provider.custom_content} initialValue={props.provider.custom_content.publications} />
          </div>
        )}
      </>
    ),
  };
};

export default TabsContainer;
