import { crudSlice, noop } from 'saga-slice-helpers';
import { sagaApi, api } from '#/apis';

import history from '#/history';
import { takeLatest, put } from 'redux-saga/effects';
import { paginationReducer } from '#/sagaSlice';

const sagaSliceModule = crudSlice({
  name: 'redirects',
  sagaApi,
  reducers: {
    readAllSuccess: paginationReducer,

    createDone: noop,

    searchRedirects: noop,
    searchRedirectsSuccess: paginationReducer,
    searchRedirectsFail: noop,
  },
  takers: {
    readAll: takeLatest,
  },
  sagas: (A) => ({
    [A.readAll]: {
      *saga({ payload = {} }) {
        const { page = 0, order = 'asc', orderBy = 'from' } = payload;
        window.scrollTo(0, 0);
        yield sagaApi.get(
          `/redirects?page=${page}&order=${order}&orderBy=${orderBy}`,
          A.readAllSuccess,
          A.readAllFail
        );
      },
    },
    [A.createDone]: {
      *saga({ payload }) {
        if (payload.data) {
          history.push('/redirects');
        }

        yield;
      },
    },
    [A.searchRedirects]: {
      *saga({ payload = '' }) {
        try {
          if (payload.length < 1) {
            yield put(A.readAll());
          } else if (payload.length > 0) {
            const { data } = yield api.get(`/redirects/search/${payload}`);
            yield put(A.searchRedirectsSuccess(data));
          }
        } catch (error) {
          yield put(A.searchRedirectsFail(error));
        }
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
