import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import SearchTypeAhead from './SearchTypeAhead';
import { onlyOnEnter } from '#/';

function ViewAssociation(props) {
  const [modal, setModal] = useState(false);
  const {
    title,
    items,
    selected = [],
    searchFn,
    disassociateFn,
    associateFn,
    parentId,
    disableSearchInitCall,
  } = props;

  useEffect(() => {
    if (!disableSearchInitCall) {
      // Calling SearchFn with a empty string triggers a read all
      modal && searchFn('');
    }
  }, [modal, searchFn, disableSearchInitCall]);

  const showModal = () => {
    const selectedIds = selected.map((item) => item.id);
    if (modal) {
      return (
        <Modal title={title} onDismiss={() => setModal(false)}>
          <SearchTypeAhead
            field='name'
            placeholder={`Filter ${title}...`}
            items={items}
            selected={selectedIds}
            searchFn={searchFn}
            onSelect={(item) => associateFn(parentId, item)}
          />
        </Modal>
      );
    }
  };

  const mapItems = () =>
    selected.map((item) => (
      <div className='grid label primary align-center justify-between mr-xs mb-xs' key={item.id}>
        <h5 className='mb-0 mt-0 text-white'>{item.name}</h5>
        <div
          role='menuitem'
          tabIndex={0}
          className='inline-block px-sm'
          onClick={() => disassociateFn(parentId, item.id)}
          onKeyDown={onlyOnEnter(() => disassociateFn(parentId, item.id))}
        >
          <i className='fas fa-times' />
        </div>
      </div>
    ));

  return (
    <div className='border border-grey-lightest radius-xs p-lg mr-sm mb-sm w-40'>
      <div className='mb-lg grid align-center'>
        <h4 className='mt-0 mb-0'>{title}</h4>
        <div
          tabIndex={0}
          role='menuitem'
          className='primary pointer px-md'
          onClick={() => setModal(true)}
          onKeyDown={onlyOnEnter(() => setModal(true))}
        >
          <i className='fas fa-plus' />
        </div>
      </div>

      <div className='grid wrap'>{mapItems()}</div>
      {showModal()}
    </div>
  );
}

export default ViewAssociation;
