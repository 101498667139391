import React from 'react';
import history from '#/history';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import RegionsForm from './Form';

export const AddRegion = (props) => {
  const onCancel = () => {
    history.push('/regions');
  };

  return (
    <div>
      <h2>Add a Region</h2>
      <RegionsForm
        onSubmit={(values) => {
          props.createRegions(values);
        }}
        onCancel={onCancel}
      />
    </div>
  );
};

export default connect(null, { createRegions: actions.create })(AddRegion);
