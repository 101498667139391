import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../sagaSlice';
import { DateTime } from 'luxon';
import Appointment from '+/Appointment';

const timezone = DateTime.local().zoneName || 'America/New_York';

//eslint-disable-next-line
const getName = (a) => (a.first_name ? `${a.first_name} ${a.last_name}` : a.company_name);

export default function () {
  const provider = useSelector((state) => _.get(state, 'providers.current'));
  let appointments = useSelector((state) => _.get(state, 'providers.appointments'));
  const auth = useSelector((state) => state.auth);
  console.log({ auth });
  const dispatch = useDispatch();
  const tz = timezone;
  const [view, setView] = useState('upcoming');
  const [search, setSearch] = useState(null);

  useEffect(() => {
    if (provider && provider.id) {
      dispatch(actions.getAllAppointments({ provider_id }));
    }
  }, [provider, provider.id]);

  if (!provider) {
    return;
  }
  const provider_id = provider.id;
  const providerName = getName(provider);

  if (!appointments) {
    return 'Loading...';
  }

  const dateKey = (datetime) =>
    datetime.setZone(tz).toLocaleString({
      weekday: 'long',
      month: 'long',
      day: '2-digit',
      year: 'numeric',
    });

  const groupByFunction = (a) => dateKey(DateTime.fromISO(a.start_time));

  const searchTerm = search ? search.trim().toLowerCase() : null;
  if (search && search.length) {
    appointments = appointments.filter(
      (a) =>
        _.get(a, 'user.first_name', '').toLowerCase().includes(searchTerm) ||
        _.get(a, 'user.last_name', '').toLowerCase().includes(searchTerm) ||
        _.get(a, 'user.email', '').toLowerCase().includes(searchTerm)
    );
  }

  const now = DateTime.utc().toISO();
  const pastAppointments = _.groupBy(
    _.orderBy(
      appointments.filter((a) => now > a.end_time && !a.canceled),
      'start_time',
      'desc'
    ),
    groupByFunction
  );

  const upcomingAppointments = _.groupBy(
    appointments.filter((a) => now <= a.end_time && !a.canceled),
    groupByFunction
  );

  const canceledAppointments = _.groupBy(
    _.orderBy(
      appointments.filter((a) => a.canceled),
      'start_time',
      'desc'
    ),
    groupByFunction
  );

  const upcomingEntries = Object.keys(upcomingAppointments);
  const upcomingSection = (
    <div>
      {upcomingEntries.map((k) => (
        <div className='appointment-day-group'>
          <div className='flex m-b-md'>
            <h5 className='m-b-0'>{k}</h5>
            {dateKey(DateTime.utc()) === k && (
              <span
                className='text-quaternary m-l-sm'
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 700,
                  letterSpacing: 1,
                  fontSize: '0.8rem',
                  alignSelf: 'end',
                }}
              >
                Today!
              </span>
            )}
            {dateKey(DateTime.utc().plus({ days: 1 })) === k && (
              <span
                className='text-secondary m-l-sm'
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 700,
                  letterSpacing: 1,
                  fontSize: '0.8rem',
                  alignSelf: 'end',
                }}
              >
                Tomorrow
              </span>
            )}
          </div>
          {upcomingAppointments[k].map((a) => (
            <Appointment appointment={a} provider={provider} />
          ))}
        </div>
      ))}
      {upcomingEntries.length === 0 && !searchTerm && (
        <p>{providerName} has no upcoming appointments at this time.</p>
      )}
      {upcomingEntries.length === 0 && searchTerm && (
        <p>
          {providerName} has no upcoming appointments matching the search term "{searchTerm}."
        </p>
      )}
    </div>
  );

  const pastEntries = Object.keys(pastAppointments);
  const pastSection = (
    <div>
      {pastEntries.map((k) => (
        <div className='appointment-day-group'>
          <h5>{k}</h5>
          {pastAppointments[k].map((a) => (
            <Appointment appointment={a} provider={provider} />
          ))}
        </div>
      ))}
      {pastEntries.length === 0 && !searchTerm && (
        <p>{providerName} has no past appointments at this time.</p>
      )}
      {pastEntries.length === 0 && searchTerm && (
        <p>
          {providerName} has no past appointments matching the search term "{searchTerm}."
        </p>
      )}
    </div>
  );

  const canceledEntries = Object.keys(canceledAppointments);
  const canceledSection = (
    <div>
      {canceledEntries.map((k) => (
        <div className='appointment-day-group'>
          <h5>{k}</h5>
          {canceledAppointments[k].map((a) => (
            <Appointment appointment={a} provider={provider} />
          ))}
        </div>
      ))}
      {canceledEntries.length === 0 && !searchTerm && (
        <p>{providerName} has no canceled appointments at this time.</p>
      )}
      {canceledEntries.length === 0 && searchTerm && (
        <p>
          {providerName} has no canceled appointments matching the search term "{searchTerm}."
        </p>
      )}
    </div>
  );

  return (
    <div className='appointment-view-container'>
      <h1 className='m-b-xs'>Appointments</h1>
      <button
        className={`md appointment-tab ${view === 'upcoming' ? 'active' : ''}`}
        onClick={() => setView('upcoming')}
      >
        <h5 className='m-b-0'>Upcoming</h5>
      </button>
      <button
        className={`md appointment-tab ${view === 'past' ? 'active' : ''}`}
        onClick={() => setView('past')}
      >
        <h5 className='m-b-0'>Past</h5>
      </button>
      <button
        className={`md appointment-tab ${view === 'canceled' ? 'active' : ''}`}
        onClick={() => setView('canceled')}
      >
        <h5 className='m-b-0'>Canceled</h5>
      </button>
      <div className='box inner-container flex column'>
        <input
          className='m-b-md'
          type='text'
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Search by client name or email.'
        />
        {view === 'upcoming' && upcomingSection}
        {view === 'past' && pastSection}
        {view === 'canceled' && canceledSection}
      </div>
    </div>
  );
}
