import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from './sagaSlice';
import { parse } from 'papaparse';

function BatchGrantAccessWidget(props) {
  const [selectedOption, setSelectedOption] = useState('');
  const [scopeColumnText, setScopeColumnText] = useState('{ account_id | provider_id | user_id }');

  const handleSelectChange = (event) => {
    if (event.target.value) {
      setScopeColumnText(`${event.target.value}_id`);
    } else {
      setScopeColumnText('{ account_id | provider_id | user_id }');
    }
    setSelectedOption(event.target.value);
  };
  const dispatch = useDispatch();
  const id = 'feature-flag-upload';

  const onSubmit = () => {
    const fileInput = document.getElementById(id);
    if (!fileInput.files[0]) {
      return alert('Hey! Gotta choose a CSV first.');
    }
    if (!selectedOption) {
      return alert('Hey! Gotta choose a scope first.');
    }
    parse(fileInput.files[0], {
      header: true,
      complete: function (results) {
        dispatch(actions.batchEnable({ scope: selectedOption, data: results.data }));
      },
    });
  };

  return (
    <div className='p-sm box m-b-md'>
      <h2>Upload Feature Flag CSV</h2>
      <h4>Directions</h4>
      <p>
        To grant feature access to a select group of accounts, providers, or users, upload a CSV
        according to the guidelines below:
      </p>

      <ul>
        <h5>Important Notes:</h5>
        <li>
          <b>The CSV you upload will be the source of truth for the feature flags you include</b>.
          Uploading a CSV will <b>remove access</b> to the selected feature for any IDs that do not
          appear in the newly uploaded CSV. Make sure to included existing IDs of accounts etc. that
          you'd like to continue to have access to this feature.
        </li>
        <li>
          If a submitted feature flag does not exist, it will be created. To see a list of active
          feature flags, see the dropdown below.
        </li>
        <li>
          You may enable access to multiple feature flags with a single CSV, but only for one
          specific scope. For instance, you can grant one account access to many features with one
          CSV, but in order to grant a provider access to one feature and a user access to another,
          you will need to upload multiple CSVs.
        </li>
        <li>
          Currently there is only backend support for account and provider level feature flags. Support for
          users will be added when needed.
        </li>
      </ul>
      <ol>
        <h5>CSV Columns:</h5>
        <li>
          <b>{scopeColumnText}</b>: Each row in this column represents the id of the account,
          provider, or user you're granting access to this feature. Note that you may only update
          access to a feature using one type of scope at a time.{' '}
        </li>
        <li>
          <b>reserved_name</b>: Each row in this column should correspond to a feature flag. Each
          flag must be written in lowercase and kebab case (
          <code>lowercase-words-separated-by-dashes</code>)
        </li>
      </ol>
      <h5>Example</h5>
      <table>
        <tr>
          <th className='lowercase'>{scopeColumnText}</th>
          <th className='lowercase'>reserved_name</th>
        </tr>
        <tr>
          <td>1234</td>
          <td>example-feature</td>
        </tr>
        <tr>
          <td>5678</td>
          <td>example-feature</td>
        </tr>
        <tr>
          <td>1234</td>
          <td>another-example-feature</td>
        </tr>
        <tr>
          <td>9101</td>
          <td>another-example-feature</td>
        </tr>
      </table>

      <hr />
      <div className='p-b-sm'>
        <label htmlFor='scope-select' className='p-r-sm'>
          Select a scope:
        </label>
        <select
          id='scope-select'
          value={selectedOption}
          onBlur={handleSelectChange}
          onChange={handleSelectChange}
        >
          <option value=''>Choose one</option>
          <option value='user'>Users</option>
          <option value='provider'>Providers</option>
          <option value='account'>Accounts</option>
        </select>
      </div>
      <div className='p-b-sm'>
        <label htmlFor={id} className='p-r-sm'>
          Select a file:
        </label>
        <input id={id} type='file' accept='.csv' className='file-input' />
      </div>
      <button onClick={onSubmit} className='success w-100'>
        Upload CSV
      </button>
    </div>
  );
}

export default BatchGrantAccessWidget;
