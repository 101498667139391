import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Modal from '+/Modal';
import SearchTypeAhead from '+/SearchTypeAhead';
import { connect } from 'react-redux';
import { actions } from '../../../sagaSlice';

export const MoveProviderToExistingAccountModal = ({
  showModal,
  toggleModal,
  getAccountsList,
  accountsList,
  setConfirmMoveModalData,
  toggleConfirmMoveModal,
  providerBeingMoved,
}) => {
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  useEffect(() => {
    getAccountsList();
  }, [getAccountsList]);

  const accountsListWithDisplayName = accountsList
    ? accountsList.map((obj) => ({
        ...obj,
        computedDisplayName: `account id: ${obj.id} name: ${obj.name}`,
      }))
    : [];

  useEffect(() => {
    if (accountsList && accountsList.length && !initialLoadDone) {
      setFilteredAccounts(accountsListWithDisplayName);
      setInitialLoadDone(true);
    }
  }, [accountsList, accountsListWithDisplayName, initialLoadDone]);

  const getProviderName = (a) => (a.first_name ? `${a.first_name} ${a.last_name}` : a.company_name);

  const searchAccounts = (searchTerm) => {
    // If not loaded, don't try to search.
    if (accountsListWithDisplayName) {
      if (searchTerm && searchTerm.length) {
        const result = accountsListWithDisplayName.filter((x) =>
          _.includes(x.computedDisplayName.toUpperCase(), searchTerm.toUpperCase())
        );
        setFilteredAccounts(result);
      } else {
        setFilteredAccounts(accountsListWithDisplayName);
      }
    } else {
      setFilteredAccounts([]);
    }
  };

  if (showModal) {
    return (
      <Modal title='Choose account' onDismiss={toggleModal}>
        {filteredAccounts && (
          <SearchTypeAhead
            field='computedDisplayName'
            placeholder='Search by account name or id...'
            items={filteredAccounts}
            selected={selectedAccountId ? [selectedAccountId] : []}
            searchFn={searchAccounts}
            onSelect={(accountId) => {
              console.log(`Selected account id ${accountId}`);
              setSelectedAccountId(accountId);
              setConfirmMoveModalData({
                accountId,
                message: `Are you sure you want to move provider id ${
                  providerBeingMoved.id
                } (${getProviderName(providerBeingMoved)}) to account id ${accountId} (name: ${
                  accountsList.find((x) => x.id === accountId).name
                })?`,
              });
              toggleModal();
              toggleConfirmMoveModal();
            }}
          />
        )}
      </Modal>
    );
  }

  return null;
};

export default connect(
  (state) => ({
    accountsList: state.providers.accountsList,
  }),
  actions
)(MoveProviderToExistingAccountModal);
