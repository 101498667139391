import React, { useState } from 'react';

const EditableTextArea = (props) => {
  const { initialState, label, name, styles, updateFn, custom_content } = props;
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(initialState);

  const onSubmit = () => {
    const payload = {};
    if (custom_content) {
      payload.custom_content = custom_content;
      payload.custom_content[name] = value;
    } else {
      payload[name] = value;
    }
    updateFn(payload);
    setEdit(false);
  };

  const onCancel = () => {
    setValue(initialState);
    setEdit((e) => !e);
  };

  if (edit) {
    return (
      <div className={`card cursor-default p-lg mx-md my-lg ${styles}`}>
        <div className='grid justify-between align-center '>
          <h4 className='mb-lg'>{label}</h4>
          <div>
            <button className='success p-xs mr-sm' onClick={onSubmit}>
              <i className='fas fa-check' />
            </button>

            <button className='error p-xs' onClick={onCancel}>
              <i className='fas fa-times' />
            </button>
          </div>
        </div>
        <hr />
        <textarea
          className={'w-100'}
          value={value || ''}
          style={{
            resize: 'none',
            height: '70%',
          }}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    );
  }

  return (
    <div className={`card cursor-default p-lg mx-md my-lg ${styles}`}>
      <div className='grid justify-between align-center '>
        <h4 className='mb-lg'>{label}</h4>
        <button className='success p-xs' onClick={() => setEdit((edit) => !edit)}>
          <i className='fas fa-edit' />
        </button>
      </div>
      <hr />
      {value ? (
        <p>{value}</p>
      ) : (
        <div className='grid justify-center'>
          <h5>N/A</h5>
        </div>
      )}
    </div>
  );
};

export default EditableTextArea;
