import React from 'react';
import history from '#/history';
import LoginForm from './LoginForm';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
export const Login = (props) => {
  const { isSignedIn, twoFactorCodeNeeded, login, loginVerifyTwoFactor } = props;

  if (isSignedIn) history.push('/');

  return (
    <>
      <h1>Login</h1>
      <LoginForm
        login={login}
        loginVerifyTwoFactor={loginVerifyTwoFactor}
        twoFactorCodeNeeded={twoFactorCodeNeeded}
      />
    </>
  );
};

export default connect((state) => state.auth, {
  login: actions.login,
  loginVerifyTwoFactor: actions.loginVerifyTwoFactor,
})(Login);
