import React from 'react';

const ToggleStateSwitch = (props) => {
  const { initialState, label, name, updateFn, custom_content } = props;

  const updateChecked = (checked) => {
    const payload = {};
    if (custom_content) {
      payload.custom_content = custom_content;
      payload.custom_content[name] = checked;
    } else {
      payload[name] = checked;
    }

    updateFn(payload);
  };

  return (
    <div className='field'>
      <h5 className='mb-sm'>{label}</h5>
      <div className='mx-md'>
        <input
          className='checkbox'
          type='checkbox'
          id={label}
          checked={initialState}
          onChange={(e) => updateChecked(e.target.checked)}
        />
        <label className='checkbox' htmlFor={label}>
          {label}
        </label>
      </div>
    </div>
  );
};

export default ToggleStateSwitch;
