import rgx from '#/regex';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'You must enter a name';
  }

  if (!values.slug) {
    errors.slug = 'You must enter a slug';
  } else if (!rgx.slug.test(values.slug)) {
    errors.slug =
      'Slug must contain only lowercase letters and must not begin or end with special chars.';
  }

  if (!values.type) {
    errors.type = 'You must Select either City or Neighborhood';
  }

  if (values.type === 'neighborhood' && !values.parent_id) {
    errors.parent_id = 'You must Select a Parent City for a Neighborhood';
  }

  return errors;
};

export default validate;
