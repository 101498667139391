import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../accounts/sagaSlice';
import getHasSpecialRoleUsers from './utils/getHasSpecialRoleUsers';
import specialRoleFriendlyNameList from './specialRoleFriendlyNameList';
import specialRoleFriendlyNameConstants from './specialRoleFriendlyNameConstants';

function RevokeSpecialRolesWidget({ account, provider }) {
  const dispatch = useDispatch();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  const [shouldRevokeClinicianAccess, setShouldRevokeClinicianAccess] = useState(false);

  const { users } = account;
  const filteredUsers = getHasSpecialRoleUsers(users);

  const handleUserChange = (event) => {
    setSelectedUserId(parseInt(event.target.value));
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const onSubmit = () => {
    let confirm = true;
    if (!selectedUserId) {
      return alert('Hey! Gotta choose a user!');
    }

    if (!selectedRole) {
      return alert('Please choose a role.');
    }

    if (shouldRevokeClinicianAccess) {
      confirm = window.confirm(
        'Are you sure? Checking "Revoke Clinician Access" will remove a users ability to log into the Therapist Portal until they are granted clinician access to a single provider! If this user is no longer active on this account, click "OK".'
      );
    }

    if (confirm) {
      dispatch(
        actions.deleteSpecialRole({
          account_id: account.id,
          user_id: selectedUserId,
          friendly_role_name: selectedRole,
          delete_clinician_scope: shouldRevokeClinicianAccess,

          // Only used for refreshing page data if on the provider page
          provider_id: provider ? provider.id : null,
        })
      );
    }
  };

  if (filteredUsers.length === 0) {
    return (
      <>
        <h4>Revoke Access</h4>
        <p>
          <i>There are no Practice Inbox users on this account</i>
        </p>
      </>
    );
  }

  const getAvailableSpecialRoles = () => {
    let result = specialRoleFriendlyNameList;

    if (selectedUserId) {
      const selectedUser = filteredUsers.find((x) => x.id === selectedUserId);

      if (selectedUser && selectedUser.roles) {
        const usersExistingRoleNames = selectedUser.roles.map((x) => x.name);
        result = specialRoleFriendlyNameList.filter((x) => usersExistingRoleNames.includes(x));
      }
    }

    return result;
  };

  return (
    <>
      <h4>Revoke Access</h4>

      <div className='box'>
        <div className='flex align-center justify-between'>
          <label htmlFor='grant-practice-inbox-access' className='w-20'>
            Select a User:
          </label>
          <select className='w-60 m-x-sm' name='grant-practice-inbox-access' id='grant-pracice-inbox-access' value={selectedUserId} onBlur={handleUserChange} onChange={handleUserChange}>
            <option value=''>Select a User</option>
            {filteredUsers.map((user) => (
              <option key={user.id} value={user.id}>
                {user.full_name ? user.full_name : `${user.first_name} ${user.last_name}`} ({user.id})
              </option>
            ))}
          </select>
          <select className='w-60 m-x-sm' value={selectedRole} onBlur={handleRoleChange} onChange={handleRoleChange}>
            <option value=''>Select a Role</option>
            {getAvailableSpecialRoles().map((roleFriendlyName) => (
              <option key={roleFriendlyName} value={roleFriendlyName}>
                {roleFriendlyName}
              </option>
            ))}
          </select>
          {selectedRole === specialRoleFriendlyNameConstants.intakeCoordinator ? (
            <>
              <input name='revoke-clinician-access' value={shouldRevokeClinicianAccess} type='checkbox' onChange={(e) => setShouldRevokeClinicianAccess(e.target.checked)} className='m-r-xs' />
              <label htmlFor='revoke-clinician-access'>Revoke Clinician Access</label>
            </>
          ) : (
            ''
          )}
          <button onClick={onSubmit} className='error  w-20 m-l-xs'>
            Revoke
          </button>
        </div>
        {selectedRole === specialRoleFriendlyNameConstants.intakeCoordinator ? (
          <div>
            <p>
              Check "Revoke Clinician Access" to remove user's ability to switch to any provider's profile on the account. Leave unchecked to remove Practice Inbox access, but still allow users to
              switch amongst profiles.
            </p>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
}

export default RevokeSpecialRolesWidget;
