import _ from 'lodash';
import specialRoleFriendlyNameList from '../specialRoleFriendlyNameList';

const getHasSpecialRoleUsers = (users) => {
  const withOwner = [...specialRoleFriendlyNameList, 'Owner'];

  const filteredUsers = users.filter((user) => {
    const roles = user.roles.map((role) => role.name);

    return _.intersection(roles, withOwner).length > 0;
  });

  return filteredUsers;
};

export default getHasSpecialRoleUsers;
