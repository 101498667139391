import React, { useState, useEffect } from 'react';
import { actions } from '../sagaSlice.js';
import { useDispatch, useSelector } from 'react-redux';

function PracticeManagementBookingLink({ provider }) {
  const dispatch = useDispatch();
  const [url, setUrl] = useState(provider?.practice_management_booking_link?.link_override);
  const isActive = provider?.practice_management_booking_link?.status === 'active';
  const { accounts } = useSelector((state) => state.providers);
  const handleUrlChange = (event) => {
    setUrl(event.target.value);
  };

  const practiceManagementAccount = accounts?.length && accounts[0].practice_management_accounts?.length && accounts[0].practice_management_accounts[0];

  useEffect(() => {
    dispatch(actions.getAccountSummary({ id: provider.id }));
  }, [dispatch, provider.id]);

  const handleToggle = (e) => {
    e.preventDefault();
    let confirm = true;
    if (!provider.practice_management_booking_link) {
      confirm = window.confirm(
        'This provider has never enabled this booking link for themselves. Are you sure you want to toggle this on? This will prevent this provider from using the Zencare Booking Tool. This might also result in unexpected behavior if this provider does not have a paid Zencare Practice Management account with a determined subdomain.'
      );
    }

    if (confirm) {
      dispatch(
        actions.updateProviderPracticeManagementBookingLink({
          provider_id: provider.id,
          link_override: url,
          status: provider.practice_management_booking_link ? (provider.practice_management_booking_link.status === 'active' ? 'inactive' : 'active') : 'active',
        })
      );
    }
  };

  const handleSaveUrl = (e) => {
    e.preventDefault();
    // You might want to handle other logic when save is clicked
    dispatch(
      actions.updateProviderPracticeManagementBookingLink({
        provider_id: provider.id,
        link_override: url,
        status: provider.practice_management_booking_link.status,
      })
    );
  };

  return (
    <div className='box m-b-md'>
      <div className='flex justify-between align-center w-100 m-b-sm'>
        <h3 className='m-t-0 m-b-0'>Practice Management Booking Link</h3>
        <div className='flex align-center'>
          <p className='m-r-sm m-y-0 text-center'>
            <span className='bold'>Status:</span> {isActive ? 'Active' : 'Inactive'}
          </p>
          <button className={`${isActive ? 'error' : 'success'}`} onClick={handleToggle}>
            {isActive ? 'Disable' : 'Enable'}
          </button>
        </div>
      </div>
      <p>
        Enter a URL below if you'd like to override the default booking link that will appear on this provider's profile. If no URL is entered below, their booking link will lead to:{' '}
        <a className='primary' href={`https://aws-portal.zencarepractice.com/${practiceManagementAccount ? practiceManagementAccount.subdomain : 'SUBDOMAIN'}/booking`}>
          https://aws-portal.zencarepractice.com/
          {practiceManagementAccount ? practiceManagementAccount.subdomain : 'SUBDOMAIN'}/booking
        </a>
        .
      </p>
      <input type='text' value={url} onChange={handleUrlChange} placeholder='Enter default URL override' className='w-100 m-b-sm' />
      <div className='flex justify-end'>
        <button className='primary' onClick={handleSaveUrl}>
          Save
        </button>
      </div>
    </div>
  );
}

export default PracticeManagementBookingLink;
