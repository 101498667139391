import React from 'react';

import TopNav from '+/nav/TopNav';
import SideNav from '+/nav/SideNav';
import Footer from '+/nav/Footer.jsx';
import Flashes from '+/flashes/Container';

const DefaultLayout = (props) => (
  <>
    <TopNav />
    <Flashes />
    <div className='grid flex-grow-1'>
      <SideNav />
      <section className='scroll-y min-w-0 flex-1 ml-10xl grid column h-min-100vh'>
        <div className='p-xl flex-1'>{props.children}</div>
        <Footer />
      </section>
    </div>
  </>
);

export default DefaultLayout;
