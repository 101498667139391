const validate = (values) => {
  const errors = {};
  if (!values.from) {
    errors.from = 'You must enter an original URL';
  }
  if (!values.to) {
    errors.to = 'You must enter the new URL';
  }
  if (!values.status_code) {
    errors.status_code = 'You must enter a status code';
  }

  return errors;
};

export default validate;
