import React, { useState } from 'react';
import ProfilePicForm from './ProfilePicForm';
import { prefixImage } from '#/';
import _ from 'lodash';

export default (props) => {
  const { provider, createFn, fetchFn } = props;
  const [editingProfilePic, setEditingProfilePic] = useState(false);

  function onSubmitProfilePic(form) {
    const photo = _.find(provider.gallery.images, (p) => p.handle === form.profile);

    const payload = {
      handle: photo.handle,
      key: photo.key,
      provider_id: provider.id,
    };

    createFn({
      img: payload,
      fetchFn: fetchFn,
    });

    setEditingProfilePic(false);
  }

  return (
    <div>
      <div>
        <div className='header'>
          <h3 className='m-b-sm'>Profile Picture</h3>
          <button
            onClick={() => setEditingProfilePic(!editingProfilePic)}
            className={`${editingProfilePic ? '' : 'primary'}`}
          >
            {editingProfilePic ? 'Cancel' : 'Edit'}
          </button>
        </div>
        <p className='instruction'>
          This is the provider's profile picture. Click edit to select a different picture from the
          provider's profile gallery as a profile picture.
        </p>

        {editingProfilePic ? (
          <ProfilePicForm provider={provider} onSubmit={onSubmitProfilePic} />
        ) : (
          <img
            alt='1'
            src={`${prefixImage(_.get(provider, 'image.key'))}?w=400&h=400&fit=crop&crop=faces`}
          ></img>
        )}
      </div>
    </div>
  );
};
