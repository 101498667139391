/* eslint-disable max-lines-per-function */
import { createModule } from 'saga-slice';
import { failReducer, loadingReducer, noop } from 'saga-slice-helpers';
import { downloadCSV } from '~/utils/csv';

import { sagaApi } from '#/apis';

const sagaSliceModule = createModule({
  name: 'developerTools',
  initialState: {},
  reducers: {
    disableAllTwilioSmsSending: loadingReducer,
    disableAllTwilioSmsSendingSuccess: noop,
    disableAllTwilioSmsSendingFail: failReducer,

    enableTwilioSmsSending: loadingReducer,
    enableTwilioSmsSendingSuccess: noop,
    enableTwilioSmsSendingFail: failReducer,

    runProviderSortOrder: loadingReducer,
    runProviderSortOrderSuccess: noop,
    runProviderSortOrderFail: failReducer,

    runMismatchedSubscriptionsFinder: loadingReducer,
    runMismatchedSubscriptionsFinderSuccess: noop,
    runMismatchedSubscriptionsFinderFail: failReducer,

    runSitemapV3: loadingReducer,
    runSitemapV3Success: noop,
    runSitemapV3Fail: failReducer,

    runRefreshAllCalendarAvailableSlots: loadingReducer,
    runRefreshAllCalendarAvailableSlotsSuccess: noop,
    runRefreshAllCalendarAvailableSlotsFail: failReducer,

    runRefreshComputedFilterOptions: loadingReducer,
    runRefreshComputedFilterOptionsSuccess: noop,
    runRefreshComputedFilterOptionsFail: failReducer,

    clearSearchSuperCacheFromRedis: loadingReducer,
    clearSearchSuperCacheFromRedisSuccess: noop,
    clearSearchSuperCacheFromRedisFail: failReducer,

    clearMostOfZencareSharedCache: loadingReducer,
    clearMostOfZencareSharedCacheSuccess: noop,
    clearMostOfZencareSharedCacheFail: failReducer,

    clearClientRegisteredEmailsFromRedis: loadingReducer,
    clearClientRegisteredEmailsFromRedisSuccess: noop,
    clearClientRegisteredEmailsFromRedisFail: failReducer,

    runKeywordSearchIndexerCronJob: loadingReducer,
    runKeywordSearchIndexerCronJobSuccess: noop,
    runKeywordSearchIndexerCronJobFail: failReducer,

    runKeywordOnlySearch: loadingReducer,
    runKeywordOnlySearchSuccess: noop,
    runKeywordOnlySearchFail: failReducer,

    runInCompleteToReviewingCronJob: loadingReducer,
    runInCompleteToReviewingCronJobSuccess: noop,
    runInCompleteToReviewingCronJobFail: failReducer,

    syncNewReimbursifyInsurancesCronJob: loadingReducer,
    syncNewReimbursifyInsurancesCronJobSuccess: noop,
    syncNewReimbursifyInsurancesCronJobFail: failReducer,

    recomputeAllCustomerioUserAttributes: loadingReducer,
    recomputeAllCustomerioUserAttributesSuccess: noop,
    recomputeAllCustomerioUserAttributesFail: failReducer,

    recomputeAllProviderAttributes: loadingReducer,
    recomputeAllProviderAttributesSuccess: noop,
    recomputeAllProviderAttributesFail: failReducer,

    exportAllIntakes: loadingReducer,
    exportAllIntakesSuccess: (state, payload) => downloadCSV(payload, 'all_referral_data_ADMIN'),
    exportAllIntakesFail: failReducer,

    updateClinicianRoleScope: loadingReducer,
    updateClinicianRoleScopeSuccess: noop,
    updateClinicianRoleScopeFail: failReducer,

    createProviderResponseRateSnapshots: loadingReducer,
    createProviderResponseRateSnapshotsSuccess: noop,
    createProviderResponseRateSnapshotsFail: failReducer,

    generateMessages: loadingReducer,
    generateMessagesSuccess: noop,
    generateMessagesFail: failReducer,

    requestClientFeedback: loadingReducer,
    requestClientFeedbackSuccess: noop,
    requestClientFeedbackFail: failReducer,

    createLiquiditySnapshot: loadingReducer,
    createLiquiditySnapshotSuccess: noop,
    createLiquiditySnapshotFail: failReducer,

    swapPracticeAccounts: loadingReducer,
    swapPracticeAccountsSuccess: noop,
    swapPracticeAccountsFail: failReducer,

    signInViaSSO: loadingReducer,
    signInViaSSOSuccess: noop,
    signInViaSSOFail: failReducer,

    listRedirectsInRedis: loadingReducer,
    listRedirectsInRedisSuccess: noop,
    listRedirectsInRedisFail: failReducer,

    checkForExistingReimbursifyAccount: loadingReducer,
    checkForExistingReimbursifyAccountSuccess: noop,
    checkForExistingReimbursifyAccountFail: failReducer,

    checkClinicBookingPageStatus: loadingReducer,
    checkClinicBookingPageStatusSuccess: noop,
    checkClinicBookingPageStatusFail: failReducer,

    updateBlocklistWithUserIds: loadingReducer,
    updateBlocklistWithUserIdsSuccess: noop,
    updateBlocklistWithUserIdsFail: failReducer,

    openRandomProfiles: loadingReducer,
    openRandomProfilesSuccess: (_, payload) => {
      for (const p of payload) {
        if (p.type === 'individual') {
          // Not all will be therapists, but we can open the page anyway
          window.open(`${process.env.ZENCARE_WEBSITE_URL}/provider/therapist/${p.slug}`);
        } else {
          window.open(`${process.env.ZENCARE_WEBSITE_URL}/practice/${p.slug}`);
        }
      }
    },
    openRandomProfilesFail: failReducer,

    updateSubscriptionsToPayImmediatelyFalse: loadingReducer,
    updateSubscriptionsToPayImmediatelyFalseSuccess: noop,
    updateSubscriptionsToPayImmediatelyFalseFail: failReducer,
    demoteProvider: loadingReducer,
    demoteProviderSuccess: noop,
    demoteProviderFail: failReducer,

    /*sendBookingToolAnnounceEmail: loadingReducer,
    sendBookingToolAnnounceEmailSuccess: noop,
    sendBookingToolAnnounceEmailFail: failReducer,
    sendBookingToolActiveProfileReminderEmail: loadingReducer,
    sendBookingToolActiveProfileReminderEmailSuccess: noop,
    sendBookingToolActiveProfileReminderEmailFail: failReducer,*/
  },
  sagas: (A) => ({
    [A.disableAllTwilioSmsSending]: {
      *saga() {
        yield sagaApi.put('/feature-flags/global/disable-twilio-sms-sending', {}, A.disableAllTwilioSmsSendingSuccess, A.disableAllTwilioSmsSendingFail);
      },
    },
    [A.enableTwilioSmsSending]: {
      *saga() {
        yield sagaApi.delete('/feature-flags/global/disable-twilio-sms-sending', {}, A.enableTwilioSmsSendingSuccess, A.enableTwilioSmsSendingFail);
      },
    },
    [A.runProviderSortOrder]: {
      *saga() {
        yield sagaApi.get('/app/provider-sort-order', A.runProviderSortOrderSuccess, A.runProviderSortOrderFail);
      },
    },
    [A.runMismatchedSubscriptionsFinder]: {
      *saga() {
        yield sagaApi.post('/app/run-mismatched-subscriptions-finder-cron-job', {}, A.runMismatchedSubscriptionsFinderSuccess, A.runMismatchedSubscriptionsFinderFail);
      },
    },
    [A.runSitemapV3]: {
      *saga() {
        yield sagaApi.post('/app/run-sitemap-v3-job', {}, A.runSitemapV3Success, A.runSitemapV3Fail);
      },
    },
    [A.runRefreshAllCalendarAvailableSlots]: {
      *saga() {
        yield sagaApi.post('/app/run-refresh-all-calendar-available-slots', {}, A.runRefreshAllCalendarAvailableSlotsSuccess, A.runRefreshAllCalendarAvailableSlotsFail);
      },
    },
    [A.runRefreshComputedFilterOptions]: {
      *saga() {
        yield sagaApi.post('/app/run-refresh-computed-filter-options-job', {}, A.runRefreshComputedFilterOptionsSuccess, A.runRefreshComputedFilterOptionsFail);
      },
    },
    [A.clearClientRegisteredEmailsFromRedis]: {
      *saga() {
        yield sagaApi.post('/app/clear-client-registered-emails-from-redis', {}, A.clearClientRegisteredEmailsFromRedisSuccess, A.clearClientRegisteredEmailsFromRedisFail);
      },
    },
    [A.clearSearchSuperCacheFromRedis]: {
      *saga() {
        yield sagaApi.post('/developer-tools/clear-search-super-cache-from-redis', {}, A.clearSearchSuperCacheFromRedisSuccess, A.clearSearchSuperCacheFromRedisFail);
      },
    },
    [A.clearMostOfZencareSharedCache]: {
      *saga() {
        yield sagaApi.post('/app/clear-most-of-zencare-shared-cache', {}, A.clearMostOfZencareSharedCacheSuccess, A.clearMostOfZencareSharedCacheFail);
      },
    },
    [A.runKeywordSearchIndexerCronJob]: {
      *saga() {
        yield sagaApi.post('/app/run-keyword-search-indexer-cron-job', {}, A.runKeywordSearchIndexerCronJobSuccess, A.runKeywordSearchIndexerCronJobFail);
      },
    },
    [A.runKeywordOnlySearch]: {
      *saga({ payload }) {
        yield sagaApi.post(`/app/run-keyword-only-search`, payload, A.runKeywordOnlySearchSuccess, A.runKeywordOnlySearchFail);
      },
    },
    [A.runInCompleteToReviewingCronJob]: {
      *saga() {
        yield sagaApi.post('/app/run-incomplete-to-reviewing-cron-job', {}, A.runInCompleteToReviewingCronJobSuccess, A.runInCompleteToReviewingCronJobFail);
      },
    },
    [A.syncNewReimbursifyInsurancesCronJob]: {
      *saga() {
        yield sagaApi.post('/app/sync-new-reimbursify-insurances', {}, A.syncNewReimbursifyInsurancesCronJobSuccess, A.syncNewReimbursifyInsurancesCronJobFail);
      },
    },
    [A.recomputeAllCustomerioUserAttributes]: {
      *saga() {
        yield sagaApi.post('/app/run-recompute-all-customerio-user-attributes-cron-job', {}, A.recomputeAllCustomerioUserAttributesSuccess, A.recomputeAllCustomerioUserAttributesFail);
      },
    },
    [A.recomputeAllProviderAttributes]: {
      *saga() {
        yield sagaApi.post('/app/run-recompute-all-provider-attributes-job', {}, A.recomputeAllProviderAttributesSuccess, A.recomputeAllProviderAttributesFail);
      },
    },
    [A.exportAllIntakes]: {
      *saga() {
        yield sagaApi.get(`/app/export-all-intakes`, A.exportAllIntakesSuccess, A.exportAllIntakesFail);
      },
    },
    [A.updateClinicianRoleScope]: {
      *saga() {
        yield sagaApi.get(`/app/update-clinician-role-scope`, A.updateClinicianRoleScopeSuccess, A.updateClinicianRoleScopeFail);
      },
    },
    [A.createProviderResponseRateSnapshots]: {
      *saga() {
        yield sagaApi.post(`/app/create-provider-response-rate-snapshots`, {}, A.createProviderResponseRateSnapshotsSuccess, A.createProviderResponseRateSnapshotsFail);
      },
    },
    [A.generateMessages]: {
      *saga() {
        yield sagaApi.post(`/app/generate-intakes`, {}, A.generateMessagesSuccess, A.generateMessagesFail);
      },
    },
    [A.createLiquiditySnapshot]: {
      *saga() {
        yield sagaApi.post(`/app/create-liquidity-snapshot`, {}, A.createLiquiditySnapshotSuccess, A.createLiquiditySnapshotFail);
      },
    },
    [A.swapPracticeAccounts]: {
      *saga({ payload }) {
        yield sagaApi.post(`/app/swap-practice-accounts`, payload, A.swapPracticeAccountsSuccess, A.swapPracticeAccountsFail);
      },
    },
    [A.checkForExistingReimbursifyAccount]: {
      *saga({ payload }) {
        const { params } = payload;
        const accountId = params.accountId;
        const userId = params.userId;

        yield sagaApi.get(`/developer-tools/check-if-reimbursify-account-already-exists/${accountId}/${userId}`, A.checkForExistingReimbursifyAccountSuccess, A.checkForExistingReimbursifyAccountFail);
      },
    },
    [A.checkClinicBookingPageStatus]: {
      *saga({ payload }) {
        const { params } = payload;
        const accountId = params.accountId;
        yield sagaApi.get(`/developer-tools/practice-management/clinic-booking-page-status/${accountId}`, A.checkClinicBookingPageStatusSuccess, A.checkClinicBookingPageStatusFail);
      },
    },
    [A.listRedirectsInRedis]: {
      *saga() {
        yield sagaApi.get(`/developer-tools/list-redirects-in-redis`, A.listRedirectsInRedisSuccess, A.listRedirectsInRedisFail);
      },
    },
    [A.updateBlocklistWithUserIds]: {
      *saga() {
        yield sagaApi.post(`/app/update-blocklist-with-user-ids`, {}, A.updateBlocklistWithUserIdsSuccess, A.updateBlocklistWithUserIdsFail);
      },
    },
    [A.signInViaSSO]: {
      *saga({ payload }) {
        const { onSuccess, params } = payload;
        try {
          yield sagaApi.post(
            `/app/reimbursify/sso`,
            { userId: params.userId },
            (res) => {
              A.signInViaSSOSuccess(res);
              onSuccess(res);
            },
            A.signInViaSSOFail
          );
        } catch (error) {
          console.error(error);
        }
      },
    },
    [A.openRandomProfiles]: {
      *saga() {
        try {
          yield sagaApi.get(`/developer-tools/open-random-profiles`, A.openRandomProfilesSuccess, A.openRandomProfilesFail);
        } catch (error) {
          console.error(error);
        }
      },
    },
    [A.updateSubscriptionsToPayImmediatelyFalse]: {
      *saga() {
        try {
          yield sagaApi.get(`/developer-tools/set-subscriptions-to-pay-immediately-false`, A.updateSubscriptionsToPayImmediatelyFalseSuccess, A.updateSubscriptionsToPayImmediatelyFalseFail);
        } catch (error) {
          console.error(error);
        }
      },
    },
    [A.demoteProvider]: {
      *saga() {
        yield sagaApi.post('/app/demote-providers-after-two-weeks-test', {}, A.demoteProviderSuccess, A.demoteProviderFail);
      },
    },

    /*[A.sendBookingToolAnnounceEmail]: function* ({ payload }) {
      yield sagaApi.post(
        `/app/booking-tool-announce-email/send/${payload.template}`,
        {},
        A.sendBookingToolAnnounceEmailSuccess,
        A.sendBookingToolAnnounceEmailFail
      );
    },
    [A.sendBookingToolActiveProfileReminderEmail]: function* ({ payload }) {
      yield sagaApi.post(
        `/app/booking-tool-active-profile-reminder-email/send/${payload.template}`,
        {},
        A.sendBookingToolActiveProfileReminderEmailSuccess,
        A.sendBookingToolActiveProfileReminderEmailFail
      );
    },*/
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
