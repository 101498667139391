import React from 'react';
import DynamicList from '../DynamicList';

const CancellationRequestReceivedButton = ({ cancellationRequestReceived, provider, cancellationRequestReceivedData }) => {
  const handleCancel = () => {
    const shouldSubmit = window.confirm('Are you sure you want to submit this request? This will could potentially pause payments for this provider\'s subscription. Click "OK" to confirm.');
    if (shouldSubmit) {
      cancellationRequestReceived({ provider_id: provider.id });
    }
  };

  return (
    <div className=''>
      <div className='p-l-xs p-t-xs p-b-xs'>
        <button onClick={handleCancel} className='error pill md'>
          Cancellation request received
        </button>
      </div>
      {cancellationRequestReceivedData && (
        <div className='box'>
          <h3>Cancellation Request Details:</h3>
          <DynamicList data={cancellationRequestReceivedData} />
        </div>
      )}
    </div>
  );
};

export default CancellationRequestReceivedButton;
