import DefaultLayout from '##/Default';

import List from './List';
import Add from './Add';
import View from './View';

export default [
  {
    path: '/regions',
    exact: true,
    layout: DefaultLayout,
    component: List,
    title: 'View All Regions',
  },
  {
    path: '/regions/add',
    exact: true,
    layout: DefaultLayout,
    component: Add,
    title: 'Add a new Region',
  },
  {
    path: '/regions/view/:id',
    exact: true,
    layout: DefaultLayout,
    component: View,
    title: 'View One Region',
  },
];
