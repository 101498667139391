import React, { useState } from 'react';
// import { Tooltip } from 'react-tooltip';
import { actions } from './sagaSlice';
import { connect } from 'react-redux';

const UserAccountBlocks = (props) => {
  const { current: user, createUserAccountBlock, deleteUserAccountBlock } = props;
  const [blockedAccountId, setBlockedAccountId] = useState('');
  const accountBlocks = user?.user_account_blocks || [];

  if (!user) {
    return null;
  }

  const handleBlockUserClick = () => {
    if (!blockedAccountId) {
      return;
    }
    const confirmed = window.confirm(`Are you sure you want to block ${user.first_name} ${user.last_name} from contacting account ${Number(blockedAccountId)}?`);
    if (confirmed) {
      createUserAccountBlock({ user_id: user.id, account_id: Number(blockedAccountId) });
    } else {
      alert(`Not blocking ${user.email} from contacting account ${blockedAccountId}`);
    }
  };

  const handleUnblockUserClick = (account_id) => (e) => {
    if (!account_id) {
      return;
    }
    const confirmed = window.confirm(`Are you sure you want to unblock ${user.first_name} ${user.last_name} from contacting account ${account_id}? This action cannot be undone.`);
    if (confirmed) {
      deleteUserAccountBlock({ user_id: user.id, account_id });
    } else {
      alert(`Not unblocking ${user.email} from contacting account ${account_id}`);
    }
  };

  return (
    <div className='box'>
      <h2>
        User Account Blocks
        {/* <i className='fas fa-info-circle m-l-xs' data-tooltip-id='user-tooltip' data-tooltip-content='Block a user from contacting all providers on any account' /> */}
      </h2>
      <p>Block a user from contacting all providers on any account</p>
      <h3>Blocked Accounts</h3>
      <p>This user is blocked from contacting the following accounts</p>
      <table style={{ marginBottom: '2rem' }}>
        <thead>
          <tr>
            <th>Account ID</th>
            <th>Account Name</th>
            <th>Blocked Since</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {accountBlocks.map((block) => (
            <tr key={block.id}>
              <td>{block.account_id}</td>
              <td>{block.account.name}</td>
              <td>{new Date(block.created_at).toLocaleString()}</td>
              <td>
                <button onClick={handleUnblockUserClick(block.account_id)}>Unblock</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h3>Block New Account</h3>
      <p>Block the user from contacting all providers on any account</p>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <input
          style={{ width: '100%', maxWidth: '800px', height: '4rem' }}
          type='number'
          value={blockedAccountId}
          onChange={(e) => setBlockedAccountId(e.target.value)}
          placeholder='Select an account'
        />
        <button style={{ width: '100%', maxWidth: '200px', height: '4rem' }} onClick={handleBlockUserClick}>
          Block User
        </button>
      </div>
      {/* <Tooltip id='user-tooltip' /> */}
    </div>
  );
};

export default connect(
  (state) => ({
    allAccounts: state.accounts,
    ...state.users,
    auth: state.auth,
  }),
  actions
)(UserAccountBlocks);
