import React from 'react';
import { Field, Form } from 'react-final-form';
import Input from '+/forms/Input';
import ToggleSwitch from '+/forms/ToggleSwitch';
import validate from './formValidation';
import RenderStatesDropdown from './components/RenderStatesDropdown';

const renderHoursField = () => {
  const daysOfTheWeek = [
    [0, 'Mon'],
    [1, 'Tue'],
    [2, 'Wed'],
    [3, 'Thur'],
    [4, 'Fri'],
    [5, 'Sat'],
    [6, 'Sun'],
  ];

  return daysOfTheWeek.map(([index, day]) => (
    <Field
      name={`hours[${index}]`}
      component={Input}
      divStyles='w-33'
      label={day}
      index={index}
      key={`hours#${index}`}
    />
  ));
};

const renderAvailabilityDropdown = ({ input, label, meta }) => {
  const className = `field ${meta.error && meta.touched ? 'error' : ''}`;

  return (
    <div className={className}>
      <label>{label}</label>
      <select {...input}>
        <option value=''></option>
        <option value={40}>Accepting new clients</option>
        <option value={20}>Accepting new clients with a 2-3 week wait</option>
        <option value={0}>Not accepting new clients</option>
      </select>
    </div>
  );
};

const LocationForm = (props) => {
  const onSubmit = (formValues) => {
    // Sanitize Payload
    delete formValues.model_name;
    delete formValues.id;
    delete formValues.model_id;
    delete formValues.latitude;
    delete formValues.longitude;
    delete formValues.areas;
    delete formValues.geocode;
    delete formValues.provider;

    if (!formValues.location_name) formValues.location_name = '';
    if (!formValues.building_name) formValues.building_name = '';

    props.onSubmit(formValues);
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={props.initialValues}
      render={({ handleSubmit, invalid, values: { remote_location } }) => (
        <form autoComplete='off' onSubmit={handleSubmit}>
          <h4 className='inline-block'>Address Info</h4>
          <hr className='my-xs' />
          <div className='grid align-center justify-between'>
            <Field
              name='address'
              component={Input}
              disabled={remote_location}
              divStyles='w-50'
              label='Address Line 1'
            />
            <Field
              name='address_2'
              component={Input}
              disabled={remote_location}
              divStyles='w-50'
              label='Address Line 2'
            />
            <Field
              name='remote_location'
              className='toggle-both-labels'
              component={ToggleSwitch}
              label='In-person Practice'
              labelRight='Remote-only Practice'
            />
          </div>
          <div className='grid align-center justify-between'>
            <Field
              name='city'
              component={Input}
              divStyles='w-33'
              label='City'
              disabled={remote_location}
            />
            <Field name='state' component={RenderStatesDropdown} divStyles='w-33' label='State' />
            <Field
              name='zip'
              component={Input}
              divStyles='w-33'
              label='ZIP/Postal Code'
              disabled={remote_location}
            />
          </div>
          <h4 className='inline-block'>Location Info</h4>
          <hr className='my-xs' />
          <Field
            name='location_name'
            component={Input}
            divStyles='w-50 inline-block'
            label='Location Name'
            disabled={remote_location}
          />
          <Field
            name='building_name'
            component={Input}
            divStyles='w-50 inline-block'
            label='Building Name'
            disabled={remote_location}
          />
          <h4 className='my-xs inline-block'>Location Hours</h4>
          <i> (Leave blank if unavailable)</i>
          <hr />
          <div className='grid'>{renderHoursField()}</div>
          <h4 className='my-xs'>Accessability:</h4>
          <hr />
          <div className='grid justify-around'>
            <Field
              name='accepting_patients'
              component={renderAvailabilityDropdown}
              label='Accepting Patients?'
            />
            <Field
              name='display_address'
              component={ToggleSwitch}
              type='checkbox'
              label='Display Address'
            />
            <Field
              name='ada_accessible'
              type='checkbox'
              component={ToggleSwitch}
              label='Ada Accessible'
            />
          </div>
          <h4 className='my-xs'>Availability:</h4>
          <hr />
          <div className='grid justify-around'>
            <Field
              name='morning_availability'
              type='checkbox'
              component={ToggleSwitch}
              label='Morning Availability'
            />
            <Field
              name='daytime_availability'
              type='checkbox'
              component={ToggleSwitch}
              label='Daytime Availability'
            />
            <Field
              name='afternoon_availability'
              type='checkbox'
              component={ToggleSwitch}
              label='Afternoon Availability'
            />
            <Field
              name='evening_availability'
              type='checkbox'
              component={ToggleSwitch}
              label='Evening Availability'
            />
            <Field
              name='weekend_availability'
              type='checkbox'
              component={ToggleSwitch}
              label='Weekend Availability'
            />
          </div>

          <div className='grid justify-between field'>
            <button type='button' onClick={props.onCancel} className='error'>
              Cancel
            </button>

            <button disabled={invalid} className='success'>
              Submit
            </button>
          </div>
        </form>
      )}
    />
  );
};

export default LocationForm;
