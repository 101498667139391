import React from 'react';
import { Field, Form } from 'react-final-form';
import formValidation from './formValidation';
import Input from '+/forms/Input';
import TextArea from '+/forms/TextArea';
import Error from '+/forms/Error';

const BlacklistForm = (props) => {
  const { onSubmit, onCancel, item } = props;

  const renderDropdown = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;

    return (
      <div className={className}>
        <label>{label}</label>
        <select {...input}>
          <option value=''></option>
          <option value='false'>Email</option>
          <option value='true'>Domain</option>
        </select>
        <Error {...meta} />
      </div>
    );
  };

  const renderTypeDropdown = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;

    return (
      <div className={className}>
        <label>{label}</label>
        <select {...input}>
          <option value=''></option>
          <option value='provider'>Provider</option>
          <option value='user'>User</option>
        </select>
        <Error {...meta} />
      </div>
    );
  };

  if (item) {
    item.regex = !!item.regex;
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={item || {}}
      validate={formValidation}
      render={({ handleSubmit, invalid }) => (
        <form autoComplete='off' onSubmit={handleSubmit}>
          <h2>{item ? 'Edit Blocklist Contact' : 'Add a New Contact To Blocklist'}</h2>
          <hr />
          <Field
            name='value'
            component={Input}
            label='Contact to Blocklist'
            placeholder='Ex. masterspammer@junkmail.net'
          />
          <Field name='regex' component={renderDropdown} label='Contact Type' />
          <Field name='type' component={renderTypeDropdown} label='Blocklist Type' />
          <Field name='first_name' component={Input} label='First name' />
          <Field name='last_name' component={Input} label='Last name' />
          <Field name='notes' component={TextArea} label='Notes' />

          <div className='grid justify-between field'>
            <button type='button' onClick={onCancel} className='error'>
              Cancel
            </button>

            <button disabled={invalid} className='success'>
              Submit
            </button>
          </div>
        </form>
      )}
    />
  );
};

export default BlacklistForm;
