import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../sagaSlice';
import { actions as tagActions } from '@/tags/sagaSlice';
import { actions as therapyGroupActions } from '@/therapy-groups/sagaSlice';
import ViewTherapyGroupAssociation from '@/therapy-groups/components/ViewTherapyGroupAssociation';
import ViewTag from '@/tags/components/ViewTagAssociations';
import TagTypes from '@/tags/types';

export const TagsTab = (props) => {
  const {
    provider,
    disassociateProvider,
    associateProvider,
    searchTherapyGroups,
    fetchFn,
    searchTags = {},
  } = props;

  return (
    <div className='grid wrap'>
      {TagTypes.map(([singular, plural]) => (
        <ViewTag
          provider={provider}
          associateFn={associateProvider}
          disassociateFn={disassociateProvider}
          fetchFn={fetchFn}
          searchFn={searchTags[plural]}
          singular={singular}
          plural={plural}
          key={singular}
        />
      ))}

      <ViewTherapyGroupAssociation
        provider={provider}
        associateFn={associateProvider}
        disassociateFn={disassociateProvider}
        searchFn={searchTherapyGroups}
        singular='therapy-group'
        plural='therapy_groups'
        fetchFn={fetchFn}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  const searchTags = TagTypes.reduce(
    (acc, [_singular, plural]) => ({
      ...acc,
      [plural]: (...args) => dispatch(tagActions[plural].searchTags(...args)),
    }),
    {}
  );

  return {
    searchTherapyGroups: (...args) => dispatch(therapyGroupActions.searchTherapyGroups(...args)),
    associateProvider: (...args) => dispatch(actions.associate(...args)),
    disassociateProvider: (...args) => dispatch(actions.disassociate(...args)),
    searchTags,
  };
};

export default connect(null, mapDispatchToProps)(TagsTab);
