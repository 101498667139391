import React, { useState } from 'react';
import { actions } from './sagaSlice';
import { useDispatch, useSelector } from 'react-redux';

const EditCard = ({ post = {}, setIsEditing }) => {
  const dispatch = useDispatch();
  const [inputValues, setInputValues] = useState({
    author: post.author || '',
    description: post.description || '',
    image_alt: post.image_alt || '',
    image_link: post.image_link || '',
    link: post.link || '',
    publish_date: post.publish_date || '',
    title: post.title || '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (post.id) {
      const payload = {
        ...inputValues,
        publish_date: new Date(inputValues.publish_date).toISOString(),
        id: post.id,
        display: !!post.display,
      };
      setIsEditing(false);
      dispatch(actions.updatePost(payload));
    } else {
      const payload = {
        ...inputValues,
        publish_date: new Date(inputValues.publish_date).toISOString(),
      };
      dispatch(actions.createBlogPost(payload));
    }
  };

  const handleCancel = () => {
    if (post.id) {
      setIsEditing(false);
    } else {
      dispatch(actions.toggleIsCreatingBlogPost());
    }
  };

  return (
    <article className='box homepage-blog-post-edit'>
      <form className='homepage-blog-post-inputs' onSubmit={handleSubmit}>
        <label htmlFor='title'>Title:</label>
        <input required placeholder='Blog post title' type='text' id='title' value={inputValues.title} onChange={(e) => setInputValues({ ...inputValues, title: e.target.value })} />
        <label htmlFor='description'>Description:</label>
        <input
          required
          placeholder='Blog post description'
          type='text'
          id='description'
          value={inputValues.description}
          onChange={(e) => setInputValues({ ...inputValues, description: e.target.value })}
        />
        <label htmlFor='author'>Author:</label>
        <input required placeholder='Blog post author' type='text' id='author' value={inputValues.author} onChange={(e) => setInputValues({ ...inputValues, author: e.target.value })} />
        <label htmlFor='image_link'>Image Link:</label>
        <input
          required
          placeholder='Link to primary image'
          type='text'
          id='image_link'
          value={inputValues.image_link}
          onChange={(e) => setInputValues({ ...inputValues, image_link: e.target.value })}
        />
        <label htmlFor='image_alt'>Image Alt:</label>
        <input
          required
          placeholder='Short description of the image'
          type='text'
          id='image_alt'
          value={inputValues.image_alt}
          onChange={(e) => setInputValues({ ...inputValues, image_alt: e.target.value })}
        />
        <label htmlFor='link'>Link:</label>
        <input required placeholder='Blog post link' type='text' id='link' value={inputValues.link} onChange={(e) => setInputValues({ ...inputValues, link: e.target.value })} />
        <label htmlFor='publish_date'>Publish Date:</label>
        <input
          required
          type='date'
          id='publish_date'
          value={inputValues.publish_date ? new Date(inputValues.publish_date).toISOString().split('T')[0] : ''}
          onChange={(e) => setInputValues({ ...inputValues, publish_date: e.target.value })}
        />
        <div className='blog-post-card-buttons'>
          <button onClick={handleCancel} className='error'>
            Cancel changes
          </button>
          <button type='submit' className='success'>
            Save changes
          </button>
        </div>
      </form>
    </article>
  );
};

export default EditCard;
