import { createModule } from 'saga-slice';
import { put, takeEvery } from 'redux-saga/effects';
import {
  noop,
  loadingReducer,
  readOneSuccess,
  deleteSuccess,
  updateSuccess,
  failReducer,
} from 'saga-slice-helpers';

import { api, sagaApi } from '#/apis';

const sagaSliceModule = createModule({
  name: 'locations',
  initialState: {
    isLoading: false,
    data: {},
  },
  reducers: {
    create: loadingReducer,
    read: loadingReducer,
    update: loadingReducer,
    deleteLocation: loadingReducer,

    createSuccess: readOneSuccess,
    readSuccess: (state, payload) => {
      state.data[payload.id] = payload;
    },
    updateSuccess: updateSuccess,
    deleteLocationSuccess: deleteSuccess,

    createFail: failReducer,
    readFail: failReducer,
    updateFail: failReducer,
    deleteLocationFail: failReducer,

    createDone: noop,
    readDone: noop,
    updateDone: noop,
    deleteLocationDone: noop,

    associateLocationToArea: loadingReducer,
    disassociateLocationToArea: loadingReducer,

    associateLocationToAreaFail: failReducer,
    disassociateLocationToAreaFail: failReducer,
  },
  sagas: (A) => ({
    [A.create]: {
      *saga({ payload: { model_name, model_id, payload, fetchFn } }) {
        yield sagaApi.post(
          `/${model_name}/${model_id}/locations`,
          payload,
          A.createSuccess,
          A.createFail,
          A.createDone
        );
        fetchFn && fetchFn();
      },
    },
    [A.read]: {
      *saga({ payload: id }) {
        yield sagaApi.get(`/locations/${id}`, A.readSuccess, A.readFail, A.readDone);
      },
      taker: takeEvery,
    },
    [A.update]: {
      *saga({ payload: { id, changeset } }) {
        yield sagaApi.put(
          `/locations/${id}`,
          changeset,
          A.updateSuccess,
          A.updateFail,
          A.updateDone
        );
      },
    },
    [A.deleteLocation]: {
      *saga({ payload: { id, fetchFn } }) {
        yield sagaApi.delete(
          `/locations/${id}`,
          null,
          A.deleteLocationSuccess,
          A.deleteLocationFail,
          A.deleteLocationDone
        );
        fetchFn && fetchFn();
      },
    },
    [A.associateLocationToArea]: {
      *saga({ payload: { areaId, locationId } }) {
        try {
          yield api.put(`/area/${areaId}/locations/${locationId}`);
          yield put(A.read(locationId));
        } catch (error) {
          put(A.associateLocationToAreaFail(error));
        }
      },
    },
    [A.disassociateLocationToArea]: {
      *saga({ payload: { areaId, locationId } }) {
        try {
          yield api.delete(`/area/${areaId}/locations/${locationId}`);
          yield put(A.read(locationId));
        } catch (error) {
          put(A.associateLocationToAreaFail(error));
        }
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
