import React, { useState, useRef } from 'react';
import TUIEditor from './TUIEditor';
const chars = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];

const EditableCard = (props) => {
  const {
    initialState = '',
    label,
    name,
    updateFn,
    styles,
    height,
    custom_content,
    editable = true,
  } = props;

  const id = [1, 2, 3, 4, 5].map(() => chars[(Math.random() * chars.length) | 0]).join``;
  const [edit, setEdit] = useState(false);
  const value = useRef(initialState);

  const onSubmit = () => {
    const payload = {};
    if (custom_content) {
      payload.custom_content = custom_content;
      payload.custom_content[name] = value.current;
    } else {
      payload[name] = value.current;
    }
    updateFn(payload);
    setEdit(false);
  };

  const onCancel = () => {
    value.current = initialState;
    setEdit(false);
  };

  if (edit) {
    return (
      <div className={`card cursor-default p-lg mx-md my-lg ${styles}`}>
        <div className='grid align-center justify-between'>
          <h3 className='mb-lg'>{label}</h3>
          <div>
            <button className='success p-xs mr-sm' onClick={onSubmit}>
              <i className='fas fa-check' />
            </button>

            <button className='error p-xs' onClick={onCancel}>
              <i className='fas fa-times' />
            </button>
          </div>
        </div>
        <hr />
        <TUIEditor
          id={id}
          value={value.current}
          height={height}
          onChange={(e) => (value.current = e)}
        />
      </div>
    );
  }

  return (
    <div
      className={`card cursor-default p-lg mx-md my-lg ${styles}`}
      style={editable ? {} : { opacity: '0.3' }}
    >
      <div className='grid align-center justify-between'>
        <h3 className='mb-lg'>{label}</h3>
        {editable && (
          <button className='success p-xs cursor-pointer' onClick={() => setEdit(true)}>
            <i className='fas fa-edit' />
          </button>
        )}
      </div>
      <hr />
      {value.current ? (
        <p dangerouslySetInnerHTML={{ __html: value.current }} />
      ) : (
        <div className='grid justify-center'>
          <h5>N/A</h5>
        </div>
      )}
    </div>
  );
};

export default EditableCard;
