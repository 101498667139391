import React from 'react';
import { useDispatch } from 'react-redux';
import { actions } from './sagaSlice';
import { parse } from 'papaparse';

function UploadInsuranceMapping() {
  const dispatch = useDispatch();
  const id = 'reimbursify-insurance-mappings-upload';

  const onSubmit = () => {
    const fileInput = document.getElementById(id);
    if (!fileInput.files[0]) {
      return alert('Hey! Gotta choose a CSV first.');
    }

    parse(fileInput.files[0], {
      header: true,
      complete: function (results) {
        dispatch(actions.uploadMapping({ data: results.data }));
      },
    });
  };

  return (
    <div className='p-sm box m-b-md'>
      <div className='p-b-sm'>
        <label htmlFor={id} className='p-r-sm'>
          Select a file:
        </label>
        <input id={id} type='file' accept='.csv' className='file-input' />
      </div>
      <button onClick={onSubmit} className='success w-100'>
        Upload CSV
      </button>
    </div>
  );
}

export default UploadInsuranceMapping;
