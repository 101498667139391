/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';

import { actions as authActions } from '@/auth/sagaSlice';
import OmniSearchInput from '../../components/omni-search/OmniSearchInput';

const SignedInLinks = (props) => (
  <>
    <li>
      <Link to='/password/update'>Update Password</Link>
    </li>
    <li>
      <Link to='/logout'>Logout</Link>
    </li>
  </>
);

const SignedOutLinks = (props) => (
  <>
    <li>
      <Link to='/login'>Login</Link>
    </li>
  </>
);

const isProduction = process.env.ZENCARE_ENV === 'production';

const TopNav = (props) => (
  <nav id='main'>
    {isProduction ? (
      <section id='branding'>
        <Link className='brand-zencare pointer' to='/' />
      </section>
    ) : (
      <section id='branding'>
        <a className='brand-zencare pointer text-tertiary' href='/'>
          <span
            className='bold text-tertiary'
            style={{
              fontFamily: 'sans-serif',
              fontSize: '20px',
              verticalAlign: 'middle',
              paddingLeft: '1rem',
            }}
          >
            STAGING!!!
          </span>
        </a>
      </section>
    )}

    {props.isSignedIn && <OmniSearchInput />}

    <section id='menu'>
      <ul className='none'>{props.isSignedIn ? <SignedInLinks {...props} /> : <SignedOutLinks {...props} />}</ul>
    </section>
  </nav>
);

export default connect((state) => state.auth, {
  ...authActions,
  push,
})(TopNav);
