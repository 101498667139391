import React, { useState } from 'react';
import { onlyOnEnter } from '#/';

const VettingStatus = (props) => {
  const { vetting_status, updateFn } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => setShowDropdown((showDropdown) => !showDropdown);

  const onSubmit = (v) => {
    updateFn({
      custom_content: {
        vetting_status: v,
      },
    });
    toggleDropdown();
  };

  if (showDropdown) {
    return (
      <div className='text-grey-lighter ml-lg grid justify-between align-center'>
        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
        <select
          value={vetting_status || 'Select Vetting Status'}
          onChange={(evt) => onSubmit(evt.target.value)}
        >
          <option disabled value='Select Vetting Status'>
            Select Vetting Status
          </option>
          {['pre-vetting', 'passed-vetting', 'did-not-pass-vetting', 'blacklisted'].map((v) => (
            <option key={v} value={v}>
              {v}
            </option>
          ))}
        </select>
        <button className='error p-sm ml-md' onClick={() => setShowDropdown(false)}>
          <i className='fas fa-times' />
        </button>
      </div>
    );
  }

  const color = {
    'passed-vetting': 'text-success',
    'did-not-pass-vetting': 'text-warning',
    blacklisted: 'text-error',
  };

  return (
    <div
      className='ml-md'
      onClick={toggleDropdown}
      onKeyDown={onlyOnEnter(toggleDropdown)}
      role='menuitem'
      tabIndex={0}
    >
      <h5 className={color[vetting_status]}>{vetting_status}</h5>
    </div>
  );
};

export default VettingStatus;
