import React from 'react';
import { Field, Form } from 'react-final-form';
import Input from '+/forms/Input';
import TextArea from '+/forms/TextArea';
import Editor from '+/forms/Editor';
import formValidation from './formValidation';

const TGForm = ({ onSubmit, onCancel, initialValue }) => (
  <Form
    onSubmit={onSubmit}
    subscription={{ invalid: true }}
    initialValues={initialValue || {}}
    validate={formValidation}
    render={({ handleSubmit, invalid }) => (
      <>
        <h1>{initialValue ? 'Edit a ' : 'Add a '} Therapy Group</h1>

        <hr />
        <form onSubmit={handleSubmit}>
          <Field name='name' component={Input} label='Enter Name' />
          <Field name='description' id='description' component={Editor} label='Enter Description' />
          <Field
            name='short_description'
            id='short_description'
            component={TextArea}
            label='Enter Short Description'
          />
          <Field name='hours' component={Input} label='Enter Hours' />
          <Field name='costs' component={Input} label='Enter Costs' />
          <Field name='slug' component={Input} label='slug' />

          <div className='grid justify-between field'>
            <button type='button' onClick={onCancel} className='error'>
              Cancel
            </button>

            <button disabled={invalid} className='success'>
              Submit
            </button>
          </div>
        </form>
      </>
    )}
  />
);

export default TGForm;
