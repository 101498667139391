import React from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../../sagaSlice';

const ExternalCalendarCard = ({ calendar }) => {
  const dispatch = useDispatch();

  const unlinkCalendar = (
    /** @type {ProviderExternalCalendar} */
    providerExternalCalendar
  ) => {
    if (window.confirm('Are you sure you want to unlink this calendar?')) {
      dispatch(actions.deleteProviderExternalCalendar({ provider_external_calendar_id: providerExternalCalendar.id, provider_id: providerExternalCalendar.provider_id }));
    }
  };

  return (
    <div className='card'>
      <div className='card-header'>
        <h2>{calendar.name}</h2>
      </div>
      <div className='card-body'>
        <p>
          <strong>Provider ID:</strong> {calendar.provider_id}
        </p>
        <p>
          <strong>External Source:</strong> {calendar.external_source_system}
        </p>
        <p>
          <strong>External Identifier:</strong> {calendar.external_identifier}
        </p>
        <p>
          <strong>Creator:</strong> {calendar.creator_user_email}
        </p>
        <p>
          <strong>Created At:</strong> {new Date(calendar.created_at).toLocaleString()}
        </p>

        <p>
          Note: If you unlink a calendar, only provider will be able to re-link it.
          <button
            onClick={() => {
              unlinkCalendar(calendar);
            }}
          >
            Unlink this calendar
          </button>
        </p>
      </div>
    </div>
  );
};

export default ExternalCalendarCard;
