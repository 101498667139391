import React from 'react';
import history from '#/history';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import UserForm from './Form';

export const AddRedirect = (props) => {
  const onCancel = () => {
    history.push('/redirects');
  };

  return <UserForm onCancel={onCancel} onSubmit={(values) => props.createRedirect(values)} />;
};

export default connect(null, { createRedirect: actions.create })(AddRedirect);
