import React from 'react';
import { onlyOnEnter, prefixImage } from '#/';

const Image = (props) => {
  const { image, onImageDelete, index } = props;

  return (
    <div
      className='relative w-10rem h-10rem select-none my-lg mr-xl'
      key={image.key}
      handle={index}
    >
      <img className='w-100 h-100 cover' src={prefixImage(image.key)} alt={`gallery${image.id}`} />
      <div
        style={{
          top: -20,
          right: -20,
        }}
        tabIndex={0}
        index={index}
        role='menuitem'
        name={image.id}
        onClick={() => onImageDelete(index)}
        onKeyDown={onlyOnEnter(() => onImageDelete(index))}
        className='absolute pin-top pin-right border-thick p-xs rounded-full border-error text-error pointer'
      >
        <div className='w-1rem h-1rem grid justify-center align-center text-error' name={image.id}>
          &#x78;
        </div>
      </div>
    </div>
  );
};

export default Image;
