import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../tags/sagaSlice';
import { onlyOnEnter } from '#/';

export const PrimaryProfession = (props) => {
  const { professions, primary_profession, primary_profession_id, updateFn, readAll } = props;
  const [showDropdown, setShowDropdown] = useState(false);

  const assertMsg1 =
    "Are you sure you want to change this provider's primary profession? This cannot be undone and will change the provider's URL.";
  const assertMsg2 = 'Are you really really sure?';

  useEffect(() => {
    if (!Object.values(professions).length) {
      readAll();
    }
  }, [professions, readAll]);

  const toggleDropdown = () => setShowDropdown((showDropdown) => !showDropdown);

  const handleProfessionChange = ({ target: { value } }) => {
    const assertOne = () => window.confirm(assertMsg1);
    const assertTwo = () => window.confirm(assertMsg2);

    if (assertOne() && assertTwo()) {
      updateFn({ primary_profession_id: value });
      setShowDropdown(!showDropdown);
    }
  };

  if (showDropdown) {
    const professionsAsArray = Object.values(professions);

    return (
      <div className='text-grey-lighter ml-lg grid justify-between align-center'>
        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
        <select
          value={primary_profession_id || 'Select Primary Profession'}
          onChange={handleProfessionChange}
        >
          <option disabled value='Select Primary Profession'>
            Select Primary Profession
          </option>
          {professionsAsArray.map((profession) => (
            <option key={profession.id} value={profession.id}>
              {profession.name}
            </option>
          ))}
        </select>
        <button
          className='error p-sm ml-md'
          onClick={() => setShowDropdown((showDropdown) => !showDropdown)}
        >
          <i className='fas fa-times' />
        </button>
      </div>
    );
  }

  return (
    <div
      onClick={toggleDropdown}
      onKeyDown={onlyOnEnter(toggleDropdown)}
      role='menuitem'
      tabIndex={0}
    >
      <h3 className='text-grey-lighter ml-lg'>
        {(primary_profession && primary_profession.name) || 'Select Primary Profession'}
      </h3>
    </div>
  );
};

export default connect(
  (state) => ({ professions: (state.professions && state.professions.data) || {} }),
  actions.professions
)(PrimaryProfession);
