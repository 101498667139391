import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import { actions as areasActions } from '@/areas/sagaSlice';
import ViewAssociation from '+/ViewAssociation';
import SeoContainer from '+/seo/View';
import Remove from '+/RemoveButton';
import RegionForm from './Form';
import diff from 'object-diff';

export const RegionView = (props) => {
  const {
    readOne,
    searchAreas,
    update,
    delete: deleteRegion,
    addRegion,
    removeRegion,
    region,
    areas,
    match = { params: {} },
  } = props;

  const { id } = match.params;

  const [edit, setEdit] = useState(props.edit || false);

  const fetchRegion = useCallback(() => readOne(id), [id, readOne]);

  useEffect(() => {
    fetchRegion();
  }, [fetchRegion]);

  const onSubmit = async (values) => {
    await update({
      id: region.id,
      changeset: diff(region, values),
    });
    setEdit(() => false);
  };

  if (!region) {
    return <p>Loading...</p>;
  }

  if (edit) {
    return (
      <RegionForm initialValues={region} onCancel={() => setEdit(false)} onSubmit={onSubmit} />
    );
  }

  return (
    <div>
      <div className='grid justify-between align-center'>
        <div className='grid align-center'>
          <h1 className='mr-lg'>{region.name}</h1>
        </div>
        <div>
          <button className='success mr-sm' onClick={() => setEdit(true)}>
            <i className='fas fa-edit' />
          </button>
          <Remove className='error' name={region.name} onClick={() => deleteRegion(region.id)} />
        </div>
      </div>

      <hr className='my-md' />

      <SeoContainer seo={region.seo} fetchFn={fetchRegion} />

      <hr className='my-xl' />

      <ViewAssociation
        title='Areas'
        items={areas}
        searchFn={searchAreas}
        selected={region.areas}
        parentId={region.id}
        disassociateFn={(region, area) =>
          removeRegion({
            area,
            region,
          })
        }
        associateFn={(region, area) =>
          addRegion({
            area,
            region,
          })
        }
      />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.match.params;

  const region = state.regions.data[id];

  return {
    region,
    areas: Object.values(state.areas.data),
  };
};

export default connect(mapStateToProps, {
  ...actions,
  searchAreas: areasActions.searchAreas,
})(RegionView);
