const formValidation = (values) => {
  const errors = {};
  if (!values.remote_location) {
    if (!values.address) errors.address = 'Address required.';
    if (!values.city) errors.city = 'City required.';
    if (!values.zip) errors.zip = 'Zip required.';
  }

  if (!values.state) errors.state = 'State required.';

  return errors;
};
export default formValidation;
