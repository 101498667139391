import React from 'react';
import DefaultLayout from '##/Default';
import { Redirect } from 'react-router-dom';
import List from './List';
import Add from './Add';
import View from './View';
import ProviderBatchUpdate from './ProviderBatchUpdate';

export default [
  {
    path: '/providers/:type',
    exact: true,
    layout: DefaultLayout,
    component: List,
    title: 'View All Providers',
  },
  {
    path: '/providers/:type/add',
    exact: true,
    layout: DefaultLayout,
    component: Add,
    title: 'Add a New Provider',
  },
  {
    path: '/providers/view/:id',
    exact: true,
    layout: DefaultLayout,
    component: View,
    title: 'View One Provider',
  },
  {
    path: '/providers/view/:id/:tab',
    exact: true,
    layout: DefaultLayout,
    component: View,
    title: 'View One Provider',
  },
  {
    path: '/providers',
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to='providers/individual' />,
  },
  {
    path: '/practices',
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to='providers/practice' />,
  },
  {
    path: '/promotions',
    exact: true,
    layout: DefaultLayout,
    component: ProviderBatchUpdate,
    title: 'Update Provider Promotions',
  },
];
