import { createModule } from 'saga-slice';
import { failReducer, loadingReducer, noop } from 'saga-slice-helpers';
import { downloadCSV } from '~/utils/csv';

import { sagaApi } from '#/apis';

const sagaSliceModule = createModule({
  name: 'reports',
  initialState: {},
  reducers: {
    exportProviderBounceDataAsCsv: loadingReducer,
    exportProviderBounceDataAsCsvSuccess: (state, payload) => {
      const d = new Date();
      const month = `${d.getMonth() + 1}`;
      const day = `${d.getDate()}`;
      const year = d.getFullYear();

      return downloadCSV(payload, `zencare-provider-email-bounce-report-${year}-${month}-${day}`);
    },
    exportProviderBounceDataAsCsvFail: failReducer,
  },
  sagas: (A) => ({
    [A.exportProviderBounceDataAsCsv]: {
      *saga() {
        yield sagaApi.get(
          `/reports/export-bounces/providers`,
          A.exportProviderBounceDataAsCsvSuccess,
          A.exportProviderBounceDataAsCsvFail
        );
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
