import React from 'react';

const RenderGhostLinks = (props) => {
  const { tag, type } = props;
  //type = "contentPage" || "listingPosts"
  const links = tag[type];

  return (links || []).map(({ id, slug }) => (
    <div key={id}>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={`${process.env.ZENCARE_CONTENT_URL}/ghost/#/editor/${id}`}
      >
        {slug}
      </a>
    </div>
  ));
};

export default RenderGhostLinks;
