import React from 'react';
import { capitalize } from 'voca';

const RenderListingLinks = (props) => {
  const { plural, tag } = props;
  const regions = [
    {
      name: 'NYC',
      region: 'nyc',
    },
    {
      name: 'Boston',
      region: 'boston-area',
    },
    {
      name: 'Connecticut',
      region: 'connecticut',
    },
    {
      name: 'Rhode Island',
      region: 'rhode-island',
    },
  ];

  let slug;
  switch (plural) {
    case 'approaches':
      slug = 'therapy-type';
      break;
    case 'professions':
      slug = 'provider-type';
      break;
    case 'insurances':
      slug = 'health-insurance';
      break;
    default:
      slug = plural;
      break;
  }

  return (regions || []).map(({ region, name }, i) => (
    <div key={name + i}>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href={`${process.env.ZENCARE_BLOG_URL}/${slug}/${tag.slug}/therapist/${region}`}
      >
        Find {capitalize(plural)} Therapists in {name}
      </a>
    </div>
  ));
};

export default RenderListingLinks;
