import React, { useState, useEffect, useCallback } from 'react';
import utils from '#/';

const CustomSession = (props) => {
  const { initialState, updateFn, custom_content, deleteNewSession, index = 0 } = props;

  const [edit, setEdit] = useState(initialState.isNew);
  const [name, setName] = useState(initialState.name);
  const [fee, setFee] = useState(initialState.fee);
  const [length, setLength] = useState(initialState.length);

  const resetState = useCallback(() => {
    setName(initialState.name);
    setFee(initialState.fee);
    setLength(initialState.length);
  }, [initialState.fee, initialState.length, initialState.name]);

  useEffect(resetState, [initialState, resetState]);

  const onSubmit = () => {
    const payload = { custom_content };
    payload.custom_content.custom_sessions[index] = {
      name,
      fee,
      length,
    };
    updateFn(payload);
    setEdit(false);
  };

  const onDelete = () => {
    const success = window.confirm('Are you sure?');
    if (success) {
      const payload = { custom_content };
      payload.custom_content.custom_sessions.splice(index, 1);
      updateFn(payload);
    }
  };

  const onCancel = () => {
    resetState();
    setEdit(false);
    initialState.isNew && deleteNewSession();
  };

  if (edit) {
    return (
      <div
        className={`${utils.tableClass(index)} grid align-center justify-around w-100 field py-xs`}
      >
        <div className={'w-25'}>
          <input
            type='text'
            className='my-sm'
            value={name}
            placeholder='Ex. Walk In Session'
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className='w-25'>
          <input
            type='number'
            placeholder='Ex. $100.00'
            value={fee}
            onChange={(e) => setFee(e.target.value)}
          />
        </div>
        <div className='w-25'>
          <input
            type='text'
            value={length}
            placeholder='Ex. 30 Minutes'
            onChange={(e) => setLength(e.target.value)}
          />
        </div>
        <div className='w-10'>
          <button className='success p-xs mr-md' onClick={onSubmit}>
            <i className='fas fa-check' />
          </button>
          <button className='error p-xs' onClick={onCancel}>
            <i className='fas fa-times' />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`${utils.tableClass(index)} grid align-center justify-around w-100 field py-xs`}
    >
      <div className='w-25'>
        <p className='m-0'>{name}</p>
      </div>
      <div className='w-25'>
        <p className='m-0'>{fee}</p>
      </div>
      <div className='w-25'>
        <p className='m-0'>{length}</p>
      </div>
      <div className='w-10'>
        <button className='success p-xs mr-md' onClick={() => setEdit(true)}>
          <i className='fas fa-edit' />
        </button>
        <button className='error p-xs' onClick={onDelete}>
          <i className='fas fa-trash' />
        </button>
      </div>
    </div>
  );
};

export default CustomSession;
