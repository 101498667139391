import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import Column from '~/shared/Column';
import useOrderBy from '+/hooks/useOrderBy';
import Pagination from '+/Pagination';
import { tableClass, debounce } from '#';

export const List = (props) => {
  const { areas, total, readAll, searchAreas } = props;

  const { order, orderBy, ordered: orderedAreas, handleColumnChange } = useOrderBy(areas, 'name');

  useEffect(() => {
    readAll({
      order,
      orderBy,
    });
  }, [order, orderBy, readAll]);

  const displayName = (area) => {
    if (area.regions && area.regions.length > 0) {
      const regions = area.regions.map((region) => region.name).join(', ');

      return `${area.name} (${regions})`;
    }

    return `${area.name}`;
  };

  const handleSearch = ({ target = { value: '' } }) => debounce(() => searchAreas(target.value));

  return (
    <>
      <div className='grid align-center justify-between'>
        <h1 className='inline-block'>Areas</h1>

        <Link to={'/areas/add'} className='button primary' title='Add New Region'>
          <i className='fas fa-plus' />
        </Link>
      </div>

      <div className='border border-grey-lightest radius-xs scroll'>
        <input
          type='text'
          className='block w-100'
          placeholder='Filter areas...'
          onKeyUp={handleSearch}
        />
        <div className='bg-grey-dark text-white grid'>
          <div className='p-md w-10'>
            <b className='mr-sm'>Id</b>
          </div>
          <Column title='Name' field='name' width='30' onChange={handleColumnChange} />
          <Column title='Slug' field='slug' width='30' onChange={handleColumnChange} />
          <Column title='Type' field='type' width='30' onChange={handleColumnChange} />
        </div>

        {orderedAreas.map((area, i) => (
          <div className={`${tableClass(i)} grid align-center areas`} key={area.id}>
            <div className='p-md w-10'>{area.id}</div>

            <div className='p-md w-30'>
              <Link to={`/areas/view/${area.id}`}>{displayName(area)}</Link>
            </div>

            <div className='p-md w-30'>{area.slug}</div>

            <div className='p-md w-30'>{area.type}</div>
          </div>
        ))}
      </div>
      <Pagination total={total} fetchFn={(page) => readAll({ page })} />
    </>
  );
};

const mapStateToProps = (state) => ({
  areas: Object.values(state.areas.data || {}),
  total: state.areas.total,
});

export default connect(mapStateToProps, actions)(List);
