import { crudSlice, noop } from 'saga-slice-helpers';
import { put } from 'redux-saga/effects';
import history from '#/history';
import { api, sagaApi } from '#/apis';
import { paginationReducer } from '#/sagaSlice';

const sagaSliceModule = crudSlice({
  name: 'areas',
  sagaApi,
  initialState: {
    isLoading: false,
    data: {},
    current: {},
    error: null,
  },
  reducers: {
    readAllSuccess: paginationReducer,

    addRegion: noop,
    addRegionFail: noop,

    removeRegion: noop,
    removeRegionFail: noop,

    addParent: noop,
    addParentFail: noop,

    removeParent: noop,
    removeParentFail: noop,

    searchAreas: noop,
    searchAreasSuccess: paginationReducer,
    searchAreasFail: noop,

    readEvery: noop,
    readEverySuccess: (state, payload) => {
      state.data = payload;
    },
    readEveryFail: noop,
    deleteSuccess: noop,
  },
  sagas: (A) => ({
    [A.readAll]: {
      *saga({ payload = {} }) {
        const { page = 0, order = 'asc', orderBy = 'name' } = payload;
        window.scrollTo(0, 0);
        yield sagaApi.get(
          `/areas?page=${page}&order=${order}&orderBy=${orderBy}`,
          A.readAllSuccess,
          A.readAllFail
        );
      },
    },
    [A.readEvery]: {
      *saga() {
        yield sagaApi.get('/areas/all', A.readEverySuccess, A.readEveryFail);
      },
    },
    [A.addRegion]: {
      *saga({ payload }) {
        try {
          const { area, region } = payload;
          yield api.put(`/area/${area}/region/${region}`);
          yield put(A.readOne(area));
        } catch (error) {
          put(A.addRegionFail(error));
        }
      },
    },
    [A.removeRegion]: {
      *saga({ payload }) {
        try {
          const { area, region } = payload;
          yield api.delete(`/area/${area}/region/${region}`);
          yield put(A.readOne(area));
        } catch (error) {
          put(A.addRegionFail(error));
        }
      },
    },
    [A.updateSuccess]: {
      *saga({ payload }) {
        yield put(A.readOne(payload.id));
      },
    },
    [A.addParent]: {
      *saga({ payload }) {
        try {
          const { child, parent } = payload;
          yield api.put(`/area/${parent}/neighborhood/${child}`);
          yield put(A.readOne(parent));
        } catch (error) {
          put(A.addParentFail(error));
        }
      },
    },
    [A.removeParent]: {
      *saga({ payload }) {
        try {
          const { child, parent } = payload;
          yield api.delete(`/area/${parent}/neighborhood/${child}`);
          yield put(A.readOne(parent));
        } catch (error) {
          put(A.addParentFail(error));
        }
      },
    },
    [A.searchAreas]: {
      *saga({ payload }) {
        try {
          if (payload.length < 1) {
            yield put(A.readAll());
          } else if (payload.length > 0) {
            const { data } = yield api.get(`/areas/search/${payload}`);
            yield put(A.searchAreasSuccess(data));
          }
        } catch (error) {
          yield put(A.searchAreasFail(error));
        }
      },
    },
    [A.createSuccess]: {
      *saga({ payload }) {
        yield history.push(`/areas/view/${payload.id}`);
      },
    },
    [A.readOneSuccess]: {
      *saga({ payload: area }) {
        yield put(A.setCurrent(area.id));
      },
    },
    [A.deleteSuccess]: {
      *saga() {
        yield history.push('/areas');
      },
    },
  }),
});

export const { actions } = sagaSliceModule;

export default sagaSliceModule;
