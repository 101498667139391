import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './sagaSlice';

function CreateWidget(props) {
  const [reservedName, setReservedName] = useState('');
  const [description, setDescription] = useState('');

  const inputId = 'feature-flag-reserved-name';
  const textAreaId = 'feature-flag-description';
  const dispatch = useDispatch();

  const onSubmit = () => {
    if (!reservedName) {
      return alert('Hey! Enter a reserved name!');
    }
    dispatch(actions.createFeatureFlag({ reserved_name: reservedName, description }));
  };

  return (
    <div className='p-sm box m-b-md'>
      <h2>Create Feature Flags </h2>
      <h4>Directions</h4>
      <p>
        Create a feature flag to start granting Accounts, Users, or Providers exclusive access to
        beta features. We'll use this feature flag in the codebase to conditionally show or hide
        features we want only a select group of people to access. Once the feature flag is create,
        access can be granted by CSV upload above, or at the individual Account, User, or Provider
        level. Get started by creating a "reserved name" and writing an optional description for the
        feature flag below. A feature flag should be all lowercase with words separated by dashes,
        or <code className='p-x-xs'>something-like-this</code>. This value is what we'll use in the
        codebase so the more typeable, the better. Think{' '}
        <code className='p-x-xs'>private-pay-booster</code> as opposed to{' '}
        <code className='p-x-xs'>reimbursify-phenomenon</code>.
      </p>
      <hr />
      <div className='flex p-b-sm'>
        <label htmlFor={inputId} className='p-r-sm w-40 p-t-xs hide'>
          Reserved name:
        </label>
        <input
          placeholder='Reserved name'
          type='text'
          id={inputId}
          className='w-100'
          onChange={(e) => setReservedName(e.target.value)}
        />
      </div>

      <div className='flex p-b-sm'>
        <label htmlFor={textAreaId} className='p-r-sm w-40 p-t-xs hide'>
          Description:
        </label>
        <textarea
          value={description}
          placeholder='Description (optional)'
          onChange={(e) => setDescription(e.target.value)}
          id={textAreaId}
          className='w-100'
          style={{
            height: '7rem',
            resize: 'none',
          }}
        />
      </div>
      <button onClick={onSubmit} className='success w-100'>
        Create feature flag{' '}
      </button>
    </div>
  );
}

export default CreateWidget;
