import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { actions } from '@/areas/sagaSlice';
import Modal from '+/Modal';
import SearchTypeAhead from '+/SearchTypeAhead';
import { onlyOnEnter } from '#/';

export const AreaAssociations = (props) => {
  const [modal, setModal] = useState(false);
  const {
    searchAreas,
    locationId,
    allAreas,
    defaultAreas = [],
    associateLocationToArea,
    disassociateLocationToArea,
  } = props;

  const searchFn = useCallback((name) => searchAreas(name), [searchAreas]);

  useEffect(() => {
    // Calling SearchFn with a empty string triggers a read all
    modal && searchFn('');
  }, [modal, searchFn]);

  const showModal = () => setModal((e) => !e);

  const displayAreaName = (area) => {
    if (area && area.regions && area.regions.length > 0) {
      const regions = area.regions.map((region) => region.name).join(', ');

      return `${area.name} (${regions})`;
    }

    return `${area.name}`;
  };

  const renderAreasModal = () => {
    if (modal) {
      return (
        <Modal title='Areas' onDismiss={showModal}>
          <SearchTypeAhead
            field={displayAreaName}
            placeholder='Filter Areas...'
            items={allAreas}
            searchFn={searchFn}
            selected={defaultAreas.map((i) => i.id)}
            onSelect={(area) =>
              associateLocationToArea({
                locationId,
                areaId: area,
              })
            }
          />
        </Modal>
      );
    }
  };

  return (
    <>
      <div className='mb-lg grid align-center'>
        <h3 className='mb-0'>Areas:</h3>
        <div
          tabIndex={0}
          role='menuitem'
          onClick={showModal}
          className='primary pointer p-md'
          onKeyDown={onlyOnEnter(showModal)}
        >
          <i className='fas fa-plus' />
        </div>
      </div>

      <div className='grid wrap'>
        {defaultAreas.map((area) => {
          const disassociateFn = () =>
            disassociateLocationToArea({
              locationId: locationId,
              areaId: area.id,
            });

          return (
            <div className='grid label primary align-center justify-between mr-md' key={area.id}>
              <h5 className='mb-0 mt-0 text-white'>{displayAreaName(area)}</h5>
              <div
                tabIndex={0}
                role='menuitem'
                onClick={disassociateFn}
                className='inline-block px-md pointer'
                onKeyDown={onlyOnEnter(disassociateFn)}
              >
                <i className='fas fa-times' />
              </div>
            </div>
          );
        })}
        {renderAreasModal()}
      </div>
    </>
  );
};

export default connect((state) => ({ allAreas: Object.values(state.areas.data) }), {
  searchAreas: actions.searchAreas,
})(AreaAssociations);
