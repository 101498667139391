import React from 'react';
import history from '#/history';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import ProviderForm from './Form';

export const AddProvider = (props) => {
  const { match = { params: { type: 'individual' } } } = props;
  const { type } = match.params;
  const onCancel = () => history.push('/providers');

  const onSubmit = (values) => {
    values.status = 'incomplete';
    props.createProvider({
      values,
      type,
    });
  };

  return (
    <div>
      <h1>Add a {type === 'individual' ? 'Provider' : 'Practice'} </h1>
      <hr />
      <ProviderForm type={type} onSubmit={onSubmit} onCancel={onCancel} />
    </div>
  );
};

export default connect(null, { createProvider: actions.create })(AddProvider);
