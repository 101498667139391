import React, { useState } from 'react';

import { onlyOnEnter } from '#/';

function Accordion({ text, /*isOpen, toggleMethod,*/ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = (e) => {
    // Prevent the event from bubbling up
    e.stopPropagation();

    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className='accordion'>
        <div
          className='toggle-text-and-icon'
          role='button'
          tabIndex={0}
          onKeyDown={onlyOnEnter((e) => toggle(e))}
          onClick={(e) => {
            toggle(e);
          }}
        >
          <i className={`toggle fas fa-fw fa-chevron-down ${isOpen ? 'active' : ''}`}></i> {text}
        </div>

        <div className={`accordion-content ${isOpen ? 'active' : ''}`}>{children}</div>
      </div>
    </>
  );
}

export default Accordion;
