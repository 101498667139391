import React from 'react';
import { capitalize } from 'voca';

import dayjs from 'dayjs';

export default (props) => (
  <div className={`alert ${props.type} w-100`}>
    <strong>{capitalize(props.type)}!</strong>
    <small>{dayjs(props.time).format('HH:mm:ss A')}</small>
    <p>{props.message}</p>
    <span className='close'>
      <a
        href='#/'
        onClick={() => {
          props.remove(props.id);
        }}
      >
        &times;
      </a>
    </span>
  </div>
);
