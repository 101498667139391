import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import AccountAttributesTable from './AccountAttributesTable';
import { Link } from 'react-router-dom';

export const AccountAttributes = (props) => {
  const { getAccountAttributesList, match = { params: {} } } = props;
  const accountIdScope = parseInt(match.params.accountId);

  const [dataLoadRun, setDataLoadRun] = useState(false);

  useEffect(() => {
    if (!dataLoadRun) {
      getAccountAttributesList();
      setDataLoadRun(true);
    }
  }, [dataLoadRun, getAccountAttributesList]);

  return (
    <div className='account-attributes'>
      <div className='grid align-center justify-between'>
        <h1 className='inline-block'>Manage Account Attributes</h1>

        <Link to={accountIdScope ? `/account-attributes/add/${accountIdScope}` : '/account-attributes/add/'} className='button'>
          Add New Account Attribute
        </Link>
      </div>
      <AccountAttributesTable accountId={accountIdScope} />
    </div>
  );
};
export default connect((state) => ({}), actions)(AccountAttributes);
