import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import utils from '#/';

function TableHeader() {
  return (
    <div className='bg-grey-dark text-white grid'>
      <div className='p-md w-10'>
        <b className='mr-sm'>Account Id</b>
      </div>
      <div className='p-md w-30'>
        <b className='mr-sm'>Reserved Name</b>
      </div>
      <div className='p-md w-20'>
        <b className='mr-sm'>Value</b>
      </div>
      <div className='p-md w-15'>
        <b className='mr-sm'>Edit</b>
      </div>
      <div className='p-md w-15'>
        <b className='mr-sm'>Delete</b>
      </div>
    </div>
  );
}

const tableClass = (i) => (i % 2 === 0 ? 'bg-grey-lightest' : 'bg-white');

export const AccountAttributesTable = (props) => {
  const { deleteAccountAttribute, accountAttributesList, accountId } = props;

  const [search, setSearch] = useState('');

  const filteredData = () => {
    let filteredData = accountAttributesList;
    if (search && search.length) {
      filteredData = filteredData.filter(
        (x) => _.includes(x.reserved_name.toUpperCase(), search.toUpperCase()) || _.includes(x.value.toUpperCase(), search.toUpperCase()) || _.includes(x.account_id.toString(), search)
      );
    }

    // Did user follow a link from an account specific page, if so filter to just that account id
    if (accountId) {
      filteredData = filteredData.filter((x) => x.account_id.toString() === accountId.toString());
    }

    return filteredData;
  };

  const handleSearch = ({ target }) => utils.debounce(() => setSearch(target.value));

  const TableRow = (props) => {
    const { deleteAccountAttribute, accountAttribute, i } = props;

    return (
      <>
        <div className={`${tableClass(i)} grid align-center data`}>
          <div className='p-md w-10'>{accountAttribute.account_id}</div>
          <div className='p-md w-30'>{accountAttribute.reserved_name}</div>
          <div className='p-md w-20'>{accountAttribute.value}</div>

          <div className='p-md w-15'>
            <Link to={`/account-attributes/edit/${accountAttribute.id}`} className='button primary m-r-sm edit-rule-grid-button'>
              Edit
            </Link>
          </div>

          <div className='p-md w-15'>
            <button
              onClick={() => {
                // eslint-disable-next-line no-restricted-globals
                if (confirm('Are you sure you want to delete this account attribute?')) {
                  deleteAccountAttribute({ account_attribute_id: accountAttribute.id });
                }
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className='border border-grey-lightest radius-xs scroll account-attributes-table'>
        <input type='text' className='block w-100' placeholder='Filter account attributes' onKeyUp={handleSearch} />
        <TableHeader />
        {filteredData().map((accountAttribute, i) => (
          <TableRow deleteAccountAttribute={deleteAccountAttribute} accountAttribute={accountAttribute} i={i} key={accountAttribute.id} />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  accountAttributesList: state.accountAttributes.accountAttributesList,
});

export default connect(mapStateToProps, actions)(AccountAttributesTable);
