import React, { useState, useEffect, useRef, useCallback } from 'react';
import Modal from '+/Modal';
import SearchTypeAhead from '+/SearchTypeAhead';
import useSortable from '+/hooks/useSortable';
import { onlyOnEnter } from '#/';

const ProviderAssociation = (props) => {
  const providerRef = useRef(null);
  const {
    provider: { id },
    data: allProviders = [],
    associate,
    disassociateProviderFromGroupPracticeProfile,
    fetchFn,
    updateSort,
    searchProviders,
  } = props;
  const singular = 'provider';
  const [modal, setModal] = useState(false);

  const searchFn = useCallback(
    (name) =>
      searchProviders({
        name,
        type: 'individual',
        association: true,
      }),
    [searchProviders]
  );

  useEffect(() => {
    // Calling SearchFn with a empty string triggers a read all
    modal && searchFn('');
  }, [modal, searchFn]);

  // Sorts Providers by there Practice Sort Order Property lowest to hightest
  const sortedProviders =
    props.provider.providers_including_inactive &&
    props.provider.providers_including_inactive.sort(
      (a, b) => a.custom_content.practice_sort_order - b.custom_content.practice_sort_order
    );

  const {
    sorted,
    unSorted: providers,
    clearSort,
    setUnsorted,
  } = useSortable(providerRef, sortedProviders);

  const saveSort = useCallback(() => {
    // Reduce sorted into an object with the `keys` as the id of the provider and the `value` is their position (index)
    const newProviderOrder = sorted.reduce(
      (acc, cur, i) => ({
        ...acc,
        [cur.id]: i,
      }),
      {}
    );

    updateSort({
      id,
      newProviderOrder,
    });
    clearSort();
  }, [id, clearSort, sorted, updateSort]);

  useEffect(() => {
    sorted.length > 0 && saveSort();
  }, [saveSort, sorted]);

  useEffect(() => {
    if ((sortedProviders || []).length !== providers.length) {
      setUnsorted(sortedProviders);
    }
  }, [sortedProviders, providers.length, setUnsorted]);

  const onDelete = (groupPracticeProviderId, providerId) => {
    window.confirm('are you sure?') &&
      disassociateProviderFromGroupPracticeProfile({
        providerId,
        groupPracticeProviderId,
        fetchFn,
      });
  };

  const renderModal = () => {
    const filteredItems = Object.values(allProviders).filter((p) => p.id !== id);
    const selectedResource = providers.map((i) => i.id);

    if (modal) {
      return (
        <Modal title='Providers' onDismiss={() => setModal(!modal)}>
          <SearchTypeAhead
            field={({ first_name, middle_name, last_name }) =>
              [first_name, middle_name, last_name].join(' ').replace(/\s{2,}/, ' ')
            }
            placeholder={'Filter Providers...'}
            items={filteredItems}
            searchFn={searchFn}
            selected={selectedResource}
            onSelect={(associationId) =>
              associate({
                providerId: id,
                association: singular,
                associationId,
                fetchFn,
              })
            }
          />
        </Modal>
      );
    }
  };

  return (
    <>
      <div className='practice-provider-association border border-grey-lightest radius-xs p-lg mr-sm mb-sm w-50'>
        <div className='mb-lg grid align-center'>
          <h2 className='m-sm'>Providers</h2>
          <div
            tabIndex={0}
            onKeyDown={onlyOnEnter(() => setModal((m) => !m))}
            onClick={() => setModal((m) => !m)}
            role='menuitem'
            className='primary pointer p-xs'
          >
            <i className='fas fa-plus' />
          </div>
        </div>

        <div ref={providerRef} className='m-sm grid wrap'>
          {providers.map((resource, i) => (
            <div
              className={`grid label primary align-center justify-between mr-xs mb-xs ${
                resource.status === 'active' ? '' : 'inactive-provider'
              }`}
              handle={i}
              key={resource.id}
            >
              <h5 className='mb-0 mt-0 text-white'>
                {`${resource.first_name} ${resource.last_name}`} (id: {resource.id}, status:{' '}
                {resource.status})
              </h5>
              <div
                role='menuitem'
                tabIndex={0}
                onKeyDown={onlyOnEnter(() => onDelete(id, resource.id))}
                onClick={() => onDelete(id, resource.id)}
                className='pointer px-md'
              >
                <i className='fas fa-times' />
              </div>
            </div>
          ))}
        </div>
        {renderModal()}
      </div>
    </>
  );
};

export default ProviderAssociation;
