/**
 * Check if field has error
 * @param {object} meta Final Form field state
 */
export const fieldHasError = (meta) => {
  const { error, visited, modified } = meta;

  // Don't show error if just tabbing through
  if (!visited || !modified) {
    return false;
  }

  // show error if user has touched
  return modified && error;
};
