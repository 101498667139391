import React from 'react';
const tableClass = (i) => (i % 2 === 0 ? 'bg-grey-lightest' : 'bg-white');

const randKey = () => Math.floor(Math.random() * 1000000);

const MappedTable = (props) => {
  const { data = [], properties = [], height = '400px', title = '', clickHandler } = props;

  const width = {
    1: '100',
    2: '50',
    3: '33',
    4: '25',
    5: '20',
  }[properties.length];

  return (
    <>
      {title && <h4>{title}</h4>}
      <div className='bg-grey-dark text-white'>
        <nav className='p-xs grid'>
          {properties.map((val) => (
            <div className={`mr-sm  w-${width}`} key={randKey()}>
              <b>{val.title}</b>
            </div>
          ))}
        </nav>
      </div>
      <div
        style={{
          height,
          overflow: 'auto',
        }}
      >
        {data.length &&
          data.map((val, i) => (
            <div className={`${tableClass(i)} grid align-center data`} key={randKey()}>
              {properties.map((p) => (
                <div className={`p-xs w-${width}`} key={randKey()}>
                  {clickHandler ? (
                    <button
                      onClick={() => {
                        clickHandler(val);
                      }}
                    >
                      {val[p.name]}
                    </button>
                  ) : (
                    val[p.name]
                  )}
                </div>
              ))}
            </div>
          ))}
      </div>
    </>
  );
};

export default MappedTable;
