import React, { useRef, useEffect, useCallback } from 'react';
import CustomSpecialty from './CustomSpecialty';
import useSortable from '+/hooks/useSortable';

const CustomSpecialties = (props) => {
  const {
    initialState = [],
    updateFn,
    styles = '',
    custom_content = {},
    custom_content_type = 'custom_specialties',
  } = props;
  const specialtiesRef = useRef(null);
  const {
    sorted,
    unSorted: specialties,
    addNewItem,
    deleteOneUnsorted,
    toggleDisabled,
    clearSort,
    setUnsorted,
    disabled,
  } = useSortable(specialtiesRef, initialState);

  const saveSort = useCallback(() => {
    const payload = { custom_content };
    payload.custom_content[custom_content_type] = sorted;

    updateFn(payload);
    clearSort();
  }, [custom_content, custom_content_type, clearSort, sorted, updateFn]);

  useEffect(() => {
    sorted.length > 0 && saveSort();
  }, [saveSort, sorted]);

  const addSpecialty = () => {
    addNewItem(
      {
        name: '',
        description: '',
      },
      true
    );
    toggleDisabled();
  };

  const updateSpecialty = (specialty, index) => {
    const payload = { custom_content };
    payload.custom_content[custom_content_type][index] = specialty;

    updateFn(payload);
    setUnsorted(initialState);
    disabled && toggleDisabled();
  };

  const deleteSpecialty = (id, isNew) => {
    if (isNew) {
      deleteOneUnsorted(id);
      disabled && toggleDisabled();
    } else if (window.confirm('are you sure?')) {
      const newSpecialties = deleteOneUnsorted(id);

      const payload = { custom_content };
      payload.custom_content[custom_content_type] = newSpecialties;
      updateFn(payload);
    }
  };

  return (
    <div className={`card cursor-default p-lg mx-md my-lg ${styles}`}>
      <div className='grid justify-between align-center'>
        <h3 className='mb-lg customSpecialties'>
          Custom {custom_content_type === 'custom_specialties' ? 'Specialties:' : 'Approaches:'}
        </h3>
        <button type='button' className='success p-xs' disabled={disabled} onClick={addSpecialty}>
          <i className='fas fa-plus' />
        </button>
      </div>
      <hr />
      <div ref={specialtiesRef} className='scroll-y grid wrap justify-between'>
        {specialties && specialties.length ? (
          specialties.map((specialty, index) => (
            <CustomSpecialty
              index={index}
              specialty={specialty}
              toggleDisabled={toggleDisabled}
              key={`specialty${index}`}
              updateSpecialty={updateSpecialty}
              deleteSpecialty={deleteSpecialty}
            />
          ))
        ) : (
          <div className='grid justify-center w-100'>
            <h5 className=''>No Custom Specialties, Press the "+" Button</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSpecialties;
