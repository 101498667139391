import DefaultLayout from '##/Default';
import AccountAttributes from './AccountAttributes';
import AddAccountAttribute from './AddAccountAttribute';
import EditAccountAttribute from './EditAccountAttribute';

export default [
  {
    path: '/account-attributes',
    exact: true,
    layout: DefaultLayout,
    component: AccountAttributes,
    auth: true,
    title: 'Account Attributes',
  },
  {
    path: '/account-attributes/filter/:accountId',
    exact: true,
    layout: DefaultLayout,
    component: AccountAttributes,
    auth: true,
    title: 'Account Attributes',
  },
  {
    path: '/account-attributes/add',
    exact: true,
    layout: DefaultLayout,
    component: AddAccountAttribute,
    auth: true,
    title: 'Add Account Attribute',
  },
  {
    path: '/account-attributes/add/:accountId',
    exact: true,
    layout: DefaultLayout,
    component: AddAccountAttribute,
    auth: true,
    title: 'Add Account Attribute',
  },
  {
    path: '/account-attributes/edit/:accountAttributeId',
    exact: true,
    layout: DefaultLayout,
    component: EditAccountAttribute,
    auth: true,
    title: 'Edit Account Attribute',
  },
];
