import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';

const EditAccountAttribute = (props) => {
  const { getAccountAttributesList, updateAccountAttribute, accountAttributesList, match = { params: {} } } = props;
  const accountAttributeId = parseInt(match.params.accountAttributeId);

  const [dataLoadRun, setDataLoadRun] = useState(false);

  useEffect(() => {
    if (!dataLoadRun) {
      getAccountAttributesList();
      setDataLoadRun(true);
    }
  }, [dataLoadRun, accountAttributesList, getAccountAttributesList]);

  const accountAttribute = accountAttributesList?.find((x) => x.id === accountAttributeId);
  const [value, setValue] = useState(accountAttribute ? accountAttribute.value : '');

  if (!accountAttributeId) {
    return <div>Invalid/missing account attribute id</div>;
  }

  if (!accountAttributesList || !accountAttribute) {
    return 'Loading...';
  }

  return (
    <div className='account-attributes-form'>
      <h1>Edit Account Attribute</h1>

      <div>
        <div className='account-attributes-field-label'>Account Id:</div>
        {accountAttribute.account_id}
      </div>
      <div>
        <div className='account-attributes-field-label'>Reserved Name:</div>
        {accountAttribute.reserved_name}
      </div>
      <div>
        <div className='account-attributes-field-label'>Value:</div>
        <input type='text' value={value} onChange={(e) => setValue(e.target.value)} placeholder='Value' />
        <div className='account-attributes-value-tip'>Tip: Many attributes values should be either "0" or "1" (never "true" or "false")</div>{' '}
      </div>
      <div className='m-t-md'>
        <button
          type='button'
          className='primary'
          onClick={() =>
            updateAccountAttribute({
              account_attribute_id: accountAttributeId,
              value,
            })
          }
        >
          Save
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  accountAttributesList: state.accountAttributes.accountAttributesList,
});

export default connect(mapStateToProps, actions)(EditAccountAttribute);
