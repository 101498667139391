import React from 'react';

const UserResults = ({ data }) => {
  if (!data || !data.length) {
    return null;
  }

  const handleRowClick = (item) => {
    window.open(`/users/view-with-mode/${item.id}/provider`);
  };

  return (
    <div>
      <h2>Users:</h2>
      <table className='data-table'>
        <thead>
          <tr>
            <th>ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Company Name</th>
            <th>Email</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr
              title='Open user in new tab'
              className='clickable-result-row'
              onClick={() => {
                handleRowClick(item);
              }}
              key={item.id}
            >
              <td>{item.id}</td>
              <td>{item.first_name}</td>
              <td>{item.last_name}</td>
              <td>{item.company_name}</td>
              <td>{item.email}</td>
              <td>{item.role}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserResults;
