import React, { useState, useEffect } from 'react';

const CustomSpecialty = (props) => {
  const { specialty = {}, index = 0, deleteSpecialty, updateSpecialty, toggleDisabled } = props;

  const [edit, setEdit] = useState(specialty.isNew);
  const [name, setName] = useState(specialty.name);
  const [description, setDescription] = useState(specialty.description);

  useEffect(() => {
    setName(specialty.name);
    setDescription(specialty.description);
  }, [specialty]);

  const toggleEdit = () => {
    setEdit((e) => !e);
    toggleDisabled();
  };

  const onSubmit = () => {
    updateSpecialty(
      {
        name,
        description,
      },
      index
    );
    setEdit((e) => !e);
  };

  const onCancel = () => {
    if (specialty.isNew) {
      deleteSpecialty(index, true);
    } else {
      setName(specialty.name);
      setDescription(specialty.description);
      toggleEdit();
    }
  };

  if (edit) {
    return (
      <div className='field w-100'>
        {index > 0 && <hr />}
        <div className='grid justify-between align-center mb-md'>
          <input
            type='text'
            className='w-80'
            placeholder='Name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <button className='success w-5 p-xs' disabled={!name || !description} onClick={onSubmit}>
            <i className='fas fa-check' />
          </button>

          <button className='error w-5 p-xs' onClick={onCancel}>
            <i className='fas fa-times' />
          </button>
        </div>

        <textarea
          value={description}
          placeholder='Description'
          className='w-100'
          onChange={(e) => setDescription(e.target.value)}
          style={{
            height: '7rem',
            resize: 'none',
          }}
        />
      </div>
    );
  } else {
    return (
      <div className='w-100 py-xs' handle={index}>
        <div className='grid align-center justify-between'>
          <h4 className='mb-lg w-80'>{name}</h4>

          <button className='success w-5 p-xs' onClick={toggleEdit}>
            <i className='fas fa-edit' />
          </button>
          <button className='error w-5 p-xs' onClick={() => deleteSpecialty(index)}>
            <i className='fas fa-trash' />
          </button>
        </div>
        <p className='my-0'>{description}</p>
      </div>
    );
  }
};

export default CustomSpecialty;
