import { createModule } from 'saga-slice';
import { failReducer, loadingReducer, noop } from 'saga-slice-helpers';
import history from '#/history';

import { sagaApi } from '#/apis';

const sagaSliceModule = createModule({
  name: 'accountAttributes',
  initialState: {
    accountAttributesList: [],
  },
  reducers: {
    addAccountAttribute: noop,
    addAccountAttributeSuccess: noop,
    addAccountAttributeFail: failReducer,

    updateAccountAttribute: noop,
    updateAccountAttributeSuccess: noop,
    updateAccountAttributeFail: failReducer,

    deleteAccountAttribute: noop,
    deleteAccountAttributeSuccess: noop,
    deleteAccountAttributeFail: failReducer,

    getAccountAttributesList: loadingReducer,
    getAccountAttributesListSuccess: (state, payload) => {
      state.accountAttributesList = payload;
    },
    getAccountAttributesListFail: failReducer,
  },
  sagas: (A) => ({
    [A.addAccountAttribute]: function* ({ payload }) {
      yield sagaApi.post('/account-attributes', payload, A.addAccountAttributeSuccess, A.addAccountAttributeFail);
      history.push('/account-attributes');
    },
    [A.updateAccountAttribute]: function* ({ payload }) {
      yield sagaApi.put(`/account-attributes/${payload.account_attribute_id}`, { value: payload.value }, A.updateAccountAttributeSuccess, A.updateAccountAttributeFail);
      history.push('/account-attributes');
    },
    [A.deleteAccountAttribute]: function* ({ payload }) {
      yield sagaApi.delete(`/account-attributes/${payload.account_attribute_id}`, payload, A.deleteAccountAttributeSuccess, A.deleteAccountAttributeFail);
      history.push('/account-attributes');
    },
    [A.getAccountAttributesList]: {
      *saga() {
        console.log('getAccountAttributesList called');
        yield sagaApi.get('/account-attributes/all', A.getAccountAttributesListSuccess, A.getAccountAttributesListFail);
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
