import React, { useState } from 'react';
import { onlyOnEnter } from '#/';

export default function Column(props) {
  const { title, field, onChange, width = '33' } = props;
  const [ascending, setAscending] = useState(false);
  const className = ascending ? 'fas fa-sort-alpha-down' : 'fas fa-sort-alpha-up';

  const handleChange = () => {
    onChange(field, ascending ? 'asc' : 'desc');
    setAscending((a) => !a);
  };

  return (
    <div className={`p-md w-${width}`}>
      <b className='mr-sm'>{title}</b>
      <i
        tabIndex={0}
        role='menuitem'
        onClick={handleChange}
        onKeyDown={onlyOnEnter(handleChange)}
        className={`text-white pointer ${className}`}
      />
    </div>
  );
}
