import React from 'react';
import { titleCase } from 'voca';

const EditableCheckBoxes = (props) => {
  const { checkboxes = {}, custom_content_name = '', custom_content, label, updateFn } = props;

  const handleChecked = (e) => {
    const { checked, id } = e.target;

    const payload = {};
    payload.custom_content = custom_content;
    payload.custom_content[custom_content_name][id] = checked;

    updateFn(payload);
  };

  return (
    <div className='grid justify-between align-center wrap'>
      {Object.entries(checkboxes).map(([bank, checked]) => (
        <div key={bank} className='w-50 grid justify-between align-center'>
          <h4 className='w-50'>
            {titleCase(bank.includes('_') ? bank.replace(/_/gm, ' ') : bank)}
          </h4>
          <div className='w-50'>
            <input
              id={bank}
              className='checkbox'
              type='checkbox'
              checked={checked}
              onChange={handleChecked}
            />
            {/* Discussion: https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/718#issuecomment-653820873 */}
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className='checkbox' htmlFor={bank}></label>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EditableCheckBoxes;
