import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import Modal from '+/Modal';
import { onlyOnEnter } from '#/';
import SearchTypeAhead from '+/SearchTypeAhead';
import StateAbbrDict from '../../utils/stateAbbrDict';

export const MultiStateSelector = (props) => {
  const [modal, setModal] = useState(false);
  const {
    // Array of currently selected states
    currentStates,

    onSelected,
    onRemoveState,
  } = props;

  const [filteredStatesList, setFilteredStatesList] = useState([]);

  const getStatesList = () => {
    const results = [];
    const stateAbbrevs = Object.keys(StateAbbrDict());
    stateAbbrevs.forEach((abbrev) => {
      results.push({
        id: abbrev,
        name: StateAbbrDict()[abbrev],
      });
    });

    return results;
  };

  const selectedStateAbbrevs = currentStates.map((state) =>
    getStatesList().find((x) => x.name === state)
  );

  const searchFn = useCallback((searchTerm) => {
    if (searchTerm && searchTerm.length) {
      const result = getStatesList().filter(
        (x) =>
          _.includes(x.id.toUpperCase(), searchTerm.toUpperCase()) ||
          _.includes(x.name.toUpperCase(), searchTerm.toUpperCase())
      );
      setFilteredStatesList(result);
    } else {
      const allStates = getStatesList();
      setFilteredStatesList(allStates);
    }
  }, []);

  useEffect(() => {
    modal && searchFn();
  }, [modal, searchFn]);

  const disassociateState = (state) => {
    onRemoveState(state);
  };

  const renderModel = () => {
    if (modal) {
      return (
        <Modal title='Select state' onDismiss={() => setModal(!modal)}>
          <SearchTypeAhead
            field='name'
            placeholder='Filter states...'
            items={filteredStatesList}
            selected={selectedStateAbbrevs}
            searchFn={searchFn}
            onSelect={(abbrev) => {
              onSelected(StateAbbrDict()[abbrev]);
            }}
          />
        </Modal>
      );
    }
  };

  return (
    <div className='card cursor-default p-lg mr-sm mb-sm w-40'>
      <div className='mb-lg grid align-center'>
        <h4 className='m-sm'>States</h4>
        <div
          className='primary pointer px-sm'
          role='menuitem'
          tabIndex={0}
          onKeyDown={onlyOnEnter(() => setModal((m) => !m))}
          onClick={() => setModal((m) => !m)}
        >
          <i className='fas fa-plus' />
        </div>
      </div>
      {renderModel()}
      <div className='m-sm grid wrap'>
        {currentStates.map((state) => (
          <div className='grid label align-center justify-between mr-xs mb-xs' key={state}>
            <h5 className='mb-0 mt-0 text-white'>{state}</h5>
            <div
              tabIndex={0}
              role='menuitem'
              onClick={() => {
                disassociateState(state);
              }}
              className='primary pointer px-sm'
              onKeyDown={onlyOnEnter(() => {
                disassociateState(state);
              })}
            >
              <i className='fas fa-times' />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({});

export default connect(mapStateToProps, {})(MultiStateSelector);
