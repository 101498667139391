import DefaultLayout from '##/Default';

import List from './List';
import Add from './Add';

export default [
  {
    path: '/redirects',
    exact: true,
    layout: DefaultLayout,
    component: List,
    title: 'View All Redirects',
  },
  {
    path: '/redirects/add',
    exact: true,
    layout: DefaultLayout,
    component: Add,
    title: 'Add New Redirect',
  },
];
