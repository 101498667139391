import React from 'react';
import { useDispatch } from 'react-redux';

import { actions } from './sagaSlice';

const id = 'reimbursify-user-vob-id';

function RerunVob() {
  const dispatch = useDispatch();

  const onSubmit = () => {
    const idInput = document.getElementById(id);
    dispatch(actions.rerunVob({ user_vob_id: idInput.value }));
  };

  return (
    <div className='p-sm box m-b-md'>
      <div className='p-b-sm'>
        <label htmlFor={id} className='p-r-sm'>
          Enter a VOB id to re-run
        </label>
        <input id={id} type='number' />
      </div>
      <button onClick={onSubmit} className='success w-100'>
        Re-run VOB
      </button>
    </div>
  );
}

export default RerunVob;
