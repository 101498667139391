import React from 'react';
import EditableCard from '+/forms/EditableCard';
import EditableTextArea from '+/forms/EditableTextArea';

const ProfileTab = ({ therapyGroup = {}, updateFn }) => (
  <div className='therapyGroupProfile'>
    <div className='grid justify-between'>
      <EditableCard
        label='Description:'
        name='description'
        initialState={therapyGroup.description}
        styles='w-50'
        updateFn={updateFn}
      />
      <EditableTextArea
        label='Short Description:'
        name='short_description'
        styles='w-50'
        updateFn={updateFn}
        initialState={therapyGroup.short_description}
      />
    </div>
    <div className='grid justify-between'>
      <EditableTextArea
        label='Hours:'
        name='hours'
        styles='w-50'
        updateFn={updateFn}
        initialState={therapyGroup.hours}
      />
      <EditableTextArea
        label='Costs:'
        name='costs'
        styles='w-50'
        updateFn={updateFn}
        initialState={therapyGroup.costs}
      />
    </div>
  </div>
);

export default ProfileTab;
