import { useState } from 'react';
const useHover = () => {
  const [hovering, setHovering] = useState(false);
  const onMouseEnter = () => setHovering(true);
  const onMouseLeave = () => setHovering(false);

  const attr = {
    onMouseEnter,
    onMouseLeave,
  };

  return [hovering, attr];
};
export default useHover;
