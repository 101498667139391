import rgx from '#/regex';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'You must enter a name';
  }

  if (!values.slug) {
    errors.slug = 'You must enter a slug';
  } else if (!rgx.slug.test(values.slug)) {
    errors.slug =
      'Slug must contain only lowercase letters and must not begin or end with special chars.';
  }

  return errors;
};

export default validate;
