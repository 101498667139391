import React, { useState, useEffect } from 'react';
import PlainEditor from '+/forms/PlainEditor';

export const CustomBox = (props) => {
  const { index, initialState, deleteBox, updateBoxes } = props;
  const isNew = initialState.length < 1;
  const [edit, setEdit] = useState(isNew);
  const [name, setName] = useState(initialState[0]);
  const [description, setDescription] = useState(initialState[1]);

  useEffect(() => {
    setName(initialState[0]);
    setDescription(initialState[1]);
  }, [initialState]);

  const toggleEdit = () => setEdit((e) => !e);

  const onSubmit = () => {
    updateBoxes([name, description], index);
    toggleEdit();
  };

  const onCancel = () => {
    setName(initialState[0]);
    setDescription(initialState[1]);
    toggleEdit();
  };

  const onDelete = () => {
    if (window.confirm('are you sure?')) {
      deleteBox(index, isNew);
    }
  };

  if (edit) {
    return (
      <div className='p-xs m-xxs ' key={`CustomBox#${index}`}>
        <div className='grid justify-between mb-md align-center'>
          <input
            type='text'
            value={name || ''}
            className='w-75'
            label={`Box #${index + 1} Name`}
            onChange={(e) => setName(e.target.value)}
          />
          <div className='w-25 pl-sm grid'>
            <button
              className='success p-xs mr-sm'
              disabled={!name || !description}
              onClick={onSubmit}
            >
              <i className='fas fa-check' />
            </button>

            <button className='error p-xs' onClick={onCancel}>
              <i className='fas fa-times' />
            </button>
          </div>
        </div>
        <PlainEditor
          id={`Box#${index}`}
          style={{
            height: '10rem',
            resize: 'none',
          }}
          initialValue={description}
          onChange={setDescription}
          label={`Box #${index + 1} Description`}
        />
      </div>
    );
  } else {
    return (
      <div className='p-xs m-xxs CustomBox'>
        <hr />
        <div className='radius-xs mr-sm mb-sm'>
          <div className='grid align-center justify-between'>
            <p className='mb-xs w-65 bold'>{name}</p>
            <div className='w-35 pl-sm grid justify-between'>
              <button className='success p-xs' onClick={toggleEdit}>
                <i className='fas fa-edit' />
              </button>
              <button className='p-sm error' onClick={onDelete}>
                <i className='fas fa-trash' />
              </button>
            </div>
          </div>
          <p className='mt-0' dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    );
  }
};

export default CustomBox;
