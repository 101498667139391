// import { put } from 'redux-saga/effects';
import { createModule } from 'saga-slice';
import { noop, loadingReducer, notLoadingReducer, failReducer } from 'saga-slice-helpers';
import { put } from 'redux-saga/effects';

import { sagaApi } from '#/apis';

const sagaSliceModule = createModule({
  name: 'photos',
  initialState: {},
  reducers: {
    create: loadingReducer,
    createSuccess: (state, payload) => {
      notLoadingReducer(state);
      state.data = payload;
    },
    createFail: failReducer,

    createGallery: loadingReducer,
    createGallerySuccess: noop,
    createGalleryFail: noop,

    delete: loadingReducer,
    deleteSuccess: (state) => {
      notLoadingReducer(state);
      state.data = {};
    },
    deleteFail: failReducer,
    deleteDone: noop,

    setProfilePic: loadingReducer,
    setProfilePicSuccess: noop,
    setProfilePicFail: noop,

    setGalleryVisibility: loadingReducer,
    setGalleryVisibilitySuccess: noop,
    setGalleryVisibilityFail: noop,

    setDisplayVideoIntroduction: loadingReducer,
    setDisplayVideoIntroductionSuccess: noop,
    setDisplayVideoIntroductionFail: noop,
  },
  sagas: (A) => ({
    [A.create]: {
      *saga({ payload }) {
        const { newPhoto, fetchFn } = payload;
        yield sagaApi.post('/image', newPhoto, A.createSuccess, A.createFail);
        fetchFn && fetchFn();
      },
    },
    [A.createGallery]: {
      *saga({ payload }) {
        const { newPhotos, fetchFn } = payload;
        yield sagaApi.post('/images', newPhotos, A.createGallerySuccess, A.createGalleryFail);
        fetchFn && fetchFn();
      },
    },
    [A.delete]: {
      *saga({ payload }) {
        const { id, fetchFn } = payload;
        yield sagaApi.delete(`/image/${id}`, null, A.deleteSuccess, A.deleteFail, A.deleteDone);
        fetchFn && fetchFn();
      },
    },
    [A.setProfilePic]: {
      *saga({ payload }) {
        const { img, fetchFn } = payload;
        yield sagaApi.post('/profile-pic', img, A.setProfilePicSuccess, A.setProfilePicFail);
        fetchFn && fetchFn();
      },
    },
    [A.setGalleryVisibility]: {
      *saga({ payload }) {
        const id = payload.provider_id;
        const fetchFn = payload.fetchFn;
        delete payload.provider_id;
        delete payload.fetchFn;

        yield sagaApi.put(
          `/providers/${id}/gallery`,
          payload,
          A.setGalleryVisibilitySuccess,
          A.setGalleryVisibilityFail
        );
        fetchFn && fetchFn();
      },
    },
    [A.setDisplayVideoIntroduction]: {
      *saga({ payload }) {
        const id = payload.provider_id;
        delete payload.provider_id;

        yield sagaApi.put(
          `/providers/${id}/video-introduction`,
          payload,
          A.setDisplayVideoIntroductionSuccess,
          A.setDisplayVideoIntroductionFail
        );

        yield put({
          type: 'providers/readOne',
          payload: id,
        });
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
