import React from 'react';

const StatusWidget = ({ currentStatus, statusOptionArray }) =>
  statusOptionArray.map((status) => {
    return (
      <div className='mb-sm'>
        <span className={status === currentStatus ? 'text-primary' : 'text-secondary'}>
          {status}
        </span>
        {status === currentStatus && (
          <span className='fas fa-check-circle ml-xs text-primary'></span>
        )}
      </div>
    );
  });
export default StatusWidget;
