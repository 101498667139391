import { createApis } from 'saga-slice-helpers';

const baseURL = process.env.ZENCARE_API_URL || 'http://localhost:4000';

const apis = createApis({
  // headers: {
  //     env: 'admin'
  // },
  // Increase default timeout to 15 minutes
  timeout: 15 * 60 * 1000,
  baseURL: `${baseURL}`,
});

export const { api, sagaApi } = apis;

export const { addAuthorization, removeAuthorization, addHeader, removeHeader } = api;
