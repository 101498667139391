import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { actions } from './sagaSlice';
import Remove from '+/RemoveButton';
import TabsContainer from './components/tabs';
import capitalize from 'voca/capitalize';
import PromoteButton from './components/PromoteButton';
import Status from '+/Status';
import PrimaryProfession from './components/PrimaryProfession';
import Slug from './components/Slug';
import VettingStatus from './components/VettingStatus';

export const View = (props) => {
  const { current: provider, readOne, update, delete: deleteProvider, updateStatus, auth } = props;

  const getDefaultTab = () => {
    if (auth && auth.scope === 'photo-uploader') {
      return 'photos';
    }

    if (auth && auth.scope === 'license-checker') {
      return 'locations';
    }

    return 'profile';
  };

  const { id, tab = getDefaultTab() } = (props.match && props.match.params) || {};

  const fetchFn = useCallback(() => {
    readOne(id);
  }, [id, readOne]);

  useEffect(fetchFn, [fetchFn]);

  if (!provider) return null;

  const isPractice = provider.type === 'practice';

  const activeIf = (_tab) => (_tab === tab ? 'text-primary border-bottom-thick border-primary' : 'text-grey');

  const tabHref = (tab) => `/providers/view/${provider.id}/${tab}`;

  const updateFn = (changeset) =>
    update({
      id,
      changeset,
    });

  // Props passed to all Tabs
  const renderTab = () =>
    TabsContainer({
      ...props,
      auth,
      provider,
      fetchFn,
      updateFn,
    })[tab]();

  const renderTabLinks = () => {
    const tabs = Object.keys(TabsContainer({ auth })).map((tab) => (
      <Link to={tabHref(tab)} className={`p-sm m-0 ${activeIf(tab)}`}>
        {capitalize(tab === 'seo' ? 'SEO' : tab)}
      </Link>
    ));

    return tabs.map((link, i) => (
      <li className='mb-0 pointer list-none' key={i}>
        {link}
      </li>
    ));
  };

  const title = () => {
    if (isPractice) {
      return provider.company_name;
    }

    return [provider.first_name, provider.middle_name, provider.last_name].join(' ');
  };

  const providerUrl = () => {
    const type = isPractice ? 'practice' : 'provider';
    let baseUrl = `${process.env.ZENCARE_WEBSITE_URL}/${type}/`;
    if (!isPractice && provider.primary_profession) {
      return (baseUrl += `${provider.primary_profession.slug}/${provider.slug}`);
    }

    return (baseUrl += provider.slug);
  };

  return (
    <div>
      <div className='grid justify-between align-center'>
        <div className='grid align-center'>
          <h1 className='mr-lg'>{title()}</h1>
          <Status
            initialStatus={provider.status}
            updateFn={(payload) =>
              updateStatus({
                id,
                ...payload,
              })
            }
            options={['active', 'approved', 'pending', 'reviewing', 'incomplete', 'inactive', 'locked']}
          />
          <VettingStatus vetting_status={provider.custom_content.vetting_status} updateFn={updateFn} />

          <PrimaryProfession primary_profession={provider.primary_profession} primary_profession_id={provider.primary_profession_id} updateFn={updateFn} />
          <Slug slug={provider.slug} updateFn={updateFn} />
        </div>
        {auth && auth.scope !== 'license-checker' && <PromoteButton promoted={provider.promoted} updateFn={updateFn} />}
      </div>
      <div>
        <a target='_blank' rel='noopener noreferrer' href={providerUrl()}>
          {providerUrl()}
        </a>
      </div>

      <nav
        style={{
          whiteSpace: 'nowrap',
          overflowX: 'auto',
        }}
        className='grid w-100 justify-between align-center mb-lg border-bottom border-top border-grey-light'
      >
        <ul className='grid list-none w-100 pb-lg m-0 justify-between'>{renderTabLinks()}</ul>
      </nav>

      {renderTab()}

      {auth && auth.scope !== 'license-checker' && (
        <div
          style={{
            float: 'right',
            marginTop: '16px',
          }}
        >
          <span style={{ marginRight: '8px' }}>Delete Provider</span>
          <Remove className='error' doubleassert='true' name={title()} onClick={() => deleteProvider(provider.id)} />
        </div>
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    ...state.providers,
    auth: state.auth,
  }),
  actions
)(View);
