import React from 'react';

const PromoteButton = (props) => {
  const { promoted = false, updateFn, index = 0, label = true } = props;

  const handleChange = ({ target: { checked } }) => updateFn({ promoted: checked });

  return (
    <div>
      {label && (
        <h5 className='mb-sm' style={{ textAlign: 'center' }}>
          Promote
        </h5>
      )}
      <input
        onChange={handleChange}
        className='checkbox'
        type='checkbox'
        id={`promoted${index}`}
        name='promoted'
        defaultChecked={promoted}
      />
      <label className='checkbox' htmlFor={`promoted${index}`}>
        Promote
      </label>
    </div>
  );
};

export default PromoteButton;
