import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import AddSearchContentButton from './AddSearchContentButton';
import SearchContentTable from './SearchContentTable';

export const SearchContentOverrides = ({ getSearchContentOverrides }) => {
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (!dataLoaded) {
      getSearchContentOverrides();
      setDataLoaded(true);
    }
  }, [dataLoaded, getSearchContentOverrides]);

  return (
    <div className='p-l-sm'>
      <h1>Search Content Overrides</h1>

      <AddSearchContentButton />
      <SearchContentTable />
    </div>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, actions)(SearchContentOverrides);
