import { createModule } from 'saga-slice';
import { noop, loadingReducer, failReducer } from 'saga-slice-helpers';
import { sagaApi } from '#/apis';

const sagaSliceModule = createModule({
  name: 'seo',
  initialState: {},
  reducers: {
    updateSeo: loadingReducer,
    updateSeoSuccess: noop,
    updateSeoFail: failReducer,

    createSeoImage: loadingReducer,
    createSeoImageSuccess: noop,
    createSeoImageFail: failReducer,
  },
  sagas: (A) => ({
    [A.updateSeo]: {
      *saga({ payload }) {
        const { id, seo, fetchFn } = payload;
        yield sagaApi.put(`/seo/${id}`, seo, A.updateSeoSuccess, A.updateSeoFail);
        fetchFn && fetchFn();
      },
    },
    [A.createSeoImage]: {
      *saga({ payload }) {
        const { newImage, fetchFn } = payload;
        yield sagaApi.post(
          `/seo/${newImage.model_id}/image`,
          newImage,
          A.createSeoImageSuccess,
          A.createSeoImageFail
        );
        fetchFn && fetchFn();
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
