import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { actions } from './sagaSlice';
import PromoteButton from './components/PromoteButton';
import Pagination from '+/Pagination';
import useOrderBy from '+/hooks/useOrderBy';
import Column from '+/Column';
import utils from '#/';

const truncateStyle = {
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
};

export const List = (props) => {
  const {
    auth,
    providers,
    total,
    readAll,
    update,
    searchProviders,
    match = { params: { type: 'individual' } },
  } = props;
  const [filterValue, setFilterValue] = useState('');
  const { type } = match.params;
  const isPractice = type === 'practice';
  const title = isPractice ? 'Practices' : 'Providers';

  const {
    order,
    orderBy,
    ordered: orderedProviders,
    handleColumnChange,
  } = useOrderBy(providers, 'first_name');

  useEffect(() => {
    searchProviders({
      name: filterValue,
      type,
      order,
      orderBy,
    });
  }, [filterValue, order, orderBy, searchProviders, type]);

  const providerDisplayName = (provider) => {
    if (isPractice) return provider.company_name;

    return `${provider.first_name} ${provider.last_name}`;
  };

  const handleSearch = ({ target }) => setFilterValue(target.value);

  return (
    <>
      <div className='grid align-center justify-between'>
        <h1 className='inline-block'>{title}</h1>
        {auth && auth.scope !== 'license-checker' && (
          <Link className='button primary' to={`/providers/${type}/add`}>
            <i className='fas fa-plus' />
          </Link>
        )}
      </div>

      <div className='border border-grey-lightest radius-xs scroll'>
        <input
          type='text'
          className='block w-100'
          placeholder={`Filter ${title.toLowerCase()}...`}
          onKeyUp={handleSearch}
        />
        <div className='bg-grey-dark text-white grid justify-between'>
          <Column width='20' title='Name' field='first_name' onChange={handleColumnChange} />
          <Column width='20' title='Status' field='status' onChange={handleColumnChange} />
          <div className='p-md w-20'>
            <b className='mr-sm'>Email</b>
          </div>
          <Column width='20' title='Created At' field='created_at' onChange={handleColumnChange} />
          {auth && auth.scope !== 'license-checker' && (
            <Column width='20' title='Promoted' field='promoted' onChange={handleColumnChange} />
          )}
        </div>
        {orderedProviders.map((provider, i) => (
          <div
            className={`${utils.tableClass(i)} grid justify-between provider`}
            style={{ alignItems: 'center' }}
            key={provider.id}
          >
            <div className='p-md w-20'>
              <Link to={`/providers/view/${provider.id}`}>{providerDisplayName(provider)}</Link>
            </div>
            <div className='p-md w-20'>{provider.status}</div>
            <div className='p-md w-20'>
              <p style={truncateStyle}>{provider.custom_content.email}</p>
            </div>
            <div className='p-md w-20'>{utils.displayDate(provider.created_at)}</div>
            {auth && auth.scope !== 'license-checker' && (
              <div className='p-md w-20 grid justify-end'>
                <PromoteButton
                  index={i}
                  label={false}
                  promoted={provider.promoted}
                  updateFn={(changeset) =>
                    update({
                      id: provider.id,
                      changeset,
                    })
                  }
                />
              </div>
            )}
          </div>
        ))}
        <Pagination
          isHidden={filterValue}
          total={total}
          fetchFn={(page) =>
            readAll({
              page,
              type,
              order,
              orderBy,
            })
          }
        />
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    providers: Object.values(state.providers.data || {}) || [],
    total: state.providers.total,
    auth: state.auth,
  }),
  actions
)(List);
