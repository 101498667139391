import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import SearchTypeAhead from '+/SearchTypeAhead';
import { actions } from '@/providers/sagaSlice';
import { actions as userActions } from './sagaSlice';
import { AccountGraph } from '@/providers/components/tabs/accounts';
import AccountNotes from '../accounts/AccountNotes';

//eslint-disable-next-line
export const ProviderAssociation = (props) => {
  const [addingProvider, setAddingProvider] = useState(false);
  const [removingProvider, setRemovingProvider] = useState(false);
  const ref = useRef(null);

  const {
    user,
    providers,
    userAccounts,
    providerAccounts,
    providerToAssociate,
    providerToDissociate,
    searchProviders,
    selectProviderToAssociate,
    selectProviderToDissociate,
    associateUserProvider,
    dissociateUserProvider,
  } = props;

  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }

    if (addingProvider && providerAccounts) {
      ref.current.scrollIntoView();
    }
  }, [addingProvider, providerAccounts]);

  const add = () => {
    setAddingProvider(true);
    setRemovingProvider(false);
  };

  const remove = () => {
    setAddingProvider(false);
    setRemovingProvider(true);
  };

  const cancel = () => {
    selectProviderToAssociate(null);
    selectProviderToDissociate(null);
    setAddingProvider(false);
    setRemovingProvider(false);
  };

  //eslint-disable-next-line
  const name = (p) => (p.first_name ? `${p.first_name} ${p.last_name}` : p.company_name);
  //eslint-disable-next-line
  const username = (u) => (u.first_name ? `${u.first_name} ${u.last_name}` : u.company_name) || u.email;

  const searchFn = useCallback(
    (name) =>
      searchProviders({
        name,
        association: true,
      }),
    [searchProviders]
  );

  useEffect(() => {
    searchFn && searchFn('');
  }, [searchFn]);

  const existingRoles = () => {
    if (!userAccounts) return;

    const users = _.flatten(
      userAccounts.map((a) =>
        a.users
          ? a.users
              .filter((u) => u.id === user.id)
              .map((u) => ({
                ...u,
                account: a,
              }))
          : []
      )
    );

    function description(u, r) {
      return (
        <>
          <span style={{ color: 'blue' }}>{username(u)}</span>
          &nbsp;has <b>{r.name}</b>-level access to account {u.account.id}
        </>
      );
    }

    const RoleLine = (props) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          border: '1px solid black',
          padding: '4px',
          margin: '4px',
          minHeight: '50px',
        }}
      >
        <div>
          <p style={{ margin: '4px 4px' }}>{props.children}</p>
          <div className=''></div>
        </div>
        {props.provider && (
          <button
            className='error hollow'
            style={{
              width: '100px',
              height: '50px',
              padding: 0,
            }}
            onClick={() => {
              remove();
              selectProviderToDissociate(props.provider);
            }}
          >
            Dissociate
          </button>
        )}
      </div>
    );

    return _.flatten(
      users.map((u) =>
        u.roles.map((r) => {
          if (r.name === 'Clinician') {
            const providers = u.account.providers.filter((p) => _.get(r, 'scope.providers', []).includes(p.id));

            return providers.map((p) => (
              <RoleLine provider={p}>
                {description(u, r)} for provider{' '}
                <span style={{ color: 'green' }}>
                  <a href={`/providers/view/${p.id}`}>{name(p)}</a>
                </span>
              </RoleLine>
            ));
          }

          return <RoleLine>{description(u, r)} </RoleLine>;
        })
      )
    );
  };

  const showAssociateOptions = () => {
    if (!userAccounts || !providerAccounts) {
      return;
    }

    let description = '';
    const options = [];

    const ownedBy = (acc) => (acc.users ? acc.users.find((u) => u.roles.some((r) => r.name === 'Owner')) : []);

    if (providerAccounts.length) {
      const acc = providerAccounts[0];
      const owner = ownedBy(acc);
      const userIsOwner = owner ? owner.id === user.id : false;

      if (userIsOwner) {
        description = `
                    This provider currently belongs to the account that this user owns. 
                    If this user needs clinician level access, add it below. If this user only needs owner level
                    access, you're all set!
                `;

        options.push({
          name: `Give this user clinician-level access to ${name(providerToAssociate)}`,
          onSelect: () =>
            associateUserProvider({
              account_id: acc.id,
              user_id: user.id,
              provider_id: providerToAssociate.id,
              onSuccess: cancel,
            }),
        });
      } else {
        if (owner) {
          console.log('owner: ');
          console.log(JSON.stringify(owner));
          description = `This provider currently belongs to account ${acc.id} owned by ${username(owner)}.`;

          options.push({
            name: `Add ${username(user)} as a clinician in ${username(owner)}'s account with access to ${name(providerToAssociate)}.`,
            onSelect: () =>
              associateUserProvider({
                account_id: acc.id,
                user_id: user.id,
                provider_id: providerToAssociate.id,
                onSuccess: cancel,
              }),
          });
        } else {
          description = `This provider currently belongs to account ${acc.id} (has no owner assigned).`;

          options.push({
            name: `Add ${username(user)} as a clinician in account (no owner assigned) with access to ${name(providerToAssociate)}.`,
            onSelect: () =>
              associateUserProvider({
                account_id: acc.id,
                user_id: user.id,
                provider_id: providerToAssociate.id,
                onSuccess: cancel,
              }),
          });
        }
      }
    } else {
      if (userAccounts.length) {
        const ownedAccounts = userAccounts.filter((a) => ownedBy(a).id === user.id);
        const otherAccounts = userAccounts.filter((a) => ownedBy(a).id !== user.id);

        description = 'This provider does not currently belong to an account.';
        ownedAccounts.forEach((a) => {
          options.push({
            name: `Add ${name(providerToAssociate)} to account ${a.id}; 
                        ${username(user)} will have owner- and clinician- level access`,
            onSelect: () =>
              associateUserProvider({
                account_id: a.id,
                user_id: user.id,
                provider_id: providerToAssociate.id,
                onSuccess: cancel,
              }),
          });
        });

        otherAccounts.forEach((a) => {
          options.push({
            name: `Add ${name(providerToAssociate)} to account ${a.id} (owned by ${username(ownedBy(a))}) and give
                        ${username(user)} clinician-level access through that account.`,
            onSelect: () =>
              associateUserProvider({
                account_id: a.id,
                user_id: user.id,
                provider_id: providerToAssociate.id,
                onSuccess: cancel,
              }),
          });
        });
      } else {
        description = 'Neither the user nor the provider are currently attached to an account';
        options.push({
          name: `Create an account owned by  ${username(user)}, attach ${name(providerToAssociate)} to account, and
                    give ${username(user)} clinician-level access to the provider.`,
          onSelect: () =>
            associateUserProvider({
              user_id: user.id,
              provider_id: providerToAssociate.id,
              onSuccess: cancel,
            }),
        });
      }
    }

    return (
      <div>
        <p>
          <b>
            Associating {name(providerToAssociate)} with {username(user)}:
          </b>
        </p>

        <p>{description}</p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignContent: 'center',
          }}
        >
          {options.map((o) => (
            <button className='pill primary mb-sm' style={{ fontWeight: 500 }} onClick={o.onSelect}>
              {o.name}
            </button>
          ))}
          <button className='pill tertiary hollow' onClick={cancel}>
            Cancel
          </button>
        </div>
      </div>
    );
  };

  const showDissociateOptions = () => {
    if (!providerToDissociate) return;

    let description = '';
    const options = [];

    const ownedBy = (acc) => acc.users.find((u) => u.roles.some((r) => r.name === 'Owner'));

    const accountWithProvider = userAccounts.find((a) => a.providers.find((p) => p.id === providerToDissociate.id));
    const owner = ownedBy(accountWithProvider);
    const userIsOwner = owner.id === user.id;
    const anotherUserWithAccess = accountWithProvider.users.find(
      (u) => u.id !== user.id && u.roles.some((r) => r.name === 'Clinician' && _.get(r, 'scope.providers', []).includes(providerToDissociate.id))
    );

    if (anotherUserWithAccess) {
      if (userIsOwner) {
        description = `
                    ${name(providerToDissociate)} is also accessible by ${username(anotherUserWithAccess)}.
                    Dissociating ${username(user)} with this provider will not detach  the provider from the account.
                    ${username(owner)} will still have owner-level access to this provider. To remove the provider from 
                    the account entirely, all clinicians with access must be removed.
                `;

        options.push({
          name: `Remove ${username(user)}'s clinician-level access to ${name(providerToDissociate)}.`,
          onSelect: () =>
            dissociateUserProvider({
              account_id: accountWithProvider.id,
              user_id: user.id,
              provider_id: providerToDissociate.id,
              onSuccess: cancel,
            }),
        });
      } else {
        description = `
                    ${name(providerToDissociate)} is also accessible by ${username(anotherUserWithAccess)}.
                    Dissociating ${username(user)} with this provider will not detach the provider from the account.
                    ${username(owner)} will still have owner-level access and ${username(anotherUserWithAccess)} will
                    still have clinician-level access. 
                `;

        options.push({
          name: `Remove ${username(user)}'s clinician-level access to ${name(providerToDissociate)} in ${username(owner)}'s account.`,
          onSelect: () =>
            dissociateUserProvider({
              account_id: accountWithProvider.id,
              user_id: user.id,
              provider_id: providerToDissociate.id,
              onSuccess: cancel,
            }),
        });
      }
    } else {
      description = `${username(user)} is the only user with clinician-level access to this provider. 
            Dissociating this user from ${name(providerToDissociate)} will also remove it from the account.`;

      options.push({
        name: `Remove ${name(providerToDissociate)} from ${username(owner)}'s account.`,
        onSelect: () =>
          dissociateUserProvider({
            account_id: accountWithProvider.id,
            user_id: user.id,
            provider_id: providerToDissociate.id,
            onSuccess: cancel,
          }),
      });
    }

    return (
      <div>
        <p>
          <b>
            Dissociating {name(providerToDissociate)} from {username(user)}:
          </b>
        </p>
        <p>{description}</p>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            alignContent: 'center',
          }}
        >
          {options.map((o) => (
            <button className='pill primary mb-sm' style={{ fontWeight: 500 }} onClick={o.onSelect}>
              {o.name}
            </button>
          ))}
          <button className='pill secondary hollow' onClick={cancel}>
            Cancel
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <hr className='mt-md' ref={ref}></hr>
      <h5>Account Roles</h5>
      <div className='mt-md'>{existingRoles()}</div>
      <div
        style={{
          maxWidth: '800px',
        }}
      >
        {!addingProvider && !removingProvider && (
          <button className='primary pill mt-md' onClick={add}>
            Give user access to provider
          </button>
        )}
        {removingProvider && providerToDissociate && <div>{showDissociateOptions()}</div>}
        {addingProvider && !providerToAssociate && (
          <div className='association-container'>
            First, select a provider that you'd like this user to be able to access:
            <SearchTypeAhead
              items={providers}
              searchFn={searchFn}
              selected={[]}
              placeholder={'Filter Providers...'}
              field={({ first_name, last_name, company_name, id }) => `${first_name ? `${first_name} ${last_name}` : company_name} (id: ${id})`}
              onSelect={(id) => selectProviderToAssociate(providers.find((p) => p.id === id))}
            />
          </div>
        )}
        {addingProvider && providerToAssociate && <div>{showAssociateOptions()}</div>}
      </div>
      <div style={{ marginTop: '100px' }}>
        <hr></hr>
        <h5>Accounts</h5>
      </div>
      {userAccounts &&
        userAccounts.map((a, i) => (
          <div
            style={{
              backgroundColor: i % 2 === 0 ? 'white' : '#e8e8e8',
            }}
          >
            <h2>Account Notes</h2>
            <AccountNotes account={a} />
            <AccountGraph account={a} user={user} />
          </div>
        ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  providers: Object.values(state.providers.data),
  providerAccounts: state.users.providerAccounts,
  providerToAssociate: state.users.providerToAssociate,
  providerToDissociate: state.users.providerToDissociate,
});

export default connect(mapStateToProps, {
  searchProviders: actions.searchProviders,
  selectProviderToAssociate: userActions.selectProviderToAssociate,
  selectProviderToDissociate: userActions.selectProviderToDissociate,
  associateUserProvider: userActions.associateUserProvider,
  dissociateUserProvider: userActions.dissociateUserProvider,
})(ProviderAssociation);
