import React from 'react';
import rgx from '#/regex';

const validate = (values) => {
  const usernameExamples = ['johndoe', 'john.doe', 'john_doe', 'john-doe'].map((un, i) => (
    <code key={i}>{un}</code>
  ));

  const errors = {};
  if (!values.username) {
    errors.username = 'You must enter a name';
  } else if (!rgx.username.test(values.username)) {
    errors.username = ['Invalid username format. Examples: '].concat(usernameExamples);
  }

  if (!values.email) {
    errors.email = 'You must enter an email address';
  } else if (!rgx.email.test(values.email)) {
    errors.email = 'Invalid email address format';
  }

  return errors;
};

export default validate;
