import React from 'react';

export default function EditBox(props) {
  // eslint-disable-next-line
  const {
    boxClassName,
    title,
    description,
    editable,
    editing,
    setEditing,
    innerEditing,
    innerStatic,
  } = props;

  return (
    <div className={`box m-b-sm ${boxClassName}`}>
      <div className='header'>
        <h3 className='m-b-sm'>{title}</h3>
        {editable && (
          <button onClick={() => setEditing(!editing)} className={`${editing ? '' : 'primary'}`}>
            {editing ? 'Cancel' : 'Edit'}
          </button>
        )}
      </div>
      {description && <p className='instruction'>{description}</p>}
      {editable && editing ? innerEditing : innerStatic}
    </div>
  );
}
