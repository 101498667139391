import React from 'react';
import { onlyOnEnter, debounce } from '#/';

const SearchTypeAhead = (props) => {
  const { field, items = [], placeholder, selected, searchFn, onSelect } = props;

  const keyUp = ({ target }) => debounce(() => searchFn(target.value));

  const select = (item) => () => {
    const input = document.getElementById('typeahead-input');
    input.value = '';
    input.focus();

    onSelect(item.id);

    searchFn('');
  };

  const filter = (item) => {
    const { id } = item;
    const notSelected = selected.indexOf(id) === -1;

    return notSelected;
  };

  const _items = () => items.filter(filter);

  const displayName = (item) => {
    if (field.constructor === Function) {
      return field(item);
    }

    return item[field];
  };

  const keyPress = (e) => {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  };
  const filteredItems = _items();

  return (
    <div className='typeahead w-100'>
      <input
        id='typeahead-input'
        type='text'
        className='w-100 mb-lg'
        placeholder={placeholder || 'Filter items...'}
        onKeyUp={keyUp}
        onKeyPress={keyPress}
      />

      <div className='options'>
        {filteredItems.length ? (
          filteredItems.map((item, i) => (
            <div
              tabIndex={0}
              key={item.id || i}
              role='menuitem'
              onClick={select(item)}
              onKeyDown={onlyOnEnter(select(item))}
            >
              {displayName(item)}
            </div>
          ))
        ) : (
          <div className='grid justify-center align-center'>
            <h5>Loading</h5>
          </div>
        )}
      </div>
    </div>
  );
};

/*
<SearchTypeAhead
    field="name"
    items={ areas.all }
    selected={ area.neighborhoods }
    placeholder="Filter Neighborhoods by Name"
    onSelect={ id => setSelected(id) }
    searchFn={ fetchNeighborhoods }
/>
*/

export default SearchTypeAhead;
