import { crudSlice, noop, failReducer, loadingReducer } from 'saga-slice-helpers';
import history from '#/history';
import { sagaApi, api } from '#/apis';
import { takeLatest, put } from 'redux-saga/effects';
import { paginationReducer } from '#/sagaSlice';
import { flashSuccess } from '+/flashes/redux';

const sagaSliceModule = crudSlice({
  name: 'providers',
  sagaApi,
  reducers: {
    readAllSuccess: paginationReducer,

    getOverview: noop,
    getOverviewSuccess: (state, payload) => {
      state.overview = payload;
    },
    getOverviewFail: noop,

    previewCreditsFromCancelingLegacySubscriptions: (state, payload) => {
      state.cancelCreditsPreviewResultLoading = true;
      state.cancelCreditsPreviewResult = null;
    },
    previewCreditsFromCancelingLegacySubscriptionsSuccess: (state, payload) => {
      state.cancelCreditsPreviewResultLoading = false;
      state.cancelCreditsPreviewResult = payload;
    },
    previewCreditsFromCancelingLegacySubscriptionsFail: failReducer,

    getAccountsList: noop,
    getAccountsListSuccess: (state, payload) => {
      state.accountsList = payload;
    },
    getAccountsListFail: noop,

    getAccountSummary: noop,
    getAccountSummarySuccess: (state, payload) => {
      state.accounts = payload;
    },
    getAccountSummaryFail: noop,

    associate: noop,
    associateSuccess: noop,
    associateFail: noop,

    disassociate: noop,
    disassociateSuccess: noop,
    disassociateFail: noop,

    disassociateProviderFromGroupPracticeProfile: noop,
    disassociateProviderFromGroupPracticeProfileSuccess: noop,
    disassociateProviderFromGroupPracticeProfileFail: noop,

    updateStatus: noop,
    updateStatusSuccess: noop,
    updateStatusFail: noop,

    updateSort: noop,
    updateSortSuccess: noop,
    updateSortFail: noop,

    batchUpdatePromoted: noop,
    batchUpdatePromotedSuccess: noop,
    batchUpdatePromotedFail: failReducer,

    searchProviders: noop,
    searchProvidersSuccess: paginationReducer,
    searchProvidersFail: noop,

    readEvery: noop,
    readEverySuccess: (state, payload) => {
      state.data = payload;
    },
    readEveryFail: noop,

    getProviderFeedback: noop,
    getProviderFeedbackSuccess: (state, payload) => {
      state.providerFeedbackList = payload;
    },
    getProviderFeedbackFail: noop,

    getProviderLicenses: noop,
    getProviderLicensesSuccess: (state, payload) => {
      state.providerLicenses = payload;
    },
    getProviderLicensesFail: noop,

    generateMetabaseEmbedUrls: noop,
    generateMetabaseEmbedUrlsSuccess: (state, payload) => {
      state.metabaseEmbedUrls = payload;
    },
    generateMetabaseEmbedUrlsFail: noop,

    updateProviderLicenses: noop,
    updateProviderLicensesSuccess: noop,
    updateProviderLicensesFailure: failReducer,

    updateTestimonialDisplay: noop,
    updateTestimonialDisplaySuccess: noop,
    updateTestimonialDisplayFailure: failReducer,

    updateProviderFeedbackIgnored: noop,
    updateProviderFeedbackIgnoredSuccess: noop,
    updateProviderFeedbackIgnoredFailure: failReducer,

    updateFeedback: noop,
    updateFeedbackSuccess: noop,
    updateFeedbackFailure: failReducer,

    updateTestimonial: noop,
    updateTestimonialSuccess: noop,
    updateTestimonialFailure: failReducer,

    getGoogleCalendarStatus: noop,
    getGoogleCalendarStatusSuccess: (state, payload) => {
      state.googleCalendarStatus = payload;
    },
    getGoogleCalendarStatusFailure: failReducer,

    deleteProviderExternalCalendar: noop,
    deleteProviderExternalCalendarSuccess: noop,
    deleteProviderExternalCalendarFailure: failReducer,

    unlinkGoogleAccount: noop,
    unlinkGoogleAccountSuccess: noop,
    unlinkGoogleAccountFailure: failReducer,

    getAvailability: noop,
    getAvailabilitySuccess: (state, payload) => {
      state.availability = payload;
    },
    getAvailabilityFailure: failReducer,

    setCalendarStartTime: (state, payload) => {
      state.calendarStartTime = payload;
    },

    createCalendar: noop,
    createCalendarSuccess: (state, payload) => {
      if (state.current) {
        state.current.calendar = [payload];
      }
    },
    createCalendarFailure: failReducer,

    getAllAppointments: noop,
    getAllAppointmentsSuccess: (state, payload) => {
      state.appointments = payload;
    },
    getAllAppointmentsFailure: failReducer,

    getProviderEventLogs: noop,
    getProviderEventLogsSuccess: (state, payload) => {
      state.providerEventLogs = payload;
    },
    getProviderEventLogsFailure: failReducer,

    assignStripeCustomer: noop,
    assignStripeCustomerSuccess: noop,
    assignStripeCustomerFailure: failReducer,

    moveProviderToAccount: noop,
    moveProviderToAccountSuccess: noop,
    moveProviderToAccountFailure: failReducer,

    moveProviderToNewAccount: noop,
    moveProviderToNewAccountSuccess: noop,
    moveProviderToNewAccountFailure: failReducer,

    changeAccountOwner: noop,
    changeAccountOwnerSuccess: (state, payload) => {
      // Load whole window, not most efficient.
      window.location.reload();
    },
    changeAccountOwnerFailure: failReducer,

    removeProviderPaidSeat: noop,
    removeProviderPaidSeatSuccess: (state, payload) => {
      // Load whole window, not most efficient.
      window.location.reload();
    },
    removeProviderPaidSeatFailure: failReducer,

    addProviderPaidSeat: noop,
    addProviderPaidSeatSuccess: (state, payload) => {
      // Load whole window, not most efficient.
      window.location.reload();
    },
    addProviderPaidSeatFailure: failReducer,

    sendUpdateOwlWebhook: noop,
    sendUpdateOwlWebhookSuccess: (state, payload) => {
      alert('Sent web hook to owl!');
    },
    sendUpdateOwlWebhookFailure: failReducer,

    downgradeSubscriptionTier: noop,
    downgradeSubscriptionTierSuccess: (state, payload) => {
      // Load whole window, not most efficient.
      window.location.reload();
    },
    downgradeSubscriptionTierFailure: failReducer,

    upgradeSubscriptionTier: noop,
    upgradeSubscriptionTierSuccess: (state, payload) => {
      // Load whole window, not most efficient.
      window.location.reload();
    },
    upgradeSubscriptionTierFailure: failReducer,

    upgradeReimbursifyTier: noop,
    upgradeReimbursifyTierSuccess: (state, payload) => {
      // Load whole window, not most efficient.
      window.location.reload();
    },
    upgradeReimbursifyTierFailure: failReducer,

    downgradeReimbursifyTier: noop,
    downgradeReimbursifyTierSuccess: (state, payload) => {
      // Load whole window, not most efficient.
      window.location.reload();
    },
    downgradeReimbursifyTierFailure: failReducer,

    addSeat: noop,
    addSeatSuccess: (state, payload) => {
      // Load whole window, not most efficient.
      window.location.reload();
    },
    addSeatFailure: failReducer,

    removeSeat: noop,
    removeSeatSuccess: (state, payload) => {
      // Load whole window, not most efficient.
      window.location.reload();
    },
    removeSeatFailure: failReducer,

    removePaidReimbursifyAddOn: noop,
    removePaidReimbursifyAddOnSuccess: (state, payload) => {
      // Load whole window, not most efficient.
      window.location.reload();
    },
    removePaidReimbursifyAddOnFailure: failReducer,

    addReimbursifyEmbedCode: () => null,
    addReimbursifyEmbedCodeSuccess: (state, payload) => {
      // Load whole window, not most efficient.
      window.location.reload();
    },
    addReimbursifyEmbedCodeFailure: failReducer,

    updateReimbursifyEmbedCode: () => null,
    updateReimbursifyEmbedCodeSuccess: (state, payload) => {
      // Load whole window, not most efficient.
      window.location.reload();
    },
    updateReimbursifyEmbedCodeFailure: failReducer,

    toggleReimbursifyEmbedCodeDisplay: () => null,
    toggleReimbursifyEmbedCodeDisplaySuccess: (state, payload) => {
      // Load whole window, not most efficient.
      window.location.reload();
    },
    toggleReimbursifyEmbedCodeDisplayFailure: failReducer,

    // Not in use as per ZEN-3198
    updateProviderNpiNumbers: () => {},
    updateProviderNpiNumbersSuccess: (state, payload) => {
      // Load whole window, not most efficient.
      // window.location.reload();
    },
    updateProviderNpiNumbersFailure: failReducer,

    updateProviderPracticeManagementBookingLink: loadingReducer,
    updateProviderPracticeManagementBookingLinkSuccess: () => {},
    updateProviderPracticeManagementBookingLinkFailure: failReducer,

    cancellationRequestReceived: loadingReducer,
    cancellationRequestReceivedSuccess: (state, payload) => {
      state.cancellationRequestReceivedData = payload;
    },
    cancellationRequestReceivedFail: failReducer,
  },
  takers: {
    readOne: takeLatest,
    readAll: takeLatest,
    searchProviders: takeLatest,
  },

  //eslint-disable-next-line
  sagas: (A) => ({
    [A.readAll]: {
      *saga({ payload = {} }) {
        const { type = 'individual', page = 0, order = 'asc', orderBy = 'first_name', association = false } = payload;
        window.scrollTo(0, 0);
        yield sagaApi.get(`/providers?${type ? `type=${type}` : ''}&page=${page}&order=${order}&orderBy=${orderBy}`, A.readAllSuccess, A.readAllFail);
        if (!association) yield put(A.resetCurrent());
      },
    },
    [A.readEvery]: {
      *saga() {
        yield sagaApi.get('/providers/all', A.readEverySuccess, A.readEveryFail);
      },
    },
    [A.create]: {
      *saga({ payload }) {
        const { values, type } = payload;

        yield sagaApi.post(`/providers/${type}`, values, A.createSuccess, A.createFail);
      },
    },
    [A.createSuccess]: {
      *saga({ payload }) {
        yield history.push(`/providers/view/${payload.id}`);
        yield put(A.setCurrent(payload.id));
      },
    },
    [A.updateSuccess]: {
      *saga({ payload }) {
        yield put(A.readOne(payload.id));
      },
    },
    [A.deleteSuccess]: {
      *saga() {
        yield history.push('/providers/individual');
      },
    },
    [A.readOneSuccess]: {
      *saga({ payload: provider }) {
        yield put(A.setCurrent(provider.id));
      },
    },
    [A.getOverview]: {
      *saga({ payload }) {
        yield sagaApi.get(`/providers/${payload.id}/overview`, A.getOverviewSuccess, A.getOverviewFail);
      },
    },
    [A.previewCreditsFromCancelingLegacySubscriptions]: {
      *saga({ payload }) {
        yield sagaApi.get(
          `/account/${payload.account_id}/preview-credits-from-canceling-legacy-subscriptions`,
          A.previewCreditsFromCancelingLegacySubscriptionsSuccess,
          A.previewCreditsFromCancelingLegacySubscriptionsFail
        );
      },
    },
    [A.getAccountsList]: {
      *saga() {
        yield sagaApi.get('/accounts', A.getAccountsListSuccess, A.getAccountsListFail);
      },
    },
    [A.getAccountSummary]: {
      *saga({ payload }) {
        if (payload.setStatus) {
          yield payload.setStatus('loading');
        }

        yield sagaApi.get(`/accounts/provider/${payload.id}`, A.getAccountSummarySuccess, A.getAccountSummaryFail);

        if (payload.setStatus) {
          yield payload.setStatus('idle');
        }
      },
    },
    [A.searchProviders]: {
      *saga({ payload = '' }) {
        const { name, type, association = false, order, orderBy } = payload;

        try {
          if (name.length < 1) {
            yield put(
              A.readAll({
                type,
                association,
                order,
                orderBy,
              })
            );
          } else if (name.length > 0) {
            const url = `/providers/search/${name}${type ? `?type=${type}` : ''}`;
            const { data } = yield api.get(url);
            yield put(A.searchProvidersSuccess(data));
          }
        } catch (error) {
          yield put(A.searchProvidersFail(error));
        }
      },
    },
    [A.associate]: {
      *saga({ payload }) {
        const { providerId, association, associationId, fetchFn } = payload;
        yield sagaApi.put(`/providers/${providerId}/${association}/${associationId}`, null, A.associateSuccess, A.associateFail);
        fetchFn && fetchFn();
      },
    },
    [A.disassociate]: {
      *saga({ payload }) {
        const { providerId, association, associationId, fetchFn } = payload;
        yield sagaApi.delete(`/providers/${providerId}/${association}/${associationId}`, null, A.disassociateSuccess, A.disassociateFail);
        fetchFn && fetchFn();
      },
    },
    [A.disassociateProviderFromGroupPracticeProfile]: {
      *saga({ payload }) {
        const { providerId, groupPracticeProviderId, fetchFn } = payload;
        yield sagaApi.delete(
          `/providers/disassociate-provider/${providerId}/${groupPracticeProviderId}`,
          null,
          A.disassociateProviderFromGroupPracticeProfileSuccess,
          A.disassociateProviderFromGroupPracticeProfileFail
        );
        fetchFn && fetchFn();
      },
    },
    [A.updateStatus]: {
      *saga({ payload }) {
        const { id, status } = payload;
        yield sagaApi.put(`/providers/${id}/status`, { status }, A.updateStatusSuccess, A.updateStatusFail);
      },
    },
    [A.updateSort]: {
      *saga({ payload }) {
        const { id, newProviderOrder } = payload;
        yield sagaApi.put(`/providers/${id}/sort`, { newProviderOrder }, A.updateStatusSuccess, A.updateStatusFail);
      },
    },
    [A.batchUpdatePromoted]: {
      *saga({ payload }) {
        yield sagaApi.put('/providers/promoted', payload, A.batchUpdatePromotedSuccess, A.batchUpdatePromotedFail);
      },
    },
    [A.batchUpdatePromotedSuccess]: {
      *saga() {
        yield history.push('/providers/individual');
      },
    },
    [A.getProviderFeedback]: {
      *saga({ payload }) {
        yield sagaApi.get(`/provider-feedback/provider/${payload.provider_id}`, A.getProviderFeedbackSuccess, A.getProviderFeedbackFail);
      },
    },
    [A.updateTestimonialDisplay]: function* ({ payload }) {
      yield sagaApi.put(
        `/provider-feedback/${payload.provider_feedback_id}/display_testimonial`,
        { display_testimonial: payload.display_testimonial },
        A.updateTestimonialDisplaySuccess,
        A.updateTestimonialDisplayFailure
      );
      // Reload list now that we have modified it
      yield sagaApi.get(`/provider-feedback/provider/${payload.provider_id}`, A.getProviderFeedbackSuccess, A.getProviderFeedbackFail);
    },
    [A.updateProviderFeedbackIgnored]: function* ({ payload }) {
      yield sagaApi.put(`/provider-feedback/${payload.provider_feedback_id}/ignored`, { ignored: payload.ignored }, A.updateProviderFeedbackIgnoredSuccess, A.updateProviderFeedbackIgnoredFailure);
      // Reload list now that we have modified it (required to change row color)
      yield sagaApi.get(`/provider-feedback/provider/${payload.provider_id}`, A.getProviderFeedbackSuccess, A.getProviderFeedbackFail);
    },
    [A.updateFeedback]: function* ({ payload }) {
      yield sagaApi.put(`/provider-feedback/${payload.provider_feedback_id}/feedback`, { feedback: payload.feedback }, A.updateFeedbackSuccess, A.updateFeedbackFailure);
      // Reload list now that we have modified it
      yield sagaApi.get(`/provider-feedback/provider/${payload.provider_id}`, A.getProviderFeedbackSuccess, A.getProviderFeedbackFail);
    },
    [A.updateTestimonial]: function* ({ payload }) {
      yield sagaApi.put(`/provider-feedback/${payload.provider_feedback_id}/testimonial`, { testimonial: payload.testimonial }, A.updateTestimonialSuccess, A.updateTestimonialFailure);
      // Reload list now that we have modified it
      yield sagaApi.get(`/provider-feedback/provider/${payload.provider_id}`, A.getProviderFeedbackSuccess, A.getProviderFeedbackFail);
    },
    [A.getProviderLicenses]: {
      *saga({ payload }) {
        yield sagaApi.get(`/provider-licenses/provider/${payload.provider_id}`, A.getProviderLicensesSuccess, A.getProviderLicensesFail);
      },
    },
    [A.updateProviderLicenses]: function* ({ payload }) {
      const providerId = payload.provider_id;
      delete payload.provider_id;
      yield sagaApi.put(`/provider-licenses/provider/${providerId}`, payload, A.updateProviderLicensesSuccess, A.updateProviderLicensesFailure);
      // Reload list now that we have modified it
      yield sagaApi.get(`/provider-licenses/provider/${providerId}`, A.getProviderLicensesSuccess, A.getProviderLicensesFail);
      // Reload provider status in case it changed
      yield put(A.readOne(providerId));
    },
    [A.generateMetabaseEmbedUrls]: {
      *saga({ payload }) {
        yield sagaApi.post('/metabase/generate-embed-urls', payload, A.generateMetabaseEmbedUrlsSuccess, A.generateMetabaseEmbedUrlsFail);
      },
    },
    [A.getGoogleCalendarStatus]: {
      *saga({ payload }) {
        yield sagaApi.get(`/providers/${payload.provider_id}/google-calendar-status`, A.getGoogleCalendarStatusSuccess, A.getGoogleCalendarStatusFailure);
      },
    },
    [A.deleteProviderExternalCalendar]: {
      *saga({ payload }) {
        yield sagaApi.delete(
          `/providers/external-calendars/${payload.provider_id}/${payload.provider_external_calendar_id}`,
          {},
          A.deleteProviderExternalCalendarSuccess,
          A.deleteProviderExternalCalendarFailure
        );

        yield put(A.getGoogleCalendarStatus({ provider_id: payload.provider_id }));
      },
    },
    [A.unlinkGoogleAccount]: {
      *saga({ payload }) {
        yield sagaApi.delete(`/providers/google-account/${payload.provider_id}`, {}, A.unlinkGoogleAccountSuccess, A.unlinkGoogleAccountFailure);

        yield put(A.getGoogleCalendarStatus({ provider_id: payload.provider_id }));
      },
    },
    [A.getAvailability]: function* (...args) {
      console.log('args', args);
      const payload = args[0].payload;
      const { calendar_id } = payload;
      delete payload.calendar_id;
      const queryString = Object.keys(payload)
        .map((k) => `${k}=${payload[k]}`)
        .join('&');

      try {
        const { data } = yield api.get(`/calendar/${calendar_id}/availability?${queryString}`);
        if (!data) {
          return yield put(A.getAvailabilityFailure());
        }

        return yield put(A.getAvailabilitySuccess(data));
      } catch (e) {
        console.log(e);
        yield put(A.getAvailabilityFailure());
      }
    },
    [A.getAllAppointments]: {
      *saga({ payload }) {
        yield sagaApi.get(`/providers/${payload.provider_id}/appointments`, A.getAllAppointmentsSuccess, A.getAllAppointmentsFailure);
      },
    },
    [A.getProviderEventLogs]: {
      *saga({ payload }) {
        yield sagaApi.get(`/providers/${payload.provider_id}/event-logs`, A.getProviderEventLogsSuccess, A.getProviderEventLogsFailure);
      },
    },
    [A.createCalendar]: {
      *saga({ payload }) {
        yield sagaApi.post(`/providers/${payload.provider_id}/calendar`, null, A.createCalendarSuccess, A.createCalendarFailure);
      },
    },
    [A.assignStripeCustomer]: {
      *saga({ payload }) {
        yield sagaApi.put(`/account/${payload.account_id}/stripe-customer/${payload.stripe_customer_id}`, {}, A.assignStripeCustomerSuccess, A.assignStripeCustomerFailure);
        if (payload.account_id) {
          // Should always be truthy
          yield put({ type: 'accounts/getAccountSummary', payload: { id: payload.account_id } });
        } else if (payload.provider_id) {
          yield put(A.getAccountSummary({ id: payload.provider_id }));
        } else if (payload.user_id) {
          yield put({
            type: 'users/getAccountSummary',
            payload: { id: payload.user_id },
          });
        }
      },
    },
    [A.moveProviderToAccount]: {
      *saga({ payload }) {
        yield sagaApi.put(`/account/${payload.account_id}/move-provider-into-account/${payload.provider_id}`, {}, A.moveProviderToAccountSuccess, A.moveProviderToAccountFailure);
        window.location.href = `/providers/view/${payload.provider_id}/account%20Information`;
      },
    },
    [A.moveProviderToNewAccount]: {
      *saga({ payload }) {
        yield sagaApi.put(`/account/move-provider-into-new-account/${payload.provider_id}`, {}, A.moveProviderToNewAccountSuccess, A.moveProviderToNewAccountFailure);
        window.location.href = `/providers/view/${payload.provider_id}/account%20Information`;
      },
    },
    [A.changeAccountOwner]: {
      *saga({ payload }) {
        yield sagaApi.put(`/account/${payload.account_id}/change-account-owner/${payload.user_id}`, {}, A.changeAccountOwnerSuccess, A.changeAccountOwnerFailure);
      },
    },
    [A.addProviderPaidSeat]: {
      *saga({ payload }) {
        yield sagaApi.put(`/provider-paid-seats/${payload.provider_id}`, {}, A.addProviderPaidSeatSuccess, A.addProviderPaidSeatFailure);
      },
    },
    [A.removeProviderPaidSeat]: {
      *saga({ payload }) {
        yield sagaApi.delete(`/provider-paid-seats/${payload.provider_id}`, {}, A.removeProviderPaidSeatSuccess, A.removeProviderPaidSeatFailure);
      },
    },
    [A.sendUpdateOwlWebhook]: {
      *saga({ payload }) {
        yield sagaApi.post('/account/practice-management/send-update-owl-webhook', payload, A.sendUpdateOwlWebhookSuccess, A.sendUpdateOwlWebhookFailure);
      },
    },
    [A.downgradeSubscriptionTier]: {
      *saga({ payload }) {
        yield sagaApi.post(
          `/stripe-subscriptions/downgrade-zpm-tier/${payload.stripe_customer_id}/${payload.stripe_subscription_id}`,
          { new_tier: payload.new_tier },
          A.downgradeSubscriptionTierSuccess,
          A.downgradeSubscriptionTierFailure
        );
      },
    },
    [A.upgradeSubscriptionTier]: {
      *saga({ payload }) {
        yield sagaApi.post(
          `/stripe-subscriptions/upgrade-zpm-tier/${payload.stripe_customer_id}/${payload.stripe_subscription_id}`,
          { new_tier: payload.new_tier },
          A.upgradeSubscriptionTierSuccess,
          A.upgradeSubscriptionTierFailure
        );
      },
    },
    [A.upgradeReimbursifyTier]: {
      *saga({ payload }) {
        yield sagaApi.post(
          `/stripe-subscriptions/upgrade-reimbursify-tier/${payload.stripe_customer_id}/${payload.stripe_subscription_id}`,
          { new_tier: payload.new_tier },
          A.upgradeReimbursifyTierSuccess,
          A.upgradeReimbursifyTierFailure
        );
      },
    },
    [A.downgradeReimbursifyTier]: {
      *saga({ payload }) {
        yield sagaApi.post(
          `/stripe-subscriptions/downgrade-reimbursify-tier/${payload.stripe_customer_id}/${payload.stripe_subscription_id}`,
          { new_tier: payload.new_tier },
          A.downgradeReimbursifyTierSuccess,
          A.downgradeReimbursifyTierFailure
        );
      },
    },
    [A.addSeat]: {
      *saga({ payload }) {
        yield sagaApi.post(
          `/stripe-subscriptions/add-seat/${payload.stripe_customer_id}/${payload.stripe_subscription_id}`,
          {
            is_ny_or_ca: payload.is_ny_or_ca,
            tier: payload.tier,
            provider_id: payload.provider_id,
          },
          A.addSeatSuccess,
          A.addSeatFailure
        );
      },
    },
    [A.removeSeat]: {
      *saga({ payload }) {
        yield sagaApi.post(
          `/stripe-subscriptions/remove-seat/${payload.stripe_customer_id}/${payload.stripe_subscription_id}`,
          {
            is_ny_or_ca: payload.is_ny_or_ca,
            tier: payload.tier,
            provider_id: payload.provider_id,
          },
          A.removeSeatSuccess,
          A.removeSeatFailure
        );
      },
    },
    [A.removePaidReimbursifyAddOn]: {
      *saga({ payload }) {
        yield sagaApi.post(
          `/stripe-subscriptions/remove-reimbursify-add-on/${payload.stripe_customer_id}/${payload.stripe_subscription_id}`,
          null,
          A.removePaidReimbursifyAddOnSuccess,
          A.removePaidReimbursifyAddOnFailure
        );
      },
    },
    [A.addReimbursifyEmbedCode]: {
      *saga({ payload }) {
        const { provider_id, embed_code } = payload;
        yield sagaApi.put(`/reimbursify-verifast-embed-codes/${provider_id}`, { embed_code }, A.addReimbursifyEmbedCodeSuccess, A.addReimbursifyEmbedCodeFailure);
      },
    },
    [A.updateReimbursifyEmbedCode]: {
      *saga({ payload }) {
        const { provider_id, embed_code } = payload;
        yield sagaApi.post(`/reimbursify-verifast-embed-codes/edit/${provider_id}`, { embed_code }, A.updateReimbursifyEmbedCodeSuccess, A.updateReimbursifyEmbedCodeFailure);
      },
    },
    [A.toggleReimbursifyEmbedCodeDisplay]: {
      *saga({ payload }) {
        const { provider_id, show } = payload;
        yield sagaApi.post(`/reimbursify-verifast-embed-codes/toggle/${provider_id}`, { show }, A.toggleReimbursifyEmbedCodeDisplaySuccess, A.toggleReimbursifyEmbedCodeDisplayFailure);
      },
    },
    // Not in use as per ZEN-3198
    [A.updateProviderNpiNumbers]: {
      *saga({ payload }) {
        yield sagaApi.post(`/provider-npi-numbers`, payload, A.updateProviderNpiNumbersSuccess, A.updateProviderNpiNumbersFailure);
      },
    },
    [A.updateProviderNpiNumbersSuccess]: {
      *saga({ payload }) {
        yield put(flashSuccess('NPI Numbers updated successfully!'));
        yield put(A.readOne(payload.provider_id));
      },
    },
    [A.updateProviderPracticeManagementBookingLink]: {
      *saga({ payload }) {
        yield sagaApi.post(`/practice-management-booking-link`, payload, () => A.updateProviderPracticeManagementBookingLinkSuccess(payload), A.updateProviderPracticeManagementBookingLinkFailure);
      },
    },
    [A.updateProviderPracticeManagementBookingLinkSuccess]: {
      *saga({ payload }) {
        yield put(flashSuccess('Booking link updated successfully!'));
        yield put(A.readOne(payload.provider_id));
      },
    },
    [A.cancellationRequestReceived]: {
      *saga({ payload }) {
        yield sagaApi.put(`/providers/${payload.provider_id}/cancellation-request-received`, payload, A.cancellationRequestReceivedSuccess, A.cancellationRequestReceivedFail);
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
