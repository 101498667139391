import { put, select, takeLatest, take, fork, cancel, delay } from 'redux-saga/effects';
import { createModule } from 'saga-slice';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { failReducer, loadingReducer, noop } from 'saga-slice-helpers';

import storage from '#/storage';
import history from '#/history';

import { addAuthorization, removeAuthorization, sagaApi } from '#/apis';

import { flashInfo } from '+/flashes/redux';
import Cookies from 'js-cookie';

dayjs.extend(utc);

// Local storage auth
let lsAuth = storage.get('auth');

const initialState = {
  isSignedIn: false,
  token: null,
  role: null,
  scope: null,
  lastLogin: null,
  authorization: null,
  settings: {},
  expires: null,
  id: null,
  isLoading: false,
  error: null,
  isCheckingSessions: false,
  twoFactorCodeNeeded: false,
};

if (lsAuth) {
  const expiry = dayjs.utc(lsAuth.expiresAt);
  const timeout = dayjs.utc(lsAuth.refreshBy);
  const now = dayjs().utc();

  if (now.isAfter(expiry) || now.isAfter(timeout)) {
    storage.rmv('auth');
  } else {
    // Add auth header to API
    addAuthorization(lsAuth.authorization);
  }
}

const sagaSliceModule = createModule({
  name: 'auth',

  // Restore from local storage
  initialState: Object.assign(initialState, {
    ...(lsAuth || {}),
    isSignedIn: !!lsAuth,
  }),
  reducers: {
    routeToLogin: noop,

    login: loadingReducer,
    loginVerifyTwoFactor: loadingReducer,
    loginSuccess: (state, payload) => {
      // See if 2FA is turned on, if so, prompt for the 2FA code to be entered
      if (payload.twoFactorCodeNeeded === true) {
        Object.assign(state, {
          isLoading: false,
          twoFactorCodeNeeded: true,
          error: null,
        });
      } else {
        Object.assign(state, {
          ...payload,
          isLoading: false,
          isSignedIn: true,
          error: null,
        });

        addAuthorization(payload.authorization);
        storage.set('auth', payload);
      }
    },
    loginVerifyTwoFactorSuccess: (state, payload) => {
      Object.assign(state, {
        ...payload,
        isLoading: false,
        isSignedIn: true,
        error: null,
      });
      addAuthorization(payload.authorization);
      storage.set('auth', payload);

      Cookies.set('two_factor_remember_token', payload.two_factor_remember_token, {
        // Expire in 30 days
        expires: 30,
        sameSite: 'strict',
        secure: true,
      });
    },
    loginFail: failReducer,

    logout: noop,
    logoutSuccess: (state, payload) => {
      Object.assign(state, {
        isLoading: false,
        isSignedIn: false,
      });
    },
    logoutFail: failReducer,
    logoutDone: (state) => Object.assign(state, initialState),

    register: noop,
    registerDone: noop,
    confirm: noop,
    confirmSuccess: noop,
    requestReset: noop,
    resetPassword: noop,
    updatePassword: noop,

    // We need to constantly check for expired auth
    setCheckingSessions: (state, checking) => {
      state.isCheckingSessions = checking;
    },
    checkSession: noop,
    checkSessionStart: noop,
    checkSessionLoop: noop,
    checkSessionCancel: noop,
    checkSessionSuccess: noop,
    checkSessionFail: noop,
    checkSessionDone: noop,
    refreshSession: noop,
    refreshSessionSuccess: noop,
    refreshSessioFail: noop,
    setShouldRefreshSession: (state, payload) => {
      state.shouldRefreshSession = payload;
    },

    noop,
  },
  // eslint-disable-next-line max-lines-per-function
  sagas: (A) => {
    const sagas = {
      [A.routeToLogin]: {
        *saga() {
          yield history.push('/login');
        },
      },
      [A.login]: {
        *saga({ payload }) {
          const newPayload = { ...payload };

          const two_factor_remember_token = Cookies.get('two_factor_remember_token');
          if (two_factor_remember_token) {
            newPayload.two_factor_remember_token = two_factor_remember_token;
          }

          yield sagaApi.put('/auth/admin', newPayload, A.loginSuccess, A.loginFail);
        },
      },
      [A.loginVerifyTwoFactor]: {
        *saga({ payload }) {
          yield sagaApi.put('/auth/admin-verify-2fa', payload, A.loginVerifyTwoFactorSuccess, A.loginFail);
        },
      },
      [A.logout]: {
        *saga() {
          yield sagaApi.delete('/auth', null, A.logoutSuccess, A.logoutFail, A.logoutDone);
        },
      },
      [A.logoutDone]: {
        *saga() {
          storage.rmv('auth');
          removeAuthorization();
          yield put(A.setCheckingSessions(false));
          yield put(A.checkSessionCancel());
          yield put(flashInfo('You have been logged out'));
          yield delay(1000);
          window.location.href = '/login';
        },
      },

      [A.register]: {
        *saga({ payload }) {
          yield sagaApi.post('/auth', payload, A.routeToLogin, A.noop);
        },
      },
      [A.confirm]: {
        *saga({ payload }) {
          yield sagaApi.get(`/auth/confirm/${payload}`, A.confirmSuccess, A.noop);
        },
      },
      [A.confirmSuccess]: {
        *saga() {
          put(A.routeToLogin());
          yield delay(1000);
          yield put(flashInfo('Your account has been confirmed'));
        },
      },
      [A.confirmFail]: {
        *saga() {
          yield history.push('/');
        },
      },
      [A.requestReset]: {
        *saga({ payload }) {
          yield sagaApi.post('/auth/password/reset/from-admin', payload, A.routeToLogin, A.noop);
        },
      },
      [A.resetPassword]: {
        *saga({ payload }) {
          yield sagaApi.put('/auth/password/reset/from-admin', payload, A.noop, A.noop, A.routeToLogin);
        },
      },

      [A.updatePassword]: {
        *saga({ payload }) {
          yield sagaApi.post('/auth/password/update/from-admin', payload, A.logoutDone, A.noop);
        },
      },
      [A.checkSession]: {
        *saga() {
          lsAuth = storage.get('auth');
          if (lsAuth) {
            addAuthorization(lsAuth.authorization);
          }
          yield sagaApi.get('/auth/session', A.checkSessionSuccess, A.logoutDone, A.checkSessionDone);
        },
        taker: takeLatest,
      },

      [A.refreshSession]: {
        *saga() {
          yield sagaApi.post('/auth/session/refresh', undefined, A.refreshSessionSuccess, A.logoutDone);
        },
        taker: takeLatest,
      },
      [A.refreshSessionSuccess]: {
        *saga({ payload }) {
          storage.set('auth', payload);
          addAuthorization(payload.authorization);
          yield put(A.setShouldRefreshSession(false));
        },
        taker: takeLatest,
      },

      /**
       * Asserts that session is valid.
       * Checks session every 60 seconds.
       * Checks if session is expired.
       * If any case is true, trigger a logou
       */
      [A.checkSessionLoop]: {
        *saga() {
          const isCheckingSessions = yield select((state) => state.auth.isCheckingSessions);
          if (isCheckingSessions) {
            return;
          }

          yield put(A.setCheckingSessions(true));
          let mod = 0;
          try {
            while (true) {
              yield delay(1000);

              mod = (mod + 1) % 5;

              const { isSignedIn, shouldRefreshSession } = yield select((state) => state.auth);

              if (!isSignedIn) {
                yield put(A.checkSessionCancel());
                break;
              }

              if (mod === 4 && shouldRefreshSession) {
                yield put(A.refreshSession());
              } else {
                yield put(A.checkSession());
              }

              yield delay(1 * 60 * 1000);
            }
          } finally {
          }
        },
      },
    };

    const checkSessionStart = function* () {
      const isCheckingSessions = yield select((state) => state.auth.isCheckingSessions);
      if (isCheckingSessions) {
        return;
      }

      yield put(A.checkSessionCancel());

      const recur = yield fork(sagas[A.checkSessionLoop].saga, {});

      yield take(A.checkSessionCancel.type);
      yield cancel(recur);
    };

    // Handle 401s to /auth/session
    const sagaApi401 = function* ({ payload }) {
      const {
        data,
        config: { method, url },
      } = payload;

      const currentAuth = storage.get('auth');

      if (currentAuth && JSON.stringify(currentAuth) !== JSON.stringify(lsAuth)) {
        lsAuth = storage.get('auth');
        addAuthorization(lsAuth.authorization);

        return;
      }

      const isAuthSession = /\/auth\/session/.test(url);
      const invalidCredentials = /invalid credentials/i.test(data.message);
      const missingAuthentication = /Missing authentication/i.test(data.message);

      // Boolean checks that would generate a logout
      // If none of these match, this array should not
      // have any `true` values
      const cases = [
        // Check to auth session
        method === 'get' && isAuthSession,

        // When checking against API and have invalid credentials
        !isAuthSession && invalidCredentials,

        // When auth is not present in store
        !isAuthSession && missingAuthentication,
      ];

      if (cases.includes(true)) {
        yield delay(2050);
        yield put(A.logoutDone());
      }
    };

    sagas[A.checkSessionStart] = {
      saga: checkSessionStart,
      taker: takeLatest,
    };

    sagas['sagaApi/401'] = {
      saga: sagaApi401,
      taker: takeLatest,
    };

    return sagas;
  },
});

export const selectScope = (state) => state.auth.scope;

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
