import React, { useRef } from 'react';
import { onlyOnEnter } from '#/';

const Pagination = (props) => {
  const { total = 0, pageSize = 50, fetchFn, isHidden = false } = props;
  const pageNumber = useRef(0);
  const totalPages = Math.ceil(total / pageSize);
  const listClass = 'list-none pointer text-info border p-sm mx-xs';
  const activeClass = 'list-none pointer bg-info text-white border p-sm mx-xs';

  if (totalPages < 2) return <nav />;

  const selectPage = (page) => {
    pageNumber.current = page;
    fetchFn(pageNumber.current);
  };

  const createPages = () => {
    const pages = [];
    for (let i = 0; i < totalPages; i++) {
      pages.push(
        <div
          tabIndex={0}
          role='menuitem'
          key={`page#${i}`}
          className={pageNumber.current === i ? activeClass : listClass}
          onClick={() => selectPage(i)}
          onKeyDown={onlyOnEnter(() => selectPage(i))}
        >
          {i + 1}
        </div>
      );
    }

    if (pages.length > 15) {
      const newPage = (
        <div key='ellipse' className={listClass}>
          ...
        </div>
      );
      pages.splice(5, pages.length - 10, newPage);
    }

    return pages;
  };

  const nextPage = () => {
    if (pageNumber.current === totalPages) return;
    pageNumber.current = pageNumber.current + 1;
    fetchFn(pageNumber.current);
  };

  const prevPage = () => {
    if (pageNumber.current >= 0) {
      pageNumber.current = pageNumber.current - 1;
      fetchFn(pageNumber.current);
    }
  };

  return (
    <nav className={`my-md scroll-x ${isHidden ? 'hide' : ''}`}>
      <div className='grid row justify-center align-center'>
        <button onClick={prevPage} disabled={pageNumber.current < 1} className='primary mr-sm'>
          Previous
        </button>

        {createPages()}

        <button
          disabled={pageNumber.current + 1 === totalPages}
          onClick={nextPage}
          className='primary ml-sm'
        >
          Next
        </button>
      </div>
    </nav>
  );
};
export default Pagination;
