/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import EditableCard from '+/forms/EditableCard';
import EditableText from '+/forms/EditableText';
import ToggleStateSwitch from '+/forms/ToggleStateSwitch';

const EditSearchContent = ({ currentSearchContentOverride, saveSearchContentOverride, searchContentOverrides, getSearchContentOverrideById, getSearchContentOverrides, match = { params: {} } }) => {
  const [rowLoadStarted, setRowLoadStarted] = useState(false);
  const [existingListLoaded, setExistingListLoaded] = useState(false);
  const [autoSaveEnabled, setAutoSaveEnabled] = useState(false);
  const [url, setUrl] = useState('');
  const [topBeforeMore, setTopBeforeMore] = useState('');
  const [topAfterMore, setTopAfterMore] = useState('');
  const [bottomContent, setBottomContent] = useState('');

  const id = parseInt(match.params.searchContentOverrideId);

  // Initial loading of row being editing
  useEffect(() => {
    if (!rowLoadStarted) {
      getSearchContentOverrideById({ id });
      setRowLoadStarted(true);
    }
  }, [getSearchContentOverrideById, id, rowLoadStarted]);

  // Initial loading of URLS already in use
  useEffect(() => {
    if (!existingListLoaded) {
      getSearchContentOverrides();
      setExistingListLoaded(true);
    }
  }, [existingListLoaded, getSearchContentOverrides]);

  const saveChanges = useCallback(() => {
    saveSearchContentOverride({
      id,
      data: {
        url,
        top_content_before_more: topBeforeMore,
        top_content_after_more: topAfterMore,
        bottom_content: bottomContent,
      },
    });
  }, [bottomContent, id, saveSearchContentOverride, topAfterMore, topBeforeMore, url]);

  // Save changes automatically (after user clicks green checkmark button on each field) but only start this once first edit has been made
  useEffect(() => {
    if (autoSaveEnabled) {
      saveChanges();
    }
  }, [autoSaveEnabled, saveChanges, url, topBeforeMore, topAfterMore, bottomContent]);

  // Initialize current values from DB but stop doing this once we start editing
  useEffect(() => {
    if (currentSearchContentOverride && !autoSaveEnabled) {
      setUrl(currentSearchContentOverride.url);
      setTopBeforeMore(currentSearchContentOverride.top_content_before_more);
      setTopAfterMore(currentSearchContentOverride.top_content_after_more);
      setBottomContent(currentSearchContentOverride.bottom_content);
    }
  }, [autoSaveEnabled, bottomContent, currentSearchContentOverride, topAfterMore, topBeforeMore]);

  if (!currentSearchContentOverride) {
    return 'Loading...';
  }

  const urlInUse = searchContentOverrides.find((x) => x.id !== id && x.url === url);

  const openCacheBustingLink = () => {
    if (url.includes('?')) {
      window.open(`${process.env.ZENCARE_WEBSITE_URL}${url}&cache_bust=${new Date().getTime()}`);
    } else {
      window.open(`${process.env.ZENCARE_WEBSITE_URL}${url}?cache_bust=${new Date().getTime()}`);
    }
  };

  const handleForceIncludeInSitemapChange = (payload) => {
    let forceIncludeInSitemap = 0;
    if (payload.force_include_in_sitemap) {
      forceIncludeInSitemap = 1;
    }

    saveSearchContentOverride({
      id,
      data: {
        url,
        force_include_in_sitemap: forceIncludeInSitemap,
      },

      callback: () => {
        getSearchContentOverrideById({ id });
      },
    });
  };

  return (
    <div className='edit-search-content-override'>
      Want to view the latest page data without having to manually bypass cloudflare cache or wait 5 hours?{' '}
      <button onClick={openCacheBustingLink}>Click this button to get a cache-busting link.</button>
      <EditableText
        initialState={currentSearchContentOverride.url}
        label='URL (e.g /test?abc=1):'
        name='url'
        styles='w-100'
        updateFn={(payload) => {
          if (!urlInUse) {
            setAutoSaveEnabled(true);
            setUrl(payload.url);
          }
        }}
      />
      {urlInUse ? <p className='text-error'>Error: URL is already in use! Please edit instead!</p> : ''}
      <EditableCard
        initialState={currentSearchContentOverride.top_content_before_more}
        label='Top Content: Text before the "More" link'
        name='top_content_before_more'
        styles={'w-100'}
        updateFn={(payload) => {
          setAutoSaveEnabled(true);
          setTopBeforeMore(payload.top_content_before_more);
        }}
      />
      <EditableCard
        initialState={currentSearchContentOverride.top_content_after_more}
        label='Top Content: Text to show after the "More" link is clicked'
        name='top_content_after_more'
        styles={'w-100'}
        updateFn={(payload) => {
          setAutoSaveEnabled(true);
          setTopAfterMore(payload.top_content_after_more);
        }}
      />
      <EditableCard
        initialState={currentSearchContentOverride.bottom_content}
        label='Bottom Content'
        name='bottom_content'
        styles={'w-100'}
        updateFn={(payload) => {
          setAutoSaveEnabled(true);
          setBottomContent(payload.bottom_content);
        }}
      />
      <div className='m-l-md'>
        <ToggleStateSwitch
          label='Force include in sitemap?'
          name='force_include_in_sitemap'
          initialState={currentSearchContentOverride.force_include_in_sitemap}
          updateFn={handleForceIncludeInSitemapChange}
        />
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    currentSearchContentOverride: state.searchContent.currentSearchContentOverride,
    searchContentOverrides: state.searchContent.searchContentOverrides,
  }),
  actions
)(EditSearchContent);
