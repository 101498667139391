import DefaultLayout from '##/Default';

import List from './List';
import View from './View';
import Add from './Add';

export default [
  {
    path: '/therapy-groups',
    exact: true,
    layout: DefaultLayout,
    component: List,
    title: 'View Therapy Groupss',
  },
  {
    path: '/therapy-groups/add',
    exact: true,
    layout: DefaultLayout,
    component: Add,
    title: 'Add New Therapy Groups',
  },
  {
    path: '/therapy-groups/view/:id',
    exact: true,
    layout: DefaultLayout,
    component: View,
    title: 'View one Therapy Groups',
  },
  {
    path: '/therapy-groups/view/:id/:tab',
    exact: true,
    layout: DefaultLayout,
    component: View,
    title: 'View one Therapy Groups',
  },
];
