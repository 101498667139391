import React, { useEffect } from 'react';
import MappedTable from './MappedTable';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import ProviderLicenseStats from './ProviderLicenseStats';

export const Dashboard = (props) => {
  const { stats, getStats, auth } = props;
  const data = stats.data;

  useEffect(() => {
    if (
      !Object.values(data).length &&
      auth &&
      ['admin', 'staff', 'admin-hipaa', 'super-admin'].includes(auth.scope)
    ) {
      getStats();
    }
  }, [data, getStats]);

  const renderTable = (table, i) => <MappedTable {...table} key={table.title} />;

  let tables = [];
  if (data.areas) {
    const totals = [
      {
        name: 'Total Areas',
        total: data.areas.total,
      },
      {
        name: 'Total Unique Locations (Areas)',
        total: data.areas_unique_locations.total,
      },
      {
        name: 'Regions',
        total: data.regions.total,
      },
      {
        name: 'Total Unique Areas (regions)',
        total: data.regions_unique_areas.total,
      },
      {
        name: 'Therapy Groups',
        total: data.therapy_groups.total,
      },
    ];
    tables = [
      {
        title: 'Totals',
        data: totals,
        properties: [
          {
            name: 'name',
            title: 'Name',
          },
          {
            name: 'total',
            title: 'Total',
          },
        ],
        height: '200px',
      },
      {
        title: 'Area Stats',
        data: data.areas_locations,
        properties: [
          {
            name: 'name',
            title: 'Name',
          },
          {
            name: 'locations',
            title: 'Locations',
          },
          {
            name: 'providers',
            title: 'Providers',
          },
        ],
      },

      {
        title: 'Providers by tags',
        data: data.providers_by_tags,
        properties: [
          {
            name: 'name',
            title: 'Name',
          },
          {
            name: 'type',
            title: 'Type',
          },
          {
            name: 'providers',
            title: 'Providers',
          },
        ],
      },

      {
        title: 'Tags by Type',
        data: data.tags_by_type,
        properties: [
          {
            name: 'type',
            title: 'Type',
          },
          {
            name: 'total',
            title: 'Total',
          },
        ],
      },
      {
        title: 'Providers by Type',
        data: data.providers,
        properties: [
          {
            name: 'type',
            title: 'Type',
          },
          {
            name: 'total',
            title: 'Total',
          },
        ],
        height: '200px',
      },
      {
        title: 'Providers by Status',
        data: data.providers_by_status,
        properties: [
          {
            name: 'status',
            title: 'Status',
          },
          {
            name: 'total',
            title: 'Total',
          },
        ],
        height: '200px',
      },
    ];
  }

  return (
    <div>
      {data.areas && tables.map(renderTable)}
      <ProviderLicenseStats />
    </div>
  );
};
export default connect(
  (state) => ({
    stats: state.stats,
    auth: state.auth,
  }),
  actions
)(Dashboard);
