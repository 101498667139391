import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../../sagaSlice';
import ExternalCalendarCard from './ExternalCalendarCard';

/**
 * @typedef GetGoogleCalendarStatusForAdminResult
 * @property {number} googleAccessTokenCount - The number of Google access tokens linked to this provider (should always be 1)
 * @property {ProviderExternalCalendarDto} linkedCalendarDtos - The list of calendars that have been linked
 */

/**
 * A DTO from a single row in the provider_external_calendars table.
 * Using DTO to make sure we don't expose any sensitive information from the users table.
 *
 * @typedef ProviderExternalCalendarDto
 * @property {number} id - The primary key of the provider external calendar. Auto-incremented.
 * @property {number} provider_id - The foreign key linking to the provider. Cannot be null.
 * @property {string} name - The name of the external calendar. Cannot be null.
 * @property {string} external_source_system - The external source system of the calendar (e.g. 'google-calendar'). Cannot be null.
 * @property {string} external_identifier - The external identifier of the calendar
 * @property {number} creator_user_id - The foreign key linking to the user who created this entry. Cannot be null.
 * @property {string} creator_user_email - The email address of the user who created this entry. Cannot be null.
 * @property {Date} created_at - The timestamp when the row was created. Cannot be null and defaults to the current timestamp.
 * @property {Date} updated_at - The timestamp when the row was last updated. Cannot be null and defaults to the current timestamp.
 */

/**
 * @typedef {Object} ProviderState
 * @property {any} current - The current provider.
 * @property {GetGoogleCalendarStatusForAdminResult} googleCalendarStatus - The Google Calendar status for admin.
 */

export default function () {
  const dispatch = useDispatch();

  /**
   * @type {ProviderState}
   */
  const { current: provider, googleCalendarStatus } = useSelector((state) => state.providers);

  useEffect(() => {
    if (provider && provider.id) {
      dispatch(actions.getGoogleCalendarStatus({ provider_id: provider.id }));
    }
  }, [dispatch, provider]);

  if (!provider || !googleCalendarStatus) {
    return 'Loading...';
  }

  return (
    <div className='google-calendar-status-container'>
      <h2>Google Calendar Status</h2>

      {googleCalendarStatus.googleAccessTokenCount > 0 ? (
        <>
          <h3>
            <i className='success-icon fas fa-check'></i> Google Account Linked
          </h3>
          <p>The provider has linked a Google account.</p>

          {googleCalendarStatus.linkedCalendarDtos.length > 0 ? (
            <p>You can only unlink the Google account if you first unlink all calendars.</p>
          ) : (
            <>
              Note: Only the provider will be able to link the provider back to the Google account. The data for connecting to the Google account will be permanently deleted (i.e. even a developer
              won't be able to restore this link) if this is the only provider connected to this Google account.
              <button
                onClick={() => {
                  if (window.confirm('Are you sure you want to unlink the Google Account?')) {
                    dispatch(actions.unlinkGoogleAccount({ provider_id: provider.id }));
                  }
                }}
              >
                Unlink Google Account
              </button>
            </>
          )}

          {googleCalendarStatus.linkedCalendarDtos.length === 0 ? (
            <h3>
              <i class='fail-icon fas fa-exclamation-triangle'></i> No External Calendars have yet been chosen to sync from.
            </h3>
          ) : (
            <>
              <h3>
                <i className='success-icon fas fa-check'></i> External Calendars
              </h3>
              <p>Syncing availability from the following calendar(s):</p>
              <div className='calendar-list'>
                {googleCalendarStatus.linkedCalendarDtos.map((calendar) => (
                  <ExternalCalendarCard key={calendar.id} calendar={calendar} />
                ))}
              </div>
            </>
          )}
        </>
      ) : (
        <h3>No Google Calendar linked</h3>
      )}
    </div>
  );
}
