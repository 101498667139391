import DefaultLayout from '##/Default';
import ReimbursifyInsurances from './ReimbursifyInsurances';

export default [
  {
    path: '/reimbursify-insurances',
    exact: true,
    layout: DefaultLayout,
    component: ReimbursifyInsurances,
    title: 'Upload Reimbursify Insurance Mappings',
  },
];
