import React, { useState, useEffect, useRef } from 'react';
import SortableJS from 'sortablejs';
import { Form, Field } from 'react-final-form';
import { prefixImage } from '#/';

export default (props) => {
  const { images, onSubmit, initialValues } = props;
  const listRef = useRef(null);
  const [sortable, setSortable] = useState(null);
  const mutators = useRef(null);

  useEffect(() => {
    if (listRef.current === null || sortable !== null) return;

    setSortable(
      SortableJS.create(listRef.current, {
        swapThreshold: 0.66,
        swap: true,
        swapClass: 'highlight',
        animation: 150,
        handle: '.handle',
        onEnd: (evt) => {
          if (!mutators.current.resort) {
            return;
          }
          mutators.current.resort(evt.oldIndex, evt.newIndex);
        },
      })
    );
  }, [sortable]);

  return (
    <Form
      initialValues={initialValues}
      initialValuesEqual={() => true}
      onSubmit={onSubmit}
      mutators={{
        resort: ([oldI, newI], state, { changeValue }) => {
          changeValue(state, 'images', (oldVal) => {
            const arr = oldVal.map((v, i) => ({
              oldIdx: i,
              ...v,
            }));
            const removedOld = arr.splice(oldI, 1);
            arr.splice(newI, 0, removedOld[0]);

            return arr;
          });
        },
      }}
      render={({ handleSubmit, pristine, valid, form, values }) => {
        mutators.current = form.mutators;

        return (
          <>
            <div className='gallery' ref={listRef}>
              {values['images'].map((v, i) => (
                <Field name={`images[${i}]`} key={v.oldIdx} handle={v.oldIdx}>
                  {(props) => <PhotoField {...props} image={images[v.oldIdx]} />}
                </Field>
              ))}
            </div>
            <div className='actions'>
              {!pristine && !valid ? (
                <button className='primary' onClick={handleSubmit} disabled>
                  Submit
                </button>
              ) : (
                <button className='primary' onClick={handleSubmit}>
                  Submit
                </button>
              )}
            </div>
          </>
        );
      }}
    />
  );
};

const PhotoField = (props) => {
  const { value, name, disabled, onChange } = props.input; // eslint-disable-line
  const id = `photo-${name}`;

  return (
    <div className={`image-selector ${value.checked ? 'selected' : 'not-selected'}`}>
      <input
        type='checkbox'
        id={id}
        defaultChecked={value.checked}
        onChange={(a) => {
          onChange({
            ...value,
            checked: a.target.checked,
          });
        }}
        disabled={disabled}
      />
      <div className='controls flex'>
        <label htmlFor={id}>
          {value.checked ? <span>Visible</span> : <span>Hidden</span>}
          {value.checked ? (
            <i className='fas fa-eye text-primary'></i>
          ) : (
            <i className='fas fa-eye-slash text-primary'></i>
          )}
        </label>
      </div>
      {props.image && (
        <img
          className={`handle gallery-photo border-primary m-b-xs m-r-sm ${
            value.checked ? '' : 'o-3'
          }`}
          src={`${prefixImage(props.image.key)}?w=400&h=400&fit=crop&crop=faces`}
          alt={`gallery${props.image.id}`}
        />
      )}
    </div>
  );
};
