import React from 'react';

import Error from './Error';
import PhoneInput from './PhoneInput';

import { fieldHasError } from '#/forms';

const Input = (formProps) => {
  const {
    input,
    label,
    meta,
    onBlur,
    disabled = false,
    divStyles,
    labelStyle,
    inputStyle,
    isphone,
    placeholder,
  } = formProps;

  const fieldState = fieldHasError(meta) ? 'error' : '';

  const className = `field ${divStyles} ${fieldState}`;

  return (
    <div className={className}>
      <label className={labelStyle}>{label}</label>
      {isphone ? (
        <PhoneInput
          {...input}
          type={input.type || 'text'}
          className={inputStyle}
          onBlur={onBlur || input.onBlur}
          disabled={disabled}
          placeholder={placeholder}
        />
      ) : (
        <input
          {...input}
          placeholder={placeholder || ''}
          type={input.type || 'text'}
          className={inputStyle}
          onBlur={onBlur || input.onBlur}
          disabled={disabled}
        />
      )}

      <Error {...meta} />
    </div>
  );
};

export default Input;
