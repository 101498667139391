import React, { useState } from 'react';
import { connect } from 'react-redux';
import diff from 'object-diff';
import ReactFilestack from 'filestack-react';

import { actions } from './sagaSlice';
import SeoForm from './Form';

export const Seo = (props) => {
  const { seo, updateSeo, createSeoImage, fetchFn } = props;

  const [edit, setEdit] = useState(false);

  const toggleEdit = () => setEdit((e) => !e);

  const onSubmit = async (values) => {
    await updateSeo({
      id: seo.id,
      seo: diff(seo, values),
      fetchFn,
    });
    toggleEdit();
  };

  const onSuccess = async (response) => {
    const { filesUploaded } = response;

    const newImage = {
      filename: filesUploaded[0].filename,
      size: filesUploaded[0].size.toString(),
      handle: filesUploaded[0].handle,
      mimetype: filesUploaded[0].mimetype,
      key: filesUploaded[0].key,
      url: filesUploaded[0].url,
      model_id: seo.id,
      model_name: 'seo',
    };

    await createSeoImage({
      newImage,
      fetchFn,
    });
  };

  const pickerOpts = {
    apikey: process.env.ZENCARE_FILESTACK_API_KEY,
    buttonText: 'Upload SEO image',
    options: {
      fromSources: ['local_file_system', 'url', 'googledrive', 'dropbox'],
      uploadInBackground: false,
    },
    onSuccess,
    onError: (e) => e,
    buttonClass: 'primary',
  };

  if (!seo) return null;

  const {
    meta_title,
    meta_description,
    og_title,
    og_description,
    twitter_title,
    twitter_description,
  } = seo;

  if (edit) {
    return <SeoForm seo={seo} toggleEdit={toggleEdit} onSubmit={onSubmit} />;
  }

  return (
    <div>
      <h3 className='my-lg'>
        SEO Info
        <button className='info sm float-right' onClick={toggleEdit}>
          <i className='fas fa-edit' />
        </button>
      </h3>

      <div>
        <ReactFilestack {...pickerOpts} />
      </div>
      <img
        className='py-lg'
        width='500'
        alt='SEO img not found'
        src={seo.image ? seo.image.url : null}
      />

      <div className='grid'>
        <div className='w-33 card p-lg mr-md'>
          <h4 className='mb-lg'>Google</h4>
          <p className='mb-md'>
            <strong>Search Title:</strong> {meta_title}
          </p>
          <p className='mb-md'>
            <strong>Search Description:</strong> {meta_description}
          </p>
        </div>

        <div className='w-33 card p-lg mr-md'>
          <h4 className='mb-lg'>Facebook</h4>
          <p className='mb-md'>
            <strong>Post Title:</strong> {og_title}
          </p>
          <p className='mb-md'>
            <strong>Post Description:</strong> {og_description}
          </p>
        </div>

        <div className='w-33 card p-lg mr-md'>
          <h4 className='mb-lg'>Twitter</h4>
          <p className='mb-md'>
            <strong>Post Title:</strong> {twitter_title}
          </p>
          <p className='mb-md'>
            <strong>Post Description:</strong> {twitter_description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default connect(null, actions)(Seo);
