import React, { useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import Editor from 'tui-editor';

const TUIEditor = (props) => {
  const created = useRef(false);

  useEffect(() => {
    if (!created.current) {
      const editor = new Editor({
        el: document.querySelector(`#${props.id}`),
        initialEditType: 'wysiwyg',
        previewStyle: 'vertical',
        height: props.height || '350px',
        events: {
          change: debounce(() => {
            props.onChange(editor.getHtml());
          }, props.debounce || 500),
          blur: () => {
            props.onBlur && props.onBlur(editor.getHtml());
          },
          load: debounce(() => {
            editor.setHtml(props.value);
          }, 10),
        },
        toolbarItems: [
          'heading',
          'bold',
          'italic',
          'divider',
          'hr',
          'quote',
          'divider',
          'ul',
          'ol',
          'divider',
          'table',
          'link',
          'divider',
          'code',
          'codeblock',
        ],
      });
      created.current = true;
    }
  }, [props]);

  return <div id={props.id} />;
};

export default TUIEditor;
