import React, { useState } from 'react';
import Modal from '+/Modal';
import { connect } from 'react-redux';
import { actions } from '../../../sagaSlice';

export const ConfirmAddSeatModal = ({
  confirmAddSeatModalMessage,
  pendingAddSeatParams,
  showModal,
  toggleModal,
  addSeat,
}) => {
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const submitClicked = () => {
    setSubmitInProgress(true);
    addSeat(pendingAddSeatParams);
  };

  if (showModal) {
    return (
      <Modal title={`Are you sure you want to add the seat?`} onDismiss={toggleModal}>
        <p>{confirmAddSeatModalMessage}</p>
        <p>
          <b>
            It is your responsibility to communicate this change to the provider (no email will be
            sent to them).
          </b>
        </p>
        <p>This will do the following:</p>
        <ul>
          <li>Webhook is sent to Remanage to update seat count.</li>
          <li>
            Stripe seat count is updated (if Reimbursify add-on exists, its seat count will also be
            updated).
          </li>
          <li>Updates status of the provider's seat to make them paid for.</li>
        </ul>
        <button disabled={submitInProgress} className='pill primary md' onClick={submitClicked}>
          {submitInProgress ? 'Submitting...' : 'Submit'}
        </button>
      </Modal>
    );
  }

  return null;
};

export default connect((state) => ({}), actions)(ConfirmAddSeatModal);
