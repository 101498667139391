import React from 'react';
import { useDispatch } from 'react-redux';
import { actions } from './sagaSlice';

const DisplayCard = ({ post, setIsEditing }) => {
  const dispatch = useDispatch();

  const handleDelete = (id) => () => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      dispatch(actions.deletePost(id));
    }
  };

  const updatePostDisplay = (id, shouldDisplay) => () => {
    dispatch(actions.updatePostDisplay({ id, display: shouldDisplay }));
  };

  return (
    <article className={`box homepage-blog-post ${post.display ? '' : 'fade'}`}>
      <a href={post.link}>
        <h4>{post.title}</h4>
      </a>
      <p>{post.description}</p>
      <p>
        <span className='bold'>By:</span> {post.author}
      </p>
      <p>
        <span className='bold'>Published:</span> {new Date(post.publish_date).toLocaleDateString()}
      </p>
      <div className='image-wrapper'>
        <img src={post.image_link} alt={post.image_alt} />
      </div>
      <p>
        <span className='bold'>Image alt:</span> {post.image_alt}
      </p>
      <div className='blog-post-card-buttons'>
        {post.display ? null : (
          <button className='error hollow' onClick={handleDelete(post.id)}>
            Delete
          </button>
        )}
        {post.display ? (
          <button className='error' onClick={updatePostDisplay(post.id, false)}>
            Hide from homepage
          </button>
        ) : (
          <button className='success' onClick={updatePostDisplay(post.id, true)}>
            Show on homepage
          </button>
        )}
        <button className='success' onClick={() => setIsEditing(true)}>
          Edit details
        </button>
      </div>
    </article>
  );
};

export default DisplayCard;
