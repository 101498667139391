/* eslint-disable max-lines-per-function */
/* eslint-disable no-fallthrough */
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import Remove from '+/RemoveButton';
import { actions } from './sagaSlice';
import LocationForm from './Form';
import Availability from './components/Availability';
import AreaAssociations from './components/AreaAssociations';
import LocationVerificationMessage from './LocationVerificationMessage';
import { onlyOnEnter } from '#/';

const acceptingStatus = (accepting) => {
  if (accepting > 20) {
    return 'Accepting new clients';
  } else if (accepting === 20) {
    return 'Accepting new clients with a 2-3 week wait';
  } else {
    return 'Not accepting new clients';
  }
};

export const LocationView = (props) => {
  const [edit, setEdit] = useState(false);

  const {
    location,
    locationId,
    read,
    deleteLocation,
    update,
    noPrimary,
    isPrimaryLocation,
    fetchFn,
    updateFn,
    disassociateLocationToArea,
    associateLocationToArea,
    showLicenseInfo,
    providerLicenses,
  } = props;

  const fetchOneLocation = useCallback(() => read(locationId), [locationId, read]);

  useEffect(() => {
    fetchOneLocation();
  }, [fetchOneLocation]);

  const toggleEdit = () => setEdit((e) => !e);

  const setPrimaryLocation = () => updateFn({ primary_location_id: locationId });

  const formatAddress = () => {
    const { remote_location, address, address_2, city, state, zip } = props.location;

    return remote_location ? state : [address, address_2, city, state, zip].join(' ');
  };

  const dayOfWeekAsString = (dayIndex) =>
    ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][dayIndex];

  const displayHours = (hours) => {
    if (!hours) {
      hours = ['N/A', 'N/A', 'N/A', 'N/A', 'N/A', 'N/A', 'N/A'];
    }

    return hours.map((day, i) => (
      <div key={`dayOfWeekAsString${i}`}>
        <u> {dayOfWeekAsString(i)} :</u> <br />
        <p className='mx-0 my-xs'>{day}</p>
      </div>
    ));
  };

  const onSubmit = (changeset) => {
    update({
      id: locationId,
      changeset,
    });
    toggleEdit();
  };

  const borderColor = isPrimaryLocation ? 'warning' : 'grey-lightest';
  const star = isPrimaryLocation ? <i className='fas fa-star text-warning' /> : null;
  let button;

  if (!isPrimaryLocation) {
    button = (
      <div
        tabIndex={0}
        role='menuitem'
        className='primary pointer p-md'
        onClick={setPrimaryLocation}
        onKeyDown={onlyOnEnter(setPrimaryLocation)}
      >
        <i className='fas fa-star' />
      </div>
    );
  }

  if (!location) {
    return null;
  }

  if (edit) {
    return (
      <LocationForm
        initialValues={location}
        locationId={locationId}
        onCancel={toggleEdit}
        onSubmit={onSubmit}
        initialValue={location}
      />
    );
  }

  return (
    <div className={`border border-${borderColor} radius-xs p-lg mr-md my-lg w-100`}>
      <div className='grid align-center justify-between'>
        <div className='grid justify-between w-70'>
          <div className={showLicenseInfo ? 'w-25' : 'w-50'}>
            <h4>{star} Address</h4>
            <p className='m-0'>{formatAddress()}</p>
          </div>
          <div className='w-25'>
            <h4>Building Name:</h4>
            <p className='m-0'>{location.building_name || 'N/A'}</p>
          </div>
          <div className='w-25'>
            <h4>Location Name:</h4>
            <p className='m-0'>{location.location_name || 'N/A'}</p>
          </div>
          {showLicenseInfo && (
            <div className='w-25'>
              <h4>State License:</h4>
              <p className='m-0'>
                {providerLicenses && (
                  <LocationVerificationMessage licenses={providerLicenses} location={location} />
                )}
              </p>
            </div>
          )}
        </div>

        <div className='w-15 grid justify-between'>
          {!noPrimary && button}
          <div
            tabIndex={0}
            role='menuitem'
            onClick={toggleEdit}
            className='primary pointer p-md'
            onKeyDown={onlyOnEnter(toggleEdit)}
          >
            <i className='fas fa-edit' />
          </div>
          <Remove
            className='error'
            name={'this location'}
            onClick={() =>
              deleteLocation({
                id: locationId,
                fetchFn,
              })
            }
          />
        </div>
      </div>

      <hr />

      <div className='grid justify-between'>
        <div className='grid justify-between w-70'>
          <div className='w-30'>
            <h3>Location Hours:</h3>
            {displayHours(location.hours)}
          </div>
          <div className='w-30'>
            <h3>Accessability:</h3>
            <div>
              <u>Accepting Patients:</u> <br />
              <p className='mx-0 my-xs'>{acceptingStatus(location.accepting_patients)}</p>
            </div>

            <Availability title='Display Address' value={location.display_address} />
            <Availability title='Ada Accessible' value={location.ada_accessible} />
          </div>
          <div className='w-30'>
            <h3>Availability:</h3>
            <Availability title='Morning Time' value={location.morning_availability} />
            <Availability title='Day Time' value={location.daytime_availability} />
            <Availability title='Afternoon Time' value={location.afternoon_availability} />
            <Availability title='Evening Time' value={location.evening_availability} />
            <Availability title='Weekend Time' value={location.weekend_availability} />
          </div>
        </div>

        <div className='w-30'>
          <AreaAssociations
            locationId={locationId}
            defaultAreas={location.areas}
            associateLocationToArea={associateLocationToArea}
            disassociateLocationToArea={disassociateLocationToArea}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  location: state.locations.data[ownProps.locationId],
  providerLicenses: state.providers.providerLicenses,
});

export default connect(mapStateToProps, actions)(LocationView);
