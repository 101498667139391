import DefaultLayout from '##/Default';
import HomepageBlogPosts from './HomepageBlogPosts';

export default [
  {
    path: '/homepage-blog-posts',
    exact: true,
    layout: DefaultLayout,
    component: HomepageBlogPosts,
    auth: true,
    title: 'Homepage Blog Posts',
  },
];
