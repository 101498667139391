import React from 'react';
import SeoContainer from '+/seo/View';
import LocationsTab from './Locations';
import ProfileTab from './Profile';
import ProvidersTab from './Providers';

export default (props) => ({
  profile: () => <ProfileTab {...props} />,
  locations: () => <LocationsTab {...props} />,
  seo: () => (
    <SeoContainer {...props} seo={props.therapyGroup.seo} fetchFn={props.fetchTherapyGroup} />
  ),
  providers: () => <ProvidersTab {...props} />,
});
