import React from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import ReactFilestack from 'filestack-react';

// model_name = 'providers' || 'galleries' ...etc
// max_files = the max amount of pictures that can be uploaded
// fetchFn = the FETCH action for whatever model your using, this is what updates the photo when uploaded

export const FileStackPhotoPicker = (props) => {
  const {
    model_name,
    max_files,
    fetchFn,
    id,
    createGallery,
    customText = 'Upload image',
    customClass = 'success',
    isProfilePics,
  } = props;

  //model_name = galleries || providers
  const success = async (response) => {
    const { filesUploaded } = response;
    const newPhotos = [];
    // eslint-disable-next-line no-unused-vars
    for (const photo of filesUploaded) {
      newPhotos.push({
        // Remove whitespace from file names before upload
        filename: photo.filename.replace(/\s+/g, ''),
        size: photo.size.toString(),
        handle: photo.handle,
        mimetype: photo.mimetype,
        key: photo.key,
        url: photo.url,
        model_id: id,
        model_name,
        profile_ar: isProfilePics ? '1' : null,
      });
    }

    createGallery({
      newPhotos,
      fetchFn,
    });
  };

  return (
    <ReactFilestack
      apikey={process.env.ZENCARE_FILESTACK_API_KEY}
      actionOptions={{
        maxFiles: max_files,
      }}
      onSuccess={success}
      onError={(e) => e}
      componentDisplayMode={{
        customText,
        customClass,
      }}
    />
  );
};

export default connect(null, actions)(FileStackPhotoPicker);
