import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './sagaSlice';

function GrantAccessWidget({ account }) {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState('');
  const { featureFlags } = useSelector((state) => state['feature-flags']);
  const activeFeatureFlags = featureFlags.filter((f) => f.active);
  const accountFeatureFlags = account.feature_flags.map((f) => f.id);
  const enableableFeatureFlags = activeFeatureFlags.filter(
    (f) => !accountFeatureFlags.includes(f.id)
  );

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const onSubmit = () => {
    if (!selectedOption) {
      return alert('Hey! Gotta choose a feature!');
    }
    dispatch(
      actions.enableFeature({
        feature_flag_reserved_name: selectedOption,
        type: 'account',
        account_id: account.id,
      })
    );
  };

  return (
    <div className='box m-b-sm'>
      <h4 className='m-0'>Enable Features</h4>
      <p>
        Select a feature from the dropdown and click enable to give this account access to a
        feature. Note that the dropdown only contains active feature flags the account does not yet
        have access to.
      </p>
      <div className='flex align-center'>
        <label htmlFor='scope-select' className='w-20'>
          Select a Feature:
        </label>
        <select
          id='feature-flag-select'
          value={selectedOption}
          onBlur={handleSelectChange}
          onChange={handleSelectChange}
          className='m-x-sm flex-1'
        >
          <option value=''>Choose one</option>
          {enableableFeatureFlags.map((f) => (
            <option key={f.id} value={f.reserved_name}>
              {f.reserved_name}
            </option>
          ))}
        </select>
        <button onClick={onSubmit} className='success  w-20'>
          Enable
        </button>
      </div>
    </div>
  );
}

export default GrantAccessWidget;
