import React from 'react';
import { Field, Form } from 'react-final-form';
import Input from '+/forms/Input';
import formValidation from './formValidation';

const SeoForm = (props) => (
  <Form
    onSubmit={props.onSubmit}
    validate={formValidation}
    // subscription={{ submitting: true }}
    initialValues={props.seo}
    render={({ handleSubmit, invalid }) => (
      <form onSubmit={handleSubmit} className='ui form error'>
        <div className='grid justify-between'>
          <div className='w-33'>
            <Field name='meta_title' component={Input} label='Google Title' />
            <Field
              name='meta_description'
              component={Input}
              className='field'
              label='Google Description'
            />
          </div>
          <div className='w-33'>
            <Field name='og_title' component={Input} label='FaceBook Title' />
            <Field name='og_description' component={Input} label='FaceBook Description' />
          </div>
          <div className='w-33'>
            <Field name='twitter_title' component={Input} label='Twitter Title' />
            <Field name='twitter_description' component={Input} label='Twitter Description' />
          </div>
        </div>

        <div className='field grid justify-between'>
          <button type='button' onClick={props.toggleEdit} className='error'>
            Cancel
          </button>
          <button disabled={invalid} type='submit' className='success'>
            Submit
          </button>
        </div>
      </form>
    )}
  />
);

export default SeoForm;
