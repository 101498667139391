import React from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import LocationForm from './Form';

export const AddLocation = (props) => {
  const { type, id, fetchFn, toggleAddition } = props;

  const onSubmit = (payload) => {
    let model_name = type;

    if (type === 'therapy-groups') {
      model_name = 'therapy_groups';
    }

    if (type === 'practices') {
      model_name = 'providers';
    }

    props.create({
      model_id: id,
      model_name,
      payload,
      fetchFn,
    });
    toggleAddition && toggleAddition();
  };

  return (
    <div>
      <h2 className='mb-0'>Add Location</h2>
      <LocationForm onSubmit={onSubmit} onCancel={toggleAddition} />
    </div>
  );
};

export default connect(null, {
  create: actions.create,
})(AddLocation);
