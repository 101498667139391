import React from 'react';
import EditableCard from '+/forms/EditableCard';
import EditableText from '+/forms/EditableText';
import ToggleStateSwitch from '+/forms/ToggleStateSwitch';
import EditableCheckBoxes from '+/forms/EditableCheckBoxes';
import SessionInfoTable from '../SessionInfoTable';
import CustomSessionInfoTable from '../CustomSessionInfoTable';

//eslint-disable-next-line
const SessionInfo = (props) => {
  const { provider, updateFn } = props;
  const { custom_content, session_fee_min } = provider;
  const {
    offer_remote_video,
    offer_inhome_services,
    custom_fee_message,
    display_sliding_scale_slots,
    display_custom_fee_message,
    payment_methods,
    session_frequency,
    offer_oon_reimbursement_superbills,
    offer_oon_reimbursement_courtesy,
    offer_oon_reimbursement_direct,
  } = custom_content;

  const properties = [
    {
      name: 'session_type',
      title: 'Session Type',
    },
    {
      name: 'session_fee',
      title: 'Session Fee',
    },
    {
      name: 'session_length',
      title: 'Session Length',
    },
  ];

  return (
    <div className='my-lg'>
      <div>
        <SessionInfoTable updateFn={updateFn} provider={provider} properties={properties} />
      </div>
      <div className='my-lg py-lg'>
        <CustomSessionInfoTable
          updateFn={updateFn}
          properties={properties}
          custom_content={custom_content}
          initialState={custom_content.custom_sessions}
        />
      </div>

      <h2>Payment Options: </h2>
      <hr />
      <div className='card cursor-default p-lg mx-md my-lg'>
        <EditableCheckBoxes
          updateFn={updateFn}
          checkboxes={payment_methods}
          custom_content={custom_content}
          custom_content_name='payment_methods'
        />
      </div>
      <h2>Session Frequency: </h2>
      <hr />
      <div className='card cursor-default p-lg mx-md my-lg'>
        <EditableCheckBoxes
          updateFn={updateFn}
          label='Session Frequency'
          checkboxes={session_frequency}
          custom_content={custom_content}
          custom_content_name='session_frequency'
        />
      </div>
      <h2 className='additionalServices'>Out-of-network billing:</h2>
      <hr />
      <div>
        <div className='grid justify-around'>
          <ToggleStateSwitch
            label='Superbilling'
            name='offer_oon_reimbursement_superbills'
            initialState={offer_oon_reimbursement_superbills}
            custom_content={custom_content}
            updateFn={updateFn}
          />
          <ToggleStateSwitch
            label='Courtesy biling'
            name='offer_oon_reimbursement_courtesy'
            initialState={offer_oon_reimbursement_courtesy}
            custom_content={custom_content}
            updateFn={updateFn}
          />
          <ToggleStateSwitch
            label='Direct billing'
            name='offer_oon_reimbursement_direct'
            initialState={offer_oon_reimbursement_direct}
            custom_content={custom_content}
            updateFn={updateFn}
          />
        </div>
      </div>

      <h2 className='additionalServices'>Additional Services: </h2>
      <hr />
      <div>
        <div className='grid justify-around'>
          <ToggleStateSwitch
            label='Display Sliding Scale:'
            initialState={display_sliding_scale_slots}
            name='display_sliding_scale_slots'
            custom_content={custom_content}
            updateFn={updateFn}
          />
          <ToggleStateSwitch
            label='Display Custom Fee Note:'
            name='display_custom_fee_message'
            initialState={display_custom_fee_message}
            custom_content={custom_content}
            updateFn={updateFn}
          />
          <ToggleStateSwitch
            label='Offer In Home Services:'
            name='offer_inhome_services'
            initialState={offer_inhome_services}
            custom_content={custom_content}
            updateFn={updateFn}
          />
          <ToggleStateSwitch
            label='Offer Remote Video:'
            name='offer_remote_video'
            initialState={offer_remote_video}
            custom_content={custom_content}
            updateFn={updateFn}
          />
        </div>
        <div className='grid justify-around'>
          <div className='w-40 '>
            {display_sliding_scale_slots && (
              <div className='card cursor-default p-lg mx-md my-lg'>
                <h4>Cost per session minimum</h4>
                <EditableText
                  type='number'
                  name='session_fee_min'
                  updateFn={updateFn}
                  validate={(val) => val >= provider.session_fee_ongoing}
                  errorText='Value can not be more then ongoing session fee'
                  initialState={session_fee_min}
                />
              </div>
            )}
          </div>
          <div className='w-40'>
            {display_custom_fee_message && (
              <EditableCard
                height='200px'
                updateFn={updateFn}
                label='Custom fee note'
                name='custom_fee_message'
                custom_content={custom_content}
                initialState={custom_fee_message}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionInfo;
