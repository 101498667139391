import React, { useCallback, useEffect } from 'react';
import history from '#/history';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import BlacklistForm from './Form';

export const AddBlacklist = (props) => {
  const { readOne, contactBlacklist } = props;

  const onCancel = () => {
    history.push('/contact-blacklist');
  };

  const { id } = (props.match && props.match.params) || {};

  const fetchFn = useCallback(() => {
    if (id) {
      readOne(id);
    }
  }, [id, readOne]);

  useEffect(fetchFn, [fetchFn]);

  return (
    <BlacklistForm
      onCancel={onCancel}
      onSubmit={id ? props.update : props.create}
      item={id && contactBlacklist.data ? contactBlacklist.data[id] : undefined}
    />
  );
};

export default connect(
  ({ 'contact-blacklist': contactBlacklist }) => ({ contactBlacklist }),
  actions
)(AddBlacklist);
