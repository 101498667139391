import React, { useState } from 'react';
import { onlyOnEnter } from '#/';

const TypeAhead = (props) => {
  const {
    field,
    items = [],
    onSelect,
    placeholder,
    criteria,
    text: initialText,
    selected: initialSelect,
  } = props;
  const [text, setText] = useState(() => initialText || '');
  const [selected, setSelected] = useState(() => initialSelect);

  const keyUp = ({ target }) => setText(target.value);

  const select = (item) => {
    setSelected((_selected) => [...selected, item.id]);
    onSelect(item.id);
  };

  const filter = (rgx, item) => {
    const name = displayName(item);
    const { id } = item;
    const notSelected = selected.indexOf(id) === -1;
    const matchesCriteria = (criteria || (() => true))(item);

    return notSelected && matchesCriteria && rgx.test(name);
  };

  const _items = () => {
    const rgx = new RegExp(text, 'i');

    return items.filter((item) => filter(rgx, item));
  };

  const displayName = (item) => {
    if (field.constructor === Function) {
      return field(item);
    }

    return item[field];
  };

  const keyPress = (e) => {
    if (e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  };
  const filteredItems = _items();

  return (
    <div className='typeahead w-100'>
      <input
        type='text'
        className='w-100 mb-lg'
        placeholder={placeholder || 'Filter items...'}
        onKeyUp={keyUp}
        onKeyPress={keyPress}
      />

      <div className='options'>
        {filteredItems.length ? (
          filteredItems.map((item, i) => (
            <div
              tabIndex={0}
              key={item.id || i}
              role='menuitem'
              onClick={() => select(item)}
              onKeyDown={onlyOnEnter(() => select(item))}
            >
              {displayName(item)}
            </div>
          ))
        ) : (
          <div className='grid justify-center align-center'>
            <h5>Loading</h5>
          </div>
        )}
      </div>
    </div>
  );
};

/*
<Typeahead
    field="name"
    criteria={ () => true }
    items={ areas.all }
    selected={ area.neighborhoods }
    placeholder="Filter Neighborhoods by Name"
/>
*/

export default TypeAhead;
