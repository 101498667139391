import React from 'react';
import { Field, Form } from 'react-final-form';
import Input from '+/forms/Input';
import formValidation from './formValidation';

const TagForm = (props) => {
  const { onSubmit, initialValue } = props;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValue || {}}
      validate={formValidation}
      render={({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit}>
          <Field name='name' component={Input} label='Enter Name' />
          <Field name='description' component={Input} label='Enter Description' />
          <Field name='slug' component={Input} label='slug' />

          <div className='grid justify-between field'>
            <button type='button' onClick={props.onCancel} className='error'>
              Cancel
            </button>

            <button disabled={invalid} className='success'>
              Submit
            </button>
          </div>
        </form>
      )}
    />
  );
};

export default TagForm;
