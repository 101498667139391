import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Modal from '+/Modal';
import { onlyOnEnter } from '#/';
import SearchTypeAhead from '+/SearchTypeAhead';

export const ViewTherapyGroupAssociation = (props) => {
  const [modal, setModal] = useState(false);
  const {
    provider,
    associateFn,
    disassociateFn,
    searchFn,
    allResource,
    singular,
    plural,
    fetchFn,
  } = props;

  useEffect(() => {
    // Calling SearchFn with no arguments should trigger a read all
    modal && searchFn();
  }, [modal, searchFn]);

  const selectedResource = provider[plural].map((i) => i.id);

  const renderModal = () => {
    if (modal) {
      return (
        <Modal title='Therapy Groups' onDismiss={() => setModal(!modal)}>
          <SearchTypeAhead
            field='name'
            placeholder={'Filter Therapy Groups...'}
            items={allResource}
            selected={selectedResource}
            searchFn={searchFn}
            onSelect={(associationId) =>
              associateFn({
                providerId: provider.id,
                association: singular,
                associationId,
                fetchFn,
              })
            }
          />
        </Modal>
      );
    }
  };

  return (
    <div className='card cursor-default radius-xs p-lg mr-sm mb-sm w-40'>
      {renderModal()}
      <div className='mb-lg grid align-center'>
        <h2 className='m-sm'>Therapy Groups</h2>
        <div
          tabIndex={0}
          role='menuitem'
          className='pointer p-md'
          onClick={() => setModal((m) => !m)}
          onKeyDown={onlyOnEnter(() => setModal((m) => !m))}
        >
          <i className='fas fa-plus' />
        </div>
      </div>

      <div className='m-sm grid wrap'>
        {provider[plural].map((resource) => {
          const disassociateProviderFn = () => {
            disassociateFn({
              providerId: provider.id,
              association: singular,
              associationId: resource.id,
              fetchFn,
            });
          };

          return (
            <div
              className='grid label primary align-center justify-between mr-xs mb-xs'
              key={resource.id}
            >
              <h5 className='mb-0 mt-0 text-white'>{resource.name}</h5>
              <div
                className='inline-block px-sm'
                role='menuitem'
                tabIndex={0}
                onClick={disassociateProviderFn}
                onKeyDown={onlyOnEnter(disassociateProviderFn)}
              >
                <i className='fas fa-times' />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  allResource: Object.values(state['therapy-groups'].data),
});

export default connect(mapStateToProps)(ViewTherapyGroupAssociation);
