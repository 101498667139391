import React from 'react';
import { actions } from './sagaSlice';
import { useDispatch } from 'react-redux';

function SwapPracticeAccounts() {
  const dispatch = useDispatch();
  const [values, setValues] = React.useState({
    currentAccountId: '',
    targetAccountId: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const confirmed = window.confirm(
      `Are you sure you want to remove Account ID ${values.currentAccountId} from it's associated Zencare Practice Management Clinic and replace it with the Account ID ${values.targetAccountId}?`
    );
    if (confirmed) {
      dispatch(actions.swapPracticeAccounts(values));
      console.log({ values });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Swap Zencare Practice Management Account IDs</h2>
      <p>This widget simplifies the process of transferring a Zencare Practice Management account from one Zencare account to another.</p>
      <p>To utilize this feature:</p>
      <ol>
        <li>On the left side, input the Zencare Account ID that is presently linked to the practice you want to target.</li>
        <li>On the right side, input the Zencare Account ID that you intend to associate with the target practice.</li>
        <li>
          Once both IDs are entered, any Practice Management Account connected to the Zencare Account ID on the left will be seamlessly switched to be associated with the Zencare Account ID on the
          right.
        </li>
      </ol>

      <div className='swap-practice-widget'>
        <label>
          Current Account ID:
          <input type='text' name='currentAccountId' value={values.currentAccountId} onChange={handleChange} placeholder='####' />
        </label>
        <label>
          Target Account ID:
          <input type='text' name='targetAccountId' value={values.targetAccountId} onChange={handleChange} placeholder='####' />
        </label>
      </div>
      <button className='primary'>Submit</button>
    </form>
  );
}

export default SwapPracticeAccounts;
