import React, { useState } from 'react';
import VerifastCodeWidget from './reimbursify/VerifastCodeWidget';
// import NpiNumbersWidget from './reimbursify/NpiNumbersWidget';

const ReimbursifyTab = (props) => {
  const {
    addReimbursifyEmbedCode,
    updateReimbursifyEmbedCode,
    toggleReimbursifyEmbedCodeDisplay,
    provider,
  } = props;

  return (
    <section className='reimbursify'>
      <h2>Reimbursify</h2>
      <h4>Private Pay Benefits Verification</h4>
      <VerifastCodeWidget
        addReimbursifyEmbedCode={addReimbursifyEmbedCode}
        updateReimbursifyEmbedCode={updateReimbursifyEmbedCode}
        toggleReimbursifyEmbedCodeDisplay={toggleReimbursifyEmbedCodeDisplay}
        provider={provider}
      />
      {/* Not in use as per ZEN-3198 */}
      {/* <NpiNumbersWidget {...props} /> */}
    </section>
  );
};

export default ReimbursifyTab;
