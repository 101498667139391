import React, { useState } from 'react';
import Modal from '+/Modal';
import { useDispatch } from 'react-redux';
import { actions } from '~/components/accounts/sagaSlice';

/**
 * @typedef StandalonePracticeManagementEditQuantityModalProps
 * @property {AdminGetAccountSummaryByTypeResultItem} a - The account info
 * @property {boolean} showModal - True to show this modal
 * @property {Function} toggleModal - Toggles visibility of this modal
 */
export const StandalonePracticeManagementEditQuantityModal = (
  /** @type {StandalonePracticeManagementEditQuantityModalProps} */

  { a, showModal, toggleModal }
) => {
  const dispatch = useDispatch();

  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [quantity, setQuantity] = useState(a.seat_based_stripe_subscription_seats.zpm);

  const handleQuantityChange = (event) => {
    const enteredNumber = parseInt(event.target.value);
    setQuantity(enteredNumber);
  };

  const submitClicked = () => {
    setSubmitInProgress(true);
    dispatch(
      actions.editStandalonePracticeManagementQuantity({
        quantity,
        stripe_customer_id: a.seat_based_stripe_customer_id,
        stripe_subscription_id: a.seat_based_stripe_subscription_id,
      })
    );
  };

  if (showModal) {
    return (
      <Modal title={`Edit (Standalone capable) Practice Management Product Seat Count`} onDismiss={toggleModal}>
        <div>
          <input type='number' value={quantity} onChange={handleQuantityChange} />
        </div>
        <p>
          <b>It is your responsibility to communicate this change to the provider (no email will be sent to them).</b>
        </p>
        <p>This will do the following:</p>
        <ul>
          <li>Webhook is sent to Remanage to update seat count.</li>
          <li>Stripe seat count for the (Standalone capable) Practice Management product is updated.</li>
          <li>“Updated Subscription” internal email is triggered to send.</li>
        </ul>
        <button disabled={submitInProgress} className='pill primary md' onClick={submitClicked}>
          {submitInProgress ? 'Submitting...' : 'Submit'}
        </button>
      </Modal>
    );
  }

  return null;
};

export default StandalonePracticeManagementEditQuantityModal;
