import _ from 'lodash';
import specialRoleFriendlyNameConstants from '../specialRoleFriendlyNameConstants';

const getRolesThatRequirePaymentForPrivatePayBooster = (users) => {
  // Note: Reimbursify Administrator roles do not require payment.
  const withOwner = [
    specialRoleFriendlyNameConstants.reimbursifyLeadPractitioner,
    specialRoleFriendlyNameConstants.reimbursifyPractitioner,
    'Owner',
  ];

  const filteredUsers = users.filter((user) => {
    const roles = user.roles.map((role) => role.name);

    return _.intersection(roles, withOwner).length > 0;
  });

  return filteredUsers;
};

export default getRolesThatRequirePaymentForPrivatePayBooster;
