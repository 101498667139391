import { createModule } from 'saga-slice';
import { failReducer, loadingReducer, noop } from 'saga-slice-helpers';
import history from '#/history';

import { sagaApi } from '#/apis';

const sagaSliceModule = createModule({
  name: 'homepageBlogPosts',
  initialState: {
    posts: [],
    isCreatingBlogPost: false,
  },
  reducers: {
    fetchHomepageBlogPosts: loadingReducer,
    fetchHomepageBlogPostsSuccess: (state, payload) => {
      state.posts = payload;
      state.isCreatingBlogPost = !payload.length;
    },
    fetchHomepageBlogPostsFail: failReducer,
    toggleIsCreatingBlogPost: (state) => {
      state.isCreatingBlogPost = !state.isCreatingBlogPost;
    },
    updatePostDisplay: noop,
    updatePostDisplaySuccess: (state, payload) => {
      state.posts = payload;
    },
    updatePostDisplayFail: failReducer,
    createBlogPost: noop,
    createBlogPostSuccess: (state, payload) => {
      state.posts = payload;
      state.isCreatingBlogPost = false;
    },
    createBlogPostFail: failReducer,
    updatePost: noop,
    updatePostSuccess: (state, payload) => {
      state.posts = payload;
    },
    updatePostFail: failReducer,
    deletePost: noop,
    deletePostSuccess: (state, payload) => {
      state.posts = payload;
    },
    deletePostFail: failReducer,
  },
  sagas: (A) => ({
    [A.fetchHomepageBlogPosts]: {
      *saga() {
        yield sagaApi.get('/homepage-blog-posts/all', A.fetchHomepageBlogPostsSuccess, A.fetchHomepageBlogPostsFail);
      },
    },
    [A.updatePostDisplay]: {
      *saga({ payload: { id, display } }) {
        yield sagaApi.put(`/homepage-blog-posts/${id}/display`, { display }, A.updatePostDisplaySuccess, A.updatePostDisplayFail);
      },
    },
    [A.createBlogPost]: {
      *saga({ payload }) {
        yield sagaApi.post(`/homepage-blog-posts`, payload, A.createBlogPostSuccess, A.createBlogPostFail);
      },
    },
    [A.updatePost]: {
      *saga({ payload }) {
        yield sagaApi.put(`/homepage-blog-posts/${payload.id}`, payload, A.updatePostSuccess, A.updatePostFail);
      },
    },
    [A.deletePost]: {
      *saga({ payload }) {
        console.log('Delete post', payload);
        yield sagaApi.delete(`/homepage-blog-posts/${payload}`, null, A.deletePostSuccess, A.deletePostFail);
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
