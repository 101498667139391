import React, { useState, useEffect } from 'react';
import { onlyOnEnter } from '#/';

const Status = (props) => {
  const { initialStatus, updateFn, options = [] } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const [status, setStatus] = useState(initialStatus);

  const toggleDropdown = () => setShowDropdown((showDropdown) => !showDropdown);

  // When a provider's licenses are verified, the provider's status can change automatically.
  useEffect(() => {
    setStatus(initialStatus);
  }, [initialStatus]);

  const handleStatusChange = async ({ target: { value } }) => {
    updateFn({ status: value });
    setStatus(value);
    toggleDropdown();
  };

  const color = {
    active: 'text-success',
    approved: 'text-success',
    pending: 'text-warning',
    incomplete: 'text-warning',
    reviewing: 'text-warning',
    inactive: 'text-error',
    locked: 'text-error',
  };

  if (showDropdown) {
    return (
      <div className='text-grey-lighter grid align-center justify-around'>
        {/* eslint-disable-next-line jsx-a11y/no-onchange */}
        <select value={status} onChange={handleStatusChange}>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <button className='error p-sm ml-md' onClick={() => setShowDropdown(!showDropdown)}>
          <i className='fas fa-times' />
        </button>
      </div>
    );
  }

  return (
    <div
      tabIndex={0}
      role='menuitem'
      onClick={toggleDropdown}
      onKeyDown={onlyOnEnter(toggleDropdown)}
    >
      <h3 className='text-grey-lighter'>
        <p className={color[status]}>{`${status}`}</p>
      </h3>
    </div>
  );
};

export default Status;
