import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { DateTime } from 'luxon';
import { dispatch } from '~/store';
import { actions } from '../sagaSlice';

const Loader = (__) => 'loading...';

const hourHeight = 50; // must be synchronized with .grid-item.hour
const dayHeight = hourHeight * 24;
// hardcoded on admin for simplicity; is it necessary
// to have this changeable here?
const timezone = DateTime.local().zoneName || 'America/New_York';

export default () => {
  const ref = useRef(null);

  const isLoading = false; //lol

  const {
    current: provider,
    availability,
    calendarStartTime,
  } = useSelector((state) => state.providers);

  const calendar = _.get(provider, 'calendar[0]');

  useEffect(() => {
    if (ref && ref.current && availability) {
      const earliestTime = Math.min(
        ...availability.availabilityBlocks.concat(availability.appointmentBlocks).map((a) => {
          if (timezone) {
            return DateTime.fromISO(a.start, { zone: timezone }).hour;
          }

          return DateTime.fromISO(a.start).hour;
        })
      );

      ref.current.scroll(0, hourHeight * (earliestTime - 1));
    }
  }, [availability, timezone]);

  const hasCalendar = calendar && calendar.id;
  const isOpen = calendar && calendar.open;
  const providerHasAcceptingLocations = provider.locations.some((l) => l.accepting_patients > 0);

  return (
    <div>
      <h1>Calendar</h1>
      {!hasCalendar && (
        <div>
          <p>This provider does not have a calendar attached!</p>
          <button
            className='pill primary'
            onClick={() => dispatch(actions.createCalendar({ provider_id: provider.id }))}
          >
            Create a calendar for this provider
          </button>
        </div>
      )}
      {!providerHasAcceptingLocations && (
        <div className='m-b-sm'>
          <p className='text-error'>
            None of this provider's locations are marked as accepting patients. Their calendar will
            display as closed and clients will not be able to book with them until at least one
            location is marked as accepting.
          </p>
        </div>
      )}
      {hasCalendar && (
        <div
          style={{
            width: 400,
            opacity: providerHasAcceptingLocations ? 1 : 0.5,
          }}
        >
          <h5 className='w-100'>Calendar Status</h5>
          {isLoading && (
            <div
              className='flex justify-center align-center'
              style={{
                backdropFilter: 'blur(2px)',
                zIndex: 5,
              }}
            >
              <div className='loader-container'>
                <Loader type='Oval' color='#37BEC3' height={50} width={50} />
              </div>
            </div>
          )}
          {!calendar.open && (
            <p className='text-error'>
              This calendar is currently closed. The provider must open their calendar for
              appointments to be visible to clients.
            </p>
          )}
        </div>
      )}
      {hasCalendar && (
        <div className={`calendar-container`}>
          {(!isOpen || !providerHasAcceptingLocations) && (
            <div
              className='flex justify-center align-center'
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                opacity: 0.5,
                background: '#fff',
                zIndex: 5,
              }}
            ></div>
          )}
          <ControlBar />
          <div className='x-scroll-container'>
            {isLoading && (
              <div
                className='flex justify-center align-center'
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  fontSize: '50px',
                  backdropFilter: 'blur(2px)',
                  zIndex: 5,
                }}
              >
                <div className='loader-container'>
                  <Loader type='Oval' color='#37BEC3' height={200} width={200} />
                </div>
              </div>
            )}

            <div className='y-scroll-container' ref={ref}>
              <DateLabels />
              <div className='flex'>
                <HourLabels />
                {availability && (
                  <>
                    <CalendarGrid availability={availability} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const CalendarGrid = ({ availability }) => {
  const [stuffByDay, setStuffByDay] = useState([]);

  useEffect(() => {
    if (!timezone) {
      return;
    }

    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = [];

      ['availabilityBlocks', 'appointmentBlocks'].forEach((type) => {
        for (const item of availability[type]) {
          const adjustedStart = DateTime.fromISO(item.start, { zone: timezone });
          const shouldDisplay = !item.canceled && item.start !== item.end;
          if (adjustedStart.weekday % 7 === i && shouldDisplay) {
            day.push(item);
          }
        }
      });
      days.push(day);
    }

    setStuffByDay(days);
  }, [availability, timezone]);

  if (!stuffByDay.length) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
        flex: '1 1 100%',
      }}
    >
      {stuffByDay.map((d, j) => (
        <DayGrid key={`day-${j}`} day={d} j={j} />
      ))}
    </div>
  );
};

const ControlBar = () => {
  const dispatch = useDispatch();
  const { current: provider, calendarStartTime } = useSelector((state) => state.providers);
  const calendar = _.get(provider, 'calendar[0]');

  useEffect(() => {
    const start = DateTime.local().set(
      {
        weekday: 0,
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      },
      { zone: timezone }
    );

    dispatch(actions.setCalendarStartTime(start));
  }, []);

  const calendarEndTime = calendarStartTime ? calendarStartTime.plus({ days: 7 }) : null;

  const startTimeIso = calendarStartTime ? calendarStartTime.toUTC().toISO() : null;
  const endTimeIso = calendarEndTime ? calendarEndTime.toUTC().toISO() : null;

  useEffect(() => {
    if (calendar && calendar.id && startTimeIso && endTimeIso) {
      dispatch(
        actions.getAvailability({
          calendar_id: calendar.id,
          provider_id: provider.id,
          //appointment_type_id: 1,
          start_time: startTimeIso,
          end_time: endTimeIso,
        })
      );
    }
  }, [provider.id, calendar.id, startTimeIso, endTimeIso, timezone]);

  if (!calendarStartTime || !timezone) {
    return null;
  }

  const shiftToTodaysWeek = () => {
    dispatch(
      actions.setCalendarStartTime(
        DateTime.local().set(
          {
            weekday: 0,
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          },
          { zone: timezone }
        )
      )
    );
  };

  const shiftOneWeekForward = () => {
    dispatch(actions.setCalendarStartTime(calendarStartTime.plus({ days: 7 })));
  };

  const shiftOneWeekBackward = () => {
    dispatch(actions.setCalendarStartTime(calendarStartTime.minus({ days: 7 })));
  };

  const name =
    calendarStartTime.month === calendarEndTime.month
      ? calendarStartTime.toFormat('LLLL yyyy')
      : `${calendarStartTime.toFormat('LLL')}-${calendarEndTime.toFormat('LLL yyyy')}`;

  return (
    <div className='calendar-controlbar'>
      <div className='date-controls w-100 flex wrap justify-between align-center'>
        <div className='flex align-center'>
          <button className='pill primary m-r-sm' onClick={shiftOneWeekBackward}>
            <i className='fas fa-chevron-left' />
          </button>
          <button className='today-button pill primary hollow m-r-sm' onClick={shiftToTodaysWeek}>
            <i className='fas fa-calendar-day m-r-sm' />
            Today
          </button>
          <button className='pill primary' onClick={shiftOneWeekForward}>
            <i className='fas fa-chevron-right' />
          </button>
        </div>
        <p style={{ fontSize: '1.5rem' }}>{name}</p>
      </div>
    </div>
  );
};

const DateLabels = () => {
  const { calendarStartTime } = useSelector((state) => state.providers);

  if (!calendarStartTime) {
    return null;
  }

  const headers = [];

  const today = DateTime.local({ zone: timezone });

  for (let i = 0; i < 7; i++) {
    const thisDay = calendarStartTime.plus({ weeks: 1 }).set({ weekday: i });
    const isToday =
      thisDay.year === today.year && today.month === thisDay.month && today.day === thisDay.day;
    headers.push(
      <div key={`dayheader-${i}`} className={`grid-item day-header ${isToday ? 'today' : ''}`}>
        <p className='day-of-week'>{thisDay.weekdayShort}</p>
        <p className={`date-of-month ${isToday ? 'today' : ''}`}>{thisDay.day}</p>
      </div>
    );
  }

  return <div className='date-labels flex'>{headers}</div>;
};

const HourLabels = () => {
  const hours = [];
  for (let i = 1; i < 24; i++) {
    hours.push(
      <div key={`hourlabels-${i}`} className='hour-grid'>
        {i % 12 || 12}&nbsp;{i < 12 ? 'AM' : 'PM'}
      </div>
    );
  }

  return <div className='hour-labels'>{hours}</div>;
};

const DayGrid = ({ day, j }) => {
  const { calendarStartTime } = useSelector((state) => state.providers);
  const dispatch = useDispatch();
  const ref = useRef(null);

  const now = DateTime.local({ zone: timezone });
  const thisDay = calendarStartTime.plus({ weeks: 1 }).set({ weekday: j });
  const isToday =
    thisDay.year === now.year && now.month === thisDay.month && now.day === thisDay.day;

  const hourGridBoxes = [];
  for (let i = 0; i < 24; i++) {
    const afterNow =
      thisDay.set({
        minute: 59,
        hour: i,
      }) >= now.set({ minute: 0 });

    hourGridBoxes.push(
      <div
        key={`day-${j}-${i}`}
        className='hour-grid'
        style={{
          cursor: afterNow ? 'pointer' : null,
        }}
      ></div>
    );
  }

  // Shift overlapping blocks slightly to the right;
  const ordered = _.orderBy(day, (x) => x.start, 'asc');
  ordered.forEach((item, i) => {
    item.offset = 0;
    const overlapping = ordered
      .slice(0, i)
      .reverse()
      .find((x) => x.id !== item.id && x.start <= item.start && x.end > item.start);

    if (overlapping) {
      item.offset = overlapping.offset + 5;
    }
  });

  const items = day.map((item) => <Block key={`block-${item.id}`} {...item} />);
  if (isToday) {
    items.push(
      <Block
        key={`block-now`}
        type='now'
        start={DateTime.utc().toISO()}
        end={DateTime.utc().plus({ minutes: 2 }).toISO()}
      />
    );
  }

  return (
    <div
      className='grid-item day-grid'
      style={{
        flexDirection: 'column',
        display: 'flex',
        height: dayHeight,
        position: 'relative',
      }}
      ref={ref}
      role='button'
      tabIndex={0}
    >
      {hourGridBoxes}
      {items}
    </div>
  );
};

const Block = (item) => {
  const tz = timezone;
  const dispatch = useDispatch();
  const start = DateTime.fromISO(item.start, { zone: tz });
  const end = DateTime.fromISO(item.end, { zone: tz });
  const height = (end.diff(start, 'minutes').toObject().minutes * hourHeight) / 60;
  const topOffset = start.hour * hourHeight + (start.minute * hourHeight) / 60;
  const isInPast = end < DateTime.local();

  let text;

  let className = `calendar-block `;

  if (item.type === 'appointment') {
    text = 'Appointment';
    className += ' appointment';
  }

  if (item.free) {
  }

  if (item.type === 'availability') {
    text = item.name || (item.free ? 'Available' : 'Busy');
    className += ` ${item.free ? 'free' : 'busy'}`;
  }

  if (item.type === 'now') {
    className += ' now-indicator';
  }

  if (isInPast) {
    className += ' in-the-past';
  }

  const style = {
    top: topOffset,
    height,
    cursor: 'pointer',
    left: item.offset,
  };

  if (item.offset > 0) {
    style.border = '1px solid white';
  }

  return (
    <div className={className} style={style} role='menu' tabIndex={0} onKeyPress={() => {}}>
      <p style={{ lineHeight: '1rem' }} className='m-b-0'>
        {text}
      </p>
      <p className='m-b-0' style={{ fontSize: '12px' }}>
        {start.toFormat('t')} - {end.toFormat('t')}
      </p>
      <p style={{ fontSize: '12px' }}>{item.free ? 'Free' : 'Busy'}</p>
    </div>
  );
};
