import React, { useState } from 'react';

const BoolToggle = (props) => {
  const { id, setValue, objectUnderEdit, property, label, isDisabled } = props;

  const [isChecked, setIsChecked] = useState(objectUnderEdit[property]);

  if (typeof objectUnderEdit[property] === 'undefined') {
    return <div>BoolToggle: Error invalid property: {property}</div>;
  }

  return (
    <>
      <div className='bool-toggle field'>
        <div className='bool-toggle-inner'>
          <input
            disabled={isDisabled}
            className='checkbox'
            type='checkbox'
            id={`bool_toggle_${id}_${property}`}
            checked={isChecked}
            onChange={(e) => {
              setIsChecked(e.target.checked);
              setValue(objectUnderEdit, property, e.target.checked);
            }}
          />
          <label className='checkbox' htmlFor={`bool_toggle_${id}_${property}`}>
            {property}?
          </label>
          <label htmlFor={`bool_toggle_${id}_${property}`} className='real-label'>
            {label ? label : ''}
          </label>
        </div>
        <div style={{ clear: 'both' }}></div>
      </div>
    </>
  );
};

export default BoolToggle;
