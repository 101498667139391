import React, { useState } from 'react';
import LocationView from '+/locations/View';
import LocationAdd from '+/locations/Add';

const LocationsTab = (props) => {
  const { therapyGroup, fetchTherapyGroup, update } = props;
  const [addNew, setAddNew] = useState(false);

  if (addNew) {
    return (
      <LocationAdd
        type='therapy-groups'
        id={therapyGroup.id}
        fetchFn={fetchTherapyGroup}
        toggleAddition={() => setAddNew((e) => !e)}
      />
    );
  }

  return (
    <div>
      <div className='grid align-center justify-between'>
        <h1 className='inline-block'>Locations</h1>

        <button onClick={() => setAddNew((e) => !e)} className='primary'>
          <i className='fas fa-plus' />
        </button>
      </div>

      {(therapyGroup.locations || []).map((location) => (
        <div key={location.id}>
          <LocationView
            noPrimary
            locationId={location.id}
            updateParent={fetchTherapyGroup}
            resource={therapyGroup}
            updateFn={update}
            showLicenseInfo={false}
          />
        </div>
      ))}
    </div>
  );
};

export default LocationsTab;
