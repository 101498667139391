import React, { useState } from 'react';
import Modal from '+/Modal';
import { connect } from 'react-redux';
import { actions } from '../../../sagaSlice';

export const ConfirmRemoveSeatModal = ({
  confirmRemoveSeatModalMessage,
  pendingRemoveSeatParams,
  showModal,
  toggleModal,
  removeSeat,
}) => {
  const [submitInProgress, setSubmitInProgress] = useState(false);

  const submitClicked = () => {
    setSubmitInProgress(true);
    removeSeat(pendingRemoveSeatParams);
  };

  if (showModal) {
    return (
      <Modal title={`Are you sure you want to remove the provider's seat?`} onDismiss={toggleModal}>
        <p>{confirmRemoveSeatModalMessage}</p>
        <p>This will do the following:</p>
        <ul>
          <li>Webhook is sent to Remanage to update seat count.</li>
          <li>“Seat removal” email is sent to provider.</li>
          <li>Stripe seat count is updated.</li>
          <li>Cancels the provider's seat and makes them not paid for.</li>
        </ul>
        <button disabled={submitInProgress} className='pill primary md' onClick={submitClicked}>
          {submitInProgress ? 'Submitting...' : 'Submit'}
        </button>
      </Modal>
    );
  }

  return null;
};

export default connect((state) => ({}), actions)(ConfirmRemoveSeatModal);
