import React from 'react';

const formatDate = (isoDate) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const date = new Date(isoDate);

  return date.toLocaleDateString('en-US', options);
};

function VacationResponderStatus({ vacationResponder }) {
  return (
    <VacationResponderStatusWrapper vacationResponder={vacationResponder}>
      {vacationResponder ? (
        <>
          <p className='m-0'>
            <span className='bold'>Status: </span>
            {vacationResponder.status.charAt(0).toUpperCase() + vacationResponder.status.slice(1)}
          </p>
          <p className='m-0'>
            <span className='bold'>Start Date: </span>
            {formatDate(vacationResponder.start_date)}
          </p>
          <p className='m-0'>
            <span className='bold'>End Date: </span>
            {formatDate(vacationResponder.end_date)}
          </p>
          <p className='m-0'>
            <span className='bold'>Updated At: </span>
            {formatDate(vacationResponder.updated_at)}
          </p>
          <p className='m-0'>
            <span className='bold'>Message: </span>
            {vacationResponder.message}
          </p>
        </>
      ) : null}
    </VacationResponderStatusWrapper>
  );
}

function VacationResponderStatusWrapper({ children, vacationResponder }) {
  return (
    <div className='box m-b-md'>
      <h3 className='m-t-0'>Vacation Responder</h3>
      {vacationResponder ? (
        children
      ) : (
        <>
          <p className='m-0'>
            <span className='bold'>Status: </span>
            Inactive
          </p>
          <p className='m-0'>
            <span className='bold'>Note: </span>
            This provider has never configured a Vacation Responder
          </p>
        </>
      )}
    </div>
  );
}

export default VacationResponderStatus;
