/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { actions as developerToolsActions } from './sagaSlice';
import { Link } from 'react-router-dom';
import RerunVob from '../user-vobs/RerunVob';
import SwapPracticeAccounts from './SwapPracticeAccounts';
import ManualReimbursifySSO from './ManualReimbursifySSO';
import CheckForExistingReimbursifyAccount from './CheckForExistingReimbursifyAccount';
import CheckClinicBookingPageStatus from './CheckClinicBookingPageStatus';
import ListRedirectsInRedis from './ListRedirectsInRedis';

export const DeveloperTools = ({
  disableAllTwilioSmsSending,
  enableTwilioSmsSending,
  runProviderSortOrder,
  clearMostOfZencareSharedCache,
  clearClientRegisteredEmailsFromRedis,
  runKeywordSearchIndexerCronJob,
  runKeywordOnlySearch,
  runInCompleteToReviewingCronJob,
  syncNewReimbursifyInsurancesCronJob,
  recomputeAllCustomerioUserAttributes,
  recomputeAllProviderAttributes,
  //sendBookingToolAnnounceEmail,
  //sendBookingToolActiveProfileReminderEmail,
  exportAllIntakes,
  updateClinicianRoleScope,
  createProviderResponseRateSnapshots,
  generateMessages,
  runMismatchedSubscriptionsFinder,
  runSitemapV3,
  runRefreshAllCalendarAvailableSlots,
  runRefreshComputedFilterOptions,
  createLiquiditySnapshot,
  clearSearchSuperCacheFromRedis,
  updateBlocklistWithUserIds,
  openRandomProfiles,
  updateSubscriptionsToPayImmediatelyFalse,
  demoteProvider,
}) => {
  const [disableAllTwilioSmsSendingClicked, setDisableAllTwilioSmsSendingClicked] = useState(false);
  const [runProviderSortOrderClicked, setRunProviderSortOrderClicked] = useState(false);
  const [keywordSearchIndexerButtonClicked, setKeywordSearchIndexerButtonClicked] = useState(false);
  const [keywordSearchTerms, setKeywordSearchTerms] = useState('');
  const [incompleteToReviewingButtonClicked, setIncompleteToReviewingButtonClicked] = useState(false);
  const [syncNewReimbursifyInsurancesClicked, setSyncNewReimbursifyInsurancesClicked] = useState(false);
  const [recomputeAllCustomerioUserAttributesClicked, setRecomputeAllCustomerioUserAttributesClicked] = useState(false);
  const [recomputeAllProviderAttributesClicked, setRecomputeAllProviderAttributesClicked] = useState(false);
  const [clearClientRegisteredEmailsFromRedisButtonClicked, setClearClientRegisteredEmailsFromRedisButtonClicked] = useState(false);
  const [clearMostOfZencareSharedCacheButtonClicked, setClearMostOfZencareSharedCacheClicked] = useState(false);
  const [exportAllIntakesClicked, setExportAllIntakesClicked] = useState(false);
  const [updateClinicianRoleScopeClicked, setUpdateClinicianRoleScopeClicked] = useState(false);
  const [createProviderResponseRateSnapshotsClicked, setCreateProviderResponseRateSnapshotsClicked] = useState(false);
  const [generateMessagesClicked, setGenerateMessagesClicked] = useState(false);
  const [runMismatchedSubscriptionsFinderClicked, setRunMismatchedSubscriptionsFinderClicked] = useState(false);
  const [runSitemapV3Clicked, setRunSitemapV3Clicked] = useState(false);
  const [runRefreshAllCalendarAvailableSlotsClicked, setRunRefreshAllCalendarAvailableSlotsClicked] = useState(false);
  const [refreshComputedFilterOptionsClicked, setRefreshComputedFilterOptionsClicked] = useState(false);
  const [createLiquiditySnapshotClicked, setCreateLiquiditySnapshotClicked] = useState(false);
  const [clearSearchSuperCacheFromRedisClicked, setClearSearchSuperCacheFromRedisClicked] = useState(false);
  const [updateBlocklistWithUserIdsClicked, setUpdateBlocklistWithUserIdsClicked] = useState(false);
  const [openRandomProfilesClicked, setOpenRandomProfilesClicked] = useState(false);
  const [updateSubscriptionsToPayImmediatelyFalseClicked, setUpdateSubscriptionsToPayImmediatelyFalseClicked] = useState(false);
  const [demoteProviderClicked, setDemoteProviderClicked] = useState(false);

  /* const [bookingToolEmail1Clicked, setBookingToolEmail1Clicked] = useState(false);
  const [bookingToolEmail2Clicked, setBookingToolEmail2Clicked] = useState(false);
  const [bookingToolEmail3Clicked, setBookingToolEmail3Clicked] = useState(false);
  const [bookingToolEmail4Clicked, setBookingToolEmail4Clicked] = useState(false);
  const [bookingToolEmail5Clicked, setBookingToolEmail5Clicked] = useState(false);
  */

  return (
    <div className='p-l-sm'>
      <div>
        <div style={{ border: '10px solid red', padding: '10px' }}>
          <h2>Emergency "Break Glass" Tools</h2>
          <p>
            BE VERY VERY CAREFUL IN USING THESE. These should only be used during spam/abuse emergencies.
            <button
              className='m-xl'
              disabled={disableAllTwilioSmsSendingClicked}
              onClick={() => {
                setDisableAllTwilioSmsSendingClicked(true);
                disableAllTwilioSmsSending();
              }}
            >
              Disable all Twilio SMS Sending (breaks 2FA logins that don't have remembered device)
            </button>
            <button
              className='m-xl'
              onClick={() => {
                enableTwilioSmsSending();
              }}
            >
              Re-enable all Twilio SMS Sending
            </button>
          </p>
        </div>
        <div>
          <h2>Next available slots tools</h2>
          <button
            disabled={runRefreshAllCalendarAvailableSlotsClicked}
            onClick={() => {
              setRunRefreshAllCalendarAvailableSlotsClicked(true);
              runRefreshAllCalendarAvailableSlots();
            }}
          >
            Run refreshAllCalendarAvailableSlots Job
          </button>
        </div>
        <div>
          <h2>computed_filter_options tools</h2>
          <button
            disabled={refreshComputedFilterOptionsClicked}
            onClick={() => {
              setRefreshComputedFilterOptionsClicked(true);
              runRefreshComputedFilterOptions();
            }}
          >
            Run refreshComputedFilterOptions Job
          </button>
        </div>
      </div>
      <div>
        <h2>Redis Tools (BE VERY CAREFUL!)</h2>
        <button
          disabled={clearClientRegisteredEmailsFromRedisButtonClicked}
          onClick={() => {
            setClearClientRegisteredEmailsFromRedisButtonClicked(true);
            clearClientRegisteredEmailsFromRedis();
          }}
        >
          Clear all queued client-registered emails from redis (ONLY CLICK IN MAJOR SPAM CLEANUP CASES!){' '}
        </button>
      </div>
      <hr />
      <div>
        <button
          disabled={clearMostOfZencareSharedCacheButtonClicked}
          onClick={() => {
            setClearMostOfZencareSharedCacheClicked(true);
            clearMostOfZencareSharedCache();
          }}
        >
          Clear most of zencare:shared in redis (This could take a LONG time)
        </button>
      </div>
      <div>
        <h2>Provider Sort Order Testing Tools</h2>
        <button
          disabled={runProviderSortOrderClicked}
          onClick={() => {
            setRunProviderSortOrderClicked(true);
            console.log('recomputing provider sort orders...');
            runProviderSortOrder();
          }}
        >
          Run Provider Sort Order Job
        </button>
      </div>
      <hr />
      <div>
        <h2>Tool for running Mismatched Subscriptions Finder cron job</h2>
        <button
          disabled={runMismatchedSubscriptionsFinderClicked}
          onClick={() => {
            setRunMismatchedSubscriptionsFinderClicked(true);
            runMismatchedSubscriptionsFinder();
          }}
        >
          Run mismatchedSubscriptionsFinder Job
        </button>
      </div>
      <hr />
      <div>
        <h2>Tool for running sitemapV3 cron job</h2>
        <button
          disabled={runSitemapV3Clicked}
          onClick={() => {
            setRunSitemapV3Clicked(true);
            runSitemapV3();
          }}
        >
          Run sitemapV3 Job
        </button>
      </div>
      <hr />
      <button
        disabled={recomputeAllCustomerioUserAttributesClicked}
        onClick={() => {
          setRecomputeAllCustomerioUserAttributesClicked(true);
          recomputeAllCustomerioUserAttributes();
        }}
      >
        Run recomputeAllCustomerioUserAttributes cron job.
      </button>
      <hr />
      <button
        disabled={recomputeAllProviderAttributesClicked}
        onClick={() => {
          setRecomputeAllProviderAttributesClicked(true);
          recomputeAllProviderAttributes();
        }}
      >
        Run recomputeAllProviderAttributes cron job.
      </button>
      <hr />
      <button
        disabled={incompleteToReviewingButtonClicked}
        onClick={() => {
          setIncompleteToReviewingButtonClicked(true);
          console.log('running incompleteToReviewing cron job...');
          runInCompleteToReviewingCronJob();
        }}
      >
        Run incompleteToReviewing cron job.
      </button>
      <hr />
      <button
        disabled={keywordSearchIndexerButtonClicked}
        onClick={() => {
          setKeywordSearchIndexerButtonClicked(true);
          runKeywordSearchIndexerCronJob();
        }}
      >
        Run keywordSearchIndexer cron job.
      </button>
      Test search (only run after index is created)
      <input onChange={(e) => setKeywordSearchTerms(e.target.value)} type='text' placeholder='Search for anything... '></input>
      <button
        onClick={(e) => {
          runKeywordOnlySearch({ keywords: keywordSearchTerms });
        }}
      >
        Run search.
      </button>
      <hr />
      <button
        disabled={syncNewReimbursifyInsurancesClicked}
        onClick={() => {
          setSyncNewReimbursifyInsurancesClicked(true);
          console.log('running syncNewReimbursifyInsurances cron job...');
          syncNewReimbursifyInsurancesCronJob();
        }}
      >
        Run syncNewReimbursifyInsurances cron job.
      </button>
      <hr />
      <button
        disabled={exportAllIntakesClicked}
        onClick={() => {
          setExportAllIntakesClicked(true);
          console.log('Exporting all intakes...');
          exportAllIntakes();
        }}
      >
        Export all client intakes
      </button>
      <p>
        <b>Note:</b> This has the potential to overwhelm our database servers. Consider notifying the team ahead of intake export, and consider performing intake export during off hours.
      </p>
      <hr />
      <button
        disabled={updateClinicianRoleScopeClicked}
        onClick={() => {
          setUpdateClinicianRoleScopeClicked(true);
          console.log('Updating practice inbox user clinician access scopes...');
          updateClinicianRoleScope();
        }}
      >
        Update Practice Inbox User Clinician Scopes
      </button>
      <p>
        <b>Note:</b> This kicks off a job that regularly runs every hour on the 25th minute. If it's urgent and we need to provide a Practice Inbox user clinician access to someone else sooner than
        the 25th minute, this is the way to do it.
      </p>
      <hr />
      <button
        disabled={createProviderResponseRateSnapshotsClicked}
        onClick={() => {
          setCreateProviderResponseRateSnapshotsClicked(true);
          console.log('Creating provider response rate snapshots now...');
          createProviderResponseRateSnapshots();
        }}
      >
        Create provider response rate snapshots
      </button>
      <p>
        <b>Note:</b> This kicks off a job that regularly runs every day at 4:30am ET. This button will allow us to run it for the first time in production without needing to wait. If we need to update
        snapshots midday at any point, this will allow us to as well... but we probably won't need to, and this is a fairly hefty job so <b>be careful</b> with this one.
      </p>
      <hr />
      <button
        disabled={createLiquiditySnapshotClicked}
        onClick={() => {
          setCreateLiquiditySnapshotClicked(true);
          console.log('creating Liquidity Snapshot...');
          createLiquiditySnapshot();
        }}
      >
        Create Liquidity Snapshot
      </button>
      <p>
        <b>Note:</b> This kicks off a job that regularly runs every day at 11pm ET.
      </p>
      <hr />
      <Link className='link primary bold font-xl' to='feature-flags'>
        Feature Flags
      </Link>
      <hr />
      <hr />
      <Link className='link primary bold font-xl' to='reimbursify-insurances'>
        Upload Insurance Tag to Reimbursify Insurance Mapping
      </Link>
      <hr />
      <h2>Re-run failed VOB</h2>
      <RerunVob />
      {/*  <Link className='link primary bold font-xl' to='user-vobs'>
        Re-Run Failed User VOBs
      </Link> */}
      <hr />
      <button
        disabled={generateMessagesClicked}
        onClick={() => {
          setGenerateMessagesClicked(true);
          console.log('Generating intakes on staging');
          generateMessages();
        }}
      >
        Generate Intakes on Staging
      </button>
      <p>
        <b>Note:</b> This runs a script that generates 100 intakes that have mock created_at dates occurring sometime over the past three weeks.
      </p>
      <hr />
      <SwapPracticeAccounts />
      <hr />
      <ListRedirectsInRedis />
      <hr />
      <CheckForExistingReimbursifyAccount />
      <hr />
      <CheckClinicBookingPageStatus />
      <hr />
      <div>
        <button
          disabled={clearSearchSuperCacheFromRedisClicked}
          onClick={() => {
            setClearSearchSuperCacheFromRedisClicked(true);
            clearSearchSuperCacheFromRedis();
          }}
        >
          Clear zencare:search:super_cache in redis (This could take a while)
        </button>
      </div>
      <hr />
      <div>
        <button
          disabled={updateBlocklistWithUserIdsClicked}
          onClick={() => {
            setUpdateBlocklistWithUserIdsClicked(true);
            updateBlocklistWithUserIds();
          }}
        >
          Update blocklist with user IDs
        </button>
      </div>
      <hr />
      <div>
        <button
          disabled={openRandomProfilesClicked}
          onClick={() => {
            setOpenRandomProfilesClicked(true);
            openRandomProfiles();
          }}
        >
          Open random provider and practice profiles
        </button>
      </div>
      <hr />
      <div>
        <button
          disabled={updateSubscriptionsToPayImmediatelyFalseClicked}
          onClick={() => {
            setUpdateSubscriptionsToPayImmediatelyFalseClicked(true);
            updateSubscriptionsToPayImmediatelyFalse();
          }}
        >
          Update subscriptions to pay_immediately = false
        </button>
      </div>
      <hr />
      <div>
        <button
          disabled={demoteProviderClicked}
          onClick={() => {
            setDemoteProviderClicked(true);
            demoteProvider();
          }}
        >
          Demote provider
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ main }) => ({});

const actions = {
  runSitemapV3: developerToolsActions.runSitemapV3,
  runRefreshAllCalendarAvailableSlots: developerToolsActions.runRefreshAllCalendarAvailableSlots,
  runRefreshComputedFilterOptions: developerToolsActions.runRefreshComputedFilterOptions,
  disableAllTwilioSmsSending: developerToolsActions.disableAllTwilioSmsSending,
  enableTwilioSmsSending: developerToolsActions.enableTwilioSmsSending,
  runProviderSortOrder: developerToolsActions.runProviderSortOrder,
  runMismatchedSubscriptionsFinder: developerToolsActions.runMismatchedSubscriptionsFinder,
  clearClientRegisteredEmailsFromRedis: developerToolsActions.clearClientRegisteredEmailsFromRedis,
  clearMostOfZencareSharedCache: developerToolsActions.clearMostOfZencareSharedCache,
  runKeywordSearchIndexerCronJob: developerToolsActions.runKeywordSearchIndexerCronJob,
  runKeywordOnlySearch: developerToolsActions.runKeywordOnlySearch,
  runInCompleteToReviewingCronJob: developerToolsActions.runInCompleteToReviewingCronJob,
  syncNewReimbursifyInsurancesCronJob: developerToolsActions.syncNewReimbursifyInsurancesCronJob,
  recomputeAllCustomerioUserAttributes: developerToolsActions.recomputeAllCustomerioUserAttributes,
  recomputeAllProviderAttributes: developerToolsActions.recomputeAllProviderAttributes,
  exportAllIntakes: developerToolsActions.exportAllIntakes,
  updateClinicianRoleScope: developerToolsActions.updateClinicianRoleScope,
  createProviderResponseRateSnapshots: developerToolsActions.createProviderResponseRateSnapshots,
  generateMessages: developerToolsActions.generateMessages,
  createLiquiditySnapshot: developerToolsActions.createLiquiditySnapshot,
  clearSearchSuperCacheFromRedis: developerToolsActions.clearSearchSuperCacheFromRedis,
  updateBlocklistWithUserIds: developerToolsActions.updateBlocklistWithUserIds,
  openRandomProfiles: developerToolsActions.openRandomProfiles,
  updateSubscriptionsToPayImmediatelyFalse: developerToolsActions.updateSubscriptionsToPayImmediatelyFalse,
  demoteProvider: developerToolsActions.demoteProvider,

  /* sendBookingToolAnnounceEmail: developerToolsActions.sendBookingToolAnnounceEmail,
  sendBookingToolActiveProfileReminderEmail:
    developerToolsActions.sendBookingToolActiveProfileReminderEmail, */
};

export default connect(mapStateToProps, actions)(DeveloperTools);
