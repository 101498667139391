import { createStore, applyMiddleware, compose } from 'redux';

import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { connectRouter } from 'connected-react-router';

import { rootSaga, rootReducer } from 'saga-slice';

import reduxModules from './modules';
import history from '#/history';

import { flashMiddleware } from '+/flashes/redux';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer(reduxModules, {
    // Extra reducers
    router: connectRouter(history),
  }),
  composeEnhancers(applyMiddleware(thunk, sagaMiddleware, flashMiddleware))
);

export const { getState, dispatch, subscribe } = store;

sagaMiddleware.run(rootSaga(reduxModules));

export default store;
