import React from 'react';
import BatchGrantAccessWidget from './BatchGrantAccessWidget';
import DisableWidget from './DisableWidget';
import CreateWidget from './CreateWidget';
import Header from './Header';

function FeatureFlags(props) {
  return (
    <section className='feature-flags'>
      <h1>Feature Flags</h1>
      <Header />
      <BatchGrantAccessWidget />
      <CreateWidget />
      <DisableWidget />
    </section>
  );
}

export default FeatureFlags;
