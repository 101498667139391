import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import SeoContainer from '+/seo/View';
import Remove from '+/RemoveButton';
import diff from 'object-diff';
import Form from './Form';
import RenderGhostLinks from './components/RenderGhostLinks';
import RenderListingLinks from './components/RenderListingLinks';

export const ViewTag = (props) => {
  const { tag, readOne, delete: deleteTag, update, match = { params: {} } } = props;
  const [edit, setEdit] = useState(props.edit || false);
  const { plural, id } = match.params;

  const fetchTag = useCallback(() => readOne(id), [readOne, id]);

  useEffect(() => {
    fetchTag();
  }, [fetchTag]);

  const onSubmit = async (values) => {
    await update({
      id: tag.id,
      changeset: diff(tag, values),
      fetchFn: fetchTag,
    });
    setEdit(() => false);
  };

  if (edit) {
    return <Form initialValue={tag} onCancel={() => setEdit(false)} onSubmit={onSubmit} />;
  }

  return (
    <div>
      <div className='grid justify-between align-center'>
        <div className='grid align-center w-90'>
          <h1 className='mr-lg'>{tag.name}</h1>
        </div>
        <div>
          <button onClick={() => setEdit(true)} className='success mr-sm'>
            <i className='fas fa-edit' />
          </button>
          <Remove className='error' name={tag.name} onClick={() => deleteTag(tag.id)} />
        </div>
      </div>
      <div className='grid justify-between wrap'>
        <div>
          <h4 className='mb-0'>Listing Posts</h4>
          <RenderGhostLinks tag={tag} type='listingPosts' />
        </div>

        <div>
          <h4 className='mb-0'>Listing Pages</h4>
          <RenderGhostLinks tag={tag} type='contentPage' />
        </div>

        <div>
          <h4 className='mb-0'>Content Pages</h4>
          <RenderListingLinks tag={tag} plural={plural} />
        </div>
      </div>

      <hr className='my-md' />

      <div>
        <div>
          <h4>Desc:</h4>
          <p>{tag.description}</p>
        </div>

        <hr className='my-md' />

        <SeoContainer seo={tag.seo} fetchFn={fetchTag} />
      </div>
    </div>
  );
};

ViewTag.defaultProps = {
  tag: {
    name: '',
    slug: '',
    description: '',
    listingPosts: [],
    contentPage: [],
  },
};

const mapStateToProps = (state, { match = { params: {} } }) => {
  const { plural, id } = match.params;

  return {
    tag: state[plural].data[id],
  };
};
const mapDispatchToProps = (dispatch, { match = { params: {} } }) =>
  Object.entries(actions[match.params.plural]).reduce(
    (acc, [key, val]) => ({
      ...acc,
      [key]: (...args) => dispatch(val(...args)),
    }),
    {}
  );

export default connect(mapStateToProps, mapDispatchToProps)(ViewTag);
