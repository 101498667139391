import React from 'react';
import { Field, Form } from 'react-final-form';
import Input from '+/forms/Input';
import formValidation from './formValidation';

const RegionForm = (props) => (
  <Form
    onSubmit={props.onSubmit}
    validate={formValidation}
    initialValues={props.initialValues || {}}
    render={({ handleSubmit, invalid }) => (
      <form onSubmit={handleSubmit}>
        <Field name='name' component={Input} label='Enter Name' />
        <Field name='slug' component={Input} label='slug' />

        <div className='grid justify-between field'>
          <button type='button' onClick={props.onCancel} className='error'>
            Cancel
          </button>

          <button disabled={invalid} className='success'>
            Submit
          </button>
        </div>
      </form>
    )}
  />
);

export default RegionForm;
