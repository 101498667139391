import rgx from '#/regex';
const isValidDomain = require('is-valid-domain');

const validate = (values) => {
  const errors = {};

  if (!values.value) {
    errors.value = 'You must enter a contact';
  } else if (!values.regex && !rgx.emailOrDomain.test(values.value)) {
    errors.value = 'Must enter valid email';
  } else if (values.regex === true && !isValidDomain(values.value)) {
    // Technically our system is set up to allow regex, but let's just make sure it is a valid domain for now.
    errors.value = 'Must enter valid domain';
  }

  if (values.regex === undefined || values.regex === null) {
    errors.regex = 'Must select contact type';
  }

  if (!values.type) {
    errors.type = 'Must select blocklist type';
  }

  return errors;
};

export default validate;
