import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import diff from 'object-diff';
import { actions } from './sagaSlice';
import { actions as regionsActions } from '@/regions/sagaSlice';
import SeoContainer from '+/seo/View';
import RemoveButton from '+/RemoveButton';
import AreaForm from './Form';
import ViewAssociation from '+/ViewAssociation';

export const AreaView = (props) => {
  const {
    current: area = {},
    readOne,
    searchAreas,
    update,
    delete: deleteArea,
    addParent,
    removeParent,
    neighborhoods,
    searchRegions,
    addRegion,
    removeRegion,
    regions,
  } = props;

  const { id } = (props.match && props.match.params) || {};

  const [edit, setEdit] = useState(props.edit || false);

  const fetchData = useCallback(() => readOne(id), [id, readOne]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const displayName = (area = {}) => {
    if (area.regions && area.regions.length > 0) {
      const regions = area.regions.map((region) => region.name).join(', ');

      return `${area.name} (${regions})`;
    }

    return `${area.name}`;
  };

  const onSubmit = async (values) => {
    await update({
      id: area.id,
      changeset: diff(area, values),
    });
    setEdit(() => false);
  };

  if (edit) {
    return <AreaForm initialValues={area} onCancel={() => setEdit(false)} onSubmit={onSubmit} />;
  }

  return (
    <div>
      <div className='grid justify-between align-center'>
        <div className='grid align-center'>
          <h1 className='mr-lg'>{displayName(area)}</h1>
          <h3 className='text-grey-lighter'>{area.type}</h3>
          <h4 className='ml-md text-grey-lighter'>{area.slug}</h4>
        </div>
        <div>
          <button className='success mr-sm' onClick={() => setEdit(true)}>
            <i className='fas fa-edit' />
          </button>
          <RemoveButton className='error' name={area.name} onClick={() => deleteArea(area.id)} />
        </div>
      </div>
      <hr className='my-md' />
      <div>
        <SeoContainer seo={area.seo} fetchFn={() => readOne(id)} />

        <hr className='my-xl' />

        <h3>Associations:</h3>
        <div className='grid'>
          <ViewAssociation
            title='Regions'
            items={regions}
            parentId={area.id}
            selected={area.regions}
            searchFn={searchRegions}
            associateFn={(area, region) =>
              addRegion({
                region,
                area,
              })
            }
            disassociateFn={(area, region) =>
              removeRegion({
                region,
                area,
              })
            }
          />

          <ViewAssociation
            title='Neighborhoods'
            items={neighborhoods}
            parentId={area.id}
            searchFn={searchAreas}
            selected={area.children}
            associateFn={(parent, child) =>
              addParent({
                parent,
                child,
              })
            }
            disassociateFn={(parent, child) =>
              removeParent({
                parent,
                child,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.areas,
  regions: Object.values(state.regions.data),
  neighborhoods: Object.values(state.areas.data).filter((area) => area.type === 'neighborhood'),
});

export default connect(mapStateToProps, {
  ...actions,
  searchRegions: regionsActions.searchRegions,
})(AreaView);
