import React, { useRef, useEffect, useCallback } from 'react';
import useSortable from '+/hooks/useSortable';
import Testimonial from './Testimonial';

const Testimonials = (props) => {
  const { testimonialName, initialValue, custom_content, updateFn, client } = props;
  const testimonialType = client ? 'Client' : 'Professional';
  const testimonialsRef = useRef(null);

  const {
    sorted,
    unSorted: testimonials,
    addNewItem,
    deleteOneUnsorted,
    toggleDisabled,
    clearSort,
    setUnsorted,
    disabled,
  } = useSortable(testimonialsRef, initialValue);

  const saveSort = useCallback(() => {
    const payload = { custom_content };
    payload.custom_content[testimonialName] = sorted;

    updateFn(payload);
    clearSort();
  }, [custom_content, testimonialName, sorted, updateFn, clearSort]);

  useEffect(() => {
    sorted.length > 0 && saveSort();
  }, [saveSort, sorted]);

  const addTestimonial = () => {
    addNewItem(
      {
        author: '',
        date: '',
        message: '',
      },
      true,
      true
    );
    toggleDisabled();
  };

  const updateTestimonial = (testimonial, index, add) => {
    const payload = { custom_content };

    if (!custom_content[testimonialName]) {
      custom_content[testimonialName] = [];
    }

    if (add) {
      payload.custom_content[testimonialName] = [testimonial, ...custom_content[testimonialName]];
    } else {
      payload.custom_content[testimonialName][index] = testimonial;
    }

    updateFn(payload);
    setUnsorted(initialValue);
    disabled && toggleDisabled();
  };

  const deleteTestimonial = (id, isNew) => {
    if (isNew) {
      deleteOneUnsorted(id);
      disabled && toggleDisabled();
    } else if (window.confirm('are you sure?')) {
      const newTestimonials = deleteOneUnsorted(id);
      const payload = { custom_content };
      payload.custom_content[testimonialName] = newTestimonials;
      updateFn(payload);
    }
  };

  return (
    <div className='card cursor-default p-lg mx-md my-lg w-50'>
      <div className='grid justify-between align-center'>
        <h3 className='mb-lg'>{testimonialType} Testimonials:</h3>
        <button className='success p-sm mr-md' disabled={disabled} onClick={addTestimonial}>
          <i className='fas fa-plus' />
        </button>
      </div>
      <hr />
      <div ref={testimonialsRef} className='scroll-y grid wrap justify-between'>
        {testimonials.length ? (
          testimonials.map((testimonial, index) => (
            <Testimonial
              index={index}
              testimonial={testimonial}
              key={`testimonial${index}`}
              toggleDisabled={toggleDisabled}
              updateTestimonial={updateTestimonial}
              deleteTestimonial={deleteTestimonial}
            />
          ))
        ) : (
          <div className='grid justify-center w-100'>
            <h5 className=''>To add new {testimonialType} Testimonials press the "+" </h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default Testimonials;
