import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import Input from '+/forms/Input';
import { Link } from 'react-router-dom';
import rgx from '#/regex';

const LoginForm = (props) => {
  const { onCancel, twoFactorCodeNeeded, login, loginVerifyTwoFactor } = props;
  const [successMessage, setSuccessMessage] = useState('');

  const submitLogin = (params) => {
    // We need to ensure that we only submit username/password (not 2FA code)
    // to login endpoint
    const loginParams = {
      email: params?.email,
      password: params?.password,
    };
    login(loginParams);
  };

  const onSubmit = (params) => {
    if (twoFactorCodeNeeded) {
      loginVerifyTwoFactor(params);
    } else {
      submitLogin(params);
    }
  };
  const required = (value) => (value ? undefined : 'Required');

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'You must enter an email address';
    } else if (!rgx.email.test(values.email)) {
      errors.email = 'Invalid email address format';
    }

    if (!values.password) {
      errors.password = 'You must enter a password';
    } else if (!rgx.password.test(values.password)) {
      errors.password =
        'Your password must be at least 12 characters long and have at least one character from three of the following four categories: lowercase, uppercase, number, special character';
    }

    if (twoFactorCodeNeeded && (!values.token || values.token.trim().length < 6 || values.token.trim().length > 6)) {
      errors.token = '6 digit secure access code is required.';
    }

    return errors;
  };

  return (
    <Form
      keepDirtyOnReinitialize={true}
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, invalid, values }) => (
        <form id='login' onSubmit={handleSubmit}>
          <div style={{ display: twoFactorCodeNeeded ? 'none' : 'block' }}>
            <Field name='email' component={Input} label='Email: ' />
            <Field type='password' component={Input} label='Password: ' name='password' />
          </div>
          {twoFactorCodeNeeded && (
            <div>
              <Field name='token' validate={required} component={Input} label='Secure Access Code: ' />
            </div>
          )}

          <div className='px-sm'>
            <div className='grid justify-between mb-md'>
              {onCancel && (
                <button onClick={onCancel} className='error'>
                  Cancel
                </button>
              )}
              <button disabled={invalid} className='primary' type='submit'>
                Submit
              </button>

              {twoFactorCodeNeeded && (
                <button
                  className='secondary'
                  onClick={(e) => {
                    e.preventDefault();
                    submitLogin(values);
                    setSuccessMessage('New code requested');

                    return false;
                  }}
                >
                  Request New Code
                </button>
              )}
            </div>
            {successMessage && <p className='text-success'>{successMessage}</p>}
            {!twoFactorCodeNeeded && <Link to='/password/request'>Forgot password?</Link>}
          </div>
        </form>
      )}
    />
  );
};

export default LoginForm;
