import React from 'react';
import Error from '+/forms/Error';
import _states from './_states';

const RenderStatesDropdown = ({ input, label, meta }) => {
  const className = `field w-33 inline-block ${meta.error && meta.touched ? 'error' : ''}`;

  return (
    <div className={className}>
      <label style={{ color: '#222' }}>{label}</label>
      <select style={{ color: '#222' }} {...input}>
        <option value='' />
        {_states.map((state) => (
          <option key={state} value={state}>
            {state}
          </option>
        ))}
      </select>
      <Error {...meta} />
    </div>
  );
};

export default RenderStatesDropdown;
