import React, { useState, useEffect } from 'react';
import { onlyOnEnter } from '#/';

const Slug = (props) => {
  const { slug, updateFn } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const [newSlug, setNewSlug] = useState(slug);

  useEffect(() => {
    setNewSlug(slug);
  }, [slug]);

  const toggleDropdown = () => setShowDropdown((showDropdown) => !showDropdown);

  const onSubmit = () => {
    updateFn({ slug: newSlug });
    toggleDropdown();
  };

  const onCancel = () => {
    setNewSlug(slug);
    toggleDropdown();
  };

  if (showDropdown) {
    return (
      <div className='grid justify-around align-center'>
        <input
          type='text'
          className='py-xs mx-md font-lg text-grey-lighter bold'
          value={newSlug}
          onChange={(e) => setNewSlug(e.target.value)}
        />

        <button className='success p-xs' onClick={onSubmit}>
          <i className='fas fa-check' />
        </button>

        <button className='error p-xs' onClick={onCancel}>
          <i className='fas fa-times' />
        </button>
      </div>
    );
  }

  return (
    <div
      onClick={toggleDropdown}
      onKeyDown={onlyOnEnter(toggleDropdown)}
      role='menuitem'
      tabIndex={0}
    >
      <h3 className='text-grey-lighter ml-lg'>/{newSlug}</h3>
    </div>
  );
};

export default Slug;
