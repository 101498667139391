import React from 'react';

import { connect } from 'react-redux';

import { actions } from './sagaSlice';

export const Logout = (props) => {
  props.logout();

  return <div />;
};

export default connect(null, actions)(Logout);
