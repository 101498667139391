/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Field, Form } from 'react-final-form';
import formValidation from './formValidation';
import Input from '+/forms/Input';
import Error from '+/forms/Error';
import PhoneInput from '+/forms/PhoneInput';

const UserForm = (props) => {
  const { onSubmit, onCancel, initialValues } = props;
  const renderRoleDropdown = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;

    return (
      <div className={className}>
        <label>{label}</label>
        <select {...input}>
          <option value=''></option>
          <option value='provider'>Provider</option>
          <option value='staff'>Staff</option>
          <option value='admin'>Admin</option>
          <option value='admin-hipaa'>Admin (HIPAA)</option>
          <option value='super-admin'>Super Admin</option>
          <option value='photo-uploader'>Photo Uploader</option>
          <option value='license-checker'>License Checker</option>
        </select>
        <Error {...meta} />
      </div>
    );
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={formValidation}
      initialValues={initialValues || {}}
      render={({ handleSubmit, invalid }) => (
        <form autoComplete='off' onSubmit={handleSubmit}>
          <h2>{initialValues ? 'Edit a' : 'Add a New'} User</h2>
          <hr />
          <Field name='username' label='Username' component={Input} />
          <Field label='Email' name='email' component={Input} />
          <Field label='First name' name='first_name' component={Input} />
          <Field label='Middle name' name='middle_name' component={Input} />
          <Field label='Last name' name='last_name' component={Input} />
          <Field label='Company name' name='company_name' component={Input} />
          <Field label='Role' name='role' component={renderRoleDropdown} />
          {initialValues && initialValues.role !== 'client' && (
            <>
              <div class='field '>
                <label>Phone Number</label>
                <Field label='Phone Number' name='phone_number' component={PhoneInput} />
              </div>
            </>
          )}

          <div className='grid justify-between field'>
            <button type='button' onClick={onCancel} className='error'>
              Cancel
            </button>

            <button disabled={invalid} className='success'>
              Submit
            </button>
          </div>
        </form>
      )}
    />
  );
};

export default UserForm;
