import { crudSlice, noop, failReducer, loadingReducer, notLoadingReducer } from 'saga-slice-helpers';
import { sagaApi } from '#/apis';

const sagaSliceModule = crudSlice({
  name: 'omniSearch',
  sagaApi,
  initialState: {
    omniSearchResults: null,
    showOmniResultsModal: false,
  },
  reducers: {
    omniSearch: loadingReducer,
    omniSearchSuccess: (state, payload) => {
      state.omniSearchResults = payload;
      state.isLoading = false;
      state.showOmniResultsModal = true;
    },
    omniSearchFail: failReducer,

    hideOmniSearchModalReducer: (state, payload) => {
      state.showOmniResultsModal = false;
    },
  },

  //eslint-disable-next-line
  sagas: (A) => ({
    [A.omniSearch]: {
      *saga({ payload }) {
        const { searchTerm } = payload;
        yield sagaApi.get(`/omni-search/${searchTerm}`, A.omniSearchSuccess, A.omniSearchFail);
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
