import React, { useState } from 'react';

function VerifastCodeWidget({
  addReimbursifyEmbedCode,
  updateReimbursifyEmbedCode,
  toggleReimbursifyEmbedCodeDisplay,
  provider,
}) {
  const { reimbursify_verifast_embed_code, id } = provider;
  const show_on_profile = reimbursify_verifast_embed_code
    ? reimbursify_verifast_embed_code.show_on_profile
    : false;
  const embed_code = reimbursify_verifast_embed_code
    ? reimbursify_verifast_embed_code.embed_code
    : '';

  const [inputValue, setInputValue] = useState('');
  const handleChange = () =>
    toggleReimbursifyEmbedCodeDisplay({ provider_id: id, show: !show_on_profile });

  const handleSubmit = () => {
    if (embed_code) {
      updateReimbursifyEmbedCode({ provider_id: id, embed_code: inputValue });
      setInputValue('');
    } else {
      addReimbursifyEmbedCode({ provider_id: id, embed_code: inputValue });
      setInputValue('');
    }
  };
  const labelText = 'Display Verifast Button on Profile';
  const actionText = embed_code ? 'Update Verifast Code' : 'Add Verifast Code';

  function copyVerifastLink() {
    if (!embed_code) {
      alert('Update the code to get a copyable link!');
    }
    navigator.clipboard.writeText(
      `https://practitioner.reimbursify.com/verifast?ec=${embed_code}&headerimage=https://assets.zencare.co/assets/img/logo5.png`
    );
    alert('Copied!');
  }

  return (
    <div className='box reimbursify-code-widget m-b-md'>
      <div className='reimbursify-code-display'>
        <h5>
          Verifast Code:{' '}
          {embed_code ? (
            <>
              <code>{embed_code}</code>{' '}
            </>
          ) : (
            'None set!'
          )}
        </h5>
        {embed_code && (
          <div className='reimbursify-link'>
            <a
              href={`https://practitioner.reimbursify.com/verifast?ec=${embed_code}&headerimage=https://assets.zencare.co/assets/img/logo5.png`}
              className='bold link primary'
              target='_blank'
              rel='noopener noreferrer'
            >
              Open Verifast Link
            </a>
            <button className='copy-button' onClick={copyVerifastLink}>
              <i className='far fa-copy'></i>
            </button>
          </div>
        )}
      </div>
      <div className='reimbursify-code-widget-inputs'>
        <form onSubmit={handleSubmit} className=''>
          <h6>{actionText}</h6>
          <div>
            <input
              onChange={(e) => setInputValue(e.target.value)}
              type='text'
              placeholder='Enter Code'
            />
            <button className='primary ml-xl' type='submit'>
              {actionText}
            </button>
          </div>
        </form>
        {embed_code && (
          <div>
            <h6>{labelText}</h6>
            <input
              onChange={handleChange}
              className='checkbox'
              type='checkbox'
              id='toggle_reimbursify_display'
              name='promoted'
              defaultChecked={show_on_profile}
            />
            <label className='checkbox' htmlFor='toggle_reimbursify_display'>
              {labelText}
            </label>
          </div>
        )}
      </div>
    </div>
  );
}

export default VerifastCodeWidget;
