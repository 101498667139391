import React, { useState } from 'react';
import { actions } from '../../../shared/photos/sagaSlice';
import { connect } from 'react-redux';

import useHover from '+/hooks/useHover';
import { onlyOnEnter } from '#/';
import ToggleStateSwitch from '+/forms/ToggleStateSwitch';

export const VideoLinks = (props) => {
  const { provider, updateFn, setGalleryVisibility, setDisplayVideoIntroduction } = props;
  const { custom_content } = provider;

  const [value, setValue] = useState('');
  const [hovering, attr] = useHover();

  const handleSubmit = () => {
    const payload = { custom_content };
    if (Array.isArray(payload.custom_content.videos)) {
      payload.custom_content.videos.push(value);
    } else {
      payload.custom_content.videos = [value];
    }
    setGalleryVisibility({
      provider_id: provider.id,
      custom_content: {
        videos: payload.custom_content.videos,
      },
    });
  };

  const removeLink = (index) => {
    if (window.confirm('are you sure?')) {
      const newVideos = { ...custom_content.videos };
      delete newVideos[index];

      const payload = { custom_content };
      payload.custom_content.videos = Object.values(newVideos);
      setGalleryVisibility({
        provider_id: provider.id,
        custom_content: {
          videos: payload.custom_content.videos,
        },
      });
    }
  };

  const handleDisplayVideoSubmit = (values) => {
    setDisplayVideoIntroduction({
      provider_id: provider.id,
      custom_content: {
        display_video_introduction: values.custom_content.display_video_introduction,
      },
    });
  };

  const renderVideos = () => {
    if (!provider) return '';

    return custom_content.videos.map((video, i) => (
      <li key={video + i} {...attr}>
        <a target='_blank' rel='noopener noreferrer' href={video}>
          {video}
        </a>
        <i
          name={video}
          className={hovering ? 'fas fa-trash' : 'hidden'}
          tabIndex={0}
          role='menuitem'
          onKeyDown={onlyOnEnter(() => removeLink(i))}
          onClick={() => removeLink(i)}
          style={{
            marginLeft: '10px',
            color: 'red',
          }}
        />
      </li>
    ));
  };

  return (
    <div className='card cursor-default p-lg my-lg w-50'>
      <h3>Video Links</h3>
      <ToggleStateSwitch
        label='Display Video on Profile'
        name='display_video_introduction'
        initialState={custom_content.display_video_introduction}
        custom_content={custom_content}
        updateFn={handleDisplayVideoSubmit}
      />
      <div className='grid justify-between'>
        <input type='text' className='w-70' onChange={(e) => setValue(e.target.value)} />
        <button disabled={!value} className='success w-25' onClick={handleSubmit}>
          Submit
        </button>
      </div>

      <ul>{renderVideos()}</ul>
    </div>
  );
};

export default connect(null, actions)(VideoLinks);
