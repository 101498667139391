import React from 'react';
import { parse } from 'papaparse';

const UserBatchUpload = (props) => {
  const id = 'user-file-upload';

  const onSubmit = () => {
    const input = document.getElementById(id);

    if (!input.files.length) {
      return;
    }

    parse(input.files[0], {
      header: true,
      complete: function (results) {
        props.onSubmit(results);
      },
    });
  };

  return (
    <div>
      <h2>Upload User CSV</h2>
      <hr />
      <input id={id} type='file' accept='.csv' />
      <button onClick={onSubmit} className='success'>
        Upload CSV
      </button>
    </div>
  );
};

export default UserBatchUpload;
