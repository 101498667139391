import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { capitalize } from 'voca';

import { actions } from '../sagaSlice';
import Modal from '+/Modal';
import { onlyOnEnter } from '#/';
import SearchTypeAhead from '+/SearchTypeAhead';

const showInsuranceOptOuts = (provider, tagId) => {
  let result = '';
  if (provider.insurance_opt_outs && provider.insurance_opt_outs.length) {
    const optOutsForTag = provider.insurance_opt_outs.filter((x) => x.tag_id === tagId);
    if (optOutsForTag.length) {
      result = ` - NOT accepting in ${optOutsForTag.map((x) => x.state_abbr).join(', ')}`;
    }
  }

  return result;
};

export const ViewTagAssociation = (props) => {
  const [modal, setModal] = useState(false);
  const {
    provider,
    allResource,
    singular,
    plural,
    searchFn,
    associateFn,
    disassociateFn,
    fetchFn,
    enableSearch,
    disableSearch,
  } = props;

  useEffect(() => {
    // Calling SearchFn with no arguments should trigger a read all
    modal && searchFn();
  }, [modal, searchFn]);

  const selectedResource = provider[plural].map((i) => i.id);

  const iconSet = (tag) => {
    if (tag.search_enabled === 1 || tag.search_enabled === true) {
      return <i className='fas fa-search' />;
    }

    return (
      <span className='fa-stack'>
        <i className='fas fa-search fa-stack-1x' />
        <i className='fas fa-ban fa-stack-2x text-white' />
      </span>
    );
  };

  const updateTag = (resource) => () => {
    if (resource.search_enabled) {
      disableSearch({
        tag_id: resource.id,
        model_id: provider.id,
        fetchFn,
      });
    } else {
      enableSearch({
        tag_id: resource.id,
        model_id: provider.id,
        fetchFn,
      });
    }
  };

  const disassociateTag = (resource) => () =>
    disassociateFn({
      providerId: provider.id,
      association: singular,
      associationId: resource.id,
      fetchFn,
    });

  const renderModel = () => {
    if (modal) {
      return (
        <Modal title={capitalize(plural)} onDismiss={() => setModal(!modal)}>
          <SearchTypeAhead
            field='name'
            placeholder={`Filter ${capitalize(plural)}...`}
            items={allResource}
            selected={selectedResource}
            searchFn={searchFn}
            onSelect={(associationId) =>
              associateFn({
                providerId: provider.id,
                association: singular,
                associationId,
                fetchFn,
              })
            }
          />
        </Modal>
      );
    }
  };

  return (
    <div className='card cursor-default p-lg mr-sm mb-sm w-40'>
      <div className='mb-lg grid align-center'>
        <h2 className='m-sm'>{capitalize(plural)}</h2>
        <div
          className='primary pointer px-sm'
          role='menuitem'
          tabIndex={0}
          onKeyDown={onlyOnEnter(() => setModal((m) => !m))}
          onClick={() => setModal((m) => !m)}
        >
          <i className='fas fa-plus' />
        </div>
      </div>
      {renderModel()}
      <div className='m-sm grid wrap'>
        {provider[plural].map((resource) => (
          <div
            className={`grid label ${
              resource.search_enabled ? 'primary' : 'error'
            } align-center justify-between mr-xs mb-xs`}
            key={resource.id}
          >
            <h5 className='mb-0 mt-0 text-white'>
              {resource.name}
              {plural === 'insurances' ? showInsuranceOptOuts(provider, resource.id) : ''}
            </h5>
            <div
              tabIndex={0}
              role='menuitem'
              className='primary pointer px-sm'
              onClick={updateTag(resource)}
              onKeyDown={onlyOnEnter(updateTag(resource))}
            >
              {iconSet(resource)}
            </div>
            <div
              tabIndex={0}
              role='menuitem'
              onClick={disassociateTag(resource)}
              className='primary pointer px-sm'
              onKeyDown={onlyOnEnter(disassociateTag(resource))}
            >
              <i className='fas fa-times' />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  allResource: Object.values(state[props.plural].data) || [],
});

export default connect(mapStateToProps, {
  enableSearch: actions.generic.enableSearch,
  disableSearch: actions.generic.disableSearch,
})(ViewTagAssociation);
