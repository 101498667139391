import React from 'react';
import { Link } from 'react-router-dom';

const ErrorLayout = () => (
  <>
    <section id='content'>
      <h1>Page Not Found</h1>
      <p>It appears you landed on a page that doesn't exist</p>
      <Link to='/' className='button'>
        Go Home
      </Link>
    </section>
  </>
);

export default ErrorLayout;
