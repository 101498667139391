import DefaultLayout from '##/Default';
import Reports from './Reports';

export default [
  {
    path: '/reports',
    exact: true,
    layout: DefaultLayout,
    component: Reports,
    auth: true,
    title: 'Reports',
  },
];
