import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './sagaSlice';
import { DateTime } from 'luxon';
import Appointment from '+/Appointment';

const timezone = DateTime.local().zoneName || 'America/New_York';

//eslint-disable-next-line
const getName = (a) => (a.first_name ? `${a.first_name} ${a.last_name}` : a.company_name);

export default function () {
  const user = useSelector((state) => _.get(state, 'users.current'));
  let appointments = useSelector((state) => _.get(state, 'users.appointments'));
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const tz = timezone;
  const [view, setView] = useState('upcoming');
  const [search, setSearch] = useState(null);

  useEffect(() => {
    if (user && user.id) {
      dispatch(actions.getAllAppointments({ id: user.id }));
    }
  }, [user, user.id]);

  if (!user) {
    return;
  }

  if (!appointments) {
    return 'Appointments loading...';
  }

  const searchTerm = search ? search.trim().toLowerCase() : null;
  if (search && search.length) {
    appointments = appointments.filter(
      (a) =>
        _.get(a, 'provider.first_name', '').toLowerCase().includes(searchTerm) ||
        _.get(a, 'provider.last_name', '').toLowerCase().includes(searchTerm) ||
        _.get(a, 'provider.company_name', '').toLowerCase().includes(searchTerm)
    );
  }

  return (
    <div className='appointment-view-container'>
      <h1 className='m-b-xs'>Appointments</h1>
      {appointments.map((a) => (
        <Appointment appointment={a} user={user} key={a.id} />
      ))}
      {appointments && appointments.length === 0 && <p>This user has not made any appointments yet.</p>}
    </div>
  );
}
