import React from 'react';
import { parse } from 'papaparse';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';

export const ProviderBatchUpdate = (props) => {
  const { batchUpdatePromoted } = props;

  const id = 'provider-file-upload';

  const onSubmit = () => {
    const input = document.getElementById(id);

    if (!input.files.length) {
      return;
    }

    parse(input.files[0], {
      header: true,
      complete: function (results) {
        if (
          window.confirm(
            'Are you sure you want to upload these promotions? Existing promotions will be overwritten!'
          )
        ) {
          batchUpdatePromoted(results);
        }
      },
    });
  };

  return (
    <div>
      <h2>Upload Provider CSV</h2>
      <hr />
      <input id={id} type='file' accept='.csv' />
      <button onClick={onSubmit} className='success'>
        Upload CSV
      </button>
    </div>
  );
};

export default connect(null, actions)(ProviderBatchUpdate);
