import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { actions } from './sagaSlice';
import UnsubscribeFromFlowModal from './UnsubscribeFromFlowModal';

export const EmailLog = (props) => {
  const {
    current: user,
    readOne,
    match = { params: {} },
    emails,
    unsubscribes,
    getEmails,
    getUnsubscribes,
    resubscribeToFlow,
  } = props;

  const { id, mode } = match.params;

  const fetchUser = useCallback(() => readOne(id), [readOne, id]);
  const fetchEmails = useCallback(() => getEmails({ id, mode }), [getEmails, id, mode]);
  const fetchUnsubscribes = useCallback(() => getUnsubscribes({ id }), [getUnsubscribes, id]);
  const [unsubscribeModalOpen, setUnsubscribeModalOpen] = useState(false);
  const toggleUnsubscribeModal = () => {
    setUnsubscribeModalOpen((e) => !e);
  };

  useEffect(() => {
    fetchUser();
    fetchEmails();
    fetchUnsubscribes();
  }, [fetchUser, fetchEmails, fetchUnsubscribes]);

  if (!emails) {
    return null;
  }

  if (!user) return null;

  return (
    <div>
      <div className='grid justify-between align-center'>
        <div className='grid align-center'>
          <h1 className='mr-lg'>{user.username}</h1>
          <h3 className='text-grey-lighter'>{user.role}</h3>
        </div>
        <Link to={`/users/view/${id}`}>
          <button className='success mr-sm'>Back to profile</button>
        </Link>
      </div>

      <hr className='my-md' />
      <h5>Unsubscribed email flows</h5>

      <button
        onClick={() => {
          setUnsubscribeModalOpen(true);
        }}
        className='pill primary sm'
      >
        Unsubscribe from an email flow
      </button>
      <UnsubscribeFromFlowModal
        user={user}
        showModal={unsubscribeModalOpen}
        toggleModal={toggleUnsubscribeModal}
      />
      <div
        className='w-100'
        style={{
          display: 'grid',
          gridTemplateColumns: '2fr 2fr 2fr 2fr',
          gridRowGap: '10px',
          gridColumnGap: '10px',
        }}
      >
        <h6>Date unsubscribed</h6>
        <h6>Email template</h6>
        <h6>Email Flow</h6>
        <h6>Action</h6>
        {_.orderBy(unsubscribes, 'created_at', 'desc').map((e) => (
          <Unsubscribe resubscribeToFlow={resubscribeToFlow} key={e.id} {...e} />
        ))}
      </div>

      <hr className='my-md' />
      <h5>
        Emails for {user.first_name} {user.last_name}
      </h5>
      <div
        className='w-100'
        style={{
          display: 'grid',
          gridTemplateColumns: '2fr 2fr 3fr 6fr 1fr',
          gridRowGap: '10px',
          gridColumnGap: '10px',
        }}
      >
        <h6>Date sent</h6>
        <h6>Email</h6>
        <h6>Provider</h6>
        <h6>Subject line</h6>
        <span></span>
        {_.orderBy(emails.emails, 'created_at', 'desc').map((e) => (
          <Email key={e.id} {...e} providers={emails.providers} />
        ))}
      </div>
    </div>
  );
};

//eslint-disable-next-line
const Email = ({ created_at, subject, email, text, metadata, providers }) => {
  const [open, setOpen] = useState(false);
  const provider = providers.find((p) => metadata.provider_id === p.id);

  return (
    <>
      <span>{dayjs(created_at).format('MM/DD/YYYY hh:mm:ss')}</span>
      <span>{email}</span>
      <span>
        {provider &&
          (provider.first_name
            ? `${provider.first_name} ${provider.last_name}`
            : provider.company_name)}
      </span>
      <span>{subject}</span>
      <button class='pill hollow' onClick={() => setOpen(!open)}>
        {open ? 'Contract' : 'Expand'}
      </button>
      {open && (
        <>
          <span></span>
          <span></span>
          <span></span>
          <span
            style={{
              whiteSpace: 'pre-wrap',
              overflow: 'auto',
              border: '1px solid black',
              padding: '8px',
            }}
          >
            {text}
          </span>
          <span></span>
        </>
      )}
    </>
  );
};

//eslint-disable-next-line
const Unsubscribe = ({ user_id, created_at, source, flow_name, resubscribeToFlow }) => {
  return (
    <>
      <span>{dayjs(created_at).format('MM/DD/YYYY hh:mm:ss')}</span>
      <span>{source}</span>
      <span>{flow_name}</span>
      <button
        onClick={() => {
          resubscribeToFlow({ user_id, flow_name });
        }}
        className='pill primary sm m-b-sm'
      >
        Re-subscribe to flow
      </button>
    </>
  );
};

export default connect((state) => state.users, actions)(EmailLog);
