// @ts-check
import React from 'react';
import { actions } from './sagaSlice';
import { useDispatch } from 'react-redux';

function CheckForExistingReimbursifyAccount() {
  const dispatch = useDispatch();
  const [accountId, setAccountId] = React.useState('');
  const [userId, setUserId] = React.useState('');

  const handleAccountIdChange = (e) => {
    const { value } = e.target;
    setAccountId(value);
  };

  const handleUserIdChange = (e) => {
    const { value } = e.target;
    setUserId(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      params: {
        accountId,
        userId,
      },
    };

    // @ts-ignore
    dispatch(actions.checkForExistingReimbursifyAccount(payload));
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Check for existing Reimbursify account</h2>
      <div>
        <label htmlFor='accountId'>Account ID:</label>
        <input
          type='text'
          name='accountID'
          id='accountId'
          value={accountId}
          onChange={handleAccountIdChange}
          placeholder='####'
        />

        <label htmlFor='userId'>User ID:</label>
        <input type='text' name='userID' id='userId' value={userId} onChange={handleUserIdChange} placeholder='####' />
        <button className='primary'>Check for account</button>
      </div>
    </form>
  );
}

export default CheckForExistingReimbursifyAccount;
