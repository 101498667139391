// @ts-check
import React from 'react';
import { actions } from './sagaSlice';
import { useDispatch } from 'react-redux';

function ManualReimbursifySSO() {
  const dispatch = useDispatch();
  const [userId, setUserId] = React.useState('');

  const handleChange = (e) => {
    const { value } = e.target;
    setUserId(value);
  };

  const getReimbursifyDomain = () => {
    const isStaging = process.env.ZENCARE_ENV === 'staging';
    let urlPrefix = 'practitioner.reimbursify.com';
    if (isStaging) {
      urlPrefix = 'staging-practitioner.reimbursify.com';
    }

    return urlPrefix;
  };

  const redirectToReimbursify = (reimbursifyJwt) => {
    if (reimbursifyJwt) {
      const urlPrefix = `https://${getReimbursifyDomain()}/auth?jwt=`;
      window.open(`${urlPrefix}${reimbursifyJwt}`, '_blank');
    } else {
      alert('Sorry, we ran into an error. Please contact support.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      params: {
        userId,
      },
      onSuccess: redirectToReimbursify,
    };

    dispatch(actions.signInViaSSO(payload));
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Sign In to Reimbursify via SSO</h2>
      <div className='manual-reimbursify-sso-widget'>
        <label htmlFor='userId'>User ID:</label>
        <input type='text' name='userID' id='userId' value={userId} onChange={handleChange} placeholder='####' />
        <button className='primary'>Sign In</button>
      </div>
    </form>
  );
}

export default ManualReimbursifySSO;
