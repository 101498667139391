import React from 'react';
import Picker from './FileStackPhotoPicker';
import Image from './Image';

const DisplayGallery = (props) => {
  const { deleteFn, provider, fetchFn, isProfilePics } = props; //eslint-disable-line
  const { gallery } = provider;
  let images;
  if (isProfilePics) {
    images = gallery.images.filter((i) => i.profile_ar);
  } else {
    images = gallery.images.filter((i) => !i.profile_ar);
  }

  const onImageDelete = async (index) => {
    const success = window.confirm('are you sure?');

    if (success) {
      const { id } = images[index];
      await deleteFn({
        id,
        fetchFn,
      });
    }
  };

  return (
    <>
      <div>
        <h3 className='inline-block'>{isProfilePics ? 'Profile Pic ' : ''} Gallery:</h3>
        <Picker
          id={gallery.id}
          max_files={20}
          fetchFn={fetchFn}
          model_name='galleries'
          customText='Upload New Gallery Image'
          customClass='primary ml-lg p-xs inline-block'
          isProfilePics={isProfilePics}
        />
      </div>
      <p>
        These are the provider's gallery pictures. The custom content pictures which show on the
        provider's profile are a subset of these.
      </p>
      <div className='mt-lg'>
        <div className='grid wrap'>
          {images.length > 0 ? (
            images.map((image, i) => (
              <Image index={i} image={image} key={`gallery# ${i}`} onImageDelete={onImageDelete} />
            ))
          ) : (
            <div>
              <p>Click the upload button to upload gallery photos</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DisplayGallery;
