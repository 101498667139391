import React from 'react';
import DefaultLayout from '##/Default';
import { Redirect } from 'react-router-dom';
import List from './List';
import View from './View';

export default [
  {
    path: '/accounts',
    exact: true,
    layout: DefaultLayout,
    component: List,
    title: 'View All Accounts',
  },
  {
    path: '/accounts/view/:id',
    exact: true,
    layout: DefaultLayout,
    component: View,
    title: 'View One Account',
  },
];
