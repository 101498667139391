import React from 'react';
import getHasSpecialRoleUsersIncludeOwner from './utils/getHasSpecialRoleUsersIncludeOwner';
import getRolesThatRequirePaymentForPrivatePayBooster from './utils/getRolesThatRequirePaymentForPrivatePayBooster';

function CurrentSpecialRoleUsers({ account }) {
  const { users } = account;
  const filteredUsers = getHasSpecialRoleUsersIncludeOwner(users);
  const rolesThatRequirePaymentForPrivatePayBooster = getRolesThatRequirePaymentForPrivatePayBooster(users);

  if (filteredUsers.length === 0) {
    return (
      <p>
        <i>No users on this account have special roles at the moment</i>
      </p>
    );
  }

  return (
    <>
      {account.seat_based_stripe_subscription_seats.reimbursify > 0 &&
      account.seat_based_stripe_subscription_seats.reimbursify !==
        rolesThatRequirePaymentForPrivatePayBooster.length ? (
        <div className='text-error'>
          WARNING: Current number of paid PPB seats is {account.seat_based_stripe_subscription_seats.reimbursify} but a
          total of {rolesThatRequirePaymentForPrivatePayBooster.length} are assigned PPB roles that require a paid seat.
          The roles that require paid seats are "Owner", "Reimbursify Practitioner", and "Reimbursify Lead
          Practitioner".
        </div>
      ) : (
        ''
      )}
      <h4>Users With Special Roles</h4>
      <table className='w-100'>
        <thead>
          <tr>
            <th>User ID</th>
            <th>User Name</th>
            <th>Roles</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user.id}>
              <td>
                <a href={`/users/view-with-mode/${user.id}/provider`}>{user.id}</a>
              </td>
              <td>
                {user.full_name ? user.full_name : `${user.first_name} ${user.last_name}`} ({user.id})
              </td>
              <td>{user.roles.map((role) => role.name).join(', ')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default CurrentSpecialRoleUsers;
