import { put, takeLatest } from 'redux-saga/effects';

import { createModule } from 'saga-slice';
import { failReducer, loadingReducer } from 'saga-slice-helpers';

import { sagaApi, api } from '#/apis';

const sagaSliceModule = createModule({
  name: 'stats',
  initialState: {
    isLoading: false,
    data: {},
    error: null,
    providerLicenseStats: null,
    providersByLicenseStatus: null,
  },
  reducers: {
    getStats: loadingReducer,
    getStatsSuccess: (state, payload) => {
      state.error = null;
      state.isLoading = false;
      state.data = payload;
    },
    getStatsFail: failReducer,

    getProviderLicenseStats: loadingReducer,
    getProviderLicenseStatsSuccess: (state, payload) => {
      state.providerLicenseStats = payload;
    },
    getProviderLicenseStatsFail: failReducer,
    getProvidersByLicenseStatus: (state) => {
      state.providersByLicenseStatus = null;
    },
    getProvidersByLicenseStatusSuccess: (state, payload) => {
      state.providersByLicenseStatus = payload;
    },
    getProvidersByLicenseStatusFail: failReducer,
  },
  sagas: (A) => ({
    [A.getStats]: {
      *saga() {
        const response = {};

        const routes = [
          ['stats/areas', 'areas'],
          ['stats/areas-locations', 'areas_locations'],
          ['stats/areas-unique-locations', 'areas_unique_locations'],
          ['stats/areas-unique-providers', 'areas_unique_providers'],
          ['stats/providers', 'providers'],
          ['stats/providers-by-status', 'providers_by_status'],
          ['stats/providers-by-tags', 'providers_by_tags'],
          ['stats/regions', 'regions'],
          ['stats/regions-unique-areas', 'regions_unique_areas'],
          ['stats/tags-by-type', 'tags_by_type'],
          ['stats/therapy-groups', 'therapy_groups'],
        ];

        try {
          // eslint-disable-next-line no-unused-vars
          for (const [route, name] of routes) {
            const { data } = yield api.get(route);
            response[name] = data;
          }

          const mergedLocations = response.areas_locations.map((area, i) => {
            const p = response.areas_unique_providers.find((el) => el.name === area.name);

            if (p) {
              return Object.assign({}, area, p);
            } else {
              return area;
            }
          });

          response.areas_locations = mergedLocations;
          yield put(A.getStatsSuccess(response));
        } catch (e) {
          yield put(A.getStatsFail(e));
        }
      },
      taker: takeLatest,
    },
    [A.getProviderLicenseStats]: {
      *saga() {
        yield sagaApi.get(
          '/provider-licenses/stats',
          A.getProviderLicenseStatsSuccess,
          A.getProviderLicenseStatsFail
        );
      },
    },
    [A.getProvidersByLicenseStatus]: {
      *saga({ payload }) {
        yield sagaApi.get(
          `/provider-licenses/providers-by-license-status/${payload.status}`,
          A.getProvidersByLicenseStatusSuccess,
          A.getProvidersByLicenseStatusFail
        );
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
