// adapted from https://loading.io/asset/685657 - free with no attribution requirements.

import React from 'react';

const OnmiLoadingSpinner = () => {
  const divStyles = [
    { letter: 'Z', styles: { left: '38px', top: '38px', animationDelay: '0s' } },
    { letter: 'E', styles: { left: '80px', top: '38px', animationDelay: '0.125s' } },
    { letter: 'N', styles: { left: '122px', top: '38px', animationDelay: '0.25s' } },
    { letter: '!', styles: { left: '38px', top: '80px', animationDelay: '0.875s' } },
    { letter: 'C', styles: { left: '122px', top: '80px', animationDelay: '0.375s' } },
    { letter: 'E', styles: { left: '38px', top: '122px', animationDelay: '0.75s' } },
    { letter: 'R', styles: { left: '80px', top: '122px', animationDelay: '0.625s' } },
    { letter: 'A', styles: { left: '122px', top: '122px', animationDelay: '0.5s' } },
  ];

  return (
    <div className='omni-loading-spinner-container'>
      <div className='omni-loading-spinner'>
        Let's find that for you...
        {divStyles.map((style, index) => (
          <div
            className='omni-loading-spinner-letter-parent'
            key={index}
            style={{
              position: 'absolute',
              ...style.styles,
            }}
          >
            <span className='omni-loading-spinner-letter'>{style.letter}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OnmiLoadingSpinner;
