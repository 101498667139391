import React from 'react';
import history from '#/history';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import AreasForm from './Form';

export const AddArea = (props) => {
  const onCancel = () => history.push('/areas');

  return (
    <div>
      <h2>Add Areas</h2>
      <AreasForm onSubmit={(values) => props.createAreas(values)} onCancel={onCancel} />
    </div>
  );
};

export default connect(null, { createAreas: actions.create })(AddArea);
