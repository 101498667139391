import React from 'react';

export default () => (
  <footer id='admin'>
    <p className='emergency'>
      If you are experiencing issues, please contact the{' '}
      <a target='_blank' rel='noopener noreferrer' href='mailto:support@zencare.co'>
        Zencare team
      </a>
    </p>
    <p className='copyright'>© {new Date().getFullYear()} Zencare Group, Inc.</p>
  </footer>
);
