import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import { Link } from 'react-router-dom';
import RemoveButton from '+/RemoveButton';
import useOrderBy from '+/hooks/useOrderBy';
import Pagination from '+/Pagination';
import utils from '../../utils';
import Column from '+/Column';

export const List = (props) => {
  const { redirects, readAll, total, searchRedirects, delete: deleteRedirect } = props;

  const {
    order,
    orderBy,
    ordered: orderedRedirects,
    handleColumnChange,
  } = useOrderBy(redirects, 'from');

  useEffect(() => {
    readAll({
      order,
      orderBy,
    });
  }, [order, orderBy, readAll]);

  const handleSearch = ({ target }) => utils.debounce(() => searchRedirects(target.value));

  return (
    <>
      <div className='grid align-center justify-between'>
        <h1 className='inline-block'>Redirects</h1>

        <Link to={'/redirects/add'} className='button primary' title='Add New Redirect'>
          <i className='fas fa-plus' />
        </Link>
      </div>

      <div className='border border-grey-lightest radius-xs scroll'>
        <input
          type='text'
          className='block w-100'
          placeholder='Filter redirects...'
          onKeyUp={handleSearch}
        />
        <div className='bg-grey-dark text-white grid'>
          <Column title='From' field='from' width='30' onChange={handleColumnChange} />{' '}
          <Column title='To' field='to' width='30' onChange={handleColumnChange} />{' '}
          <div className='p-md w-30'>
            <b className='mr-sm'>Status Code</b>
          </div>
          <div className='p-md mr-sm w-10'>
            <b>Remove</b>
          </div>
        </div>

        {orderedRedirects.map((redirect, i) => (
          <div className={`${utils.tableClass(i)} grid align-center redirects`} key={redirect.id}>
            <div className='p-md w-30'>{redirect.from}</div>

            <div className='p-md w-30'>{redirect.to}</div>

            <div className='p-md w-30'>{redirect.status_code}</div>

            <div className='p-md mr-sm w-10'>
              <RemoveButton
                className='error'
                name={redirect.from}
                onClick={() => deleteRedirect(redirect.id)}
              />
            </div>
          </div>
        ))}
        <Pagination total={total} fetchFn={(page) => readAll({ page })} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  redirects: Object.values(state.redirects.data || {}),
  total: state.redirects.total,
});

export default connect(mapStateToProps, actions)(List);
