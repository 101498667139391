import React, { useState } from 'react';
import { connect } from 'react-redux';
import { actions as reportActions } from './sagaSlice';

export const Reports = ({ exportProviderBounceDataAsCsv }) => {
  const [exportBounceReportClicked, setExportBounceReportClicked] = useState(false);

  return (
    <div className='p-l-sm'>
      <h1>Reports</h1>
      <button
        disabled={exportBounceReportClicked}
        onClick={() => {
          setExportBounceReportClicked(true);
          console.log('Exporting report...');
          exportProviderBounceDataAsCsv();
        }}
      >
        Export provider email bounce report to CSV
      </button>

      <hr />
    </div>
  );
};

const mapStateToProps = ({ main }) => ({});

const actions = {
  exportProviderBounceDataAsCsv: reportActions.exportProviderBounceDataAsCsv,
};

export default connect(mapStateToProps, actions)(Reports);
