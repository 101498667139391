import React from 'react';
import { Form, Field } from 'react-final-form';
import Input from '+/forms/Input';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import rgx from '#/regex';

const validate = (values) => {
  const errors = {};

  if (!values.password) {
    errors.password = 'You must enter an password';
  } else if (!rgx.new_password.test(values.password)) {
    errors.password =
      'Your password must be at least 12 characters long and have at least one character from three of the following four categories: lowercase, uppercase, number, special character';
  }

  if (values.password !== values.confirm_password) {
    errors.confirm_password = 'Passwords must match';
  }

  return errors;
};

export const ResetPassword = (props) => {
  const { resetPassword, match = { params: {} } } = props;
  const { reset_token } = match.params;

  const onSubmit = (formValues) =>
    resetPassword({
      password: formValues.password,
      reset_token,
    });

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit} className='grid justify-between column align-center'>
          <div>
            <h1>Set New Password</h1>
            <p>
              Your password must be at least 12 characters long and have at least one character from
              three of the following four categories: lowercase, uppercase, number, special
              character.
            </p>
          </div>
          <div className='w-75'>
            <Field type='password' component={Input} label='Password: ' name='password' />
            <Field
              type='password'
              component={Input}
              label='Confirm Password: '
              name='confirm_password'
            />

            <button type='submit' className='primary' disabled={invalid}>
              Reset Password
            </button>
          </div>
        </form>
      )}
    />
  );
};

export default connect(null, { resetPassword: actions.resetPassword })(ResetPassword);
