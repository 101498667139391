import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../sagaSlice';
import StatusWidget from '../StatusWidget';

export const ManageReimbursify = ({ account, removePaidReimbursifyAddOn, upgradeReimbursifyTier, downgradeReimbursifyTier, formatDateAndTime }) => {
  let currentReimbursifyPlanStatus = 'None';

  if (account.reimbursify_tier === 'professional') {
    currentReimbursifyPlanStatus = 'Professional';
  } else if (account.reimbursify_tier === 'premium') {
    currentReimbursifyPlanStatus = 'Premium';
  }

  return (
    <div style={{ display: 'flex' }}>
      {' '}
      <div className='w-33 box mr-sm'>
        <h5>Private Pay Booster Status</h5>
        <StatusWidget
          currentStatus={account.has_paid_reimbursify_plan ? 'Has Paid Private Pay Booster Add-On' : 'Does not have Paid Private Pay Booster Add-On'}
          statusOptionArray={['Does not have Paid Private Pay Booster Add-On', 'Has Paid Private Pay Booster Add-On']}
        />
      </div>
      <div className='w-33 box mr-sm'>
        <h5>Private Pay Booster Tier</h5>
        <StatusWidget currentStatus={currentReimbursifyPlanStatus} statusOptionArray={['None', 'Professional', 'Premium']} />

        {currentReimbursifyPlanStatus === 'None' && (
          <p>
            <button
              onClick={() => {
                if (
                  true ===
                  window.confirm(
                    `Are you sure you want to upgrade all ${account.total_subscription_seats} seats to Private Pay Booster professional? This will do the following: 1. “Upgraded Private Pay Booster” internal email is triggered to send. 2. Stripe subscription tier is updated. 3. “Private Pay Booster Add-On successfully upgraded“ will be sent to provider (only to account owner).`
                  )
                ) {
                  upgradeReimbursifyTier({
                    new_tier: 'professional',
                    stripe_customer_id: account.seat_based_stripe_customer_id,
                    stripe_subscription_id: account.seat_based_stripe_subscription_id,
                  });
                }
              }}
              className='primary pill  mr-sm mb-sm'
            >
              Upgrade to Professional
            </button>
          </p>
        )}

        {(currentReimbursifyPlanStatus === 'None' || currentReimbursifyPlanStatus === 'Professional') && (
          <p>
            <button
              onClick={() => {
                if (
                  true ===
                  window.confirm(
                    `Are you sure you want to upgrade all ${account.total_subscription_seats} seats to Private Pay Booster premium? This will do the following: 1. “Upgraded Private Pay Booster” internal email is triggered to send. 2. Stripe subscription tier is updated. 3. “Private Pay Booster Add-On successfully upgraded“ will be sent to provider (only to account owner).`
                  )
                ) {
                  upgradeReimbursifyTier({
                    new_tier: 'premium',
                    stripe_customer_id: account.seat_based_stripe_customer_id,
                    stripe_subscription_id: account.seat_based_stripe_subscription_id,
                  });
                }
              }}
              className='primary pill  mr-sm mb-sm'
            >
              Upgrade to Premium
            </button>
          </p>
        )}

        {currentReimbursifyPlanStatus === 'Premium' && (
          <p>
            <button
              onClick={() => {
                if (
                  true ===
                  window.confirm(
                    `Are you sure you want to downgrade all ${account.total_subscription_seats} seats to Private Pay Booster professional? This will do the following: 1. Stripe subscription tier is updated. 2. **No emails will be sent** - it is your responsibility to communicate the downgrade to the provider.`
                  )
                ) {
                  downgradeReimbursifyTier({
                    new_tier: 'professional',
                    stripe_customer_id: account.seat_based_stripe_customer_id,
                    stripe_subscription_id: account.seat_based_stripe_subscription_id,
                  });
                }
              }}
              className='primary pill  mr-sm mb-sm'
            >
              Downgrade to Professional
            </button>
          </p>
        )}

        {account.has_paid_reimbursify_plan && (
          <p>
            <button
              onClick={() => {
                if (
                  true ===
                  window.confirm(
                    'Are you sure you want to remove the paid Private Pay Booster add-on? Note: This will send the account owner a "Private Pay Benefits Verification Tool Add-On successfully removed" email and an internal "Updated Subscription to remove Private Pay Benefits Verification Tool" email.'
                  )
                ) {
                  removePaidReimbursifyAddOn({
                    stripe_customer_id: account.seat_based_stripe_customer_id,
                    stripe_subscription_id: account.seat_based_stripe_subscription_id,
                  });
                }
              }}
              className='primary pill mr-sm mb-sm'
            >
              Remove Paid Private Pay Booster Add-on
            </button>
          </p>
        )}
      </div>
      <div className='w-33 box mr-sm'>
        <h5>Reimbursify Account Status</h5>
        <h6>Account Created Date:</h6>
        {account.reimbursify_account_info.created_at ? formatDateAndTime(account.reimbursify_account_info.created_at) : '(Account not created)'}
        <h6>
          NPI Type 2 <br />
          (if entered during account setup):
        </h6>
        {account.reimbursify_account_info.group_npi_type2}
      </div>
    </div>
  );
};

export default connect(() => ({}), actions)(ManageReimbursify);
