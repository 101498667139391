import React, { useState } from 'react';

const EditableCardText = (props) => {
  const {
    initialState,
    label,
    name,
    updateFn,
    styles = '',
    disabled = false,
    type = 'text',
    custom_content,
  } = props;

  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(initialState);

  const onSubmit = () => {
    const payload = {};
    if (custom_content) {
      payload.custom_content = custom_content;
      payload.custom_content[name] = value;
    } else {
      payload[name] = value;
    }
    updateFn(payload);
    setEdit(false);
  };

  const onCancel = () => {
    setValue(initialState);
    setEdit(false);
  };

  if (edit) {
    return (
      <div className={`card cursor-default p-lg mx-md ${styles}`}>
        <div className='grid align-center justify-between'>
          <h3 className='mb-lg'>{label}</h3>
          <div>
            <button className='success p-xs mr-sm' onClick={onSubmit}>
              <i className='fas fa-check' />
            </button>

            <button className='error p-xs' onClick={onCancel}>
              <i className='fas fa-times' />
            </button>
          </div>
        </div>
        <hr />
        <input
          type={type}
          className='w-100'
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
    );
  } else {
    return (
      <div
        className={`card cursor-default p-lg mx-md ${disabled ? 'bg-grey-light' : ''} ${styles}`}
      >
        <div className='grid align-center justify-between'>
          <h3 className='mb-lg'>{label}</h3>
          <button className='success p-xs cursor-pointer' onClick={() => setEdit(true)}>
            <i className='fas fa-edit' />
          </button>
        </div>
        <hr />
        {value ? (
          <p>{value}</p>
        ) : (
          <div className='grid justify-center'>
            <h5>N/A</h5>
          </div>
        )}
      </div>
    );
  }
};

export default EditableCardText;
