import React from 'react';
import history from '#/history';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import UserForm from './Form';
import UserBatchUpload from './UserBatchUpload';

export const AddUser = (props) => {
  const { create, batchCreate } = props;

  const onCancel = () => {
    history.push('/users');
  };

  return (
    <>
      <UserForm
        onCancel={onCancel}
        onSubmit={(values) => {
          values.autoGenerate = true;
          create(values);
        }}
      />

      <hr />
      <h3>Or</h3>

      <UserBatchUpload onSubmit={batchCreate} />
    </>
  );
};

export default connect(null, actions)(AddUser);
