import { crudSlice, noop } from 'saga-slice-helpers';
import { takeLatest, put } from 'redux-saga/effects';

import { sagaApi, api } from '#/apis';
import { paginationReducer } from '#/sagaSlice';
import history from '#/history';

const sagaSliceModule = crudSlice({
  name: 'therapy-groups',
  sagaApi,
  reducers: {
    readAllSuccess: paginationReducer,

    addProvider: noop,
    addProviderFail: noop,

    removeProvider: noop,
    removeProviderFail: noop,

    searchTherapyGroups: noop,
    searchTherapyGroupsSuccess: paginationReducer,
    searchTherapyGroupsFail: noop,
  },
  takers: {
    readAll: takeLatest,
    searchProviders: takeLatest,
  },
  sagas: (A) => ({
    [A.readAll]: {
      *saga({ payload = {} }) {
        const { page = 0, order = 'asc', orderBy = 'name' } = payload;
        window.scrollTo(0, 0);
        yield sagaApi.get(
          `/therapy-groups?page=${page}&order=${order}&orderBy=${orderBy}`,
          A.readAllSuccess,
          A.readAllFail
        );
        yield put(A.resetCurrent());
      },
    },
    [A.readOneSuccess]: {
      *saga({ payload: therapyGroup }) {
        yield put(A.setCurrent(therapyGroup.id));
      },
    },
    [A.createSuccess]: {
      *saga({ payload }) {
        yield history.push(`/therapy-groups/view/${payload.id}`);
      },
    },
    [A.delete]: {
      *saga({ payload: id }) {
        yield sagaApi.delete(
          `/therapy-groups/${id}`,
          null,
          A.deleteSuccess,
          A.deleteFail,
          A.deleteDone
        );
      },
    },
    [A.deleteSuccess]: {
      *saga() {
        yield history.push('/therapy-groups');
      },
    },
    [A.updateSuccess]: {
      *saga({ payload }) {
        yield put(A.readOne(payload.id));
      },
    },
    [A.addProvider]: {
      *saga({ payload }) {
        try {
          const { provider, therapyGroup } = payload;
          yield api.put(`/providers/${provider}/therapy-group/${therapyGroup}`);
          yield put(A.readOne(therapyGroup));
        } catch (error) {
          put(A.addProviderFail(error));
        }
      },
    },
    [A.removeProvider]: {
      *saga({ payload }) {
        try {
          const { provider, therapyGroup } = payload;
          yield api.delete(`/providers/${provider}/therapy-group/${therapyGroup}`);
          yield put(A.readOne(therapyGroup));
        } catch (error) {
          put(A.removeProviderFail(error));
        }
      },
    },
    [A.searchTherapyGroups]: {
      *saga({ payload = '' }) {
        try {
          if (payload.length < 1) {
            yield put(A.readAll());
          } else if (payload.length > 0) {
            const { data } = yield api.get(`/therapy-groups/search/${payload}`);
            yield put(A.searchTherapyGroupsSuccess(data));
          }
        } catch (error) {
          yield put(A.searchTherapyGroupsFail(error));
        }
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
