import React from 'react';
import EditableCard from '+/forms/EditableCard';
import CustomSpecialties from '../CustomSpecialties';
import CustomBoxes from '../practices/CustomBoxes';
import EducationEmploymentV2 from '../EducationEmploymentV2';
import PronounForm from '../PronounForm';
import VacationResponderStatus from '../VacationResponderStatus';
import PracticeManagementBookingLink from '../PracticeManagementBookingLink';
import ProviderEventLog from '../ProviderEventLog';

const ProfileTab = (props) => {
  const { provider, updateFn } = props;

  if (!provider || !provider.custom_content) {
    return null;
  }
  const { custom_content } = provider;
  const { custom_specialties, boxes, education, education_v2, employment_v2, custom_approaches } = custom_content;

  const isPractice = provider.type === 'practice';

  return (
    <div className='grid column'>
      <div className='grid justify-between'>
        <EditableCard label='Professional statement' name='professional_statement' initialState={provider.professional_statement} styles={'w-50'} updateFn={updateFn} />

        <EditableCard label='Message to clients:' name='message_to_clients' initialState={provider.message_to_clients} styles={'w-50'} updateFn={updateFn} />
      </div>
      <div className='grid justify-between'>
        <CustomSpecialties custom_content_type='custom_specialties' initialState={custom_specialties} updateFn={updateFn} custom_content={custom_content} styles='w-50' />
        <CustomSpecialties custom_content_type='custom_approaches' initialState={custom_approaches} updateFn={updateFn} custom_content={custom_content} styles='w-50' />
      </div>
      {!isPractice && <EducationEmploymentV2 education_v2={education_v2} employment_v2={employment_v2} updateFn={updateFn} />}
      <VacationResponderStatus vacationResponder={provider.out_of_office_message} />
      <PracticeManagementBookingLink provider={provider} />
      <PronounForm provider={provider} updateFn={updateFn} />
      <ProviderEventLog provider={provider} />
      <div className='grid justify-between'>
        {isPractice ? (
          <CustomBoxes initialState={boxes} updateFn={updateFn} styles='w-100' custom_content={custom_content} />
        ) : (
          <EditableCard label='Education (v1):' name='education' custom_content={provider.custom_content} initialState={education} editable={false} styles='w-50' updateFn={updateFn} />
        )}
      </div>
    </div>
  );
};

export default ProfileTab;
