import React from 'react';

const ProviderResults = ({ data }) => {
  if (!data || !data.length) {
    return null;
  }

  const handleRowClick = (item) => {
    window.open(`/providers/view/${item.id}`);
  };

  return (
    <div>
      <h2>Providers:</h2>
      <table className='data-table'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Status</th>
            <th>Name</th>
            <th>Type</th>
            <th>Contact Email</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr
              title='Open provider in new tab'
              className='clickable-result-row'
              onClick={() => {
                handleRowClick(item);
              }}
              key={item.id}
            >
              <td>{item.id}</td>
              <td>{item.status}</td>
              <td>
                {item.first_name} {item.last_name} {item.company_name}
              </td>
              <td>{item.type}</td>
              <td>{item.contact_email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProviderResults;
