import AccountAttributesSlice from '@/accounts/account-attributes/sagaSlice';
import AccountsSlice from '@/accounts/sagaSlice';
import AreasSlice from '@/areas/sagaSlice';
import AuthSlice from '@/auth/sagaSlice';
import RegionsSlice from '@/regions/sagaSlice';
import TherapyGroupsSlice from '@/therapy-groups/sagaSlice';
import UsersSlice from '@/users/sagaSlice';
import ProviderSlice from '@/providers/sagaSlice';
import InsuranceRulesSlice from '@/insurance-rules/sagaSlice';
import DeveloperToolsRulesSlice from '@/developer-tools/sagaSlice';
import ReportsSlice from '@/reports/sagaSlice';
import SearchContentSlice from '@/search-content/sagaSlice';
import TagsSlices from '@/tags/sagaSlice';
import DashboardSlice from '@/dashboard/sagaSlice';
import RedirectsSlice from '@/redirects/sagaSlice';
import BlacklistSlice from '@/contact-blacklist/sagaSlice';
import LocationsSlice from '+/locations/sagaSlice';
import PhotosSlice from '+/photos/sagaSlice';
import SeoSlice from '+/seo/sagaSlice';
import FeatureFlags from '@/feature-flags/sagaSlice';
import ReimbursifyInsurances from '@/reimbursify-insurances/sagaSlice';
import UserVobs from '@/user-vobs/sagaSlice';
import OmniSearch from '@/omni-search/sagaSlice';
import HomepageBlogPosts from '@/homepage-blog-posts/sagaSlice';

export default [
  AccountAttributesSlice,
  AccountsSlice,
  AreasSlice,
  AuthSlice,
  RegionsSlice,
  TherapyGroupsSlice,
  UsersSlice,
  ProviderSlice,
  DashboardSlice,
  DeveloperToolsRulesSlice,
  ReportsSlice,
  SearchContentSlice,
  InsuranceRulesSlice,
  ...Object.values(TagsSlices),
  LocationsSlice,
  PhotosSlice,
  SeoSlice,
  RedirectsSlice,
  BlacklistSlice,
  FeatureFlags,
  ReimbursifyInsurances,
  UserVobs,
  OmniSearch,
  HomepageBlogPosts,
];
