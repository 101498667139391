// @ts-check

import React from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '~/components/accounts/sagaSlice';

/**
 * @typedef StandalonePracticeManagementUpgradeDowngradeProps
 * @property {AdminGetAccountSummaryByTypeResultItem} a - The account info
 */

function StandalonePracticeManagementUpgradeDowngrade(
  /** @type {StandalonePracticeManagementUpgradeDowngradeProps} */
  { a }
) {
  const dispatch = useDispatch();

  return (
    <>
      {a.seat_based_stripe_subscription_seats.zpm && a.tier === 'professional' && (
        <p>
          <button
            onClick={() => {
              if (
                true ===
                window.confirm(
                  `Are you sure you want to upgrade all ${a.seat_based_stripe_subscription_seats.zpm} seats to premium? This will do the following: 1) “Updated Subscription” internal email is triggered to send. 2. Webhook is sent to Remanage to update subscription tier. 3. Stripe subscription tier is updated. 4. NO EMAIL will be sent to provider (you will need to do this manually).`
                )
              ) {
                dispatch(
                  // @ts-ignore
                  actions.upgradeStandalonePracticeManagementTier({
                    new_tier: 'premium',
                    stripe_customer_id: a.seat_based_stripe_customer_id,
                    stripe_subscription_id: a.seat_based_stripe_subscription_id,
                  })
                );
              }
            }}
            className='primary pill  mr-sm mb-sm'
          >
            Upgrade to Premium
          </button>
        </p>
      )}
      {a.seat_based_stripe_subscription_seats.zpm && a.tier === 'premium' && (
        <button
          onClick={() => {
            if (
              true ===
              window.confirm(
                `Are you sure you want to downgrade all ${a.seat_based_stripe_subscription_seats.zpm} seats to professional? This will do the following: 1) “Updated Subscription” internal email is triggered to send. 2. Webhook is sent to Remanage to update subscription tier. 3. Stripe subscription tier is updated. 4. NO EMAIL will be sent to provider (you will need to do this manually).`
              )
            ) {
              dispatch(
                // @ts-ignore
                actions.downgradeStandalonePracticeManagementTier({
                  new_tier: 'professional',
                  stripe_customer_id: a.seat_based_stripe_customer_id,
                  stripe_subscription_id: a.seat_based_stripe_subscription_id,
                })
              );
            }
          }}
          className='primary pill  mr-sm mb-sm'
        >
          Downgrade to Professional
        </button>
      )}
    </>
  );
}

export default StandalonePracticeManagementUpgradeDowngrade;
