import React, { useState } from 'react';
import CustomSession from './CustomSession';
const randKey = () => Math.floor(Math.random() * 100000);

const CustomSessionInfoTable = (props) => {
  const { initialState, updateFn, custom_content, properties } = props;
  const [newSessionInProgress, setNewSessionInProgress] = useState(false);
  const [sessions, setSessions] = useState(initialState);

  const addSession = () => {
    setSessions((oldSessions) => [...oldSessions, { isNew: true }]);
    setNewSessionInProgress(true);
  };

  const deleteNewSession = () => {
    setSessions((oldSessions) => {
      oldSessions.pop();

      return oldSessions;
    });
    setNewSessionInProgress(false);
  };

  const updateSessions = (payload) => {
    updateFn(payload);
    setSessions(() => [...initialState]);
    newSessionInProgress && setNewSessionInProgress(false);
  };

  return (
    <div>
      <div className='grid justify-between align-center'>
        <h2>Custom Session Info:</h2>

        <button className='success' disabled={newSessionInProgress} onClick={addSession}>
          <i className='fas fa-plus' />
        </button>
      </div>
      <hr />
      <div className='scroll-y'>
        {sessions && (
          <div>
            <div className='bg-grey-dark text-white'>
              <div className='p-md grid align-center justify-between'>
                {properties.map((val, i) => (
                  <div className='mr-sm w-30 sessionTitle' key={randKey()}>
                    <b>{val.title}</b>
                  </div>
                ))}
                <div className='w-15' />
              </div>
            </div>
            <div>
              {sessions.length ? (
                sessions.map((session, index) => (
                  <CustomSession
                    index={index}
                    initialState={session}
                    updateFn={updateSessions}
                    custom_content={custom_content}
                    deleteNewSession={deleteNewSession}
                    key={`session${index}`}
                  />
                ))
              ) : (
                <div className='grid justify-center w-100'>
                  <h5>No Custom Sessions, Press the "+" Button</h5>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSessionInfoTable;
