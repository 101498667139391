import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import InsuranceRulesTable from './InsuranceRulesTable';
import AddInsuranceRuleButton from './AddInsuranceRuleButton';

export const InsuranceRules = (props) => {
  const { getInsurancesWithParents } = props;
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (!dataLoaded) {
      getInsurancesWithParents();
      setDataLoaded(true);
    }
  }, [dataLoaded, getInsurancesWithParents]);

  return (
    <div className='insurance-rules'>
      <div className='grid align-center justify-between'>
        <h1 className='inline-block'>Manage Insurance Rules</h1>

        <AddInsuranceRuleButton />
      </div>
      <InsuranceRulesTable />
    </div>
  );
};
export default connect((state) => ({}), actions)(InsuranceRules);
