import React from 'react';
import OmniModal from '~/components/omni-search/OmniModal';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './sagaSlice';
import AccountResults from './results/AccountResults';
import ProviderResults from './results/ProviderResults';
import UserResults from './results/UserResults';

export const OmniResultsModal = () => {
  const dispatch = useDispatch();
  const { showOmniResultsModal, omniSearchResults } = useSelector((state) => state['omniSearch']);

  const toggleModal = () => {
    dispatch(actions.hideOmniSearchModalReducer());
  };

  const noResults =
    omniSearchResults &&
    omniSearchResults.accountResults &&
    !omniSearchResults.accountResults.length &&
    omniSearchResults.providerResults &&
    !omniSearchResults.providerResults.length &&
    omniSearchResults.userResults &&
    !omniSearchResults.userResults.length;

  if (showOmniResultsModal) {
    return (
      <OmniModal title={`Omni Search Results`} onDismiss={toggleModal}>
        <div className='omni-results-modal'>
          <AccountResults data={omniSearchResults.accountResults} />
          <ProviderResults data={omniSearchResults.providerResults} />
          <UserResults data={omniSearchResults.userResults} />

          {noResults ? 'Sorry, we could not find matches for that query. Please try again.' : ''}
        </div>
      </OmniModal>
    );
  }

  return null;
};

export default OmniResultsModal;
