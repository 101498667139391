import React from 'react';
import UploadInsuranceMapping from './UploadInsuranceMapping';

function ReimbursifyInsurances() {
  return (
    <div>
      <h1>Upload Reimbursify Insurance Mappings</h1>
      <UploadInsuranceMapping />
    </div>
  );
}

export default ReimbursifyInsurances;
