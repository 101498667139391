/* eslint-disable max-lines-per-function */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import { Prompt } from 'react-router';
import history from '#/history';
import { Field, Form } from 'react-final-form';
import EditableTextArea from '+/forms/EditableTextArea';
import BoolToggle from '+/forms/BoolToggle';
import MultiStateSelector from './MultiStateSelector';
import { commonInsuranceNames } from './constants';
import ViewAssociation from '+/ViewAssociation';

const onCancel = () => history.push('/insurance-rules');

// Note: The possible insurance_rule DB row types are:
// show-hide, sub-insurance-group, sub-insurance
const EditInsuranceRule = (props) => {
  const {
    insuranceTags,
    rulesForCurrentTag,
    rulesForCurrentTagLoaded,
    getInsuranceTags,
    getRulesForTag,
    bulkUpdate,
    bulkDelete,
    match = { params: {} },
  } = props;
  const tagId = parseInt(match.params.tagId);

  // Note: modeAll is the same as having no insurance_rule row of type 'show-hide' in DB.
  // The default behavior is to show all
  // we only need to store a record with modeAll when isGloballyCommon is true
  const modeAll = 'Show in all states';
  const modeInclude = 'Only show insurance in these states...';

  const modeExclude = 'Hide insurance from these states...';

  const childRuleModeAll = 'Show sub-insurance group in all states';
  const childRuleModeInclude = 'Show sub-insurance group only in these states...';
  const childRuleModeExclude = 'Hide sub-insurance group from these states...';

  const modeToReservedName = (mode) => {
    if (mode === modeAll || mode === childRuleModeAll) {
      return 'all';
    } else if (mode === modeInclude || mode === childRuleModeInclude) {
      return 'include';
    } else if (mode === modeExclude || mode === childRuleModeExclude) {
      return 'exclude';
    } else {
      throw new Error(`unknown mode ${mode}`);
    }
  };

  const [insuranceTagsLoadStarted, setInsuranceTagsLoadStarted] = useState(false);
  const [insuranceTagsInitialized, setInsuranceTagsInitialized] = useState(false);
  const [rulesForTagLoadStarted, setRulesForTagLoadStarted] = useState(false);
  const [rulesForTagInitialized, setRulesForTagInitialized] = useState(false);
  const [filteredInsuranceTags, setFilteredInsuranceTags] = useState(null);
  const [topLevelTag, setTopLevelTag] = useState(null);
  const [topLevelStates, setTopLevelStates] = useState([]);
  const [isGloballyCommon, setIsGloballyCommon] = useState(false);
  const [subInsuranceGroups, setSubInsuranceGroups] = useState([]);
  const [temporaryRuleIdCounter, setTemporaryRuleIdCounter] = useState(0);
  const [ruleIdsPendingDelete, setRuleIdsPendingDelete] = useState([]);
  const [existingShowHideRule, setExistingShowHideRule] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [initialValues, setInitialValues] = useState({
    // Note: If there is not a showHideRule, by definition the showHideMode is modeAll.
    showHideMode: modeAll,
    isCommon: isGloballyCommon,
  });

  useEffect(() => {
    if (!insuranceTagsInitialized && Object.values(insuranceTags).length) {
      const currentTag = insuranceTags.find((x) => x.id === tagId);
      setTopLevelTag(currentTag);
      setFilteredInsuranceTags(insuranceTags.filter((x) => x.id !== currentTag.id));
      setIsGloballyCommon(commonInsuranceNames.includes(currentTag.name.trim()));
      setInsuranceTagsInitialized(true);
    } else if (!insuranceTagsLoadStarted) {
      getInsuranceTags();
      setInsuranceTagsLoadStarted(true);
    }
  }, [insuranceTags, getInsuranceTags, insuranceTagsInitialized, insuranceTagsLoadStarted, tagId]);

  useEffect(() => {
    if (!rulesForTagLoadStarted) {
      getRulesForTag({ tagId });
      setRulesForTagLoadStarted(true);
    }
  }, [getRulesForTag, rulesForTagLoadStarted, tagId]);

  useEffect(() => {
    if (insuranceTagsInitialized && rulesForCurrentTagLoaded && !rulesForTagInitialized) {
      const copiedInitialValues = { ...initialValues };
      const showHideRule = rulesForCurrentTag.find((x) => x.type === 'show-hide');
      if (showHideRule) {
        setExistingShowHideRule(showHideRule);
        if (!isGloballyCommon) {
          copiedInitialValues.isCommon = showHideRule.is_common === 1;
        }
        if (showHideRule.states) {
          if (showHideRule.states === 'ALL') {
            copiedInitialValues.showHideMode = modeAll;
          } else {
            copiedInitialValues.showHideMode = modeInclude;
            setTopLevelStates(showHideRule.states.split(','));
          }
        } else {
          copiedInitialValues.showHideMode = modeExclude;
          setTopLevelStates(showHideRule.exclude_states.split(','));
        }
      }

      const newSubInsuranceGroups = [];
      const subInsuranceGroupsFromDb = rulesForCurrentTag.filter(
        (x) => x.type === 'sub-insurance-group'
      );
      subInsuranceGroupsFromDb.forEach((subInsuranceGroup, i) => {
        const subInsurances = [];
        subInsuranceGroup.childRules.forEach((subInsuranceRule) => {
          const tag = insuranceTags.find((x) => x.id === subInsuranceRule.tag_id);
          subInsurances.push(tag);
        });

        let states = [];
        if (subInsuranceGroup.states) {
          if (subInsuranceGroup.states === 'ALL') {
            copiedInitialValues[`group-${i}-showHideMode`] = childRuleModeAll;
          } else {
            states = subInsuranceGroup.states.split(',');
            copiedInitialValues[`group-${i}-showHideMode`] = childRuleModeInclude;
          }
        } else if (subInsuranceGroup.exclude_states) {
          states = subInsuranceGroup.exclude_states.split(',');
          copiedInitialValues[`group-${i}-showHideMode`] = childRuleModeExclude;
        }

        newSubInsuranceGroups.push({
          // We need to make ruleId a string since we send new with temporary string id
          ruleId: subInsuranceGroup.id.toString(),
          description: subInsuranceGroup.description,
          states,
          requires_child_select: subInsuranceGroup.requires_child_select === 1,
          subInsurances,
        });
      });

      if (newSubInsuranceGroups.length) {
        setSubInsuranceGroups([...subInsuranceGroups, ...newSubInsuranceGroups]);
      }

      setInitialValues(copiedInitialValues);

      setRulesForTagInitialized(true);
    }
  }, [
    insuranceTagsInitialized,
    initialValues,
    isGloballyCommon,
    rulesForCurrentTag,
    rulesForCurrentTagLoaded,
    rulesForTagInitialized,
    subInsuranceGroups,
    insuranceTags,
  ]);

  const searchInsuranceTags = (searchTerm) => {
    if (searchTerm && searchTerm.length) {
      const result = insuranceTags.filter(
        (x) => _.includes(x.name.toUpperCase(), searchTerm.toUpperCase()) && x.id !== topLevelTag.id
      );
      setFilteredInsuranceTags(result);
    } else {
      setFilteredInsuranceTags(insuranceTags.filter((x) => x.id !== topLevelTag.id));
    }
  };

  const onAddChildRule = () => {
    setIsDirty(true);
    setSubInsuranceGroups([
      ...subInsuranceGroups,
      {
        // Until saved to DB, the rule id is null
        ruleId: `temporary_id_${temporaryRuleIdCounter}`,
        description: '',
        states: [],
        requires_child_select: false,
        subInsurances: [],
      },
    ]);

    setTemporaryRuleIdCounter((temporaryRuleIdCounter) => temporaryRuleIdCounter + 1);
  };

  const onSubmit = (values) => {
    const subInsuranceGroupsPayload = [];
    subInsuranceGroups.forEach((subInsuranceGroup, i) => {
      if (!ruleIdsPendingDelete.includes(subInsuranceGroup.ruleId)) {
        const subInsuranceGroupForPayload = _.cloneDeep(subInsuranceGroup);
        subInsuranceGroupForPayload.showHideMode = modeToReservedName(
          values[`group-${i}-showHideMode`]
        );
        subInsuranceGroupForPayload.subInsurances = subInsuranceGroup.subInsurances.map(
          (tag) => tag.id
        );
        subInsuranceGroupsPayload.push(subInsuranceGroupForPayload);
      }
    });

    const existingRuleIdsPendingDelete = ruleIdsPendingDelete.filter(
      (x) => !x.includes('temporary_id_')
    );
    const payload = {
      topLevelTagId: topLevelTag.id,
      // We don't set isCommon to true if globally common
      isCommon: isGloballyCommon ? false : values.isCommon,
      topLevelShowHideMode: modeToReservedName(values.showHideMode),
      topLevelStates,
      subInsuranceGroups: subInsuranceGroupsPayload,
      ruleIdsPendingDelete: existingRuleIdsPendingDelete,
    };
    // Stop unsaved warnings as we are about to save
    setIsDirty(false);
    bulkUpdate(payload);
  };

  const deleteSubInsuranceGroup = (subInsuranceGroup) => {
    if (window.confirm('Are you sure you want to delete this rule?')) {
      setIsDirty(true);
      setRuleIdsPendingDelete([...ruleIdsPendingDelete, subInsuranceGroup.ruleId]);
    }
  };

  const onDeleteParentRule = (formProps) => {
    if (
      window.confirm(
        `Are you sure you want to delete this rule so that every state will be able to see ${topLevelTag.name}?`
      )
    ) {
      const existingRuleIdsPendingDelete = ruleIdsPendingDelete.filter(
        (x) => !x.includes('temporary_id_')
      );
      const localRuleIdsPendingDelete = [...existingRuleIdsPendingDelete, existingShowHideRule.id];
      const payload = { ruleIdsPendingDelete: localRuleIdsPendingDelete };
      // Stop unsaved warnings as we are about to delete
      setIsDirty(false);
      bulkDelete(payload);
    }
  };

  const nonDeletedSubInsuranceGroups = subInsuranceGroups.filter(
    (x) => !ruleIdsPendingDelete.includes(x.ruleId)
  );

  // Disable submit until selecting a show/hide mode and any required states for each 'sub-insurance-group'
  const isSubmitDisabled = (formProps) => {
    let isDisabled = false;
    subInsuranceGroups.forEach((subInsuranceGroup, i) => {
      if (!ruleIdsPendingDelete.includes(subInsuranceGroup.ruleId)) {
        if (formProps.values[`group-${i}-showHideMode`]) {
          if (
            (formProps.values[`group-${i}-showHideMode`] === modeInclude ||
              formProps.values[`group-${i}-showHideMode`] === modeExclude) &&
            subInsuranceGroup.states.length === 0
          ) {
            isDisabled = true;
          }
        } else {
          isDisabled = true;
        }
      }
    });

    return isDisabled;
  };

  const renderSubInsuranceGroupEditor = (formProps) =>
    nonDeletedSubInsuranceGroups.map((subInsuranceGroup, i) => (
      <div key={i} className='box m-b-sm'>
        <h2>Sub-Insurance Group {i + 1}</h2>

        <h3 className='m-b-0'>Applicable sub-insurances</h3>
        <p className='m-t-xs'>Which insurances are part of this sub-insurance group?</p>
        <ViewAssociation
          disableSearchInitCall={true}
          title='Applicable sub-insurances'
          items={filteredInsuranceTags}
          searchFn={searchInsuranceTags}
          selected={subInsuranceGroup.subInsurances}
          parentId={subInsuranceGroup.ruleId}
          disassociateFn={(parentId, tagId) => {
            setIsDirty(true);
            // Reactively edit element of the subInsuranceGroups array
            const subInsuranceGroupCopy = [...subInsuranceGroups];
            const itemToEdit = {
              ...subInsuranceGroupCopy[i],
              subInsurances: subInsuranceGroup.subInsurances.filter((x) => x.id !== tagId),
            };
            subInsuranceGroupCopy[i] = itemToEdit;
            setSubInsuranceGroups(subInsuranceGroupCopy);
          }}
          associateFn={(parentId, tagId) => {
            setIsDirty(true);
            const tag = insuranceTags.find((x) => x.id === tagId);
            // Don't allow duplicates or setting a tag as a sub-insurance of itself.
            if (tag.id !== topLevelTag.id && !subInsuranceGroup.subInsurances.includes(tag)) {
              // Reactively edit element of the subInsuranceGroups array
              const subInsuranceGroupCopy = [...subInsuranceGroups];
              const itemToEdit = {
                ...subInsuranceGroupCopy[i],
                subInsurances: [...subInsuranceGroup.subInsurances, tag],
              };
              subInsuranceGroupCopy[i] = itemToEdit;
              setSubInsuranceGroups(subInsuranceGroupCopy);
            }
          }}
        />

        <h3
          className='m-b-0'
          style={{
            borderTop: '1px solid lightgray',
            paddingTop: '2rem',
          }}
        >
          Applicable states
        </h3>
        <p className='m-t-xs'>Which states use this sub-insurance group?</p>
        <div className='flex align-baseline'>
          <Field
            name={`group-${i}-showHideMode`}
            component='input'
            type='radio'
            value={childRuleModeAll}
            id={`group-${i}-mode-all`}
          />
          <label className='m-b-sm' htmlFor={`group-${i}-mode-all`}>
            {childRuleModeAll}
          </label>
        </div>
        <div className='flex align-baseline'>
          <Field
            name={`group-${i}-showHideMode`}
            component='input'
            type='radio'
            value={childRuleModeInclude}
            id={`group-${i}-mode-show`}
          />
          <label className='m-b-sm' htmlFor={`group-${i}-mode-show`}>
            {childRuleModeInclude}
          </label>
        </div>
        <div className='flex align-baseline'>
          <Field
            name={`group-${i}-showHideMode`}
            component='input'
            type='radio'
            value={childRuleModeExclude}
            id={`group-${i}-mode-hide`}
          />
          <label className='m-b-sm' htmlFor={`group-${i}-mode-hide`}>
            {childRuleModeExclude}
          </label>
        </div>

        {formProps.values[`group-${i}-showHideMode`] !== childRuleModeAll && (
          <MultiStateSelector
            currentStates={subInsuranceGroup.states}
            onSelected={(state) => {
              setIsDirty(true);
              // Don't allow duplicates
              if (!subInsuranceGroup.states.includes(state)) {
                // Reactively edit element of the subInsuranceGroups array
                const subInsuranceGroupCopy = [...subInsuranceGroups];
                const itemToEdit = {
                  ...subInsuranceGroupCopy[i],
                  states: [...subInsuranceGroup.states, state],
                };
                subInsuranceGroupCopy[i] = itemToEdit;
                setSubInsuranceGroups(subInsuranceGroupCopy);
              }
            }}
            onRemoveState={(state) => {
              setIsDirty(true);
              // Reactively edit element of the subInsuranceGroups array
              const subInsuranceGroupCopy = [...subInsuranceGroups];
              const itemToEdit = {
                ...subInsuranceGroupCopy[i],
                states: subInsuranceGroup.states.filter((existingState) => existingState !== state),
              };
              subInsuranceGroupCopy[i] = itemToEdit;
              setSubInsuranceGroups(subInsuranceGroupCopy);
            }}
          />
        )}

        <h3
          className='m-b-0'
          style={{
            borderTop: '1px solid lightgray',
            paddingTop: '2rem',
          }}
        >
          Sub-insurance required?
        </h3>
        <p className='m-t-xs'>
          Do you want to require the provider to select a sub-insurance? (e.g. BCBS IL for IL
          providers)
        </p>
        <div className='requires-child-selection'>
          <BoolToggle
            label='Must select sub-insurance?'
            id={`group-${i}-requires_child_select`}
            setValue={(formValues, property, newValue) => {
              setIsDirty(true);
              // Reactively edit element of the subInsuranceGroups array
              const subInsuranceGroupCopy = [...subInsuranceGroups];
              const itemToEdit = {
                ...subInsuranceGroupCopy[i],
                requires_child_select: newValue,
              };
              subInsuranceGroupCopy[i] = itemToEdit;
              setSubInsuranceGroups(subInsuranceGroupCopy);
            }}
            objectUnderEdit={subInsuranceGroup}
            property='requires_child_select'
          />
        </div>

        <h3>Instruction</h3>
        <EditableTextArea
          label='Instructions to show under members'
          name='description'
          updateFn={(payload) => {
            setIsDirty(true);
            // Reactively edit element of the subInsuranceGroups array
            const subInsuranceGroupCopy = [...subInsuranceGroups];
            const itemToEdit = {
              ...subInsuranceGroupCopy[i],
              description: payload.description,
            };
            subInsuranceGroupCopy[i] = itemToEdit;
            setSubInsuranceGroups(subInsuranceGroupCopy);
          }}
          initialState={subInsuranceGroup.description}
        />

        <button className='error p-xs' onClick={() => deleteSubInsuranceGroup(subInsuranceGroup)}>
          <i className='fas fa-trash' /> Delete Sub-insurance Group
        </button>
      </div>
    ));

  return (
    <div className='edit-insurance-rule'>
      <Prompt
        when={isDirty}
        message='You have unsaved changes! Are you sure you want to leave without saving?'
      />
      {insuranceTagsInitialized && rulesForTagInitialized && (
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={{
            setValue: ([name, value], state, utils) => {
              utils.changeValue(state, name, () => value);
            },
          }}
          render={(formProps) => (
            <form onSubmit={formProps.handleSubmit}>
              {topLevelTag && <h1>{topLevelTag.name}</h1>}

              <div className='box m-b-md'>
                <h2>Show-Hide Rule</h2>

                <h3>Show or hide</h3>
                <div className='flex align-baseline'>
                  <Field
                    name='showHideMode'
                    component='input'
                    type='radio'
                    value={modeAll}
                    id='mode-all'
                  />
                  <label className='m-b-sm' htmlFor='mode-all'>
                    {modeAll}
                  </label>
                </div>
                <div className='flex align-baseline'>
                  <Field
                    name='showHideMode'
                    component='input'
                    type='radio'
                    value={modeInclude}
                    id='mode-show'
                  />
                  <label className='m-b-sm' htmlFor='mode-show'>
                    {modeInclude}
                  </label>
                </div>
                <div className='flex align-baseline'>
                  <Field
                    name='showHideMode'
                    component='input'
                    type='radio'
                    value={modeExclude}
                    id='mode-hide'
                  />
                  <label className='m-b-sm' htmlFor='mode-hide'>
                    {modeExclude}
                  </label>
                </div>
                {formProps.values.showHideMode !== modeAll && (
                  <MultiStateSelector
                    currentStates={topLevelStates}
                    onSelected={(state) => {
                      setIsDirty(true);
                      // Don't allow duplicates
                      if (!topLevelStates.includes(state)) {
                        setTopLevelStates([...topLevelStates, state]);
                      }
                    }}
                    onRemoveState={(state) => {
                      setTopLevelStates(topLevelStates.filter((x) => x !== state));
                    }}
                  />
                )}

                <h3>Display as 'Common' insurance</h3>
                <div className='is-common'>
                  {/* Note: BoolToggle doesn't reactively update when isCommon form value is set from server
                                    so we wait to render form until we initialize all initial values */}
                  {isGloballyCommon ? (
                    <p>This insurance is always common.</p>
                  ) : (
                    <BoolToggle
                      label='Treat as common? (very rarely used)'
                      id='isCommon'
                      setValue={(formValues, property, newValue) => {
                        setIsDirty(true);
                        formProps.form.mutators.setValue(property, newValue);
                      }}
                      objectUnderEdit={formProps.values}
                      property='isCommon'
                    />
                  )}
                </div>
              </div>

              {renderSubInsuranceGroupEditor(formProps)}

              <button type='button' onClick={onAddChildRule} className='primary'>
                Add Sub-Insurance Group
              </button>

              <div className='grid justify-between field'>
                <button type='button' onClick={onCancel} className='error'>
                  Cancel
                </button>

                {existingShowHideRule ? (
                  <button
                    type='button'
                    disabled={nonDeletedSubInsuranceGroups.length}
                    onClick={() => {
                      onDeleteParentRule(formProps);
                    }}
                    className='error'
                  >
                    <i className='fas fa-trash' /> Delete Show-Hide Rule
                  </button>
                ) : (
                  ''
                )}

                <button disabled={isSubmitDisabled(formProps)} className='success'>
                  Submit
                </button>
              </div>
            </form>
          )}
        />
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    insuranceTags: state.insuranceRules.insuranceTags,
    rulesForCurrentTag: state.insuranceRules.rulesForCurrentTag,
    rulesForCurrentTagLoaded: state.insuranceRules.rulesForCurrentTagLoaded,
  }),
  actions
)(EditInsuranceRule);
