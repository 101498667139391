import React from 'react';
import Accordion from '../../../shared/Accordion';

const AccountResults = ({ data }) => {
  if (!data || !data.length) {
    return null;
  }

  const handleRowClick = (item) => {
    window.open(`/accounts/view/${item.id}`);
  };

  const getNotesSubstring = (item) => {
    if (!item.notes) return '';

    // If there are notes, display the first 300 characters
    return item.notes.length >= 300 ? `${item.notes.substring(0, 300)}...` : item.notes;
  };

  return (
    <div>
      <h2>Accounts:</h2>
      <table className='data-table'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Account Name</th>
            <th>Paid products</th>
            <th>PPB / ZPM Clinic Info</th>
            <th>Account Users</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr
              title='Open account in new tab'
              className='clickable-result-row'
              onClick={() => {
                handleRowClick(item);
              }}
              key={item.id}
            >
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>
                <div>
                  {item.accountPaymentStatusFlags.has_practice_management && !(item.accountPaymentStatusFlags.seat_based_stripe_subscription_seats.zpm > 0) ? (
                    <>
                      <img src='/images/icons/zpm.png' className='product-icon' alt='ZPM' /> ZPM {item.accountPaymentStatusFlags.tier} (Add-on)
                    </>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  {item.accountPaymentStatusFlags.seat_based_stripe_subscription_seats.zpm > 0 ? (
                    <>
                      <img src='/images/icons/zpm.png' className='product-icon' alt='ZPM' /> ZPM {item.accountPaymentStatusFlags.standalone_zpm_tier} (Standalone)
                    </>
                  ) : (
                    ''
                  )}
                </div>

                <div>
                  {item.accountPaymentStatusFlags.seat_based_stripe_subscription_seats.reimbursify > 0 ? (
                    <>
                      <img src='/images/icons/ppb.png' className='product-icon' alt='PPB' /> Private Pay Booster {item.accountPaymentStatusFlags.reimbursify_tier}
                    </>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  {item.accountPaymentStatusFlags.seat_based_stripe_subscription_seats.ny > 0 || item.accountPaymentStatusFlags.seat_based_stripe_subscription_seats.non_ny > 0 ? (
                    <>
                      <img src='/images/icons/marketing.png' className='product-icon' alt='Marketing' /> Seat Based-Marketing
                    </>
                  ) : (
                    ''
                  )}
                </div>
                <div>
                  {item.accountPaymentStatusFlags.legacy_subscription_count > 0 ? (
                    <>
                      <img src='/images/icons/marketing.png' className='product-icon' alt='Marketing' /> Possible <b>Legacy</b> Marketing
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </td>

              <td>
                <div className='m-b-lg'>{item.accountPaymentStatusFlags.reimbursify_account_created ? <>Reimbursify account created!</> : ''}</div>

                {item.zpmAccounts.map((zpmAccount) => (
                  <div className='m-b-md'>
                    <div className='m-b-xs'>
                      Owl clinic id: <b>{zpmAccount.owl_clinic_id}</b>
                    </div>
                    <div>
                      Clinic subdomain: <b>{zpmAccount.subdomain}</b>
                    </div>
                  </div>
                ))}

                {item.clinic_creator_email && item.clinic_creator_email.length ? (
                  <div>
                    <b>Clinic Creator Info:</b>
                    <br />
                    {item.clinic_creator_first_name} {item.clinic_creator_last_name} {item.clinic_creator_email ? `(${item.clinic_creator_email})` : ''}
                  </div>
                ) : (
                  ''
                )}
              </td>
              <td>
                {item.accountOwner ? (
                  <div>
                    <b>Owner:</b> {item.accountOwner.first_name} {item.accountOwner.last_name}
                    {item.accountOwner.company_name ? ` - ${item.accountOwner.company_name}` : ''} {item.accountOwner.email ? ` (${item.accountOwner.email})` : ''}
                  </div>
                ) : (
                  ''
                )}
                {item.likelyInterestingUsers.length ? (
                  <div className='mt-xs'>
                    Users in this account who matched your search terms:
                    <ul>
                      {item.likelyInterestingUsers.map((user) => (
                        <li>
                          <b>{user.account_role_name}:</b>
                          {user.first_name} {user.last_name}
                          {user.company_name ? ` - ${user.company_name}` : ''} {user.email ? ` (${user.email})` : ''}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  ''
                )}
                {item.nonOwnerAccountUsers.length > 0 ? (
                  <Accordion text='See all users in account'>
                    <div className='m-t-md'>
                      {item.nonOwnerAccountUsers.length ? (
                        <>
                          All non-owner roles in account
                          <ul>
                            {item.nonOwnerAccountUsers.map((user) => (
                              <li>
                                <b>{user.account_role_name}:</b>
                                {user.first_name} {user.last_name}
                                {user.company_name ? ` - ${user.company_name}` : ''} {user.email ? ` (${user.email})` : ''}
                              </li>
                            ))}
                          </ul>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </Accordion>
                ) : (
                  ''
                )}
              </td>
              <td className='notes'>{getNotesSubstring(item)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AccountResults;
