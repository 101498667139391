import { crudSlice, noop } from 'saga-slice-helpers';
import { sagaApi } from '#/apis';

const sagaSliceModule = crudSlice({
  name: 'reimbursify-insurances',
  sagaApi,
  reducers: {
    uploadMapping: noop,
    uploadMappingSuccess: noop,
    uploadMappingFail: noop,
  },
  takers: {},
  //eslint-disable-next-line
  sagas: (A) => ({
    [A.uploadMapping]: {
      *saga({ payload }) {
        yield sagaApi.put(
          '/reimbursify-insurances/upload-mapping',
          payload,
          A.uploadMappingSuccess,
          A.uploadMappingFail
        );
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
