/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';

import Flash from './Flash';
import { setInterceptor } from './redux';

import './styles.scss';

export default () => {
  const [messages, setMessages] = useState({});
  const [loaded, setLoaded] = useState(false);

  const remove = useCallback((id) => {
    if (messages[id]) {
      delete messages[id];
      setMessages(messages);
    }
  }, []);

  const addMessage = useCallback((message) => {
    messages[message.id] = {
      ...message,
      type: message.type || 'info',
      message: message.message || message.data.message,
    };

    setMessages(() => ({ ...messages }));

    if (message.permanent) return;

    setTimeout(() => remove(message.id), 7000);
  }, []);

  useEffect(() => {
    if (!loaded) {
      setInterceptor(addMessage);
      setLoaded(true);
    }
  }, [loaded]);

  return (
    <div id='flashes'>
      {Object.values(messages).map((message) => (
        <Flash key={message.id} {...message} remove={remove} />
      ))}
    </div>
  );
};
