import { crudSlice, noop } from 'saga-slice-helpers';
import { sagaApi, api } from '#/apis';

import history from '#/history';
import { takeLatest, put } from 'redux-saga/effects';
import { paginationReducer } from '#/sagaSlice';

const sagaSliceModule = crudSlice({
  name: 'contact-blacklist',
  sagaApi,
  reducers: {
    readAllSuccess: paginationReducer,

    createDone: noop,
    updateDone: noop,

    searchBlacklist: noop,
    searchBlacklistSuccess: paginationReducer,
    searchBlacklistFail: noop,
  },
  takers: {
    readAll: takeLatest,
  },
  sagas: (A) => ({
    [A.readAll]: {
      *saga({ payload = {} }) {
        const { page = 0, order = 'asc', orderBy = 'value' } = payload;
        window.scrollTo(0, 0);
        yield sagaApi.get(
          `/contact-blacklist?page=${page}&order=${order}&orderBy=${orderBy}`,
          A.readAllSuccess,
          A.readAllFail
        );
      },
    },
    [A.update]: {
      *saga({ payload = {} }) {
        const id = payload.id;
        delete payload.id;
        delete payload.created_at;
        delete payload.updated_at;

        Object.keys(payload).forEach((k) => {
          if (payload[k] === null) {
            delete payload[k];
          }
        });

        yield sagaApi.put(`/contact-blacklist/${id}`, payload, A.updateDone, A.readAllFail);
      },
    },
    [A.updateDone]: {
      *saga() {
        yield history.push('/contact-blacklist');
      },
    },
    [A.createDone]: {
      *saga({ payload }) {
        if (payload.data) {
          history.push('/contact-blacklist');
        }

        yield;
      },
    },
    [A.searchBlacklist]: {
      *saga({ payload = '' }) {
        try {
          if (payload.length < 1) {
            yield put(A.readAll());
          } else if (payload.length > 0) {
            const { data } = yield api.get(`/contact-blacklist/search/${payload}`);
            yield put(A.searchBlacklistSuccess(data));
          }
        } catch (error) {
          yield put(A.searchBlacklistFail(error));
        }
      },
    },
    [A.deleteSuccess]: {
      *saga() {
        yield put(A.readAll());
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
