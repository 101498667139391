import { crudSlice, noop, failReducer } from 'saga-slice-helpers';
import { sagaApi } from '#/apis';

const sagaSliceModule = crudSlice({
  name: 'userVobs',
  sagaApi,
  initialState: {
    result: null,
  },
  reducers: {
    rerunVob: noop,
    rerunVobSuccess: (state, payload) => {
      alert(JSON.stringify(payload));
    },
    rerunVobFail: failReducer,
  },
  takers: {},
  //eslint-disable-next-line
  sagas: (A) => ({
    [A.rerunVob]: {
      *saga({ payload }) {
        yield sagaApi.post(
          `/reimbursify-client-vob/rerun/${payload.user_vob_id}`,
          {},
          A.rerunVobSuccess,
          A.rerunVobFail
        );
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
