import React, { useState } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';

export const RequestReset = (props) => {
  const { requestReset } = props;
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    requestReset({ email });
  };

  const handleChange = ({ target: { value } }) => {
    setEmail(value);
  };

  return (
    <form onSubmit={handleSubmit} className='grid justify-between column align-center'>
      <div>
        <h1>Forgotten Password</h1>
        <p>Request a password reset by inputting your email below</p>
        <p>You'll receive a email with a link to create a new password</p>
      </div>
      <div>
        <input onChange={handleChange} type='text' placeholder='Email Address' />
        <button className='primary ml-xl' type='submit'>
          Reset Password
        </button>
      </div>
    </form>
  );
};

export default connect(null, { requestReset: actions.requestReset })(RequestReset);
