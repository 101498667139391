import React from 'react';
import DefaultLayout from '##/Default';
import FeatureFlags from './FeatureFlags';

export default [
  {
    path: '/feature-flags',
    exact: true,
    layout: DefaultLayout,
    component: FeatureFlags,
    title: 'Manage Feature Flags',
  },
];
