import { crudSlice, noop } from 'saga-slice-helpers';
import { sagaApi } from '#/apis';
import { put } from 'redux-saga/effects';
import { flashSuccess, flashError } from '+/flashes/redux';

const sagaSliceModule = crudSlice({
  name: 'feature-flags',
  initialState: {
    featureFlags: [],
  },
  sagaApi,
  reducers: {
    batchEnable: noop,
    batchEnableSuccess: noop,
    batchEnableFail: noop,

    getFeatureFlags: noop,
    getFeatureFlagsSuccess: (state, result) => {
      state.featureFlags = result;
    },
    getFeatureFlagsFailure: noop,

    disableFeatureFlag: noop,
    disableFeatureFlagSuccess: noop,
    disableFeatureFlagFailure: noop,

    enableFeature: noop,
    enableFeatureSuccess: noop,
    enableFeatureFailure: noop,

    disableFeature: noop,
    disableFeatureSuccess: noop,
    disableFeatureFailure: noop,

    enableFeatureProvider: noop,
    enableFeatureProviderSuccess: noop,
    enableFeatureProviderFailure: noop,

    disableFeatureProvider: noop,
    disableFeatureProviderSuccess: noop,
    disableFeatureProviderFailure: noop,

    createFeatureFlag: noop,
    createFeatureFlagSuccess: noop,
    createFeatureFlagFailure: noop,
  },
  takers: {},
  //eslint-disable-next-line
  sagas: (A) => ({
    [A.batchEnable]: {
      *saga({ payload }) {
        yield sagaApi.put('/feature-flags/enable', payload, A.batchEnableSuccess, A.batchEnableFail);
      },
    },
    [A.enableFeature]: {
      *saga({ payload }) {
        const { type, feature_flag_reserved_name, account_id } = payload;
        yield sagaApi.put(
          `/feature-flags/${type}/${feature_flag_reserved_name}`,
          { account_id },
          () => A.enableFeatureSuccess(account_id),
          A.enableFeatureFailure
        );
      },
    },
    [A.disableFeature]: {
      *saga({ payload }) {
        const { type, feature_flag_reserved_name, account_id } = payload;
        yield sagaApi.delete(
          `/feature-flags/${type}/${account_id}/${feature_flag_reserved_name}`,
          null,
          () => A.disableFeatureSuccess(account_id),
          A.disableFeatureFailure
        );
      },
    },
    [A.enableFeatureProvider]: {
      *saga({ payload }) {
        const { type, feature_flag_reserved_name, provider_id } = payload;
        yield sagaApi.put(
          `/feature-flags/${type}/${feature_flag_reserved_name}`,
          { provider_id },
          () => A.enableFeatureProviderSuccess(provider_id),
          A.enableFeatureProviderFailure
        );
      },
    },
    [A.disableFeatureProvider]: {
      *saga({ payload }) {
        const { type, feature_flag_reserved_name, provider_id } = payload;
        yield sagaApi.delete(
          `/feature-flags/${type}/${provider_id}/${feature_flag_reserved_name}`,
          null,
          () => A.disableFeatureProviderSuccess(provider_id),
          A.disableFeatureProviderFailure
        );
      },
    },
    [A.createFeatureFlag]: {
      *saga({ payload }) {
        yield sagaApi.post(`/feature-flags`, payload, A.createFeatureFlagSuccess, A.createFeatureFlagFailure);
      },
    },
    [A.getFeatureFlags]: {
      *saga() {
        yield sagaApi.get('/feature-flags/all', A.getFeatureFlagsSuccess, A.getFeatureFlagsFailure);
      },
    },
    [A.disableFeatureFlag]: {
      *saga({ payload }) {
        yield sagaApi.put('/feature-flags/disable', payload, A.disableFeatureFlagSuccess, A.createFeatureFlagFailure);
      },
    },
    [A.batchEnableSuccess]: {
      *saga() {
        yield put(A.getFeatureFlags());
        yield put(flashSuccess('The CSV was uploaded successfully!'));
      },
    },
    [A.createFeatureFlagSuccess]: {
      *saga({ payload }) {
        yield put(A.getFeatureFlags());
        yield put(flashSuccess('The feature flag has been created!'));
      },
    },
    [A.disableFeatureFlagSuccess]: {
      *saga() {
        yield put(A.getFeatureFlags());
        yield put(flashSuccess('The feature flag has been disabled!'));
      },
    },
    [A.enableFeatureSuccess]: {
      *saga({ payload }) {
        yield put(A.getFeatureFlags());
        yield put({ type: 'accounts/getAccountSummary', payload: { id: payload } });
      },
    },
    [A.disableFeatureSuccess]: {
      *saga({ payload }) {
        yield put(A.getFeatureFlags());
        yield put({ type: 'accounts/getAccountSummary', payload: { id: payload } });
      },
    },
    [A.enableFeatureProviderSuccess]: {
      *saga({ payload }) {
        yield put({ type: 'providers/readOne', payload });
      },
    },
    [A.disableFeatureProviderSuccess]: {
      *saga({ payload }) {
        yield put({ type: 'providers/readOne', payload });
      },
    },
    [A.createFeatureFlagFailure]: {
      *saga({ payload }) {
        if (payload.response && payload.response.status === 418) {
          yield put(flashError('The feature already exists! Are you a teapot?'));
        }
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
