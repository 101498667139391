import { createModule } from 'saga-slice';
import { failReducer, loadingReducer, noop } from 'saga-slice-helpers';
import history from '#/history';

import { sagaApi } from '#/apis';

const sagaSliceModule = createModule({
  name: 'insuranceRules',
  initialState: {
    insurancesWithParents: [],
    insuranceTags: [],
    rulesForCurrentTagLoaded: false,
    rulesForCurrentTag: [],
  },
  reducers: {
    getInsurancesWithParents: loadingReducer,
    getInsurancesWithParentsSuccess: (state, payload) => {
      state.insurancesWithParents = payload;

      // Whenever we load the main list, clear some required edit state
      state.rulesForCurrentTagLoaded = false;
    },
    getInsurancesWithParentsFail: failReducer,

    getRulesForTag: loadingReducer,
    getRulesForTagSuccess: (state, payload) => {
      state.rulesForCurrentTag = payload;
      state.rulesForCurrentTagLoaded = true;
    },
    getRulesForTagFail: failReducer,

    bulkUpdate: noop,
    bulkUpdateSuccess: noop,
    bulkUpdateFailure: failReducer,

    bulkDelete: noop,
    bulkDeleteSuccess: noop,
    bulkDeleteFailure: failReducer,

    getInsuranceTags: loadingReducer,
    getInsuranceTagsSuccess: (state, payload) => {
      state.insuranceTags = payload.results.sort((a, b) =>
        a.name.trim() > b.name.trim() ? 1 : -1
      );
    },
    getInsuranceTagsFail: failReducer,
  },
  sagas: (A) => ({
    [A.getInsurancesWithParents]: {
      *saga() {
        yield sagaApi.get(
          '/insurance-rules/insurances-with-parents',
          A.getInsurancesWithParentsSuccess,
          A.getInsurancesWithParentsFail
        );
      },
    },
    [A.getRulesForTag]: {
      *saga({ payload }) {
        yield sagaApi.get(
          `/insurance-rules/get-rules-for-tag/${payload.tagId}`,
          A.getRulesForTagSuccess,
          A.getRulesForTagFail
        );
      },
    },
    [A.bulkUpdate]: function* ({ payload }) {
      yield sagaApi.post(
        '/insurance-rules/bulk-update',
        payload,
        A.bulkUpdateSuccess,
        A.bulkUpdateFailure
      );
      // Reload list after update so Insurance Rules list stays updated
      yield history.push('/insurance-rules');
    },
    [A.bulkDelete]: function* ({ payload }) {
      yield sagaApi.post(
        '/insurance-rules/bulk-delete',
        payload,
        A.bulkDeleteSuccess,
        A.bulkDeleteFailure
      );
      yield history.push('/insurance-rules');
    },
    [A.getInsuranceTags]: {
      *saga() {
        yield sagaApi.get(
          '/insurances?limit=100000',
          A.getInsuranceTagsSuccess,
          A.getInsuranceTagsFail
        );
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
