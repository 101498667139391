import React from 'react';
import Modal from '+/Modal';
import { connect } from 'react-redux';
import { actions } from '../../../sagaSlice';

export const ConfirmChangeOwnerModal = ({
  accountId,
  confirmChangeOwnerModalMessage,
  newAccountOwnerUserId,
  showModal,
  toggleModal,
  changeAccountOwner,
}) => {
  const submitClicked = () => {
    changeAccountOwner({
      account_id: accountId,
      user_id: newAccountOwnerUserId,
    });
  };

  if (showModal) {
    return (
      <Modal title='Are you sure you want to change the account owner?' onDismiss={toggleModal}>
        <p>{confirmChangeOwnerModalMessage}</p>

        <button className='pill primary md' onClick={submitClicked}>
          Submit
        </button>
      </Modal>
    );
  }

  return null;
};

export default connect((state) => ({}), actions)(ConfirmChangeOwnerModal);
