const specialRoleFriendlyNameConstants = {
  owner: 'Owner',
  intakeCoordinator: 'Intake Coordinator',
  reimbursifyPractitioner: 'Reimbursify Practitioner',

  // Used because for a group practice to see VOBs run against the owner, they must be a lead practitioner.
  reimbursifyLeadPractitioner: 'Reimbursify Lead Practitioner',

  reimbursifyAdministrator: 'Reimbursify Administrator',
};

export default specialRoleFriendlyNameConstants;
