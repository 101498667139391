import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import history from '#/history';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import Modal from '+/Modal';
import SearchTypeAhead from '+/SearchTypeAhead';

const AddInsuranceRuleButton = (props) => {
  const { insuranceTags, getInsuranceTags } = props;

  const [insuranceTagsLoaded, setInsuranceTagsLoaded] = useState(false);
  const [filteredInsuranceTags, setFilteredInsuranceTags] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal((e) => !e);
  };

  useEffect(() => {
    if (Object.values(insuranceTags).length) {
      if (!insuranceTagsLoaded) {
        setInsuranceTagsLoaded(true);
        setFilteredInsuranceTags(insuranceTags);
      }
    } else {
      getInsuranceTags();
    }
  }, [insuranceTags, getInsuranceTags, insuranceTagsLoaded]);

  const searchInsuranceTags = (searchTerm) => {
    if (searchTerm && searchTerm.length) {
      const result = insuranceTags.filter((x) =>
        _.includes(x.name.toUpperCase(), searchTerm.toUpperCase())
      );
      setFilteredInsuranceTags(result);
    } else {
      setFilteredInsuranceTags(insuranceTags);
    }
  };

  const renderChooseTagModal = () => {
    if (showModal) {
      return (
        <Modal title='Choose insurance' onDismiss={toggleModal}>
          <SearchTypeAhead
            field='name'
            placeholder='Search by name...'
            items={filteredInsuranceTags}
            selected={[]}
            searchFn={searchInsuranceTags}
            onSelect={(tagId) => {
              history.push(`/insurance-rules/edit/${tagId}`);
            }}
          />
        </Modal>
      );
    }
  };

  return (
    <>
      <div>
        <button type='button' className='primary' onClick={() => setShowModal(true)}>
          <i className='fas fa-plus' />
        </button>

        {renderChooseTagModal()}
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    insuranceTags: state.insuranceRules.insuranceTags,
  }),
  actions
)(AddInsuranceRuleButton);
