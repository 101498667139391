import DefaultLayout from '##/Default';

import List from './List';
import View from './View';
import Add from './Add';

export default [
  {
    path: '/areas',
    exact: true,
    layout: DefaultLayout,
    component: List,
    title: 'View Areas',
  },
  {
    path: '/areas/add',
    exact: true,
    layout: DefaultLayout,
    component: Add,
    title: 'Add New Area',
  },
  {
    path: '/areas/view/:id',
    exact: false,
    layout: DefaultLayout,
    component: View,
    title: 'View one Area',
  },
];
