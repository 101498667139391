import React from 'react';
import { Field } from 'react-final-form';
import Error from '+/forms/Error';

// Subscribes to errors for a field and renders below
export default ({ name }) => (
  <Field
    name={name}
    subscription={{
      error: true,
      visited: true,
      modified: true,
    }}
    render={({ meta }) => Error(meta)}
  />
);
