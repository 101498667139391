import React, { useState, useEffect } from 'react';
import AddableForm from '+/AddableForm';
import EditBox from '+/EditBox';
import { connect } from 'react-redux';
import { actions } from '../sagaSlice';
import StateAbbrDict from '../../../utils/stateAbbrDict';
const stateOptions = Object.keys(StateAbbrDict()).map((k) => StateAbbrDict()[k]);
const curYear = new Date().getUTCFullYear();
const yearOptions = [...Array(70).keys()].map((x) => curYear + 20 - x);
const verificationOptions = ['Unverified', 'Verified', 'Not found', 'Legacy'];

const columns = [
  {
    header: 'State',
    key: 'state',
    type: 'select',
    options: stateOptions,
    placeholder: 'Select State',
  },
  {
    header: 'License Number',
    key: 'number',
    type: 'input',
    placeholder: 'Number',
  },
  {
    header: 'License Type',
    key: 'license_type',
    type: 'input',
    placeholder: 'e.g. PhD, LCSW, LCAT',
  },
  {
    header: 'Expiration Year',
    key: 'expiration',
    type: 'select',
    options: yearOptions,
    placeholder: 'Select Year',
  },
  {
    header: 'Verification',
    key: 'verification',
    type: 'select',
    options: verificationOptions,
    placeholder: 'Select verification status',
  },
  {
    header: 'Notes regarding verification (visible to provider)',
    key: 'notes',
    type: 'input',
    placeholder: 'Optional notes...',
  },
  {
    header: 'Supervisor Name',
    key: 'supervisor_name',
    type: 'input',
    placeholder: 'Optional...',
  },
  {
    header: 'Supervisor License',
    key: 'supervisor_license',
    type: 'input',
    placeholder: 'Optional...',
  },
  {
    header: 'Supervisor License Type',
    key: 'supervisor_license_type',
    type: 'input',
    placeholder: 'Optional...',
  },
];

export const Licenses = ({
  providerId,
  providerLicenses,
  updateProviderLicenses,
  getProviderLicenses,
}) => {
  const [editingLicenses, setEditingLicenses] = useState(false);

  useEffect(() => {
    getProviderLicenses({ provider_id: providerId });
  }, [getProviderLicenses, providerId]);

  const updateFn = (updatedLicenses) => {
    const deleteLicenseIds = providerLicenses
      .filter((x) => !updatedLicenses.find((y) => x.id === y.id))
      .map((x) => x.id);

    // Delete extra fields before sending to server
    // Add missing empty 'notes' field if needed (otherwise deleting note won't work)
    updatedLicenses.forEach((updatedLicense) => {
      delete updatedLicense.provider_id;
      delete updatedLicense.created_at;
      delete updatedLicense.updated_at;
      delete updatedLicense.deleted;
      if (!updatedLicense.notes) {
        updatedLicense.notes = '';
      }
    });

    const payload = {
      provider_id: providerId,
      licenses: updatedLicenses,
      deleteLicenseIds,
    };
    updateProviderLicenses(payload);
  };

  return (
    <div className='provider-licenses'>
      <div className='grid align-center justify-between'>
        <h2 className='inline-block'>Licensures</h2>
      </div>
      {providerLicenses && (
        <EditBox
          title='Manage Provider Licenses'
          editable={true}
          editing={editingLicenses}
          setEditing={setEditingLicenses}
          innerEditing={
            <AddableForm
              name='provider_licenses'
              onEdit={(values) => {
                updateFn(values);
                setEditingLicenses(false);
              }}
              dataList={providerLicenses.map((x) => ({
                ...x,
                deletable: true,
              }))}
              columns={columns}
              defaultNewItem={{
                state: '',
                number: '',
                license_type: '',
                expiration: '',
                verification: 'Unverified',
                notes: '',
              }}
              addText='License'
              footnote="To remove a supervisor information, remove the supervisor related metadata from only the last location row and click 'submit'."
            />
          }
          innerStatic={
            <div className='provider-license-grid'>
              <h5> State</h5>
              <h5> Number</h5>
              <h5> License type</h5>
              <h5> Expiration</h5>
              <h5> Verification status</h5>
              <h5> Notes regarding verification</h5>
              <h5> Supervisor Name</h5>
              <h5> Supervisor License</h5>
              <h5> Supervisor License Type</h5>
              {providerLicenses.map((license) => (
                <React.Fragment key={`license-${license.id}`}>
                  <h6 style={{ margin: 0 }}>{license.state}</h6>
                  <div>{license.number}</div>
                  <div>{license.license_type}</div>
                  <div>{license.expiration}</div>
                  <div>{license.verification}</div>
                  <div>{license.notes}</div>
                  <div>{license.supervisor_name}</div>
                  <div>{license.supervisor_license}</div>
                  <div>{license.supervisor_license_type}</div>
                </React.Fragment>
              ))}
            </div>
          }
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  providerLicenses: state.providers.providerLicenses,
});

export default connect(mapStateToProps, actions)(Licenses);
