import { crudSlice, noop } from 'saga-slice-helpers';
import { createModule } from 'saga-slice';
import { api, sagaApi } from '#/apis';
import history from '#/history';
import TagTypes from './types';
import { put } from 'redux-saga/effects';
import { paginationReducer } from '#/sagaSlice';

const modules = {};
export const actions = {};

// eslint-disable-next-line no-unused-vars
for (const [singular, plural] of TagTypes) {
  modules[plural] = crudSlice({
    name: plural,
    sagaApi,
    reducers: {
      readAllSuccess: paginationReducer,
      deleteDone: noop,
      updateDone: noop,

      searchTags: noop,
      searchTagsSuccess: paginationReducer,
      searchTagsFail: noop,

      readEvery: noop,
      readEverySuccess: (state, payload) => {
        state.data = payload;
      },
      readEveryFail: noop,
    },
    sagas: (A) => ({
      [A.readAll]: {
        *saga({ payload = {} }) {
          const { page = 0, order = 'asc', orderBy = 'name' } = payload;
          window.scrollTo(0, 0);
          yield sagaApi.get(
            `/${plural}?page=${page}&order=${order}&orderBy=${orderBy}`,
            A.readAllSuccess,
            A.readAllFail
          );
        },
      },
      [A.readEvery]: {
        *saga() {
          yield sagaApi.get(`/${plural}/all`, A.readEverySuccess, A.readEveryFail);
        },
      },
      [A.readOne]: {
        *saga({ payload: id }) {
          yield sagaApi.get(`/tag/${id}`, A.readOneSuccess, A.readOneFail, A.readOneDone);
        },
      },
      [A.create]: {
        *saga({ payload }) {
          const { plural, values } = payload;
          yield sagaApi.post(`/${plural}`, values, A.createSuccess, A.createFail);
        },
      },
      [A.createSuccess]: {
        *saga({ payload }) {
          yield history.push(`/${plural}/view/${payload.id}`);
        },
      },
      [A.delete]: {
        *saga({ payload: id }) {
          yield sagaApi.delete(`/tag/${id}`, null, A.deleteSuccess, A.deleteFail, A.deleteDone);
        },
      },
      [A.deleteDone]: {
        *saga() {
          yield history.push(`/${plural}`);
        },
      },
      // Override default sagas
      [A.update]: {
        *saga({ payload }) {
          const { id, changeset, fetchFn } = payload;
          yield sagaApi.put(`/tag/${id}`, changeset, A.updateSuccess, A.updateFail, A.updateDone);
          fetchFn && fetchFn();
        },
      },
      [A.searchTags]: {
        *saga({ payload = '' }) {
          try {
            if (payload.length < 1) {
              yield put(A.readAll());
            } else if (payload.length > 0) {
              const { data } = yield api.get(`/${singular}/search/${payload}`);
              yield put(A.searchTagsSuccess(data));
            }
          } catch (error) {
            yield put(A.searchTagsFail(error));
          }
        },
      },
    }),
  });

  actions[plural] = modules[plural].actions;
}
modules.generic = createModule({
  name: 'genericTags',
  initialState: {},
  reducers: {
    enableSearch: noop,
    disableSearch: noop,
  },
  sagas: (A) => ({
    [A.enableSearch]: {
      *saga({ payload }) {
        const { tag_id, model_id, fetchFn } = payload;
        yield api.get(`/tag/${tag_id}/${model_id}/enable`);
        fetchFn && fetchFn();
      },
    },
    [A.disableSearch]: {
      *saga({ payload }) {
        const { tag_id, model_id, fetchFn } = payload;
        yield api.get(`/tag/${tag_id}/${model_id}/disable`);
        fetchFn && fetchFn();
      },
    },
  }),
});

actions.generic = modules.generic.actions;

export default modules;
