import React from 'react';
import { Form, Field } from 'react-final-form';
import _ from 'lodash';
import ErrorField from '+/forms/ErrorField';

//eslint-disable-next-line
const AddableForm = ({
  onEdit,
  dataList = [],
  columns,
  beforeAdd = () => new Promise((resolve) => resolve()),
  beforeDelete = () => new Promise((resolve) => resolve()),
  validate = () => new Promise((resolve) => resolve({})),
  name,
  addText,
  defaultNewItem,
  gridStyles,
  footnote = '',
}) => {
  /*
   * Filter out any empty entries.
   */
  function onEditWrapper(formValues) {
    let values = _.clone(formValues[name]);
    // filter out any values with no value for a required column
    values = values.filter((v) =>
      _.some(
        columns.map((c) => c.key).map((key) => !_.isNil(_.get(v, key)) && _.get(v, key) !== '')
      )
    );

    values.forEach((v) => {
      delete v.deletable;
      delete v.nameReadOnly;
    });

    return onEdit(values);
  }

  // extra 0.5fr for the delete button, whether it's deletable or not.
  const gridStyle = gridStyles || {
    gridTemplateColumns: columns
      .map((c) => (c.type === 'select' ? '1fr' : '3fr'))
      .concat(['0.5fr'])
      .join(' '),
  };

  /*
   * Renders a single field based on column structure
   */
  const renderField = ({ key, type, placeholder, options, inputType }, name, i, v) => (
    <>
      <Field
        name={`${name}[${i}].${key}`}
        component={type}
        placeholder={placeholder}
        type={inputType}
        initialValue={v[key]}
      >
        {type === 'select' ? (
          <>
            <option value='' disabled selected hidden>
              {placeholder}
            </option>
            {options.map((o) => (
              <option value={o}>{o}</option>
            ))}
            <i className='fas fa-caret-down'></i>
          </>
        ) : null}
      </Field>
      {key === 'fee' && <span className='units'>$</span>}
      {key === 'length' && <span className='units'>min</span>}
    </>
  );

  /*
   * Renders fields based on column structure.
   */
  const renderFields = (values, remove) => (
    <>
      {columns.map((c) => (
        <h5 className='addable-tag-form-field-group header'>{c.header}</h5>
      ))}
      <div></div>

      {values.map((v, i) => (
        <>
          {columns.map((c) => (
            <div className={`field ${c.key}`}>
              {c.key === 'name' && v.nameReadOnly ? (
                <div className='fixed-name'>{v[c.key]}</div>
              ) : (
                renderField(c, name, i, v)
              )}
            </div>
          ))}
          {/* Delete line button*/}
          <div className='delete flex-center'>
            {v.deletable && (
              <span
                role='presentation'
                onClick={() => {
                  beforeDelete().then(() => {
                    remove(i);
                  });
                }}
              >
                delete
              </span>
            )}
          </div>
          {columns.map((c) => (
            <span>
              <ErrorField name={`${name}[${i}].${c.key}`} />
            </span>
          ))}
          <span></span>
        </>
      ))}
    </>
  );

  const mutators = {
    add: ([newItem], state, { changeValue }) => {
      changeValue(state, name, (oldVal) => [...oldVal, newItem]);
    },
    remove: ([index], state, { changeValue }) => {
      changeValue(state, name, (oldVal) => {
        const arr = [...oldVal];
        arr.splice(index, 1);

        return arr;
      });
    },
  };

  return (
    <Form
      onSubmit={onEditWrapper}
      mutators={mutators}
      initialValues={{
        [name]: dataList,
      }}
      //Don't re-render
      initialValuesEqual={() => true}
      validate={validate}
      render={({ handleSubmit, values, valid, pristine, form: { mutators } }) => (
        <div className='addable-form'>
          <div className='addable-form-inner' style={gridStyle}>
            {renderFields(values[name], mutators.remove)}
          </div>
          {/* Add another item button*/}
          <button
            className='add'
            onClick={(evt) => {
              evt.target.blur();
              beforeAdd(values).then((newItem) => {
                if (!newItem) {
                  newItem = _.clone(defaultNewItem);
                }
                mutators.add(defaultNewItem);
              });
            }}
          >
            <i className='far fa-plus-circle'></i>
            Add {addText}
          </button>
          <div className='actions'>
            {!pristine && !valid ? (
              <button className='primary' disabled>
                Submit
              </button>
            ) : (
              <button className='primary' onClick={handleSubmit}>
                Submit
              </button>
            )}
          </div>
          {footnote && (
            <p className='m-b-0'>
              <b>Note: </b>
              {footnote}
            </p>
          )}
        </div>
      )}
    />
  );
};

export default AddableForm;
