import React from 'react';
import { Field, Form } from 'react-final-form';
import formValidation from './formValidation';
import Input from '+/forms/Input';
import Error from '+/forms/Error';

const RedirectForm = (props) => {
  const { onSubmit, onCancel } = props;

  const renderDropdown = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;

    return (
      <div className={className}>
        <label>{label}</label>
        <select {...input}>
          <option value=''></option>
          <option value='301'>301</option>
          <option value='302'>302</option>
        </select>
        <Error {...meta} />
      </div>
    );
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={formValidation}
      render={({ handleSubmit, invalid }) => (
        <form autoComplete='off' onSubmit={handleSubmit}>
          <h2>Add a New Redirect</h2>
          <hr />
          <Field name='from' component={Input} label='Original URL' />
          <Field name='to' component={Input} label='New URL' />
          <Field name='status_code' component={renderDropdown} label='Status Code' />

          <div className='grid justify-between field'>
            <button type='button' onClick={onCancel} className='error'>
              Cancel
            </button>

            <button disabled={invalid} className='success'>
              Submit
            </button>
          </div>
        </form>
      )}
    />
  );
};

export default RedirectForm;
