import _ from 'lodash';
import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';

const timezone = DateTime.local().zoneName || 'America/New_York';

//eslint-disable-next-line
const getName = (a) => (a.first_name ? `${a.first_name} ${a.last_name}` : a.company_name);

const Appointment = ({ appointment: a, provider: p, user }) => {
  const tz = timezone;
  const created_at = DateTime.fromISO(a.created_at).setZone(tz).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);
  const start = DateTime.fromISO(a.start_time).setZone(tz).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);
  const end = DateTime.fromISO(a.end_time).setZone(tz).toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);
  const provider = p || a.provider;
  const clientUser = user || a.user;
  const [showDetails, setShowDetails] = useState(false);
  const canceledBy = a.canceled_by ? (clientUser.id === a.canceled_by ? getName(clientUser) : getName(provider)) : null;
  const rescheduledBy = a.rescheduled_by ? (clientUser.id === a.rescheduled_by ? getName(clientUser) : getName(provider)) : null;

  return (
    <div className='appointment-item'>
      <div className='flex w-100 justify-between column'>
        <table>
          <tbody>
            {!!p && (
              <tr>
                <td>Client</td>
                <td>
                  <Link to={`/users/view-with-mode/${clientUser.id}/all`}>{getName(clientUser)}</Link>
                </td>
              </tr>
            )}
            {!!user && (
              <tr>
                <td>Provider</td>
                <td>
                  <Link to={`/providers/view/${provider.id}`}>{getName(provider)}</Link>
                </td>
              </tr>
            )}
            <tr>
              <td>Created at</td>
              <td>{created_at}</td>
            </tr>
            <tr>
              <td>Appointment start</td>
              <td>{start}</td>
            </tr>
            <tr>
              <td>Appointment end</td>
              <td>{end}</td>
            </tr>
            <tr>
              <td>ID</td>
              <td>{a.id}</td>
            </tr>
            <tr>
              <td>Calendar ID</td>
              <td>{a.calendar_id}</td>
            </tr>
          </tbody>
        </table>
        {!!a.canceled && <p className='text-error'>This appointment was canceled.</p>}
        {!!a.rescheduled && <p className='text-quaternary'>This appointment was rescheduled.</p>}
        {showDetails ? (
          <button onClick={() => setShowDetails(false)} className='primary md'>
            Hide Details
          </button>
        ) : (
          <button onClick={() => setShowDetails(true)} className='primary md'>
            Show Details
          </button>
        )}
      </div>
      {showDetails && (
        <div>
          {!!canceledBy && <p>This appointment was canceled by {canceledBy}</p>}
          {!!rescheduledBy && <p>This appointment was last rescheduled by {rescheduledBy}</p>}
          {a.messages && a.messages.length > 0 && (
            <>
              <div>
                <h5>Messages</h5>
                <div className='flex justify-between'>
                  <b>{getName(clientUser)}</b>
                  <b>{getName(provider)}</b>
                </div>
                {a.messages.map((m) => {
                  const style = m.sender === 'client' ? { justifyContent: 'flex-start' } : { justifyContent: 'flex-end' };

                  const type = {
                    cancel_message: 'Cancellation message',
                    reschedule_message: 'Rescheduling message',
                  }[m.type];

                  return (
                    <div className='w-100 flex m-b-sm' style={style}>
                      <div className='message' style={style}>
                        <p className='message-text'>
                          {type ? `${type}: ` : ''}
                          {m.message}
                        </p>
                        <p className='message-date'>{DateTime.fromISO(m.created_at).toLocaleString(DateTime.DATETIME_SHORT)}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <hr></hr>
            </>
          )}
          <div>
            <h5>Intake Form</h5>
            {Object.keys(a.intake_form || {}).map((key) => {
              const question = key;
              const answer = (a.intake_form || {})[key] || <i>No response</i>;

              return (
                <>
                  <b className='m-b-0'>{question}</b>
                  <p className='m-b-sm'>{answer}</p>
                </>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Appointment;
