import { useState } from 'react';
import utils from '#/';

const useOrderBy = (unorderedArray, initialOrderBy, initialOrder = 'asc') => {
  const [order, setOrder] = useState(initialOrder);
  const [orderBy, setOrderBy] = useState(initialOrderBy);

  const handleColumnChange = (field, sort) => {
    setOrder(sort);
    setOrderBy(field);
  };

  let ordered = unorderedArray.sort(utils.sortByField(orderBy));

  if (order === 'desc') {
    ordered = ordered.reverse();
  }

  return {
    order,
    orderBy,
    ordered,
    handleColumnChange,
  };
};

export default useOrderBy;
