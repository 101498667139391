import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import Pagination from '+/Pagination';
import Column from '+/Column';
import useOrderBy from '+/hooks/useOrderBy';
import utils from '#/';

export const UsersList = (props) => {
  const { users, total, searchUsers, readAll, match = { params: { type: 'provider' } } } = props;

  const type = match.params.type === 'all' ? 'all' : 'provider';
  const {
    order,
    orderBy,
    ordered: orderedUsers,
    handleColumnChange,
  } = useOrderBy(users, 'username');

  useEffect(() => {
    readAll({
      order,
      orderBy,
      type,
    });
  }, [order, orderBy, readAll, type]);

  const lockedIcon = (user) => {
    if (user.locked_at !== null) {
      return <i className='fas fa-lock text-error mr-md' />;
    }
  };

  const handleSearch = ({ target }) =>
    utils.debounce(() =>
      searchUsers({
        q: target.value,
        type,
      })
    );

  return (
    <>
      <div className='grid align-center justify-between'>
        <h1 className='inline-block'>Users</h1>

        <Link to={'/users/add/form'} className='button primary' title='Add New User'>
          <i className='fas fa-plus' />
        </Link>
      </div>

      <div className='border border-grey-lightest radius-xs scroll'>
        <input
          type='text'
          className='block w-100'
          placeholder='Filter users...'
          onKeyUp={handleSearch}
        />

        <div className='bg-grey-dark text-white grid'>
          <Column width='25' title='Username' field='username' onChange={handleColumnChange} />
          <Column width='25' title='Email' field='email' onChange={handleColumnChange} />
          <Column width='25' title='Last Login' field='last_login' onChange={handleColumnChange} />
          <Column
            width='25'
            title='Last Logout'
            field='last_logout'
            onChange={handleColumnChange}
          />
        </div>

        {orderedUsers.map((user, i) => (
          <div className={`${utils.tableClass(i)} grid`} key={user.id}>
            <div className='p-md w-25'>
              <Link to={`/users/view-with-mode/${user.id}/${type}`}>
                {lockedIcon(user)}
                {user.username}
              </Link>
            </div>

            <div className='p-md w-25'>{user.email}</div>

            <div className='p-md w-25'>{utils.displayDate(user.last_login)}</div>

            <div className='p-md w-25'>{utils.displayDate(user.last_logout)}</div>
          </div>
        ))}

        <Pagination total={total} fetchFn={(page) => readAll({ page })} />
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  users: Object.values(state.users.data),
  total: state.users.total,
});

export default connect(mapStateToProps, actions)(UsersList);
