import _ from 'lodash';
import specialRoleFriendlyNameList from '../specialRoleFriendlyNameList';

const getHasSpecialRoleUsers = (users) => {
  const filteredUsers = users.filter((user) => {
    const roles = user.roles.map((role) => role.name);

    return _.intersection(roles, specialRoleFriendlyNameList).length > 0;
  });

  return filteredUsers;
};

export default getHasSpecialRoleUsers;
