import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import Pagination from '~/shared/Pagination';
import useOrderBy from '+/hooks/useOrderBy';
import capitalize from 'voca/capitalize';
import { Link } from 'react-router-dom';
import Column from '+/Column';
import utils from '#/';

export const TagsList = (props) => {
  const { tags, readAll, total, searchTags, match = { params: {} } } = props;
  const { plural } = match.params;

  const { order, orderBy, ordered: orderedTags, handleColumnChange } = useOrderBy(tags, 'name');

  useEffect(() => {
    readAll({
      order,
      orderBy,
    });
  }, [order, orderBy, readAll]);

  const handleSearch = ({ target }) => utils.debounce(() => searchTags(target.value));

  return (
    <>
      {plural === 'insurances' && (
        <div>
          <Link to='/insurance-rules' className='button primary'>
            Manage Insurance Rules
          </Link>
        </div>
      )}
      <div className='grid align-center justify-between'>
        <h1 className='inline-block'>{capitalize(plural)}</h1>
        <Link
          to={`/${plural}/add`}
          className='button primary'
          title={`Add New ${capitalize(plural)}`}
        >
          <i className='fas fa-plus' />
        </Link>
      </div>
      <div className='border border-grey-lightest radius-xs scroll'>
        <input
          type='text'
          className='block w-100'
          placeholder={`Filter ${plural}...`}
          onKeyUp={handleSearch}
        />

        <div className='bg-grey-dark text-white grid'>
          <div className='p-md w-10'>
            <b>Id</b>
          </div>
          <Column title='Name' field='name' width='50' onChange={handleColumnChange} />

          <Column title='Slug' field='slug' width='50' onChange={handleColumnChange} />
        </div>

        {orderedTags.map((tag, i) => (
          <div className={`${utils.tableClass(i)} grid align-center`} key={tag.id}>
            <div className='p-md w-10'>{tag.id}</div>

            <div className='p-md w-50'>
              <Link to={`/${plural}/view/${tag.id}`}>{tag.name}</Link>
            </div>

            <div className='p-md w-50'>{tag.slug}</div>
          </div>
        ))}

        <Pagination total={total} fetchFn={(page) => readAll({ page })} />
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  tags: Object.values(state[ownProps.match.params.plural].data),
  total: state[ownProps.match.params.plural].total,
});

const mapDispatchToProps = (dispatch, ownProps) =>
  Object.entries(actions[ownProps.match.params.plural]).reduce(
    (acc, [key, val]) => ({
      ...acc,
      [key]: (...args) => dispatch(val(...args)),
    }),
    {}
  );

export default connect(mapStateToProps, mapDispatchToProps)(TagsList);
