import React from 'react';

import Flashes from '+/flashes/Container';
import TopNav from '+/nav/TopNav';

const AuthLayout = (props) => (
  <>
    <TopNav />
    <Flashes />
    <section id='content' className='mx-10xl'>
      {props.children}
    </section>
  </>
);

export default AuthLayout;
