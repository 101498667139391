import React from 'react';

export default ({ licenses, location }) => {
  if (licenses.length) {
    const licensesForState = licenses.filter((license) => license.state === location.state);
    const verifiedLicensesForState = licensesForState.filter(
      (license) => license.verification === 'Verified'
    );
    if (verifiedLicensesForState.length) {
      return <div className='text-success'>Verified</div>;
    }

    const legacyLicensesForState = licensesForState.filter(
      (license) => license.verification === 'Legacy'
    );
    if (legacyLicensesForState.length) {
      return (
        <div className='text-success'>
          <p>Legacy state credential exists.</p>
        </div>
      );
    }

    const unverifiedLicensesForState = licensesForState.filter(
      (license) => license.verification === 'Unverified'
    );
    if (unverifiedLicensesForState.length) {
      return <div className='text-error'>Pending verification by Zencare</div>;
    }

    const notFoundLicensesForState = licensesForState.filter(
      (license) => license.verification === 'Not found'
    );
    if (notFoundLicensesForState.length) {
      return (
        <div className='text-error'>
          <p>Not found: State credential unable to be verified.</p>
        </div>
      );
    }
  }

  // No relevant license found for this state.
  if (!location.should_display) {
    return (
      <div className='text-error'>
        <p>No state credential added.</p>
      </div>
    );
  }

  return (
    <div className='text-error'>Error: Location is displayed but no license found on record</div>
  );
};
