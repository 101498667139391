import React from 'react';
import { onlyOnEnter } from '#/';

const Modal = (props) => {
  const { actions, onDismiss, title, children, modalStyles } = props;

  const footer = () => actions && <footer>{actions}</footer>;

  return (
    <div className='fixed w-100vw h-100vh pin-top pin-left grid align-center justify-center z-150'>
      <div id='overlay' tabIndex={0} role='menuitem' className='show' onClick={onDismiss} onKeyDown={onlyOnEnter(onDismiss)} />

      <div id='modal' style={modalStyles} className='modal fixed h-60 w-60'>
        <div id='close' tabIndex={0} role='menuitem' onClick={onDismiss} className='cursor-pointer' onKeyDown={onlyOnEnter(onDismiss)}>
          <i className='fas fa-times' />
        </div>
        <header>
          <h3>{title}</h3>
        </header>
        <section className='content scroll-y'>{children}</section>
        {footer()}
      </div>
    </div>
  );
};

export default Modal;
