import dayjs from 'dayjs';

export const scrollTop = () => global && global.scrollTo && global.scrollTo(0, 0);
export const randomId = () => Math.floor(Math.random() * 10000);
export const $ = (qry) => Array.from(global.document.querySelectorAll(qry));
export const tableClass = (i) => (i % 2 === 0 ? 'bg-grey-lightest' : 'bg-white');
export const displayDate = (date) => dayjs(date).format('MM/DD/YY h:mm a');
export const onlyOnEnter = (fn) => (e) => e.which === 13 && fn();
export const onlyOnEscape = (fn) => (e) => e.key === 'Escape' && fn();
export const capitalizeFirstLetter = (str) =>
  str ? `${str[0].toUpperCase()}${str.slice(1)}` : str;

export const sortByField = (field) => (a, b) => {
  let A = a[field];
  let B = b[field];
  if (A === null || B === null) return 0;

  if (A.constructor === String) {
    A = A.toLowerCase();
    B = B.toLowerCase();
  }

  if (A < B) return -1;

  if (A > B) return 1;

  return 0;
};

export const sort = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }

  return 0;
};

export const prefixImage = (imageName) =>
  `${process.env.ZENCARE_ASSETS_URL}/${encodeURIComponent(imageName)}`;

export const mapKeys = (array) =>
  array.reduce((acc, cur) => {
    acc[cur.id] = cur;

    return acc;
  }, {});

let timeout = null;
export const debounce = (func, time = 300) => {
  clearTimeout(timeout);
  timeout = setTimeout(func, time);
};

export default {
  displayDate,
  tableClass,
  randomId,
  $,
  sortByField,
  sort,
  mapKeys,
  debounce,
  prefixImage,
};
