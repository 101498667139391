import React from 'react';
import ToggleStateSwitch from '+/forms/ToggleStateSwitch';
import EditableText from '+/forms/EditableText';
import EditableCardText from '+/forms/EditableCardText';

const ContactTab = (props) => {
  const { updateFn, provider } = props;
  const { custom_content, type } = provider;

  return (
    <>
      <h3 className='mt-md'>Contact Info:</h3>
      <hr />
      <div className='contactInfo'>
        <div className='card p-lg'>
          {type === 'practice' ? (
            <div className='grid w-100'>
              <EditableText
                label='Company Name:'
                name='company_name'
                updateFn={updateFn}
                initialState={provider.company_name}
              />
              <EditableText
                label='Tagline:'
                name='tagline'
                updateFn={updateFn}
                custom_content={custom_content}
                initialState={custom_content.tagline}
              />
            </div>
          ) : (
            <div className='grid w-100 align-center border-bottom'>
              <div className='w-33'>
                <EditableText
                  label='First Name:'
                  name='first_name'
                  styles='w-30'
                  updateFn={updateFn}
                  initialState={provider.first_name}
                />
              </div>
              <div className='w-33'>
                <EditableText
                  label='Middle Name:'
                  name='middle_name'
                  styles='w-30'
                  updateFn={updateFn}
                  initialState={provider.middle_name}
                />
              </div>
              <div className='w-33'>
                <EditableText
                  label='Last Name:'
                  name='last_name'
                  styles='w-30'
                  updateFn={updateFn}
                  initialState={provider.last_name}
                />
              </div>
            </div>
          )}
          <div className='grid w-100'>
            <EditableText
              label='Phone Number:'
              name='phone_number'
              styles='w-40'
              updateFn={updateFn}
              initialState={provider.phone_number}
            />
            <EditableText
              label='Email:'
              name='email'
              styles='w-40'
              updateFn={updateFn}
              custom_content={custom_content}
              initialState={custom_content.email}
            />
          </div>
        </div>
        <hr />
        <div className='grid align-center justify-between'>
          <div className='card p-md w-50'>
            <h4 className='px-sm'>Toggle Contact Display:</h4>
            <hr />
            <div className='grid justify-around'>
              <ToggleStateSwitch
                label='Display Contact Form'
                name='display_contact_form'
                updateFn={updateFn}
                custom_content={custom_content}
                initialState={custom_content.display_contact_form}
              />
              <ToggleStateSwitch
                label='Display Phone Number'
                name='display_phone_number'
                updateFn={updateFn}
                custom_content={custom_content}
                initialState={custom_content.display_phone_number}
              />
              <ToggleStateSwitch
                label='Display Acuity Link'
                name='display_acuity_scheduling_link'
                updateFn={updateFn}
                custom_content={custom_content}
                initialState={custom_content.display_acuity_scheduling_link}
              />
            </div>
          </div>
          <div className='w-50'>
            <EditableCardText
              text
              updateFn={updateFn}
              name='acuity_link'
              label='Acuity Scheduling Link:'
              custom_content={custom_content}
              initialState={custom_content.acuity_link}
              disabled={!custom_content.display_acuity_scheduling_link}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactTab;
