const sideLink = [
  {
    text: 'Accounts',
    link: {
      to: '/accounts',
    },
    role: ['admin', 'admin-hipaa', 'super-admin', 'staff', 'photo-uploader', 'license-checker'],
  },
  {
    text: 'Providers',
    link: {
      to: '/providers',
    },
    role: ['admin', 'admin-hipaa', 'super-admin', 'staff', 'photo-uploader', 'license-checker'],
  },
  {
    text: 'Practices',
    link: {
      to: '/practices',
    },
    role: ['admin', 'admin-hipaa', 'super-admin', 'staff', 'photo-uploader', 'license-checker'],
  },
  {
    text: 'Therapy Groups',
    link: {
      to: '/therapy-groups',
    },
    role: ['admin', 'admin-hipaa', 'super-admin', 'staff'],
  },
  {
    text: 'Search Content',
    link: {
      to: '/search-content',
    },
    role: ['admin', 'admin-hipaa', 'super-admin', 'staff'],
  },
  {
    text: 'Reports',
    link: {
      to: '/reports',
    },
    role: ['admin', 'admin-hipaa', 'super-admin', 'staff'],
  },
  // {
  //   text: 'Regions',
  //   link: {
  //     to: '/regions',
  //   },
  //   role: ['admin', 'admin-hipaa', 'staff'],
  // },
  // {
  //   text: 'Areas',
  //   link: {
  //     to: '/areas',
  //   },
  //   role: ['admin', 'admin-hipaa', 'staff'],
  // },
  {
    text: 'Insurances',
    link: {
      to: '/insurances',
    },
    role: ['admin', 'admin-hipaa', 'super-admin', 'staff'],
  },
  {
    text: 'Specialties',
    link: {
      to: '/specialties',
    },
    role: ['admin', 'admin-hipaa', 'super-admin', 'staff'],
  },
  {
    text: 'Approaches',
    link: {
      to: '/approaches',
    },
    role: ['admin', 'admin-hipaa', 'super-admin', 'staff'],
  },
  {
    text: 'Clientele',
    link: {
      to: '/clientele',
    },
    role: ['admin', 'admin-hipaa', 'super-admin', 'staff'],
  },
  {
    text: 'Professions',
    link: {
      to: '/professions',
    },
    role: ['admin', 'admin-hipaa', 'super-admin', 'staff'],
  },
  {
    text: 'Degrees',
    link: {
      to: '/degrees',
    },
    role: ['admin', 'admin-hipaa', 'super-admin', 'staff'],
  },
  {
    text: 'Genders',
    link: {
      to: '/genders',
    },
    role: ['admin', 'admin-hipaa', 'super-admin', 'staff'],
  },
  {
    text: 'Identities',
    link: {
      to: '/identities',
    },
    role: ['admin', 'admin-hipaa', 'super-admin', 'staff'],
  },
  {
    text: 'Languages',
    link: {
      to: '/languages',
    },
    role: ['admin', 'admin-hipaa', 'super-admin', 'staff'],
  },
  {
    text: 'Provider Users',
    link: {
      to: '/users',
    },
    role: ['admin', 'admin-hipaa', 'super-admin', 'staff'],
  },
  {
    text: 'Blog Posts',
    link: {
      to: '/homepage-blog-posts',
    },
    role: ['admin-hipaa', 'super-admin', 'admin'],
  },
  {
    text: 'All Users',
    link: {
      to: '/users/all',
    },
    role: ['admin-hipaa', 'super-admin'],
  },
  {
    text: 'Redirects',
    link: {
      to: '/redirects',
    },
    role: ['admin', 'admin-hipaa', 'super-admin'],
  },
  {
    text: 'Blocklist',
    link: {
      to: '/contact-blacklist',
    },
    role: ['admin', 'admin-hipaa', 'super-admin'],
  },
  {
    text: 'Promotions',
    link: {
      to: '/promotions',
    },
    role: ['admin', 'admin-hipaa', 'super-admin'],
  },
  {
    text: 'PPB SSO Tool',
    link: {
      to: '/ppb-sso-tool',
    },
    role: ['super-admin'],
  },
  {
    text: 'Developer Tools',
    link: {
      to: '/developer-tools',
    },
    role: ['super-admin'],
  },
];

export const sideLinks = (role) => sideLink.filter((link) => link.role.includes(role));
