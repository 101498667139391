import DefaultLayout from '##/Default';
import SearchContentOverrides from './SearchContentOverrides';
import EditSearchContent from './EditSearchContent';

export default [
  {
    path: '/search-content',
    exact: true,
    layout: DefaultLayout,
    component: SearchContentOverrides,
    auth: true,
    title: 'Search Content',
  },
  {
    path: '/search-content/edit/:searchContentOverrideId',
    exact: true,
    layout: DefaultLayout,
    component: EditSearchContent,
    auth: true,
    title: 'Edit Search Content',
  },
];
