import React, { useState, useCallback, useEffect, useRef } from 'react';
import Modal from '+/Modal';
import UnregisteredProviderForm from './UnregisteredProviderForm';
import UnregisteredProvider from './UnregisteredProvider';
import useSortable from '+/hooks/useSortable';
import { onlyOnEnter } from '#/';

const UnregisteredProviders = (props) => {
  const { provider, updateFn } = props;
  const { custom_content } = provider;

  const [modal, setModal] = useState(false);
  const providerRef = useRef(null);

  const initialProviders = provider.custom_content.providers;
  const {
    sorted,
    unSorted: providers,
    clearSort,
    setUnsorted,
    toggleDisabled,
  } = useSortable(providerRef, initialProviders);

  const saveSort = useCallback(() => {
    const payload = { custom_content };
    payload.custom_content.providers = sorted;

    updateFn(payload);
    // Reset the sort to an empty array
    clearSort();
  }, [custom_content, clearSort, sorted, updateFn]);

  useEffect(() => {
    // Sorted is empty unless a sort occurred
    // Whenever a sort occurs we trigger the save Sort
    sorted.length > 0 && saveSort();
  }, [saveSort, sorted]);

  useEffect(() => {
    // Update unsorted providers whenever props change
    if (initialProviders.length !== providers.length) {
      setUnsorted(initialProviders);
    }
  }, [modal, initialProviders, providers.length, setUnsorted]);

  const onSubmit = (values) => {
    const payload = { custom_content };
    payload.custom_content.providers.push(values);

    updateFn(payload);
    setModal(() => false);
  };

  const disassociate = (providerToRemove) => {
    const payload = { custom_content };
    const updated = payload.custom_content.providers.filter((p) => p !== providerToRemove);

    payload.custom_content.providers = updated;
    updateFn(payload);
  };

  const updateUnregisteredProvider = (values, i) => {
    const payload = { custom_content };
    payload.custom_content.providers[i] = values;
    // Update unsorted providers whenever a single provider changes
    setUnsorted(payload.custom_content.providers);
    updateFn(payload);
    setModal(() => false);
  };

  const renderAdditionModal = () => (
    <Modal title='Add New Provider (unregistered)' onDismiss={() => setModal(() => false)}>
      <UnregisteredProviderForm onSubmit={onSubmit} onCancel={() => setModal(() => false)} />
    </Modal>
  );

  return (
    <div className='border border-grey-lightest radius-xs p-lg mr-sm mb-sm w-50'>
      <div className='mb-lg grid align-center'>
        <h2 className='mb-0 mt-0'>Providers (unregistered)</h2>
        <div
          onClick={() => setModal(!modal)}
          onKeyDown={onlyOnEnter(() => setModal(!modal))}
          role='menuitem'
          tabIndex={0}
          className='primary pointer p-xs'
        >
          <i className='fas fa-plus' />
        </div>
      </div>

      <div ref={providerRef} className='grid wrap'>
        {providers.map((p, i) => (
          <UnregisteredProvider
            key={`unregisteredProvider${i}`}
            provider={p}
            handle={i}
            toggleDisabled={toggleDisabled}
            updateUnregisteredProvider={(payload) => updateUnregisteredProvider(payload, i)}
            disassociate={disassociate}
          />
        ))}
      </div>
      {modal && renderAdditionModal()}
    </div>
  );
};

export default UnregisteredProviders;
