import React, { useState } from 'react';
import CustomBox from './CustomBox';

const CustomBoxes = (props) => {
  const { initialState, updateFn, styles, custom_content } = props;
  const [boxes, setBoxes] = useState(initialState);

  const updateBoxes = (box, index) => {
    const payload = { custom_content };
    payload.custom_content.boxes[index] = box;
    setBoxes(() => payload.custom_content.boxes);
    updateFn(payload);
  };

  const addBox = () => {
    setBoxes((boxes) => [...boxes, []]);
  };

  const deleteBox = (index, isNew = false) => {
    const newBoxes = { ...boxes };
    delete newBoxes[index];

    setBoxes(() => Object.values(newBoxes));

    if (!isNew) {
      const payload = { custom_content };
      payload.custom_content.boxes = Object.values(newBoxes);
      updateFn(payload);
    }
  };

  return (
    <div className={`card cursor-default p-lg mx-md my-lg ${styles}`}>
      <div className='grid justify-between align-center'>
        <h3 className='mb-lg'>Custom Boxes:</h3>
        <button className='success' onClick={addBox}>
          <i className='fas fa-plus' />
        </button>
      </div>
      <div>
        {boxes.length ? (
          boxes.map((box, i) => (
            <CustomBox
              index={i}
              initialState={box}
              updateBoxes={updateBoxes}
              deleteBox={deleteBox}
              key={`CustomBox#${i}`}
            />
          ))
        ) : (
          <div className='grid justify-center w-100'>
            <h5>No Content Boxes created</h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomBoxes;
