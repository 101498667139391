/**
 * A utility function that accepts CSV data and a file name and downloads that data to the user's file system
 * @param payload CSV data to be downloaded
 * @param fileName The name of the file without the extension
 */
export const downloadCSV = (payload, fileName) => {
  const blob = new Blob([payload]);
  // Create a URL for the file
  const url = window.URL.createObjectURL(blob);

  // Create a link element
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = `${fileName}.csv`;

  // Append the link element to the document
  document.body.appendChild(a);

  // Click the link to trigger the download
  a.click();

  // Remove the link element from the document
  document.body.removeChild(a);
};

/**
 * @deprecated Not currently in use, but able to append
 * the date to the file name if need be
 */
const parseFileName = (string) => {
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();

  return `${string}_${hours}${minutes}_${month}_${day}_${year}.csv`;
};
