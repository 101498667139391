import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import Input from '+/forms/Input';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import rgx from '#/regex';

const validate = (values) => {
  const errors = {};
  const { new_password, confirm_new_password } = values;

  if (!new_password) {
    errors.new_password = 'You must enter an password';
  } else if (!rgx.new_password.test(new_password)) {
    errors.new_password =
      'Your password must be at least 12 characters long and have at least one character from three of the following four categories: lowercase, uppercase, number, special character';
  } else if (new_password !== confirm_new_password) {
    errors.confirm_new_password = 'Passwords must match';
  }

  return errors;
};

export const UpdatePassword = (props) => {
  const { updatePassword } = props;
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = (values) => {
    delete values.confirm_new_password;
    updatePassword(values);
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit} className='grid justify-between column align-center'>
          <div>
            <h1>Update Password</h1>
            <p>
              Your password must be at least 12 characters long and have at least one character from
              three of the following four categories: lowercase, uppercase, number, special
              character.
            </p>
          </div>
          <div className='w-75'>
            <Field
              type={showPassword ? 'text' : 'password'}
              component={Input}
              label='Old Password:'
              name='old_password'
            />
            <Field
              type={showPassword ? 'text' : 'password'}
              component={Input}
              label='New Password:'
              name='new_password'
            />
            <Field
              type={showPassword ? 'text' : 'password'}
              component={Input}
              label='Confirm New Password:'
              name='confirm_new_password'
            />
            <div>
              <p>
                <input type='checkbox' onChange={(e) => setShowPassword(e.target.checked)} /> Show
                password
              </p>
              <button type='submit' className='primary' disabled={invalid}>
                Update Password
              </button>
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default connect(null, { updatePassword: actions.updatePassword })(UpdatePassword);
