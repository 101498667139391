import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './sagaSlice';
import OmniLoadingSpinner from './OmniLoadingSpinner';
import OmniResultsModal from './OmniResultsModal';

const OmniSearchInput = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state['omniSearch']);

  const [textInputValue, setTextInputValue] = useState('');

  const runSearch = () => {
    return dispatch(actions.omniSearch({ searchTerm: textInputValue }));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      runSearch();
    }
  };

  return (
    <>
      <div className='omni-search-nav-input'>
        <input
          style={{ width: '80%', height: '3rem', border: 'none', background: 'none', padding: '0.25rem 0rem .25rem 1.25rem' }}
          onChange={(e) => setTextInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
          type='text'
          className='block m-r-xs'
          placeholder='Search for anything... '
        ></input>
        <button style={{ height: '3rem', borderRadius: '50px', width: '20%' }} onClick={runSearch}>
          Search
        </button>
      </div>
      {isLoading ? <OmniLoadingSpinner /> : <OmniResultsModal />}
    </>
  );
};

export default OmniSearchInput;
