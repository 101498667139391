import React from 'react';

function Header() {
  return (
    <>
      {' '}
      <hr className='m-0' />
      <h2>Directions</h2>
      <p>
        Welcome to Feature Flags! Use this page to create, deactivate, and bulk-update Account,
        Provider, and User access to features through the management of{' '}
        <code className='p-x-xs'>feature-flags</code>! Please read the direction for each
        corresponding feature before using any of the utilities. If you're looking for individual
        Account, Provider, or User level feature access control, that can be located on each
        corresponding page. As of the middle of April, 2023, we have only implemented Account level
        feature flag support. Support for Providers and Users will be built as the need arises.
      </p>
      <hr className='m-0' />
      <div className='p-md flex justify-around align-center'>
        <a
          href='https://metabase.zctools.de/question/1091-feature-flags'
          target='_blank'
          rel='noopener noreferrer'
          className='link primary '
        >
          Feature Flags
        </a>
        |
        <a
          href='https://metabase.zctools.de/question/1090-account-feature-flags-with-account-data'
          target='_blank'
          rel='noopener noreferrer'
          className='link primary '
        >
          Account Feature Flags
        </a>
        |
        <a href='/' className='link primary error'>
          Provider Feature Flags
        </a>
        |
        <a href='/' className='link primary error'>
          User Feature Flags
        </a>
      </div>
      <hr className='m-t-0 m-b-md' />
    </>
  );
}

export default Header;
