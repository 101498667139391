/* eslint-disable max-lines-per-function */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect, useSelector } from 'react-redux';
import { actions } from '../../sagaSlice';
import { actions as featureFlagActions } from '@/feature-flags/sagaSlice';
import dayjs from 'dayjs';
import MoveProviderToExistingAccountModal from './accounts/MoveProviderToExistingAccountModal';
import ConfirmMoveModal from './accounts/ConfirmMoveModal';
import ChangeAccountOwnerModal from './accounts/ChangeAccountOwnerModal';
import ConfirmChangeOwnerModal from './accounts/ConfirmChangeOwnerModal';
import StatusWidget from './StatusWidget';
import ManageReimbursify from './accounts/ManageReimbursify';
import AccountNotes from '../../../accounts/AccountNotes';
import RemoveSeatModal from './accounts/RemoveSeatModal';
import ConfirmRemoveSeatModal from './accounts/ConfirmRemoveSeatModal';
import AddSeatModal from './accounts/AddSeatModal';
import ConfirmAddSeatModal from './accounts/ConfirmAddSeatModal';
import GrantAccessWidget from '@/feature-flags/GrantAccessWidget';
import RevokeAccessWidget from '~/components/feature-flags/RevokeAccessWidget';
import AddProviderFeatureFlags from '@/feature-flags/providers/AddProviderFeatureFlags';
import RemoveProviderFeatureFlags from '~/components/feature-flags/providers/RemoveProviderFeatureFlags';
import CurrentSpecialRoleUsers from '~/components/special-roles/CurrentSpecialRoleUsers';
import GrantSpecialRolesWidget from '~/components/special-roles/GrantSpecialRolesWidget';
import RevokeSpecialRolesWidget from '~/components/special-roles/RevokeSpecialRolesWidget';
import AddPracticeManagementClinicCreatorWidget from './accounts/AddPracticeManagementClinicCreatorWidget';
import StandalonePracticeManagementUpgradeDowngrade from './accounts/StandalonePracticeManagementUpgradeDowngrade';
import StandalonePracticeManagementEditQuantityModal from './accounts/StandalonePracticeManagementEditQuantityModal';
import CancellationRequestReceivedButton from './CancellationRequestReceivedButton';

const stripeUrl = (type, entity) => `https://dashboard.stripe.com/${process.env.ZENCARE_ENV === 'production' ? '' : 'test/'}${type}/${entity.id}`;

const stripeUrlFromId = (type, entityId) => `https://dashboard.stripe.com/${process.env.ZENCARE_ENV === 'production' ? '' : 'test/'}${type}/${entityId}`;

//eslint-disable-next-line
const fmtdate = (date) => (date ? dayjs(date).format('MMMM DD, YYYY') : 'null');
const formatDateAndTime = (date) => (date ? dayjs(date).format('MMMM DD, YYYY h:mm A') : 'null');

const formatDiscount = (discount) => {
  let str;

  if (_.get(discount, 'coupon.percent_off')) {
    str = `${discount.coupon.id} - ${discount.coupon.percent_off}% off`;
  } else if (_.get(discount, 'coupon.amount_off')) {
    str = `${discount.coupon.id} - $${discount.coupon.amount_off / 100} off`;
  }

  if (_.get(discount, 'coupon.duration') === 'once') {
    str = `${str} once`;
  }

  return str;
};

/* No longer used, kept for reference for now.
const getStatusFromInvoiceItem = (invoiceItem, subscriptions) => {
  if (!invoiceItem.subscription) {
    return;
  }

  return (subscriptions.find((s) => s.id === invoiceItem.subscription) || {}).status;
};
*/

const AccountsTab = ({ provider, accounts, getAccountSummary, cancellationRequestReceived, cancellationRequestReceivedData }) => {
  const [status, setStatus] = useState('idle');
  const { id } = provider;

  useEffect(() => {
    getAccountSummary({
      id,
      setStatus,
    });
  }, [getAccountSummary, id]);

  if (status === 'loading') {
    return 'Loading... ';
  }

  if (!provider || !provider.custom_content || !accounts) {
    return null;
  }

  const customers = _.flatten(accounts.map((a) => a.stripe_customers));

  let linkedInvoicesFromActiveSubscriptions = [];
  let linkedInvoicesFromInactiveSubscriptions = [];

  // Get the subscription which pays for this provider.
  const linkedSubs = _.flattenDeep(customers.map((sc) => sc.stripe_subscriptions)).filter((x) => {
    // First see if this provider is associated with an active seat-based subscription. If so they will have
    // a provider_paid_seat record that is non-null.
    if (provider.provider_paid_seats && provider.provider_paid_seats.length && provider.provider_paid_seats.filter((x) => x.canceled_at === null).length === 1) {
      // Match any subscription in this stripe customer that has seat_based yes. This should only be 1.
      if (x.metadata && x.metadata.seat_based === 'yes') {
        return true;
      }
    }

    return _.get(x, 'metadata.provider_id') === id || _.get(x, 'metadata.provider_ids', []).includes(id);
  });

  let linkedSub = linkedSubs.find((x) => !['canceled', 'incomplete_expired'].includes(x.status)) || linkedSubs[0];

  // No non-canceled or expired subscription found? Go ahead and allow matching those
  if (!linkedSub) {
    const linkedSubsIncludingCanceledSeats = _.flattenDeep(customers.map((sc) => sc.stripe_subscriptions)).filter((x) => {
      if (provider.provider_paid_seats && provider.provider_paid_seats.length === 1) {
        // Match any subscription in this stripe customer that has seat_based yes (even if seat is canceled). This should only be 1.
        if (x.metadata && x.metadata.seat_based === 'yes') {
          return true;
        }
      }

      return _.get(x, 'metadata.provider_id') === id || _.get(x, 'metadata.provider_ids', []).includes(id);
    });

    // Sort so we show most recent canceled or incomplete_expired subscription at the top of admin
    linkedSubsIncludingCanceledSeats.sort((a, b) => (new Date(a.created).getTime() > new Date(b.created).getTime() ? -1 : 1));

    linkedSub = linkedSubsIncludingCanceledSeats.find((x) => ['canceled', 'incomplete_expired'].includes(x.status));
  }

  if (linkedSubs.length) {
    // Get all invoices related to these subscriptions.
    const customerInvoices = _.flatten(customers.filter((c) => linkedSubs.map((x) => x.customer_id).includes(c.id)).map((c) => c.stripe_invoices));

    linkedInvoicesFromActiveSubscriptions = customerInvoices.filter((i) =>
      _.get(i, 'lines.data', []).some((l) =>
        linkedSubs
          .filter((x) => !['canceled', 'incomplete_expired'].includes(x.status))
          .map((s) => s.id)
          .includes(l.subscription)
      )
    );
    linkedInvoicesFromActiveSubscriptions = _.orderBy(linkedInvoicesFromActiveSubscriptions, 'created', 'desc');

    linkedInvoicesFromInactiveSubscriptions = customerInvoices.filter((i) =>
      _.get(i, 'lines.data', []).some((l) =>
        linkedSubs
          .filter((x) => ['canceled', 'incomplete_expired'].includes(x.status))
          .map((s) => s.id)
          .includes(l.subscription)
      )
    );
    linkedInvoicesFromInactiveSubscriptions = _.orderBy(linkedInvoicesFromInactiveSubscriptions, 'created', 'desc');
  }

  return (
    <div className='accounts-container' style={{}}>
      <h2>Account Notes</h2>
      {accounts.map((a) => (
        <AccountNotes key={a.id} account={a} />
      ))}
      {linkedSub ? (
        <>
          <h2>Current Subscription</h2>
          <div className='current-subscription'>
            <div className='box current-subscription-details'>
              <p>
                <a target='_blank' rel='noopener noreferrer' href={stripeUrl('subscriptions', linkedSub)}>
                  {linkedSub.id}
                </a>
              </p>

              <p>
                <b>Product Names, Prices, and Quantities:</b>{' '}
                {linkedSub && linkedSub.items && linkedSub.items.data && (
                  <>
                    {linkedSub.items.data.map((item) => (
                      <>
                        {_.get(item, 'price.recurring') && (
                          <p>
                            {item.quantity} x {item.price.nickname} ${item.price.unit_amount / 100}/{item.price.recurring.interval} each
                          </p>
                        )}
                      </>
                    ))}
                  </>
                )}
              </p>

              <p>
                <b>Status:</b> {linkedSub.status}
              </p>
              <p>
                <b>Created on:</b> {fmtdate(linkedSub.created)}
              </p>
              <p>
                <b>Current period start:</b> {fmtdate(linkedSub.current_period_start)}
              </p>
              <p>
                <b>Current period end:</b> {fmtdate(linkedSub.current_period_end)}
              </p>
              <p>
                <b>Canceled at (date on which this subscription was canceled):</b> {fmtdate(linkedSub.canceled_at)}
              </p>
              <p>
                <b>Cancel at (future date when this subscription will cancel):</b> {fmtdate(linkedSub.cancel_at)}
              </p>
              <p>
                <b>Most recent discount used:</b>{' '}
                {
                  // If the sub doesn't have a discount, the first invoice for the sub could have a one-time coupon applied
                  linkedSub.discount ? formatDiscount(linkedSub.discount) : formatDiscount(_.get(linkedInvoicesFromActiveSubscriptions, '[0].discount')) || 'none'
                }
              </p>
            </div>
            <div>
              <div className='box'>
                {linkedInvoicesFromActiveSubscriptions && linkedInvoicesFromActiveSubscriptions.length > 0 ? (
                  <>
                    <p>Billing history associated with the provider's subscription (this will be same for all providers if seat based):</p>
                    {linkedInvoicesFromActiveSubscriptions.map((i) =>
                      _.get(i, 'lines.data', []).map((l) => (
                        <div
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '2fr 2fr 2fr 1fr',
                            marginBottom: '4px',
                          }}
                        >
                          <span>{fmtdate(i.created)}</span>
                          <span>
                            ${l.amount / 100}
                            {_.get(l, 'discount_amounts[0].amount') ? <i> (discount: ${_.get(l, 'discount_amounts[0].amount') / 100})</i> : ''}
                          </span>
                          <a target='_blank' rel='noopener noreferrer' href={stripeUrl('invoices', i)}>
                            {' '}
                            <span>{l.description}</span>
                          </a>
                          <span>{i.status}</span>
                        </div>
                      ))
                    )}
                  </>
                ) : (
                  <p>There is no active billing history for this provider currently in our database. Check on Stripe to see if there are older invoices.</p>
                )}
              </div>
              {/* <hr /> */}
              {linkedInvoicesFromInactiveSubscriptions && linkedInvoicesFromInactiveSubscriptions.length > 0 && (
                <div className='box'>
                  <p>
                    Billing history associated with provider for <b>previous</b> subscriptions:
                  </p>
                  {linkedInvoicesFromInactiveSubscriptions.map((i) =>
                    _.get(i, 'lines.data', []).map((l) => (
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '2fr 2fr 2fr 1fr',
                          marginBottom: '4px',
                        }}
                      >
                        <span>{fmtdate(i.created)}</span>
                        <span>
                          ${l.amount / 100}
                          {_.get(l, 'discount_amounts[0].amount') ? <i> (discount: ${_.get(l, 'discount_amounts[0].amount') / 100})</i> : ''}
                        </span>
                        <a target='_blank' rel='noopener noreferrer' href={stripeUrl('invoices', i)}>
                          {' '}
                          <span>{l.description}</span>
                        </a>
                        <span>{i.status}</span>
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
            <CancellationRequestReceivedButton cancellationRequestReceived={cancellationRequestReceived} provider={provider} cancellationRequestReceivedData={cancellationRequestReceivedData} />
          </div>
        </>
      ) : (
        <p>
          (Ignore this note if this provider is using new seat-based plan) This provider does not seem to be associated with any subscriptions. If the provider does have a subscription, you can fix
          this issue by editing the metadata of the subscription in the Stripe dashboard to add <b>Key: provider_id</b> and <b>Value: {provider.id}</b>. If the subscription that pays for this provider
          includes other providers and has a <b>provider_ids</b> key in the metadata, add their id too
        </p>
      )}
      <p></p>
      <p>This provider is attached to {accounts.length} account(s).</p>
      {accounts.map((a) => (
        <AccountGraph account={a} provider={provider} />
      ))}
    </div>
  );
};

const colors = {
  user: 'green',
  provider: 'blue',
  account: 'red',
  'stripe customer': 'purple',
};

const BubbleTitle = ({ type }) => (
  <span
    className='bubble-line bubble-type'
    style={{
      color: colors[type],
    }}
  >
    {type}
  </span>
);

/**
 * @typedef AccountGraphInnerProps
 * @property {AdminGetAccountSummaryByTypeResultItem} account - The account info
 * @property {Function} assignStripeCustomer - method to assign a stripe customer
 * @property {any} provider
 * @property {any} user
 * @property {Function} addProviderPaidSeat
 * @property {Function} removeProviderPaidSeat
 * @property {Function} sendUpdateOwlWebhook - Not currently used but could be used in future.
 * @property {Function} downgradeSubscriptionTier
 * @property {Function} upgradeSubscriptionTier
 * @property {Function} previewCreditsFromCancelingLegacySubscriptions
 * @property {Function} getFeatureFlags
 */

const AccountGraphInner = (
  /** @type {AccountGraphInnerProps} */
  {
    account: a,
    assignStripeCustomer,
    provider = {},
    user = {},
    addProviderPaidSeat,
    removeProviderPaidSeat,
    sendUpdateOwlWebhook,
    downgradeSubscriptionTier,
    upgradeSubscriptionTier,
    previewCreditsFromCancelingLegacySubscriptions,
    getFeatureFlags,
  }
) => {
  useEffect(() => {
    getFeatureFlags();
  }, [getFeatureFlags]);

  const [hovering, setHovering] = useState({
    type: null,
    id: null,
  });
  // @ts-ignore
  const { cancelCreditsPreviewResult, cancelCreditsPreviewResultLoading } = useSelector((state) => state.providers);

  const [addCustomerId, setAddCustomerId] = useState(null);
  const [editingAccount, setEditingAccount] = useState(false);

  const [showChangeAccountOwnerModal, setShowChangeAccountOwnerModal] = useState(false);
  const toggleChangeAccountOwnerModal = () => {
    setShowChangeAccountOwnerModal((e) => !e);
  };
  const [confirmChangeOwnerModalMessage, setConfirmChangeOwnerModalMessage] = useState(null);
  const [newAccountOwnerUserId, setNewAccountOwnerUserId] = useState(null);
  const [showConfirmChangeOwnerModal, setShowConfirmChangeOwnerModal] = useState(false);
  const toggleConfirmChangeOwnerModal = () => {
    setShowConfirmChangeOwnerModal((e) => !e);
  };

  const [showRemoveSeatModal, setShowRemoveSeatModal] = useState(false);
  const toggleRemoveSeatModal = () => {
    setShowRemoveSeatModal((e) => !e);
  };
  const [confirmRemoveSeatModalMessage, setConfirmRemoveSeatModalMessage] = useState(null);
  const [pendingRemoveSeatParams, setPendingRemoveSeatParams] = useState(null);
  const [showConfirmRemoveSeatModal, setShowConfirmRemoveSeatModal] = useState(false);
  const toggleShowConfirmRemoveSeatModal = () => {
    setShowConfirmRemoveSeatModal((e) => !e);
  };

  const [showAddSeatModal, setShowAddSeatModal] = useState(false);
  const toggleAddSeatModal = () => {
    setShowAddSeatModal((e) => !e);
  };
  const [confirmAddSeatModalMessage, setConfirmAddSeatModalMessage] = useState(null);
  const [pendingAddSeatParams, setPendingAddSeatParams] = useState(null);
  const [showConfirmAddSeatModal, setShowConfirmAddSeatModal] = useState(false);
  const toggleShowConfirmAddSeatModal = () => {
    setShowConfirmAddSeatModal((e) => !e);
  };

  const [showStandalonePracticeManagementEditQuantityModal, setShowStandalonePracticeManagementEditQuantityModal] = useState(false);
  const toggleStandalonePracticeManagementEditQuantityModal = () => {
    setShowStandalonePracticeManagementEditQuantityModal((e) => !e);
  };

  const [showMoveProviderToExistingAccountModal, setShowMoveProviderToExistingAccountModal] = useState(false);

  const toggleMoveProviderToExistingAccountModal = () => {
    setShowMoveProviderToExistingAccountModal((e) => !e);
  };

  const [providerBeingMoved, setProviderBeingMoved] = useState(null);
  const [confirmMoveModalData, setConfirmMoveModalData] = useState(null);
  const [showConfirmMoveModal, setShowConfirmMoveModal] = useState(false);
  const [subscriptionExpandedList, setSubscriptionExpandedList] = useState([]);

  const [showMoreInstructionsTableOfContents, setShowMoreInstructionsTableOfContents] = useState(false);
  const [cancelCreditsPreviewResultClicked, setCancelCreditsPreviewResultClicked] = useState(false);
  const toggleConfirmMoveModal = () => {
    setShowConfirmMoveModal((e) => !e);
  };
  //eslint-disable-next-line
  const providerName = (provider) => (provider.first_name ? `${provider.first_name} ${provider.last_name}` : provider.company_name);

  // Get the subscription which pays for this provider.
  const getValidLegacyLinkedSubscriptionsForProvider = (provider) =>
    _.flattenDeep(a.stripe_customers.map((sc) => sc.stripe_subscriptions.filter((ss) => !['canceled', 'incomplete_expired'].includes(ss.status)))).filter((x) => {
      return _.get(x, 'metadata.provider_id') === provider.id || _.get(x, 'metadata.provider_ids', []).includes(provider.id);
    });

  const unhover = () =>
    setHovering({
      type: null,
      id: null,
    });

  const hover = (type, id) =>
    setHovering({
      type,
      id,
    });

  const getStyle = (type, ent) => {
    if (type === 'user' && hovering.type === 'provider') {
      if (ent && ent.roles && (ent.roles.map((r) => r.name).includes('Owner') || ent.roles.some((r) => _.get(r, 'scope.providers')?.includes(hovering.id)))) {
        return {
          border: `1px solid ${colors[type]}`,
          color: colors[type],
        };
      }
    }

    if (type === 'provider' && hovering.type === 'user') {
      const user = a.users.find((u) => u.id === hovering.id);
      if (user && user.roles && (user.roles.map((r) => r.name).includes('Owner') || user.roles.some((r) => _.get(r, 'scope.providers')?.includes(ent.id)))) {
        return {
          border: `1px solid ${colors[type]}`,
          color: colors[type],
        };
      }
    }

    return {};
  };

  const moveToExistingAccount = (provider) => {
    console.log(`moveToExistingAccount called with provider.id = ${provider.id}`);
    setProviderBeingMoved(provider);
    setShowMoveProviderToExistingAccountModal(true);
  };

  let firstPracticeManagementAccount = null;
  if (a.practice_management_accounts && a.practice_management_accounts.length && a.practice_management_accounts[0]) {
    firstPracticeManagementAccount = a.practice_management_accounts[0];
  }

  let firstPracticeManagementSubscriptionStat = null;
  if (a.practice_management_subscription_stats && a.practice_management_subscription_stats.length && a.practice_management_subscription_stats[0]) {
    firstPracticeManagementSubscriptionStat = a.practice_management_subscription_stats[0];
  }

  const practiceManagementDomainName = process.env.ZENCARE_PRACTICE_MANAGEMENT_DOMAIN_NAME;

  const showEventDetails = (accountEvent) => alert(JSON.stringify(accountEvent.data));

  const practiceManagementStatusOptions = [
    'PM Account Not Created',
    'PM Account Not Created + Paid',
    'PM Account Created + Trialing',
    'PM Account Created + Trial Expired',
    'PM Account Created + Paid',
  ];
  let practiceManagementStatus = 'PM Account Not Created';

  if (firstPracticeManagementAccount && firstPracticeManagementAccount.trial_end) {
    if (dayjs().isBefore(dayjs(firstPracticeManagementAccount.trial_end))) {
      practiceManagementStatus = 'PM Account Created + Trialing';
    } else {
      practiceManagementStatus = 'PM Account Created + Trial Expired';
    }
  }

  if (a.has_practice_management_plan) {
    if (firstPracticeManagementAccount) {
      practiceManagementStatus = 'PM Account Created + Paid';
    } else {
      practiceManagementStatus = 'PM Account Not Created + Paid';
    }
  }

  const tierToFriendlyName = (tier) => {
    switch (tier) {
      case 'basic':
        return 'Basic';
      case 'professional':
        return 'Pro';
      case 'premium':
        return 'Premium';
      default:
        return tier;
    }
  };

  const color = {
    active: 'text-success',
    approved: 'text-success',
    pending: 'text-warning',
    incomplete: 'text-warning',
    reviewing: 'text-warning',
    inactive: 'text-error',
    locked: 'text-error',
  };

  return (
    <>
      <div className='box'>
        <p>
          Reminder: You must add 'seat_based: yes' to Stripe subscription metadata if you are manually creating a new subscription or converting a subscription to be seat based. You should also NOT
          set provider_id or provider_ids (you must remove those).
        </p>
      </div>

      <h2>Basic Information</h2>
      <div className='account-holder'>
        {/* <span className='connection vertical' style={{ marginLeft: '100px' }}></span> */}
        <div
          className='account-bubble-container'
          style={{
            marginRight: '1rem',
          }}
        >
          <div
            className='w-49 box'
            style={{
              marginRight: '2rem',
            }}
          >
            <p className='bubble-line'>
              <b>Account ID: {a.id}</b>
            </p>
            {a.name && <p className='bubble-line'>Account Name: {a.name}</p>}
            {editingAccount && (
              <div className='box m-t-sm'>
                <input onChange={(evt) => setAddCustomerId(evt.target.value)} placeholder='Add stripe customer id' type='text'></input>
                <p>If the stripe customer is already attached to another account, it will be detached and attached to this account.</p>

                <button
                  className='pill primary sm'
                  onClick={() =>
                    assignStripeCustomer({
                      account_id: a.id,
                      stripe_customer_id: addCustomerId,
                      provider_id: provider ? provider.id : null,
                      user_id: user ? user.id : null,
                    })
                  }
                >
                  Add customer to account
                </button>
              </div>
            )}
            {!editingAccount && (
              <button className='pill primary sm m-y-sm m-r-md' onClick={() => setEditingAccount(true)}>
                Add Stripe Customer
              </button>
            )}
            <button className='primary sm pill m-y-sm' onClick={() => toggleChangeAccountOwnerModal()}>
              Change Account Owner
            </button>
          </div>

          <div className='box w-49'>
            <p className='bubble-line'>
              <b>Credits Remaining in Account</b>
              <br />
              <span className='bubble-line'>
                {cancelCreditsPreviewResultLoading && 'Loading...'}
                {cancelCreditsPreviewResultClicked && cancelCreditsPreviewResult ? `$${cancelCreditsPreviewResult.totalCredit.toFixed(2)}` : ''}
              </span>
            </p>

            {!cancelCreditsPreviewResultClicked && (
              <button
                className='primary sm pill m-y-sm'
                onClick={() => {
                  setCancelCreditsPreviewResultClicked(true);
                  previewCreditsFromCancelingLegacySubscriptions({
                    account_id: a.id,
                  });
                }}
              >
                View Remaining Credits
              </button>
            )}

            {cancelCreditsPreviewResultClicked && cancelCreditsPreviewResult && (
              <>
                <p>List of stripe subscriptions used to compute this number:</p>
                {cancelCreditsPreviewResult.resultDetails.map((resultDetail) => (
                  <li>
                    <a target='_blank' rel='noopener noreferrer' href={stripeUrlFromId('subscriptions', resultDetail.preview.subscription)}>
                      {resultDetail.preview.subscription}
                    </a>{' '}
                    - ${((resultDetail.preview.total * -1) / 100).toFixed(2)}{' '}
                    <i
                      style={{
                        cursor: 'pointer',
                      }}
                      title='View details'
                      role='button'
                      tabIndex={0}
                      className='fas fa-angle-right'
                      onKeyDown={() => {
                        setSubscriptionExpandedList((oldArray) => [...oldArray, resultDetail.subscription.id]);
                      }}
                      onClick={() => {
                        setSubscriptionExpandedList((oldArray) => [...oldArray, resultDetail.subscription.id]);
                      }}
                    ></i>
                    {subscriptionExpandedList.includes(resultDetail.subscription.id) && (
                      <>
                        <ul>
                          <li>Status: {resultDetail.subscription.status}</li>
                          <li>Interval: {resultDetail.subscription.items.data[0].price.recurring.interval}</li>
                          <li>Created Period Start: {formatDateAndTime(resultDetail.subscription.current_period_start)}</li>
                          <li>Current Period End: {formatDateAndTime(resultDetail.subscription.current_period_end)}</li>

                          <li>Subscription Start Date: {formatDateAndTime(resultDetail.subscription.start_date)}</li>

                          <li>Metadata: {JSON.stringify(resultDetail.subscription.metadata)}</li>

                          <li>Discount: {resultDetail.subscription.discount && resultDetail.subscription.discount.coupon ? resultDetail.subscription.discount.coupon.id : ''}</li>

                          {resultDetail.subscription.status === 'trialing' && (
                            <li>
                              View detailed trialing credit computation debug info:{' '}
                              <button
                                onClick={() => {
                                  alert(JSON.stringify(resultDetail.debug_data));
                                }}
                              >
                                View
                              </button>
                            </li>
                          )}
                        </ul>
                      </>
                    )}
                  </li>
                ))}
              </>
            )}
            {/*
            <p>
              <a
                href=''
                style='
    color: #37bec3;
    font-weight: 600;
    text-decoration: underline;
'
              >
                View subscription in Stripe{' '}
              </a>
            </p> */}
          </div>
        </div>
        <h2>Reimbursify</h2>
        {/* @ts-ignore */}
        <ManageReimbursify account={a} formatDateAndTime={formatDateAndTime} />
        <h2>Practice Management</h2>
        <div className='flex m-b-md'>
          <div className='w-33 box mr-sm'>
            <h5>Practice Management Status</h5>

            <StatusWidget currentStatus={practiceManagementStatus} statusOptionArray={practiceManagementStatusOptions} />
          </div>
          <div className='w-33 box mr-sm'>
            <h5>Practice Management Subscription Tier</h5>
            <StatusWidget currentStatus={tierToFriendlyName(a.tier)} statusOptionArray={['Basic', 'Pro', 'Premium']} />

            <div
              style={{
                marginTop: '2rem',
              }}
            >
              {!a.seat_based_stripe_subscription_seats.zpm && a.tier === 'basic' && a.total_subscription_seats ? (
                <p>
                  {' '}
                  <button
                    onClick={() => {
                      if (
                        true ===
                        window.confirm(
                          `Are you sure you want to upgrade all ${a.total_subscription_seats} seats to professional? This will do the following: 1) “Updated Subscription” internal email is triggered to send. 2. Webhook is sent to Remanage to update subscription tier. 3. Stripe subscription tier is updated. 4. NO EMAIL will be sent to provider (you will need to do this manually).`
                        )
                      ) {
                        upgradeSubscriptionTier({
                          new_tier: 'professional',
                          stripe_customer_id: a.seat_based_stripe_customer_id,
                          stripe_subscription_id: a.seat_based_stripe_subscription_id,
                        });
                      }
                    }}
                    className='primary pill  mr-sm mb-sm'
                  >
                    Upgrade to Professional
                  </button>
                </p>
              ) : null}
              {!a.seat_based_stripe_subscription_seats.zpm && a.tier !== 'premium' && a.total_subscription_seats ? (
                <p>
                  <button
                    onClick={() => {
                      if (
                        true ===
                        window.confirm(
                          `Are you sure you want to upgrade all ${a.total_subscription_seats} seats to premium? This will do the following: 1) “Updated Subscription” internal email is triggered to send. 2. Webhook is sent to Remanage to update subscription tier. 3. Stripe subscription tier is updated. 4. NO EMAIL will be sent to provider (you will need to do this manually).`
                        )
                      ) {
                        upgradeSubscriptionTier({
                          new_tier: 'premium',
                          stripe_customer_id: a.seat_based_stripe_customer_id,
                          stripe_subscription_id: a.seat_based_stripe_subscription_id,
                        });
                      }
                    }}
                    className='primary pill  mr-sm mb-sm'
                  >
                    Upgrade to Premium
                  </button>
                </p>
              ) : null}
              {!a.seat_based_stripe_subscription_seats.zpm && a.tier !== 'basic' && a.total_subscription_seats ? (
                <button
                  onClick={() => {
                    if (
                      true ===
                      window.confirm(
                        `Are you sure you want to downgrade all ${a.total_subscription_seats} seats to basic? This will do the following: 1) “Updated Subscription” internal email is triggered to send. 2. (Webhook is NOT sent to Remanage to update subscription tier.) 3. “Subscription downgrade” email is sent to provider. 4. Stripe subscription tier is updated. `
                      )
                    ) {
                      downgradeSubscriptionTier({
                        new_tier: 'basic',
                        stripe_customer_id: a.seat_based_stripe_customer_id,
                        stripe_subscription_id: a.seat_based_stripe_subscription_id,
                      });
                    }
                  }}
                  className='primary pill mr-sm mb-sm'
                >
                  Downgrade to Basic
                </button>
              ) : null}
              {!a.seat_based_stripe_subscription_seats.zpm && a.tier === 'premium' && (
                <button
                  onClick={() => {
                    if (
                      true ===
                      window.confirm(
                        `Are you sure you want to downgrade all ${a.total_subscription_seats} seats to professional? This will do the following: 1) “Updated Subscription” internal email is triggered to send. 2. Webhook is sent to Remanage to update subscription tier. 3. “Subscription downgrade” email is sent to provider. 4. Stripe subscription tier is updated. `
                      )
                    ) {
                      downgradeSubscriptionTier({
                        new_tier: 'professional',
                        stripe_customer_id: a.seat_based_stripe_customer_id,
                        stripe_subscription_id: a.seat_based_stripe_subscription_id,
                      });
                    }
                  }}
                  className='primary pill  mr-sm mb-sm'
                >
                  Downgrade to Professional
                </button>
              )}
              {a.seat_based_stripe_subscription_seats.zpm ? <StandalonePracticeManagementUpgradeDowngrade a={a} /> : ''}
            </div>
          </div>
          <div className='w-33 box'>
            <h5>Practice Management Purchase Details</h5>
            <ul>
              <li>
                PM purchase date:{' '}
                {firstPracticeManagementSubscriptionStat && firstPracticeManagementSubscriptionStat.purchase_date ? fmtdate(firstPracticeManagementSubscriptionStat.purchase_date) : ''}
              </li>
              <li>PM Trial Start Date: {firstPracticeManagementAccount && firstPracticeManagementAccount.created_at ? fmtdate(firstPracticeManagementAccount.created_at) : ''}</li>
              <li>PM Trial Expiration Date: {firstPracticeManagementAccount && firstPracticeManagementAccount.trial_end ? fmtdate(firstPracticeManagementAccount.trial_end) : ''}</li>
            </ul>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          {(a.tier === 'professional' || a.tier === 'premium') && !a.seat_based_stripe_subscription_seats.zpm && a.total_subscription_seats ? (
            <div className='box w-50 m-b-md'>
              <h5>Send ZPM Webhook to Owl Manually (if needed)</h5>
              <button
                onClick={() => {
                  if (true === window.confirm('Are you sure you want to send Remanage the updated purchased seat count/plan level?')) {
                    sendUpdateOwlWebhook({
                      account_id: a.id,
                      tier: a.tier,
                      seat_count: a.total_subscription_seats,
                    });
                  }
                }}
                className='primary sm pill m-y-sm'
              >
                ZPM-Add-on: Send up-to-date subscription data to Remanage
              </button>
              <div>
                If you changed something manually in Stripe, you can use this to send the current ZPM-Add-on subscription tier ({a.tier}) and # seats ({a.total_subscription_seats}) to Remanage. Make
                sure there are no override seats in Remanage before using this.
              </div>
            </div>
          ) : (
            ''
          )}
          {a.seat_based_stripe_subscription_seats.zpm ? (
            <div className='box w-50 m-b-md'>
              <h5>Send ZPM Webhook to Owl Manually (if needed)</h5>
              <button
                onClick={() => {
                  if (true === window.confirm('Are you sure you want to send Remanage the updated purchased seat count/plan level?')) {
                    sendUpdateOwlWebhook({
                      account_id: a.id,
                      tier: a.standalone_zpm_tier,
                      seat_count: a.seat_based_stripe_subscription_seats.zpm,
                    });
                  }
                }}
                className='primary sm pill m-y-sm'
              >
                ZPM Standalone: Send up-to-date subscription data to Remanage
              </button>
              <div>
                If you changed something manually in Stripe, you can use this to send the current subscription tier ({a.standalone_zpm_tier}) and # seats {a.seat_based_stripe_subscription_seats.zpm}{' '}
                to Remanage. Make sure there are no override seats in Remanage before using this.
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className='box m-b-md'>
          <h4>Clinic Info</h4>
          <ul>
            <li>Clinic Id: {firstPracticeManagementAccount ? firstPracticeManagementAccount.owl_clinic_id : ''}</li>
            {firstPracticeManagementAccount && (
              <li>
                Clinic link in ReManage:{' '}
                <a target='_blank' rel='noopener noreferrer' href={`${process.env.ZENCARE_OWL_REMANAGE_CLINIC_LINK_PREFIX_AWS}${firstPracticeManagementAccount.owl_clinic_id}`}>
                  {`${process.env.ZENCARE_OWL_REMANAGE_CLINIC_LINK_PREFIX_AWS}${firstPracticeManagementAccount.owl_clinic_id}`}
                </a>
                <br />
                <br />
                (for older clinics the link might be:{' '}
                <a target='_blank' rel='noopener noreferrer' href={`${process.env.ZENCARE_OWL_REMANAGE_CLINIC_LINK_PREFIX}${firstPracticeManagementAccount.owl_clinic_id}`}>
                  {`${process.env.ZENCARE_OWL_REMANAGE_CLINIC_LINK_PREFIX}${firstPracticeManagementAccount.owl_clinic_id}`}
                </a>
                )
              </li>
            )}
            <li>
              Clinic Link for Providers:{' '}
              {firstPracticeManagementAccount ? (
                <a target='_blank' rel='noopener noreferrer' href={`https://${firstPracticeManagementAccount.subdomain}.${practiceManagementDomainName}/`}>
                  {`https://${firstPracticeManagementAccount.subdomain}.${practiceManagementDomainName}/`}
                </a>
              ) : (
                ''
              )}
            </li>
            <li>
              Default Booking Link:{' '}
              {firstPracticeManagementAccount ? (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={`https://aws-portal.zencarepractice.com/${firstPracticeManagementAccount ? firstPracticeManagementAccount.subdomain : 'SUBDOMAIN'}/booking`}
                >
                  https://aws-portal.zencarepractice.com/{firstPracticeManagementAccount ? firstPracticeManagementAccount.subdomain : 'SUBDOMAIN'}/booking
                </a>
              ) : (
                ''
              )}
            </li>
          </ul>
        </div>
        <AddPracticeManagementClinicCreatorWidget account={a} />
        <h2>Seats / Stripe Subscriptions</h2>
        <div>
          {a.seat_based && (
            <>
              {a.provider_ids_covered_by_seats.length !== a.total_subscription_seats && (
                <div className='text-error mb-md'>
                  Warning!
                  <div>
                    "Provider IDs covered by seats" does not match "Total seats in Subscription."
                    <br />
                    Make sure to click "Remove provider from Subscription" or "Mark provider as paid" button on the providers who should be updated
                  </div>
                </div>
              )}

              <div
                style={{
                  display: 'flex',
                }}
              >
                <div className='box w-49 mr-sm mb-md'>
                  <ul>
                    <li>
                      # Total Zencare Marketing (and/or ZPM legacy) seats in Subscription: {a.total_subscription_seats}
                      <ul>
                        <li>
                          # NY/CA seats in Subscription: {a.seat_based_stripe_subscription_seats.ny}{' '}
                          {a.total_subscription_seats > 1 && a.seat_based_stripe_subscription_seats.ny > 0 && (
                            <>
                              {' '}
                              {!a.seat_based_stripe_subscription_seats.zpm && (
                                <button
                                  onClick={() => {
                                    setPendingRemoveSeatParams({
                                      provider_id: null,
                                      tier: a.tier,
                                      is_ny_or_ca: true,
                                      stripe_customer_id: a.seat_based_stripe_customer_id,
                                      stripe_subscription_id: a.seat_based_stripe_subscription_id,
                                    });
                                    setShowRemoveSeatModal(true);
                                  }}
                                  className='primary pill mr-sm mb-sm'
                                >
                                  Remove one seat
                                </button>
                              )}{' '}
                            </>
                          )}
                          {!a.seat_based_stripe_subscription_seats.zpm && (
                            <button
                              onClick={() => {
                                setPendingAddSeatParams({
                                  provider_id: null,
                                  tier: a.tier,
                                  is_ny_or_ca: true,
                                  stripe_customer_id: a.seat_based_stripe_customer_id,
                                  stripe_subscription_id: a.seat_based_stripe_subscription_id,
                                });
                                setShowAddSeatModal(true);
                              }}
                              className='primary pill mr-sm mb-sm'
                            >
                              Add one NY/CA seat
                            </button>
                          )}
                        </li>
                        <li>
                          # Other seats in Subscription: {a.seat_based_stripe_subscription_seats.non_ny}{' '}
                          {a.total_subscription_seats > 1 && a.seat_based_stripe_subscription_seats.non_ny > 0 && (
                            <>
                              {!a.seat_based_stripe_subscription_seats.zpm && (
                                <button
                                  onClick={() => {
                                    setPendingRemoveSeatParams({
                                      provider_id: null,
                                      tier: a.tier,
                                      is_ny_or_ca: false,
                                      stripe_customer_id: a.seat_based_stripe_customer_id,
                                      stripe_subscription_id: a.seat_based_stripe_subscription_id,
                                    });
                                    setShowRemoveSeatModal(true);
                                  }}
                                  className='primary pill mr-sm mb-sm'
                                >
                                  Remove one seat
                                </button>
                              )}{' '}
                            </>
                          )}
                          {!a.seat_based_stripe_subscription_seats.zpm && (
                            <button
                              onClick={() => {
                                setPendingAddSeatParams({
                                  provider_id: null,
                                  tier: a.tier,
                                  is_ny_or_ca: false,
                                  stripe_customer_id: a.seat_based_stripe_customer_id,
                                  stripe_subscription_id: a.seat_based_stripe_subscription_id,
                                });
                                setShowAddSeatModal(true);
                              }}
                              className='primary pill mr-sm mb-sm'
                            >
                              Add one non-NY/CA seat
                            </button>
                          )}
                        </li>
                      </ul>
                    </li>
                    <li># Reimbursify seats in Subscription: {a.seat_based_stripe_subscription_seats.reimbursify}</li>
                    <li>
                      # ZPM Standalone seats in Subscription: {a.seat_based_stripe_subscription_seats.zpm}{' '}
                      {a.seat_based_stripe_subscription_seats.zpm ? (
                        <button onClick={toggleStandalonePracticeManagementEditQuantityModal} className='primary pill mr-sm mb-sm'>
                          Edit..
                        </button>
                      ) : null}
                    </li>
                  </ul>
                </div>
                <div className='box w-49 mb-md'>
                  <ul>
                    <li># Seats Available for Zencare profiles: {a.available_subscription_seats}</li>
                    <li># Seats used by Zencare profiles: {a.total_used_subscription_seats}</li>
                    <li>Provider IDs covered by seats: {a.provider_ids_covered_by_seats.join(',')}</li>
                  </ul>
                </div>
              </div>
            </>
          )}
          {a.stripe_customers.length > 0 && (
            <>
              {/*<span className='connection horizontal' style={{ marginTop: '30px' }}></span> */}
              <div className='customer-bubble-container box'>
                {a.stripe_customers.map((sc) => (
                  <a target='_blank' rel='noopener noreferrer' href={stripeUrl('customers', sc)} className='customer-bubble bubble'>
                    <BubbleTitle type='stripe customer' />
                    <span className='bubble-line'>
                      <b>{sc.id}</b>
                    </span>
                    {sc.metadata.form_title && <span className='bubble-line'>{sc.metadata.form_title}</span>}
                    <span className='bubble-line'>{sc.email}</span>
                    <span className='bubble-line'>{sc.name}</span>
                    <ul className='bubble-line'>
                      subscriptions:
                      {sc.stripe_subscriptions.map((s2) => (
                        <li>
                          <a target='_blank' rel='noopener noreferrer' href={stripeUrl('subscriptions', s2)}>
                            <span className='bubble-line'>
                              {s2.id} - {s2.status}
                            </span>
                            {s2 && s2.items && s2.items.data && (
                              <>
                                {s2.items.data.map((item) => (
                                  <>
                                    {_.get(item, 'price.recurring') && (
                                      <span className='bubble-line'>
                                        {item.price.nickname} x {item.quantity} for ${item.price.unit_amount / 100} per {item.price.recurring.interval_count} {item.price.recurring.interval} each
                                      </span>
                                    )}
                                  </>
                                ))}
                              </>
                            )}
                            {s2.metadata && !(s2.metadata.seat_based === 'yes') && (
                              <span className='bubble-line'>providers covered: {s2.metadata.provider_ids ? _.uniq(s2.metadata.provider_ids).join(', ') : s2.metadata.provider_id}</span>
                            )}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </a>
                ))}
              </div>
            </>
          )}
        </div>
        <h2>Users</h2>
        <div className='user-bubble-container box'>
          {a.users &&
            a.users.map((u) => (
              <a href={`/users/view-with-mode/${u.id}/provider`} className='bubble' onMouseEnter={() => hover('user', u.id)} onMouseLeave={unhover} onBlur={unhover} style={getStyle('user', u)}>
                <BubbleTitle type='user' />
                <span className='bubble-line'>
                  <b>{u.email}</b>
                </span>
                <span className='bubble-line'>id: {u.id}</span>
                {u.full_name && <span className='bubble-line'>full name: {u.full_name}</span>}
                <ul className='bubble-line'>
                  roles:
                  {u.roles.map((r) => (
                    <li className='bubble-line'>
                      {r.name}
                      {r.scope && ` with access to ${r.scope.providers.toString().split(',').join(', ')}`}
                    </li>
                  ))}
                </ul>
              </a>
            ))}
        </div>
        <h2>Providers</h2>
        {a.providers.length > 0 && (
          <>
            {/*<span className='connection vertical' style={{ marginLeft: '100px' }}></span> */}
            <div className='user-bubble-container box'>
              {a.providers.map((p) => (
                <a
                  href={`/providers/view/${p.id}`}
                  className='bubble provider-bubble'
                  onMouseEnter={() => hover('provider', p.id)}
                  onMouseLeave={unhover}
                  onBlur={unhover}
                  style={getStyle('provider', p)}
                >
                  <BubbleTitle type='provider' />
                  <span className='bubble-line'>
                    <b>{providerName(p)}</b>
                  </span>
                  <span className='bubble-line'>id: {p.id}</span>
                  <span className='bubble-line'>
                    status: <span className={`bold ${color[p.status]}`}>{p.status}</span>
                  </span>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      moveToExistingAccount(p);
                    }}
                    className='primary sm pill m-y-sm'
                  >
                    Move to existing account
                  </button>{' '}
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setProviderBeingMoved(p);
                      setConfirmMoveModalData({
                        accountId: null,
                        message: `Are you sure you want to move provider id ${p.id} (${providerName(
                          p
                        )}) to its own account? You will need to attach a Stripe customer AND subscription in that stripe customer after this (if relevant).`,
                      });
                      toggleConfirmMoveModal();
                    }}
                    className='primary sm pill'
                  >
                    Create and move to new account
                  </button>
                  {p.provider_paid_seats && p.provider_paid_seats.filter((x) => x.canceled_at === null).length === 0 && (
                    <>
                      {getValidLegacyLinkedSubscriptionsForProvider(p).length === 0 ? (
                        <>
                          <div>
                            <button
                              disabled={a.available_subscription_seats <= 0}
                              onClick={(e) => {
                                e.preventDefault();
                                if (
                                  window.confirm(
                                    'Are you sure sure you want to let this provider use a paid seat? If so: Webhook is sent to Remanage to update # seats. No email to provider is triggered.'
                                  )
                                ) {
                                  addProviderPaidSeat({
                                    provider_id: p.id,
                                  });
                                }
                              }}
                              className='primary sm pill'
                            >
                              Mark Provider as Paid
                            </button>
                            {a.available_subscription_seats <= 0 && (
                              <p>To move this provider to a seat-based subscription, first create the seat-based subscription in Stripe or add seats to an existing subscription.</p>
                            )}
                          </div>{' '}
                        </>
                      ) : (
                        <div>
                          This provider is associated with a valid (not canceled or incomplete_expired) legacy subscription (
                          {getValidLegacyLinkedSubscriptionsForProvider(p).map((x) => (
                            <>
                              <a target='_blank' rel='noopener noreferrer' href={stripeUrlFromId('subscriptions', x.id)}>
                                {x.id}
                              </a>{' '}
                            </>
                          ))}
                          ) so cannot be assigned a seat at this time.
                        </div>
                      )}
                    </>
                  )}
                  {p.provider_paid_seats && p.provider_paid_seats.filter((x) => x.canceled_at === null).length > 0 && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        if (window.confirm("Are you sure sure? This frees up this provider's seat and makes them not paid for. Removing a seat does not send a cancellation email to the provider")) {
                          removeProviderPaidSeat({
                            provider_id: p.id,
                          });
                        }
                      }}
                      className='primary sm pill'
                    >
                      Remove Provider from Subscription
                    </button>
                  )}
                </a>
              ))}
            </div>
          </>
        )}
        <h2 id='feature-flags'>Feature Flags</h2>
        <h3>Account Feature Flags</h3>
        <GrantAccessWidget account={a} />
        <RevokeAccessWidget account={a} />
        {provider ? (
          <>
            <h3>Provider Feature Flags</h3>
            <AddProviderFeatureFlags provider={provider} />
            <RemoveProviderFeatureFlags provider={provider} />
          </>
        ) : null}
        <h2 id='user-roles'>User Roles</h2>
        <CurrentSpecialRoleUsers account={a} />
        <GrantSpecialRolesWidget account={a} provider={provider} />
        <RevokeSpecialRolesWidget account={a} provider={provider} />
        <h2>Account Event Logs</h2>
        <ul className='box'>
          {a.events.map((accountEvent) => (
            <li>
              <button
                className='m-r-sm'
                onClick={() => {
                  showEventDetails(accountEvent);
                }}
              >
                View Details
              </button>
              {formatDateAndTime(accountEvent.created_at)} <b>{accountEvent.type}</b>
            </li>
          ))}
        </ul>
        <h2>Account Subscription Events</h2>
        <table>
          <thead>
            <tr>
              <td>Date</td>
              <td>Type</td>
              <td>Provider Id</td>
              <td>Subscription Amount</td>
              <td>Setup Fee Amount</td>
              <td>Product Name (and lookup key)</td>
              <td>Setup Name (and lookup key)</td>
              <td>Add-On Amount, Add-On Name, and lookup key</td>
              <td>Coupon Id</td>
            </tr>
          </thead>
          <tbody>
            {a.subscription_events.map((subscriptionEvent) => (
              <tr>
                <td>{formatDateAndTime(subscriptionEvent.created_at)}</td>
                <td>{subscriptionEvent.type}</td>
                <td>{subscriptionEvent.provider_id}</td>
                <td>{subscriptionEvent.subscription_amount}</td>
                <td>{subscriptionEvent.setup_fee_amount}</td>

                <td>
                  {subscriptionEvent.product_name} {subscriptionEvent.product_lookup_key}
                </td>
                <td>
                  {subscriptionEvent.setup_option} {subscriptionEvent.setup_lookup_key}
                </td>
                <td>
                  {subscriptionEvent.add_on_amount ? '$' : ''}
                  {subscriptionEvent.add_on_amount} {subscriptionEvent.add_on}
                  {subscriptionEvent.add_on_lookup_key}
                  {subscriptionEvent.add_on_amount && subscriptionEvent.reimbursify_amount ? <br /> : ''}
                  {subscriptionEvent.reimbursify_amount ? '$' : ''}
                  {subscriptionEvent.reimbursify_amount} {subscriptionEvent.reimbursify_product_name}
                  {subscriptionEvent.reimbursify_lookup_key}
                </td>
                <td>{subscriptionEvent.coupon_id}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* @ts-ignore */}
      <MoveProviderToExistingAccountModal
        providerBeingMoved={providerBeingMoved}
        showModal={showMoveProviderToExistingAccountModal}
        toggleModal={toggleMoveProviderToExistingAccountModal}
        setConfirmMoveModalData={setConfirmMoveModalData}
        toggleConfirmMoveModal={toggleConfirmMoveModal}
      />
      {/* @ts-ignore */}
      <ConfirmMoveModal showModal={showConfirmMoveModal} toggleModal={toggleConfirmMoveModal} providerBeingMoved={providerBeingMoved} confirmMoveModalData={confirmMoveModalData} />
      <ChangeAccountOwnerModal
        users={a.users}
        showModal={showChangeAccountOwnerModal}
        toggleModal={toggleChangeAccountOwnerModal}
        toggleConfirmChangeOwnerModal={toggleConfirmChangeOwnerModal}
        setConfirmChangeOwnerModalMessage={setConfirmChangeOwnerModalMessage}
        setNewAccountOwnerUserId={setNewAccountOwnerUserId}
      />
      {/* @ts-ignore */}
      <ConfirmChangeOwnerModal
        accountId={a.id}
        showModal={showConfirmChangeOwnerModal}
        toggleModal={toggleConfirmChangeOwnerModal}
        newAccountOwnerUserId={newAccountOwnerUserId}
        confirmChangeOwnerModalMessage={confirmChangeOwnerModalMessage}
      />
      <RemoveSeatModal
        providers={a.providers}
        showModal={showRemoveSeatModal}
        toggleModal={toggleRemoveSeatModal}
        toggleShowConfirmRemoveSeatModal={toggleShowConfirmRemoveSeatModal}
        setConfirmRemoveSeatModalMessage={setConfirmRemoveSeatModalMessage}
        setPendingRemoveSeatParams={setPendingRemoveSeatParams}
      />
      {/* @ts-ignore */}
      <ConfirmRemoveSeatModal
        showModal={showConfirmRemoveSeatModal}
        toggleModal={toggleShowConfirmRemoveSeatModal}
        pendingRemoveSeatParams={pendingRemoveSeatParams}
        confirmRemoveSeatModalMessage={confirmRemoveSeatModalMessage}
      />
      <AddSeatModal
        providers={a.providers}
        showModal={showAddSeatModal}
        toggleModal={toggleAddSeatModal}
        toggleShowConfirmAddSeatModal={toggleShowConfirmAddSeatModal}
        setConfirmAddSeatModalMessage={setConfirmAddSeatModalMessage}
        setPendingAddSeatParams={setPendingAddSeatParams}
      />
      {/* @ts-ignore */}
      <ConfirmAddSeatModal
        showModal={showConfirmAddSeatModal}
        toggleModal={toggleShowConfirmAddSeatModal}
        pendingAddSeatParams={pendingAddSeatParams}
        confirmAddSeatModalMessage={confirmAddSeatModalMessage}
      />
      {/* @ts-ignore */}
      <StandalonePracticeManagementEditQuantityModal showModal={showStandalonePracticeManagementEditQuantityModal} toggleModal={toggleStandalonePracticeManagementEditQuantityModal} a={a} />
    </>
  );
};

export const AccountGraph = connect(() => ({}), {
  ...actions,
  ...featureFlagActions,
  // @ts-ignore
})(AccountGraphInner);

export default connect(
  (state) => ({
    overview: _.get(state, 'providers.accounts'),
  }),
  actions
)(AccountsTab);
