import React from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import DisplayProfilePic from './DisplayProfilePic';
import DisplayGallery from './DisplayGallery';
import DisplayCustomPhotos from './DisplayCustomPhotos';
import ToggleStateSwitch from '+/forms/ToggleStateSwitch';

export const ViewPhotos = (props) => {
  const {
    provider,
    fetchFn,
    updateFn,
    delete: deleteFn,
    setProfilePic,
    setGalleryVisibility,
  } = props;

  if (!props.provider) return null;

  function updateCTA(show) {
    updateFn({
      custom_content: {
        cta: {
          photovideoshoot: show,
        },
      },
    });
  }

  return (
    <div>
      <div className='field'>
        <h5 className='mb-sm'>Photo/Videoshoot CTA Display on Members</h5>
        <div className='mx-md'>
          <input
            className='checkbox'
            type='checkbox'
            id='photo-cta'
            checked={props.provider.custom_content.cta.photovideoshoot}
            onChange={(e) => updateCTA(e.target.checked)}
          />
          <label className='checkbox' htmlFor='photo-cta'>
            Photo/Videoshoot CTA Display on Members
          </label>
        </div>
      </div>
      <DisplayProfilePic provider={provider} createFn={setProfilePic} fetchFn={fetchFn} />
      <DisplayCustomPhotos provider={provider} updateFn={setGalleryVisibility} fetchFn={fetchFn} />
      <DisplayGallery
        fetchFn={fetchFn}
        provider={provider}
        deleteFn={deleteFn}
        updateFn={updateFn}
      />
      <DisplayGallery
        fetchFn={fetchFn}
        provider={provider}
        updateFn={updateFn}
        deleteFn={deleteFn}
        isProfilePics={true}
      />
    </div>
  );
};

export default connect(null, actions)(ViewPhotos);
