/* eslint-disable indent */
import { useCallback, useEffect, useReducer } from 'react';
import SortableJS from 'sortablejs';

const reducer = (state, action) => {
  const { unSorted, disabled } = state;

  switch (action.type) {
    case 'SET_UNSORTED':
      return {
        ...state,
        unSorted: { ...action.payload },
      };
    case 'ADD_UNSORTED':
      if (action.isNew) {
        action.payload.isNew = action.isNew;
      }
      if (action.isFirst) {
        const newUnsorted = Object.values(unSorted);
        newUnsorted.unshift(action.payload);

        return {
          ...state,
          unSorted: {
            ...newUnsorted,
          },
        };
      }

      return {
        ...state,
        unSorted: {
          ...unSorted,
          // Make the Key the index of the new Item
          [Object.values(unSorted).length]: action.payload,
        },
      };
    case 'DELETE_ONE_UNSORTED':
      delete unSorted[action.payload];
      const newUnsorted = Object.values(unSorted);

      return {
        ...state,
        unSorted: { ...newUnsorted },
      };
    case 'RESET_SORT':
      return {
        ...state,
        sorted: [],
      };
    case 'TOGGLE_DISABLE_SORT':
      return {
        ...state,
        disabled: !disabled,
      };
    case 'SORT':
      // Get a Array of Sorted elements with the 'index' as the handle
      const sortedElements = action.payload;

      const newSort = [];
      // Loop through Sorted Array
      while (sortedElements.length) {
        const handle = sortedElements.shift();
        newSort.push(unSorted[handle]);
      }

      return {
        ...state,
        sorted: newSort,
      };
    default:
      throw new Error('Unknown Action type');
  }
};
// Take in a Ref for the parent div that iterates through the unsorted array
const useSortable = (elementRef, initialUnSorted = []) => {
  const initialState = {
    // Convert the initial array into an Array of Objects for easier manipulation
    unSorted: { ...initialUnSorted },
    // An empty array, should only be full when a sort is in progress, after a sort is saved the `clearSort` function should be called
    sorted: [],
    // Determine weather the sort is disabled or not
    disabled: false,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { sorted, unSorted, disabled } = state;

  const onSort = useCallback(() => {
    // Sort through an array of unsorted items and grabs the handle from each
    // The handle should be the index of the item
    const sortedElements = Array.from(elementRef.current.children).map((el) =>
      el.getAttribute('handle')
    );

    dispatch({
      type: 'SORT',
      payload: sortedElements,
    });
  }, [elementRef]);

  // Instantiate the sortable Instance, save it to a property in state
  useEffect(() => {
    if (!elementRef.current) return;
    if (state.sortable) return;

    state.sortable = SortableJS.create(elementRef.current, {
      swapThreshold: 0.66,
      swap: true,
      swapClass: 'highlight',
      animation: 150,
      onEnd: onSort,
    });
  }, [onSort, elementRef, state.sortable]);

  // disabled sort based on the value of disabled
  useEffect(() => {
    state.sortable.option('disabled', disabled);
  }, [disabled, state.sortable]);

  // Keep unSorted State up to date with initialUnsorted Prop
  // If Disabled is true then we don't run this because a new item is in progress
  useEffect(() => {
    if (initialUnSorted.length !== Object.values(unSorted).length && !disabled) {
      dispatch({
        type: 'SET_UNSORTED',
        payload: initialUnSorted,
      });
    }
  }, [disabled, initialUnSorted, unSorted]);

  const toggleDisabled = () => dispatch({ type: 'TOGGLE_DISABLE_SORT' });
  const clearSort = () => dispatch({ type: 'RESET_SORT' });

  const setUnsorted = (payload) => {
    dispatch({
      type: 'SET_UNSORTED',
      payload,
    });

    return Object.values(unSorted);
  };

  const deleteOneUnsorted = (id) => {
    dispatch({
      type: 'DELETE_ONE_UNSORTED',
      payload: id,
    });

    return Object.values(unSorted);
  };
  // Adds a new Item with the properties sent from the payload, optionally includes a `isNew` property
  const addNewItem = (payload, isNew = false, isFirst = false) => {
    dispatch({
      type: 'ADD_UNSORTED',
      payload,
      isNew,
      isFirst,
    });

    return Object.values(unSorted);
  };

  return {
    sorted,
    unSorted: Object.values(unSorted),
    clearSort,
    setUnsorted,
    toggleDisabled,
    disabled,
    deleteOneUnsorted,
    addNewItem,
    sortable: state.sortable,
  };
};

export default useSortable;
