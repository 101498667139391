const validate = ({ meta_title, og_title, twitter_title }) => {
  const errors = {};

  if (!meta_title) {
    errors.meta_title = 'You must enter a Meta SEO Title';
  }
  if (!og_title) {
    errors.og_title = 'You must enter a Facebook SEO Title';
  }
  if (!twitter_title) {
    errors.twitter_title = 'You must enter a twitter SEO Title';
  }

  return errors;
};

export default validate;
