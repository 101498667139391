import AccountAttributesRoutes from '@/accounts/account-attributes/routes';
import AuthRoutes from '@/auth/routes';
import AreasRoutes from '@/areas/routes';
import ProvidersRoutes from '@/providers/routes';
import RedirectsRoutes from '@/redirects/routes';
import RegionsRoutes from '@/regions/routes';
import DeveloperToolsRoutes from '@/developer-tools/routes';
import ReportsRoutes from '@/reports/routes';
import SearchContentRoutes from '@/search-content/routes';
import InsuranceRulesRoutes from '@/insurance-rules/routes';
import TagsRoutes from '@/tags/routes';
import TherapyGroupsRoutes from '@/therapy-groups/routes';
import UserRoutes from '@/users/routes';
import DashboardRoutes from '@/dashboard/routes';
import BlacklistRoutes from '@/contact-blacklist/routes';
import AccountsRoutes from '@/accounts/routes';
import FeatureFlags from '@/feature-flags/routes';
import ReimbursifyInsurances from '@/reimbursify-insurances/routes';
import HomepageBlogPosts from './components/homepage-blog-posts/routes';

export default [
  ...AccountAttributesRoutes,
  ...AuthRoutes,
  ...AreasRoutes,
  ...AccountsRoutes,
  ...ProvidersRoutes,
  ...RedirectsRoutes,
  ...RegionsRoutes,
  ...TherapyGroupsRoutes,
  ...DashboardRoutes,
  ...UserRoutes,
  ...BlacklistRoutes,
  ...DeveloperToolsRoutes,
  ...ReimbursifyInsurances,
  ...ReportsRoutes,
  ...SearchContentRoutes,
  ...InsuranceRulesRoutes,
  ...FeatureFlags,
  ...HomepageBlogPosts,
  ...TagsRoutes,
];
