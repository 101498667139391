import React from 'react';
import Error from './Error';

const ToggleSwitch = (props) => {
  const { input, label, meta } = props;
  const className = `${props.className} field ${meta.error && meta.touched ? 'error' : ''}`;

  return (
    <div className={className}>
      <h5 className='my-sm'>{label}</h5>
      <input
        {...input}
        className='checkbox'
        type='checkbox'
        id={input.name}
        defaultChecked={input.value}
      />
      <label className='checkbox' htmlFor={input.name}>
        {label}
      </label>
      {props.labelRight && <h5 className='my-sm'>{props.labelRight}</h5>}
      <Error {...meta} />
    </div>
  );
};

export default ToggleSwitch;
