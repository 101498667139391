import specialRoleFriendlyNameConstants from './specialRoleFriendlyNameConstants';

const specialRoleFriendlyNameList = [
  specialRoleFriendlyNameConstants.intakeCoordinator,
  specialRoleFriendlyNameConstants.reimbursifyPractitioner,
  specialRoleFriendlyNameConstants.reimbursifyLeadPractitioner,
  specialRoleFriendlyNameConstants.reimbursifyAdministrator,
];

export default specialRoleFriendlyNameList;
