import React from 'react';
import CalendarView from '../CalendarView';
import AppointmentView from '../AppointmentView.jsx';
import GoogleCalendarStatus from '../google-calendar/GoogleCalendarStatus.jsx';

export default () => {
  return (
    <div>
      <GoogleCalendarStatus />
      <AppointmentView />
      <CalendarView />
    </div>
  );
};
