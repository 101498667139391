import React from 'react';

import { fieldHasError } from '#/forms';

const Error = (meta) => {
  if (fieldHasError(meta)) {
    return <small className='help text-error'>{meta.error}</small>;
  }

  return null;
};

export default Error;
