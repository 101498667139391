import React, { useEffect, useState } from 'react';
import { AccountGraph } from '../providers/components/tabs/accounts';
import { connect } from 'react-redux';
import { actions as accountActions } from './sagaSlice';
import { Link } from 'react-router-dom';
import AccountNotes from './AccountNotes';

export const View = (props) => {
  const { account, getAccountSummary, exportAccountIntakes, scope } = props;
  const { id } = props.match.params;
  const [status, setStatus] = useState('idle');

  useEffect(() => {
    getAccountSummary({
      id,
      setStatus,
    });
  }, [getAccountSummary, id]);

  if (status === 'loading') {
    return <h1>Loading...</h1>;
  }

  if (!account) {
    return null;
  }

  return (
    <>
      <div className='grid align-center justify-between'>
        <div className='column'>
          <h1>{account.name ? `${account.name}'s Account` : `Account #${account.id}`}</h1>
        </div>
        <div className='column' style={{ minWidth: '190px' }}>
          <Link to={`/account-attributes/filter/${account.id}`} className='button m-t-lg m-b-lg'>
            Account Attributes
          </Link>
        </div>
      </div>
      <hr />
      <h2>Account Notes</h2>
      <AccountNotes account={account} />
      <AccountGraph account={account} provider={null} />
      {/* TODO: Put this in AccountGraphInner */}
      <div className={`${['admin-hipaa', 'super-admin'].includes(scope) ? '' : 'hide'}`}>
        <hr />
        <div className='intake-export'>
          <h2>Account Referrals</h2>
          <div className='box'>
            <h3>Download Referral Data</h3>
            <p className='intake-export-desc'>Get a CSV of all referrals this account has ever received.</p>
            <p className='intake-export-text'>Note that all appointment times are indicated in UTC.</p>
            <button className='primary pill md' onClick={() => exportAccountIntakes({ account_id: account.id })}>
              Download CSV
            </button>
            <p className='intake-export-note'>
              <b>Note: </b>Do not upload this file to Google Drive. Delete immediately and empty your trash after use.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    ...state.accounts,
    ...state.auth,
  }),
  {
    getAccountSummary: accountActions.getAccountSummary,
    exportAccountIntakes: accountActions.exportAccountIntakes,
  }
)(View);
