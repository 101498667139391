import React, { useState, useEffect } from 'react';

const EditableText = (props) => {
  const {
    initialState,
    label,
    name,
    updateFn,
    custom_content,
    errorText = 'Error',
    custom_sessions,
    index,
    styles,
    validate = () => false,
    type = 'text',
  } = props;
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(initialState);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (validate) {
      validate(value) ? setError(errorText) : setError(null);
    }
  }, [errorText, validate, value]);

  useEffect(() => {
    // If the initial state changes update the current value
    setValue(initialState);
  }, [initialState]);

  const onSubmit = () => {
    const payload = {};
    if (custom_content) {
      payload.custom_content = custom_content;
      if (custom_sessions) {
        payload.custom_content.custom_sessions = custom_sessions;
        payload.custom_content.custom_sessions[index][name] = value;
      } else {
        payload.custom_content[name] = value;
      }
    } else {
      payload[name] = value;
    }
    updateFn(payload);
    setEdit(false);
  };

  if (edit) {
    return (
      <div className='field w-100 py-xs'>
        <div className='grid field w-100 align-center justify-around'>
          {label && <h4 className='mb-lg w-25'>{label}</h4>}
          <input
            type={type}
            value={value || ''}
            onChange={(e) => setValue(e.target.value)}
            className={`${label ? 'w-40' : 'w-60'} ${styles}`}
          />

          <div className='w-25 pl-sm grid'>
            <button className='success p-xs mr-sm' disabled={error} onClick={onSubmit}>
              <i className='fas fa-check' />
            </button>

            <button className='error p-xs' onClick={() => setEdit(false)}>
              <i className='fas fa-times' />
            </button>
          </div>
        </div>

        {error && (
          <div className='w-100 mt-md'>
            <small className='help text-error'>{error}</small>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className='grid w-100 align-center justify-around py-xs'>
        {label && <h4 className='mb-lg w-30'>{label}</h4>}

        <p className={`${label ? 'w-50' : 'w-75'} my-0 ${styles}`}>
          {value === 0 ? '0' : value || 'N/A'}
        </p>

        <div className={label && 'w-10'}>
          <button className='success p-xs' onClick={() => setEdit((edit) => !edit)}>
            <i className='fas fa-edit' />
          </button>
        </div>
      </div>
    );
  }
};

export default EditableText;
