import React, { useState } from 'react';
import AddableForm from '+/AddableForm';
import EditBox from '+/EditBox';
import _ from 'lodash';

export default (props) => {
  const [editingEd, setEditingEd] = useState(false);
  const [editingEmpl, setEditingEmpl] = useState(false);

  if (!props.education_v2 || !props.employment_v2) {
    return <></>;
  }

  const sortBy = (el) =>
    _.orderBy(
      el,
      (e) => {
        const endDate = _.get(e, 'when.end') || _.get(e, 'completion');

        if (['Present', 'present'].includes(endDate)) {
          return 9001;
        }

        if (!_.get(e, 'when.end') && !_.get(e, 'when.start') && !_.get(e, 'completion')) {
          return -1;
        }

        return endDate;
      },
      'desc'
    );

  const empv2 = sortBy(props.employment_v2);
  const eduv2 = sortBy(props.education_v2);

  return (
    <div>
      <div></div>
      <EditBox
        title='Work Experience (v2)'
        editable={true}
        editing={editingEmpl}
        setEditing={setEditingEmpl}
        innerEditing={
          <AddableForm
            name='employment_v2'
            onEdit={(values) => {
              const payload = {
                custom_content: {
                  employment_v2: values,
                },
              };
              props.updateFn(payload);
              setEditingEmpl(false);
            }}
            dataList={empv2.map((x) => ({
              ...x,
              deletable: true,
            }))}
            columns={employmentColumns}
            defaultNewItem={{
              employer: '',
              position: '',
              when: {
                start: '',
                end: '',
              },
            }}
            addText='Work Experience'
          />
        }
        innerStatic={
          <div className='egrid'>
            <h5> Employer</h5>
            <h5> Position</h5>
            <h5> Years</h5>
            {empv2.map((c) => (
              <>
                <h6 style={{ margin: 0 }}>{c.employer}</h6>
                <div>{c.position}</div>
                <div>
                  {c.when && (
                    <>
                      {c.when.start}
                      {c.when.start && c.when.end ? '-' : ''}
                      {c.when.end}
                    </>
                  )}
                </div>
              </>
            ))}
          </div>
        }
      />
      <EditBox
        title='Education & Training (v2)'
        editable={true}
        editing={editingEd}
        setEditing={setEditingEd}
        innerEditing={
          <AddableForm
            name='education_v2'
            onEdit={(values) => {
              const payload = {
                custom_content: {
                  education_v2: values,
                },
              };
              props.updateFn(payload);
              setEditingEd(false);
            }}
            dataList={eduv2.map((x) => ({
              ...x,
              deletable: true,
            }))}
            columns={educationColumns}
            defaultNewItem={{
              institution: '',
              degree: '',
              completion: '',
            }}
            addText='Education or Training'
          />
        }
        innerStatic={
          <div className='egrid'>
            <h5> Institution</h5>
            <h5> Degree or Certificate</h5>
            <h5> Completion Year</h5>
            {eduv2.map((c) => (
              <>
                <h6 style={{ margin: 0 }}> {c.institution}</h6>
                <div> {c.degree} </div>
                <div> {c.completion} </div>
              </>
            ))}
          </div>
        }
      />
    </div>
  );
};

const curYear = new Date().getUTCFullYear();
const yearOptions = ['', 'Present'].concat([...Array(70).keys()].map((x) => curYear - x));
const employmentColumns = [
  {
    header: 'Employer',
    key: 'employer',
    type: 'input',
    placeholder: 'Employer',
  },
  {
    header: 'Position',
    key: 'position',
    type: 'input',
    placeholder: 'Position',
  },
  {
    header: 'Start Year',
    key: 'when.start',
    type: 'select',
    options: yearOptions,
    placeholder: 'Start Year',
  },
  {
    header: 'End Year',
    key: 'when.end',
    type: 'select',
    options: yearOptions,
    placeholder: 'End Year',
  },
];

const educationColumns = [
  {
    header: 'Institution',
    key: 'institution',
    type: 'input',
    placeholder: 'Institution',
  },
  {
    header: 'Degree or Certificate',
    key: 'degree',
    type: 'input',
    placeholder: 'Degree or Certificate',
  },
  {
    header: 'Completion Year',
    key: 'completion',
    type: 'select',
    // without 'Present'
    options: [yearOptions[0]].concat(yearOptions.slice(2, yearOptions.length)),
    placeholder: 'Completion Year',
  },
];
