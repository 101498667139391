// @ts-check
import React from 'react';
import { actions } from './sagaSlice';
import { useDispatch } from 'react-redux';

function CheckClinicBookingPageStatus() {
  const dispatch = useDispatch();
  const [accountId, setAccountId] = React.useState('');

  const handleAccountIdChange = (e) => {
    const { value } = e.target;
    setAccountId(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      params: {
        accountId,
      },
    };

    // @ts-ignore
    dispatch(actions.checkClinicBookingPageStatus(payload));
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Check ZPM Clinic Booking Page Status</h2>
      <div>
        <label htmlFor='accountId'>Account ID:</label>
        <input type='text' name='accountID' id='accountId' value={accountId} onChange={handleAccountIdChange} placeholder='####' />

        <button className='primary'>Check status of booking page (check Network Tab of Chrome dev tools for result)</button>
      </div>
    </form>
  );
}

export default CheckClinicBookingPageStatus;
