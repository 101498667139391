import rgx from '#/regex';

const validate = (type) => (values) => {
  const errors = {};

  if (type === 'practice') {
    if (!values.company_name) {
      errors.company_name = 'You must enter a Group name';
    }

    return errors;
  }

  if (!values.first_name) {
    errors.first_name = 'You must enter a first name';
  }
  if (!values.last_name) {
    errors.last_name = 'You must enter a last name';
  }

  if (!rgx.slug.test(values.slug)) {
    errors.slug = 'Slug may not begin or end in a special character or contain spaces';
  }

  return errors;
};

export default validate;
