import React from 'react';

import Input from 'react-phone-number-input/input';

/**
 * @description PhoneInput can be used as a standalone OR as a wrapped component
 * in Input.jsx using the isphone prop. Use this input in either way according to
 * the architecture of surrounding inputs
 */
const PhoneInput = (props) => {
  const { input } = props;

  return <Input {...props} {...input} country='US' />;
};

export default PhoneInput;
