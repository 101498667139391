import React from 'react';
import get from 'lodash/get';
import Input from '+/forms/Input';
import Error from '+/forms/Error';
import { connect } from 'react-redux';
import formValidation from './formValidation';
import { Field, Form } from 'react-final-form';

export const AreaForm = (props) => {
  const { onCancel, onSubmit, cities, initialValues = {} } = props;

  const renderTypeDropdown = ({ input, label, meta }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;

    return (
      <div className={className}>
        <label>{label}</label>
        <select {...input}>
          <option value='' />
          <option value='city'>City</option>
          <option value='neighborhood'>Neighborhood</option>
        </select>
        <Error {...meta} />
      </div>
    );
  };

  const renderParentCityDropdown = ({ input, label, meta, disableOpt }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;

    return (
      <div className={className}>
        <label>{label}</label>
        <select {...input} disabled={disableOpt}>
          <option value='' />
          {(cities || []).map((city) => (
            <option key={city.id} value={city.id}>
              {city.name}
            </option>
          ))}
        </select>
        <Error {...meta} />
      </div>
    );
  };

  return (
    <Form
      initialValues={initialValues}
      validate={formValidation}
      onSubmit={onSubmit}
      render={({ handleSubmit, invalid, values }) => (
        <form onSubmit={handleSubmit}>
          <Field name='name' component={Input} label='Enter Name' />
          <Field name='slug' component={Input} label='Enter Slug' />
          <Field name='type' component={renderTypeDropdown} label='Select Area type' />
          <Field
            name='parent_id'
            component={renderParentCityDropdown}
            label='Select Parent City (For Neighborhoods)'
            disableOpt={values.type !== 'neighborhood'}
          />

          <div className='grid justify-between field'>
            <button type='button' onClick={onCancel} className='error'>
              Cancel
            </button>

            <button disabled={invalid} className='success'>
              Submit
            </button>
          </div>
        </form>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  const cities = Object.values(state.areas.data).filter((area) => area.type === 'city');

  const type = get(state.form, 'areaForm.values.type', 'city');
  const disableOpt = type === 'city';

  return {
    cities,
    disableOpt,
  };
};

export default connect(mapStateToProps)(AreaForm);
