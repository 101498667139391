import { createModule } from 'saga-slice';
import { failReducer, loadingReducer, noop } from 'saga-slice-helpers';
import { put } from 'redux-saga/effects';
import { flashSuccess } from '+/flashes/redux';

import { sagaApi } from '#/apis';

const sagaSliceModule = createModule({
  name: 'searchContent',
  initialState: {
    currentSearchContentOverride: null,
    searchContentOverrides: [],
  },
  reducers: {
    getSearchContentOverrides: loadingReducer,
    getSearchContentOverridesSuccess: (state, payload) => {
      state.searchContentOverrides = payload;
    },
    getSearchContentOverridesFail: failReducer,

    addSearchContentOverride: loadingReducer,
    addSearchContentOverrideSuccess: noop,
    addSearchContentOverrideFail: failReducer,

    getSearchContentOverrideById: loadingReducer,
    getSearchContentOverrideByIdSuccess: (state, payload) => {
      state.currentSearchContentOverride = payload;
    },
    getSearchContentOverrideByIdFail: failReducer,

    saveSearchContentOverride: loadingReducer,
    saveSearchContentOverrideSuccess: noop,
    saveSearchContentOverrideFail: failReducer,
  },
  sagas: (A) => ({
    [A.getSearchContentOverrides]: {
      *saga() {
        yield sagaApi.get('/search-content-overrides', A.getSearchContentOverridesSuccess, A.getSearchContentOverridesFail);
      },
    },
    [A.getSearchContentOverrideById]: {
      *saga({ payload }) {
        yield sagaApi.get(`/search-content-overrides/${payload.id}`, A.getSearchContentOverrideByIdSuccess, A.getSearchContentOverrideByIdFail);
      },
    },
    [A.addSearchContentOverride]: {
      *saga({ payload }) {
        yield sagaApi.post('/search-content-overrides/add', payload.data, A.addSearchContentOverrideSuccess, A.addSearchContentOverrideFail);

        payload.callback();
      },
    },
    [A.saveSearchContentOverride]: {
      *saga({ payload }) {
        yield sagaApi.put(`/search-content-overrides/${payload.id}`, payload.data, A.saveSearchContentOverrideSuccess, A.saveSearchContentOverrideFail);
        yield put(flashSuccess('Saved!'));
        if (payload.callback) {
          payload.callback();
        }
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
