import React, { useEffect } from 'react';
import Pagination from '~/shared/Pagination';
import useOrderBy from '+/hooks/useOrderBy';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import Column from '+/Column';
import utils from '#/';

export const RegionsList = (props) => {
  const { regions, total, readAll, searchRegions } = props;

  const {
    order,
    orderBy,
    ordered: orderedRegions,
    handleColumnChange,
  } = useOrderBy(regions, 'name');

  useEffect(() => {
    readAll({
      order,
      orderBy,
    });
  }, [order, orderBy, readAll]);

  const handleSearch = ({ target }) => utils.debounce(() => searchRegions(target.value));

  return (
    <>
      <div className='grid align-center justify-between'>
        <h1 className='inline-block'>Regions</h1>

        <Link to='/regions/add' className='button primary' title='Add New Region'>
          <i className='fas fa-plus' />
        </Link>
      </div>
      <div className='border border-grey-lightest radius-xs scroll'>
        <input
          type='text'
          className='block w-100'
          placeholder={'Filter Regions...'}
          onKeyUp={handleSearch}
        />

        <div className='bg-grey-dark text-white grid'>
          <div className='p-md w-10'>
            <b>Id</b>
          </div>
          <Column title='Name' field='name' width='40' onChange={handleColumnChange} />
          <Column title='Slug' field='slug' width='40' onChange={handleColumnChange} />
        </div>

        {orderedRegions.map((region, i) => (
          <div className={`${utils.tableClass(i)} grid align-center`} key={region.id}>
            <div className='p-md w-10'>{region.id}</div>
            <div className='p-md w-40'>
              <Link to={`/regions/view/${region.id}`}>{region.name}</Link>
            </div>
            <div className='p-md w-40'>{region.slug}</div>
          </div>
        ))}
        <Pagination total={total} fetchFn={(page) => readAll({ page })} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  regions: Object.values(state.regions.data || {}) || [],
  total: state.regions.total,
});

export default connect(mapStateToProps, actions)(RegionsList);
