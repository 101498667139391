import React from 'react';
import { onlyOnEnter } from '#/';

const OmniModal = (props) => {
  const { onDismiss, title, children } = props;

  return (
    <div className='fixed w-100vw h-100vh pin-top pin-left grid align-center justify-center z-150'>
      <div id='overlay' tabIndex={0} role='menuitem' className='show' onClick={onDismiss} onKeyDown={onlyOnEnter(onDismiss)} />

      <div id='modal' className='modal fixed omni-modal'>
        <div id='close' tabIndex={0} role='menuitem' onClick={onDismiss} className='cursor-pointer' onKeyDown={onlyOnEnter(onDismiss)}>
          <i className='fas fa-times' />
        </div>
        <header>
          <h3>{title}</h3>
        </header>
        <section style={{ height: 'auto' }} className='content scroll-y'>
          {children}
        </section>
      </div>
    </div>
  );
};

export default OmniModal;
