/* eslint-disable max-lines-per-function */
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import { Link } from 'react-router-dom';

import diff from 'object-diff';
import { displayDate } from '#/';
import ProviderAssociation from './ProviderAssociation';
import Remove from '+/RemoveButton';
import UserForm from './Form';
import AppointmentList from './AppointmentList.jsx';
import { formatPhoneNumber } from '~/utils/formatPhone';
import UserAccountBlocks from './UserAccountBlocks.jsx';

export const ViewUser = (props) => {
  const {
    auth,
    current: user,
    readOne,
    accounts,
    delete: deleteUser,
    redact,
    lockUser,
    unlockUser,
    twoFactorEnroll,
    twoFactorUnenroll,
    update,
    requestPasswordReset,
    confirmAccount,
    match = { params: {} },
  } = props;

  const { id, mode } = match.params;
  const [edit, setEdit] = useState(props.edit || false);

  const fetchUser = useCallback(() => readOne(id), [readOne, id]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const onSubmit = async (values) => {
    ['first_name', 'middle_name', 'last_name', 'company_name', 'full_name'].forEach((k) => {
      if (values[k] === null || values[k] === undefined) {
        values[k] = '';
      }

      try {
        values[k] = values[k].trim();
      } catch (e) {}
    });

    await update({
      id,
      changeset: diff(user, values),
    });
    setEdit(() => false);
  };

  const resetPassword = () => {
    const assertReset = window.confirm(`This will send an email notification with a reset link to ${user.email}. Are you sure?`);

    if (assertReset) {
      requestPasswordReset({ id });
    }
  };

  const handleConfirm = () => {
    const assertReset = window.confirm(`This will confirm ${user.email}'s account without email verification. Are you sure?`);

    if (assertReset) {
      confirmAccount({ id });
      fetchUser();
    }
  };

  if (!user) return null;

  const locked = user.locked_at;

  if (edit) {
    return <UserForm initialValues={user} onCancel={() => setEdit(false)} onSubmit={onSubmit} />;
  }

  const shareLink = `${process.env.ZENCARE_MEMBERS_URL}/signup?referrer=${encodeURIComponent(btoa(user.email))}`;

  const onCopy = () => {
    const copyText = document.getElementById('copyInput');
    copyText.select();
    document.execCommand('copy');
  };

  return (
    <div>
      <div className='grid justify-between align-center'>
        <div className='grid align-center'>
          <h1 className='mr-lg'>{user.username}</h1>
          <h3 className='text-grey-lighter mr-lg'>{user.role}</h3>
        </div>
        <div>
          <Link to={`/users/view/${id}/email-log/${mode}`}>
            <button className='success mr-sm hollow'>View emails</button>
          </Link>
          <button className='success mr-sm' onClick={() => setEdit(true)}>
            <i className='fas fa-edit' />
          </button>
          <Remove className='error' name={user.username} onClick={() => deleteUser(user.id)} />
          {['super-admin'].includes(auth.scope) && user.role === 'client' ? (
            <Remove doubleassert='true' className='error m-l-sm m-t-sm' name={user.username} onClick={() => redact({ user_id: user.id })}>
              Purge Client User Data
            </Remove>
          ) : (
            ''
          )}
        </div>
      </div>

      <hr className='my-md' />

      <div className='grid my-lg'>
        <div className='card cursor-default p-lg mr-md w-50'>
          <h2 className='mb-lg'>User Info:</h2>
          {user.redacted ? (
            <p className='mt-0 mb-sm text-error'>
              <b>Redaction Status:</b> This user's data has been redacted to remove all identifying information and messages.
            </p>
          ) : (
            ''
          )}
          <p className='mt-0 mb-sm'>
            <b>Email:</b> {user.email}
          </p>
          <p className='mt-0 mb-sm'>
            <b>First name:</b> {user.first_name}
          </p>
          <p className='mt-0 mb-sm'>
            <b>Middle name:</b> {user.middle_name}
          </p>
          <p className='mt-0 mb-sm'>
            <b>Last name:</b> {user.last_name}
          </p>
          <p className='mt-0 mb-sm'>
            <b>Company name:</b> {user.company_name}
          </p>
          {locked && (
            <div className='my-lg'>
              <h5>Account locked At {displayDate(user.locked_at)}</h5>
            </div>
          )}
          <div className='grid justify-between w-50'>
            <button onClick={() => (locked ? unlockUser({ id }) : lockUser({ id }))}>{locked ? 'Unlock User' : 'Lock User'}</button>
            <button onClick={resetPassword}>Reset Password</button>
            {!user.confirmed_at && <button onClick={handleConfirm}>Confirm Account</button>}
          </div>

          {user.role !== 'client' && (
            <div className='grid justify-between w-50 m-t-md'>
              {user.user_two_factor_enrollment ? (
                <>
                  <button onClick={() => twoFactorUnenroll({ id })}>Disable 2FA</button>
                  <div>2FA is enabled</div>
                </>
              ) : (
                <>
                  <button onClick={() => twoFactorEnroll({ id })}>Enable 2FA</button>
                  <div>2FA not enabled</div>
                </>
              )}
            </div>
          )}
        </div>

        <div className='card cursor-default p-lg mr-md w-50'>
          <h2 className='mb-lg'>User session info:</h2>
          <p className='mb-md'>
            <b>Confirmed at:</b> {user.confirmed_at ? displayDate(user.confirmed_at) : 'User has not been confirmed'}
          </p>
          <p className='mb-md'>
            <b>Last login:</b> {user.last_login ? displayDate(user.last_login) : 'User has not logged in'}
          </p>
          <p className='mb-md'>
            <b>Last logout:</b> {user.last_logout ? displayDate(user.last_logout) : 'User has not logged out'}
          </p>
          <p className='mb-md'>
            <b>Created at:</b> {user.created_at ? displayDate(user.created_at) : 'User has not been created'}
          </p>
          <p className='mb-md'>
            <b>Updated at:</b> {user.updated ? displayDate(user.updated) : 'User has not been updated'}
          </p>
        </div>
      </div>
      {user.phone_number && (
        <p className='mb-md'>
          <b>User Phone Number: </b>
          {formatPhoneNumber(user.phone_number)}
        </p>
      )}
      {user.role === 'provider' && (
        <>
          <p className='mb-md flex'>
            <b className='mr-md'>User referral link:</b>
            <input className='mr-md' style={{ width: 300 }} id='copyInput' type='input' readonly='' value={shareLink} />
            <button onClick={onCopy} className='mr-md primary sm'>
              Copy link
            </button>
          </p>
          <div style={{ display: 'flex' }}>
            <ProviderAssociation user={user} userAccounts={accounts} />
          </div>
        </>
      )}
      <UserAccountBlocks user={user} />
      {mode === 'all' && <AppointmentList />}
    </div>
  );
};

export default connect(
  (state) => ({
    allAccounts: state.accounts,
    ...state.users,
    auth: state.auth,
  }),
  actions
)(ViewUser);
