import React from 'react';
import TUIEditor from './TUIEditor';
const chars = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];

const PlainEditor = ({ height, initialValue, onChange, onBlur }) => {
  // Generate Random Id for Editor
  const id = [1, 2, 3, 4, 5].map(() => chars[(Math.random() * chars.length) | 0]).join``;

  return (
    <TUIEditor
      id={id}
      height={height}
      value={initialValue}
      onChange={onChange}
      onBlur={onBlur}
      debounce={100}
    />
  );
};

export default PlainEditor;
