import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import utils from '#/';

function TableHeader() {
  return (
    <div className='bg-grey-dark text-white grid'>
      <div className='p-md w-10'>
        <b className='mr-sm'>Id</b>
      </div>
      <div className='p-md w-30'>
        <b className='mr-sm'>Insurance</b>
      </div>
      <div className='p-md w-20'>
        <b className='mr-sm'>Rule exists?</b>
      </div>
      <div className='p-md w-30'>
        <b className='mr-sm'>Edit Rule</b>
      </div>
    </div>
  );
}

const tableClass = (i) => (i % 2 === 0 ? 'bg-grey-lightest' : 'bg-white');

export const InsuranceRulesTable = (props) => {
  const { insurancesWithParents } = props;

  const [search, setSearch] = useState('');

  const filteredData = () => {
    // When searching also check parent tags so you can search for name of parent and see all children
    if (search && search.length) {
      return insurancesWithParents.filter(
        (x) =>
          _.includes(x.name.toUpperCase(), search.toUpperCase()) ||
          _.includes(
            x.parentTags ? x.parentTags.map((x) => x.name).join() : '',
            search.toUpperCase()
          )
      );
    }

    return insurancesWithParents;
  };

  const handleSearch = ({ target }) => utils.debounce(() => setSearch(target.value));

  const renderParentTagButtons = (tag) =>
    tag.parentTags.map((parentTag) => (
      <Link
        key={`parent-tag-id-${parentTag.id}`}
        to={`/insurance-rules/edit/${parentTag.id}`}
        className='button primary m-r-sm edit-rule-grid-button'
      >
        Edit {parentTag.name}
      </Link>
    ));

  const TableRow = (props) => {
    const { tag, i } = props;

    return (
      <>
        <div className={`${tableClass(i)} grid align-center data`}>
          <div className='p-md w-10'>{tag.id}</div>
          <div className='p-md w-30'>{tag.name}</div>
          <div className='p-md w-20'>
            <div className='rule-exists-note'>
              {tag.directRuleExists ? (
                <>
                  Yes
                  <br />
                  {tag.name}
                </>
              ) : (
                ''
              )}
            </div>
            <div className='rule-exists-note'>
              {tag.isSubInsurance ? (
                <>
                  {tag.directRuleExists ? '' : <>Yes</>}
                  <br />
                  {tag.parentTags.map((x) => x.name).join(', ')}
                </>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className='p-md w-30'>
            {tag.directRuleExists && (
              <Link
                to={`/insurance-rules/edit/${tag.id}`}
                className='button primary m-r-sm edit-rule-grid-button'
              >
                Edit
              </Link>
            )}
            {tag.isSubInsurance && <>{renderParentTagButtons(tag)}</>}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className='border border-grey-lightest radius-xs scroll insurance-rules-table'>
        <input
          type='text'
          className='block w-100'
          placeholder='Filter insurances'
          onKeyUp={handleSearch}
        />
        <TableHeader />
        {filteredData().map((tag, i) => (
          <TableRow tag={tag} i={i} key={tag.id} />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  insurancesWithParents: state.insuranceRules.insurancesWithParents,
});

export default connect(mapStateToProps, actions)(InsuranceRulesTable);
