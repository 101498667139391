/* eslint-disable max-lines-per-function */
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from '../sagaSlice';
import BoolToggle from '+/forms/BoolToggle';
import EditableTextArea from '+/forms/EditableTextArea';

function FormatDate({ date }) {
  // Note: Migrated feedback won't have a sent_to_provider_at date set.
  if (date) {
    const dateString = dayjs(date).format('MMM, D, YYYY');

    return <>{dateString}</>;
  }

  return '--';
}

function TableHeader() {
  return (
    <div className='bg-grey-dark text-white grid'>
      <div className='p-md' style={{ width: '5rem' }}>
        <b className='mr-sm'>Id</b>
      </div>
      <div className='p-md w-10'>
        <b className='mr-sm'>Type</b>
      </div>
      <div className='p-md w-10'>
        <b className='mr-sm'>Rating</b>
      </div>
      <div className='p-md w-20'>
        <b className='mr-sm'>Testimonial</b>
      </div>
      <div className='p-md w-10'>
        <b className='mr-sm' style={{ whiteSpace: 'nowrap' }}>
          Display *
        </b>
      </div>
      <div className='p-md w-20'>
        <b className='mr-sm'>Feedback</b>
      </div>
      <div className='p-md w-10'>
        <b className='mr-sm'>Submitted</b>
      </div>
      <div className='p-md w-10'>
        <b className='mr-sm'>Sent to provider</b>
      </div>
      <div className='p-md' style={{ width: '6rem' }}>
        <b className='mr-sm' style={{ whiteSpace: 'nowrap' }}>
          Ignore **
        </b>
      </div>
    </div>
  );
}

export const ClientFeedback = (props) => {
  const {
    provider,
    providerFeedbackList,
    getProviderFeedback,
    updateTestimonialDisplay,
    updateProviderFeedbackIgnored,
    updateTestimonial,
    updateFeedback,
  } = props;

  const [providerFeedbackLoaded, setProviderFeedbackLoaded] = useState(false);

  useEffect(() => {
    if (provider && provider.id && !providerFeedbackLoaded) {
      setProviderFeedbackLoaded(true);
      getProviderFeedback({ provider_id: provider.id });
    }
  }, [provider, provider.id, providerFeedbackLoaded, getProviderFeedback]);

  const testimonialDisplayChanged = (dto, property, newValue) => {
    updateTestimonialDisplay({
      provider_id: provider.id,
      provider_feedback_id: dto.id,
      display_testimonial: newValue,
    });
  };

  const ignoredChanged = (dto, property, newValue) => {
    updateProviderFeedbackIgnored({
      provider_id: provider.id,
      provider_feedback_id: dto.id,
      ignored: newValue,
    });
  };

  const editTestimonialText = (payload, dto) => {
    updateTestimonial({
      provider_id: provider.id,
      provider_feedback_id: dto.id,
      testimonial: payload.testimonial,
    });
  };

  const editFeedbackText = (payload, dto) => {
    updateFeedback({
      provider_id: provider.id,
      provider_feedback_id: dto.id,
      feedback: payload.feedback,
    });
  };

  const ratingToText = {
    1: 'Terrible',
    2: 'Bad',
    3: 'Okay',
    4: 'Great',
    5: 'Excellent',
  };

  const renderMetabaseLink = (dto) => {
    switch (dto.contact_type) {
      case 'contact-form': {
        return (
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={`https://metabase.zctools.de/question/213-contact-form-provider-feedback-client-contact-lookup?provider_contact_id=${dto.id}`}
          >
            {dto.id}
          </a>
        );
      }
      case 'zencare-appointment': {
        return (
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={`https://metabase.zctools.de/question/1213-booking-tool-provider-feedback-client-contact-lookup?provider_contact_id=${dto.id}`}
          >
            {dto.id}
          </a>
        );
      }
      case 'phone': {
        return (
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={`https://metabase.zctools.de/question/212-acuity-provider-feedback-client-contact-lookup?provider_contact_id=${dto.id}`}
          >
            {dto.id}
          </a>
        );
      }
      default: {
        return dto.id;
      }
    }
  };

  return (
    <div className='card cursor-default p-lg mx-md my-lg'>
      <div className='grid justify-between align-center'>
        <div>
          <h3 className='mb-lg'>Client Testimonials &amp; Feedback:</h3>
          <p>
            * Display = Display testimonial publicly on the provider provider - changing display
            should only be done with permission of the provider.
            <br />
            ** Ignore = If a row is ignored the feedback and testimonial in it will not be shown to
            providers or publicly on the site.
          </p>
        </div>
      </div>
      <hr />
      <div className='border border-grey-lightest radius-xs scroll'>
        <TableHeader />
        {providerFeedbackList.map((dto, i) => (
          <div className={`grid align-center ${dto.ignored ? 'bg-grey-light' : ''}`} key={dto.id}>
            <div className='p-md' style={{ width: '5rem' }}>
              {renderMetabaseLink(dto)}
            </div>
            <div className='p-md w-10'>
              {dto.contact_type} / {dto.feedback_type}
            </div>
            <div className='p-md w-10'>
              {dto.feedback_type === 'initial'
                ? dto.provider_responded
                  ? 'Heard from provider'
                  : 'Did not hear from provider'
                : ratingToText[dto.rating]}
            </div>
            <div className='p-md w-20'>
              {dto.submitted_at ? (
                <div>
                  <EditableTextArea
                    label=''
                    name='testimonial'
                    updateFn={(payload) => {
                      editTestimonialText(payload, dto);
                    }}
                    initialState={dto.testimonial}
                  />
                  <div>
                    {/* Show legacy author field if set */}
                    {dto.author ? <span>- {dto.author}</span> : null}
                  </div>
                </div>
              ) : (
                '--'
              )}
            </div>

            <div className='p-md w-10'>
              {(dto.testimonial && dto.testimonial.length) || dto.display_testimonial ? (
                <BoolToggle
                  id={dto.id}
                  setValue={testimonialDisplayChanged}
                  objectUnderEdit={dto}
                  property='display_testimonial'
                />
              ) : null}
            </div>

            <div className='p-md w-20'>
              {dto.submitted_at ? (
                <EditableTextArea
                  label=''
                  name='feedback'
                  updateFn={(payload) => {
                    editFeedbackText(payload, dto);
                  }}
                  initialState={dto.feedback}
                />
              ) : (
                '--'
              )}
            </div>

            <div className='p-md w-10'>
              {dto.submitted_at ? (
                <FormatDate date={dto.submitted_at} />
              ) : (
                <FormatDate date={dto.updated_at} />
              )}
            </div>

            <div className='p-md w-10'>
              {dto.sent_to_provider ? (
                <FormatDate date={dto.sent_to_provider_at} />
              ) : dto.submitted_at ? (
                <div>(not sent yet)</div>
              ) : (
                <div>---</div>
              )}
            </div>

            <div className='p-md' style={{ width: '6rem' }}>
              {dto.submitted_at && (
                <BoolToggle
                  id={dto.id}
                  setValue={ignoredChanged}
                  objectUnderEdit={dto}
                  property='ignored'
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  providerFeedbackList: state.providers.providerFeedbackList || [],
});

export default connect(mapStateToProps, actions)(ClientFeedback);
