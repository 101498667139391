import DefaultLayout from '##/Default';
import InsuranceRules from './InsuranceRules';
import EditInsuranceRule from './EditInsuranceRule';

export default [
  {
    path: '/insurance-rules',
    exact: true,
    layout: DefaultLayout,
    component: InsuranceRules,
    auth: true,
    title: 'Insurance Rules',
  },
  {
    path: '/insurance-rules/edit/:tagId',
    exact: true,
    layout: DefaultLayout,
    component: EditInsuranceRule,
    auth: true,
    title: 'Edit Insurance Rule',
  },
];
