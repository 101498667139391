import React, { useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { actions } from '../sagaSlice';

const formatDateAndTime = (date) => (date ? dayjs(date).format('MMMM DD, YYYY h:mm A') : 'null');
const showEventDetails = (providerEvent) => alert(JSON.stringify(providerEvent.data));

function ProviderEventLog({ provider, providerEventLogs, getProviderEventLogs }) {
  const fetchFn = useCallback(() => {
    getProviderEventLogs({ provider_id: provider.id });
  }, [provider, getProviderEventLogs]);

  useEffect(fetchFn, [fetchFn]);

  return (
    <div>
      <h2>Provider Event Logs</h2>
      {providerEventLogs && providerEventLogs.length ? (
        <ul className='box'>
          {providerEventLogs.map((providerEvent) => (
            <li key={JSON.stringify(providerEvent.data)}>
              <button
                className='m-r-sm'
                onClick={() => {
                  showEventDetails(providerEvent);
                }}
              >
                View Details
              </button>
              {formatDateAndTime(providerEvent.created_at)} <b>{providerEvent.type}</b>
            </li>
          ))}
        </ul>
      ) : (
        'Loading...'
      )}
    </div>
  );
}

export default connect(
  (state) => ({
    providerEventLogs: state.providers.providerEventLogs,
  }),
  actions
)(ProviderEventLog);
