import React, { useState } from 'react';
import UnregisteredProviderForm from './UnregisteredProviderForm';
import Modal from '+/Modal';
import { onlyOnEnter } from '#/';

const UnregisteredProvider = (props) => {
  const { provider = {}, disassociate, updateUnregisteredProvider, handle, toggleDisabled } = props;
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal((e) => !e);
    toggleDisabled();
  };
  const onSubmit = (payload) => {
    updateUnregisteredProvider(payload);
    toggleModal();
  };

  const renderModal = () => {
    if (showModal) {
      return (
        <Modal title='Edit New Provider (unregistered)' onDismiss={toggleModal}>
          <UnregisteredProviderForm
            provider={provider}
            onSubmit={onSubmit}
            onCancel={toggleModal}
          />
        </Modal>
      );
    }
  };

  return (
    <>
      {renderModal()}
      <div handle={handle} className='grid label primary align-center justify-between mr-xs mb-xs'>
        <h5 className='mb-0 mt-0'>
          {provider.first_name} {provider.last_name}
        </h5>
        <div
          role='menuitem'
          className='pl-md'
          tabIndex={0}
          onClick={toggleModal}
          onKeyDown={onlyOnEnter(toggleModal)}
        >
          <i className='fas fa-edit' />
        </div>
        <div
          role='menuitem'
          className='px-md'
          tabIndex={0}
          onKeyDown={onlyOnEnter(() => disassociate(provider))}
          onClick={() => disassociate(provider)}
        >
          <i className='fas fa-times' />
        </div>
      </div>
    </>
  );
};

export default UnregisteredProvider;
