import DefaultLayout from '##/Default';
import List from './List';
import Add from './Add';
import View from './View';

export default [
  {
    path: '/:plural',
    exact: true,
    layout: DefaultLayout,
    component: List,
    title: 'View All Tags',
  },
  {
    path: '/:plural/add',
    exact: true,
    layout: DefaultLayout,
    component: Add,
    title: 'Add New Tags',
  },
  {
    path: '/:plural/view/:id',
    exact: true,
    layout: DefaultLayout,
    component: View,
    title: 'View One Tag',
  },
];
