import React from 'react';
import { Form, Field } from 'react-final-form';
import { prefixImage } from '#/';
import _ from 'lodash';

export default ({ provider, onSubmit }) => (
  <Form
    initialValues={{ profile: _.get(provider, 'image.handle') }}
    initialValuesEqual={() => true}
    onSubmit={onSubmit}
    render={({ handleSubmit, pristine, valid, values }) => (
      <div className='profile-select-modal-interior'>
        <div className='gallery'>
          {_.get(provider, 'gallery.images', [])
            .filter((p) => p.profile_ar)
            .map((p) => (
              <label htmlFor={p.handle} key={p.handle} className='image-selector'>
                <Field
                  name='profile'
                  value={p.handle}
                  type='radio'
                  component='input'
                  id={p.handle}
                />
                <img
                  className={`handle gallery-photo border-primary m-b-xs ${
                    values.profile === p.handle ? '' : 'o-3'
                  }`}
                  alt='1'
                  src={`${prefixImage(_.get(p, 'key'))}?w=400&h=400&fit=crop&crop=faces`}
                ></img>
              </label>
            ))}
        </div>
        <div className='actions'>
          {!pristine && !valid ? (
            <button className='primary' onClick={handleSubmit} disabled>
              Submit
            </button>
          ) : (
            <button className='primary' onClick={handleSubmit}>
              Submit
            </button>
          )}
        </div>
      </div>
    )}
  />
);
