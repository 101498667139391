import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions } from '../../sagaSlice';
import dayjs from 'dayjs';

const ReferralsTab = (props) => {
  const { provider, metabaseEmbedUrls, generateMetabaseEmbedUrls, auth } = props;

  // Key is question id, value is height iframe should be
  const iframeHeightDictionary = {
    // Referrals by month
    186: '300px',

    // Availability by month
    96: '300px',

    // Insurance by month
    94: '300px',

    // Status by month
    95: '300px',

    // Client referrals
    354: '500px',
  };

  const [referralsLoading, setReferralsLoading] = useState(false);

  useEffect(() => {
    if (!referralsLoading && provider && auth && ['admin', 'admin-hipaa', 'super-admin'].includes(auth.scope)) {
      setReferralsLoading(true);

      const referralsByMonth = {
        questionId: 186,
        params: {
          provider_id: provider.id,
          date: dayjs().subtract(7, 'month').format('YYYY-MM-DD'),
        },
      };

      const availabilityByMonth = {
        questionId: 96,
        params: {
          id: provider.id,
        },
      };

      const insuranceStatusByMonth = {
        questionId: 94,
        params: {
          id: provider.id,
        },
      };

      const profileStatusByMonth = {
        questionId: 95,
        params: {
          id: provider.id,
        },
      };

      const clientReferrals = {
        questionId: 354,
        params: {
          provider_id: provider.id,
        },
      };

      generateMetabaseEmbedUrls({
        questionParams: [referralsByMonth, availabilityByMonth, insuranceStatusByMonth, profileStatusByMonth, clientReferrals],
      });
    }
  }, [provider, auth, generateMetabaseEmbedUrls, referralsLoading]);

  if (!metabaseEmbedUrls) {
    return 'Loading metabase reports...';
  }

  return (
    <div className='grid column'>
      <p>
        Tip: Seeing zencare.auth.us-east-1.amazoncognito.com refused to connect? You just need to go to{' '}
        <a href='https://metabase.zctools.de/' rel='noopener noreferrer' target='_blank'>
          https://metabase.zctools.de/
        </a>{' '}
        and login to Amazon Cognito (you can ignore the Metabase login - you do NOT need to login to Metabase just Amazon Cognito) and then come back here and refresh the page.
      </p>
      {(metabaseEmbedUrls || []).map((result) => {
        let urlParams = '';
        Object.keys(result.params).forEach((key) => {
          if (urlParams !== '') {
            urlParams += '&';
          }
          urlParams += `${key}=${result.params[key]}`;
        });

        return (
          <React.Fragment key={result.questionId}>
            <iframe style={{ height: iframeHeightDictionary[result.questionId] }} title='Metabase embed' src={result.iframeUrl}></iframe>
            <div
              style={{
                marginTop: '5px',
                marginBottom: '30px',
              }}
            >
              <a target='_blank' rel='noopener noreferrer' href={`https://metabase.zctools.de/question/${result.questionId}?${urlParams}`}>
                View on Metabase
              </a>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default connect(
  (state) => ({
    metabaseEmbedUrls: state.providers.metabaseEmbedUrls,
    auth: state.auth,
  }),
  actions
)(ReferralsTab);
