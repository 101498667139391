import Error from './Error';
import React from 'react';

const Textarea = ({ input, label, meta }) => {
  const onKeyPress = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  return (
    <div className={`field ${meta.error && meta.touched ? 'error' : ''}`}>
      <label>{label}</label>

      <textarea
        {...input}
        style={{ resize: 'none' }}
        className='h-xxs'
        type='text'
        autoComplete='off'
        onKeyPress={onKeyPress}
      />

      <Error {...meta} />
    </div>
  );
};

export default Textarea;
