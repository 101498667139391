import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { actions } from '@/tags/sagaSlice';
import Select from 'react-select';
import Input from '+/forms/Input';
import ReactFilestack from 'filestack-react';
import { onlyOnEnter } from '#/';

export const UnregisteredProviderForm = (props) => {
  const { provider = {}, degrees, readAllDegrees, onCancel } = props;
  const initialDegrees = provider.degrees
    ? provider.degrees.map((degree = {}) => ({
        label: degree.name,
        value: degree,
      }))
    : [];
  const [selectedDegrees, setSelectedDegrees] = useState(initialDegrees);
  const [image, setImage] = useState(provider.image || {});

  // const allDegreesFetched = degrees.length < 1;
  useEffect(() => {
    readAllDegrees();
  }, [readAllDegrees]);

  const renderPicker = () => {
    if (!image || !image.url) {
      return (
        <ReactFilestack
          apikey={process.env.ZENCARE_FILESTACK_API_KEY}
          buttonText='Add new profile image'
          options={{
            fromSources: ['local_file_system', 'url', 'googledrive', 'dropbox'],
          }}
          onSuccess={profileSuccess}
          buttonClass='primary'
        />
      );
    }

    return (
      <div className='relative w-10rem h-10rem select-none'>
        <img
          src={image.url}
          height='150px'
          width='150px'
          alt={image.filename}
          style={{ borderRadius: '50%' }}
        />
        <div
          role='menuitem'
          tabIndex={0}
          onKeyDown={onlyOnEnter(() => setImage(null))}
          onClick={() => setImage(null)}
          className='absolute border-thick p-xs rounded-full border-error text-error pointer'
          style={{
            top: -20,
            right: -20,
          }}
        >
          <div className='w-1rem h-1rem grid justify-center align-center text-error'>&#x78;</div>
        </div>
      </div>
    );
  };

  const profileSuccess = async (response) => {
    const { filesUploaded } = response;

    setImage({
      handle: filesUploaded[0].handle,
      url: filesUploaded[0].url,
      filename: filesUploaded[0].filename,
      size: filesUploaded[0].size,
      mimetype: filesUploaded[0].mimetype,
      key: filesUploaded[0].key,
    });
  };

  const onSubmit = (formValues) => {
    const degrees = selectedDegrees.map((degree) => degree.value);
    formValues.degrees = degrees;

    formValues.image = image || {};

    props.onSubmit(formValues);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={provider}
      render={({ handleSubmit, pristine }) => (
        <form onSubmit={handleSubmit}>
          <h3>Personal Info:</h3>
          <div>
            <Field
              name='first_name'
              component={Input}
              divStyles='w-33 inline-block'
              label='First name'
            />

            <Field
              name='middle_name'
              component={Input}
              divStyles='w-33 inline-block'
              label='Middle name'
            />

            <Field
              name='last_name'
              component={Input}
              divStyles='w-33 inline-block'
              label='Last name'
            />
            <Field name='slug' component={Input} divStyles='w-33 inline-block' label='Slug' />

            <Field
              name='display_name'
              component={Input}
              divStyles='w-33 inline-block'
              label='Display Name'
            />
            <Field name='title' component={Input} divStyles='w-33 inline-block' label='Title' />
          </div>
          <hr />
          <div className='grid justify-between'>
            <div className='w-50'>
              <h3>Profile Picture:</h3>
              {renderPicker()}
            </div>
            <div className='field w-50'>
              <h3>Eduction:</h3>
              <div className='field'>
                <label htmlFor='education'>Degrees</label>
                <Select
                  isMulti
                  name='degrees'
                  options={degrees}
                  value={selectedDegrees}
                  classNamePrefix='select'
                  onChange={setSelectedDegrees}
                  className='basic-multi-select'
                />
              </div>
            </div>
          </div>
          <hr />
          <div className='grid justify-between mt-md'>
            <button onClick={onCancel} className='error'>
              Cancel
            </button>
            <button disabled={pristine} className='success'>
              Submit
            </button>
          </div>
        </form>
      )}
    />
  );
};
const mapStateToProps = (state) => ({
  degrees: Object.values(state.degrees.data).map((degree) => ({
    label: degree.name,
    value: degree,
  })),
});

export default connect(mapStateToProps, { readAllDegrees: actions.degrees.readEvery })(
  UnregisteredProviderForm
);
