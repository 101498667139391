import DefaultLayout from '##/Default';
import List from './List';
import Add from './Add';
import View from './View';
import EmailLog from './EmailLog';

export default [
  {
    path: '/users/:type?',
    exact: true,
    layout: DefaultLayout,
    component: List,
    title: 'View All Users',
  },
  {
    // Note: added /form because needed another slash since /users/:type? was matching
    path: '/users/add/form',
    exact: true,
    layout: DefaultLayout,
    component: Add,
    title: 'Add New User',
  },

  // People might have old bookmarks saved, keep these working for viewing provider users.
  {
    path: '/users/view/:id',
    exact: true,
    layout: DefaultLayout,
    component: View,
    title: 'View One User',
  },

  {
    path: '/users/view-with-mode/:id/:mode',
    exact: true,
    layout: DefaultLayout,
    component: View,
    title: 'View One User',
  },
  {
    path: '/users/view/:id/email-log/:mode',
    exact: true,
    layout: DefaultLayout,
    component: EmailLog,
    title: 'View One User',
  },
];
