/* eslint-disable jsx-a11y/no-onchange */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import { accountAttributeReservedNames } from './constants';
const AddAccountAttribute = (props) => {
  const { addAccountAttribute, match = { params: {} } } = props;
  const accountIdScope = match.params.accountId ? parseInt(match.params.accountId) : null;

  const [selectedReservedName, setSelectedReservedName] = useState(accountAttributeReservedNames[0] || '');
  const [value, setValue] = useState('');
  const [accountId, setAccountId] = useState(accountIdScope);

  return (
    <div className='account-attributes-form'>
      <h1>Add Account Attribute</h1>
      <div>
        <div className='account-attributes-field-label'>Account Id:</div>
        <input type='text' value={accountId} onChange={(e) => setAccountId(parseInt(e.target.value))} placeholder='Account id' />
      </div>

      <div>
        <div className='account-attributes-field-label'> Reserved Name:</div>
        <select
          value={selectedReservedName}
          onChange={(event) => {
            setSelectedReservedName(event.target.value);
          }}
        >
          {accountAttributeReservedNames.map((x) => (
            <option key={x} value={x}>
              {x}
            </option>
          ))}
        </select>
      </div>

      <div>
        <div className='account-attributes-field-label'>Value:</div>
        <input type='text' value={value} onChange={(e) => setValue(e.target.value)} placeholder='Value' />
        <div className='account-attributes-value-tip'>
          {selectedReservedName === 'online_only' ? (
            <>
              <b>Tip</b>: Here are the recommended values for online_only:
              <ul>
                <li>For a group practice that has no in person locations set value to "1" (without the quotes)</li>
                <li>For a group practice that DOES have in person locations set value to "0" (without the quotes)</li>
              </ul>
            </>
          ) : null}
          {selectedReservedName === 'reimbursify_plan_type_value_override' ? (
            <>
              <b>Tip</b>: Here are the recommended values for reimbursify_plan_type_value_override:
              <ul>
                <li>For a trial of "Private Pay Booster Professional" set value to "7" (without the quotes)</li>
                <li>For a trial of "Private Pay Booster Premium" set value to "3" (without the quotes)</li>
              </ul>
            </>
          ) : null}
          <p>If you’re confused, hit up the friendly Product team :)</p>
        </div>
      </div>

      <div className='m-t-md'>
        <button
          type='button'
          className='primary'
          onClick={() => {
            addAccountAttribute({
              account_id: accountId,
              reserved_name: selectedReservedName,
              value,
            });
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default connect((state) => ({}), actions)(AddAccountAttribute);
