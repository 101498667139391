import DefaultLayout from '##/Default';
import Dashboard from './Dashboard';

export default [
  {
    path: '/',
    exact: true,
    layout: DefaultLayout,
    component: Dashboard,
    auth: true,
    title: 'Dashboard',
  },
];
