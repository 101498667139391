import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../accounts/sagaSlice';
import specialRoleFriendlyNameList from './specialRoleFriendlyNameList';
import specialRoleFriendlyNameConstants from './specialRoleFriendlyNameConstants';

function GrantSpecialRolesWidget({ account, provider }) {
  const dispatch = useDispatch();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  const { users } = account;
  const clinicianUsers = users.filter((user) => {
    const roles = user.roles.map((role) => role.name);
    const isClinician = roles.includes('Clinician');

    return isClinician;
  });

  const handleUserChange = (event) => {
    setSelectedUserId(parseInt(event.target.value));
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const onSubmit = () => {
    if (!selectedUserId) {
      return alert('Hey! Gotta choose a user!');
    }
    dispatch(
      actions.createSpecialRole({
        account_id: account.id,
        user_id: selectedUserId,
        friendly_role_name: selectedRole,

        // Only used for refreshing page data if on the provider page
        provider_id: provider ? provider.id : null,
      })
    );
  };

  if (clinicianUsers.length === 0) {
    return (
      <>
        <h4>Grant Access</h4>
        <p>
          <i>There are no clinician users available for access on this account</i>
        </p>
      </>
    );
  }

  const getAvailableSpecialRoles = () => {
    let result = specialRoleFriendlyNameList;

    if (selectedUserId) {
      const selectedUser = clinicianUsers.find((x) => x.id === selectedUserId);

      if (selectedUser && selectedUser.roles) {
        const usersExistingRoleNames = selectedUser.roles.map((x) => x.name);
        const rolesToRemove = usersExistingRoleNames;

        // Depending on the role's the user already has, they shouldn't be allowed to add certain other ones.
        if (usersExistingRoleNames.includes(specialRoleFriendlyNameConstants.owner)) {
          rolesToRemove.push(specialRoleFriendlyNameConstants.reimbursifyPractitioner);
          rolesToRemove.push(specialRoleFriendlyNameConstants.reimbursifyLeadPractitioner);
          rolesToRemove.push(specialRoleFriendlyNameConstants.reimbursifyAdministrator);
        }

        if (usersExistingRoleNames.includes(specialRoleFriendlyNameConstants.reimbursifyPractitioner)) {
          rolesToRemove.push(specialRoleFriendlyNameConstants.reimbursifyAdministrator);
        }

        if (usersExistingRoleNames.includes(specialRoleFriendlyNameConstants.reimbursifyAdministrator)) {
          rolesToRemove.push(specialRoleFriendlyNameConstants.reimbursifyPractitioner);
        }

        result = result.filter((x) => !rolesToRemove.includes(x));
      }
    }

    return result;
  };

  return (
    <>
      <h4>Grant Access</h4>
      <div className='box flex align-center justify-between'>
        <label htmlFor='user-selector' className='w-20'>
          Select a User:
        </label>
        <select className='w-60 m-x-sm' name='user-selector' id='user-selector' value={selectedUserId} onBlur={handleUserChange} onChange={handleUserChange}>
          <option value=''>Select a User</option>
          {clinicianUsers.map((user) => (
            <option key={user.id} value={user.id}>
              {user.full_name ? user.full_name : `${user.first_name} ${user.last_name}`} ({user.id})
            </option>
          ))}
        </select>
        <select className='w-60 m-x-sm' value={selectedRole} onBlur={handleRoleChange} onChange={handleRoleChange}>
          <option value=''>Select a Role</option>
          {getAvailableSpecialRoles().map((roleFriendlyName) => (
            <option key={roleFriendlyName} value={roleFriendlyName}>
              {roleFriendlyName}
            </option>
          ))}
        </select>
        <button onClick={onSubmit} className='success w-20'>
          Add Role
        </button>
      </div>
    </>
  );
}

export default GrantSpecialRolesWidget;
