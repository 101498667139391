import React, { useState, useEffect } from 'react';
import PlainEditor from '+/forms/PlainEditor';

const Testimonial = (props) => {
  const { testimonial, deleteTestimonial, updateTestimonial, index, toggleDisabled } = props;

  const [edit, setEdit] = useState(false);
  const [author, setAuthor] = useState(testimonial.author);
  const [date, setDate] = useState(testimonial.date);
  const [message, setMessage] = useState(testimonial.message);

  useEffect(() => {
    setAuthor(testimonial.author);
    setDate(testimonial.date);
    setMessage(testimonial.message);
  }, [testimonial]);

  const toggleEdit = () => {
    setEdit((e) => !e);
    toggleDisabled();
  };

  const onSubmit = () => {
    updateTestimonial(
      {
        author,
        date,
        message,
      },
      index,
      testimonial.isNew
    );
    setEdit(false);
  };

  const onCancel = () => {
    if (testimonial.isNew) {
      deleteTestimonial(index, true);
    } else {
      setAuthor(testimonial.author);
      setDate(testimonial.date);
      setMessage(testimonial.message);
      toggleEdit();
    }
  };
  // Set Edit to true if the testimonial is true
  testimonial.isNew && !edit && setEdit(true);

  if (edit) {
    return (
      <div className='p-xs m-xs grid column w-100'>
        {index > 0 && <hr />}
        <div className='grid justify-between mb-md align-center'>
          <input
            type='text'
            name='author'
            value={author}
            placeholder='Testimonials Author'
            onChange={(e) => setAuthor(e.target.value)}
            className='py-md w-40'
          />

          <input
            type='month'
            name='date'
            className='w-40'
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />

          <button type='button' className='w-5 px-sm py-md success' onClick={onSubmit}>
            <i className='fas fa-check' />
          </button>
          <button type='button' className='w-5 px-sm py-md error' onClick={onCancel}>
            <i className='fas fa-times' />
          </button>
        </div>

        <PlainEditor
          id={`Box#${index}`}
          initialValue={message}
          onChange={setMessage}
          placeholder='Testimonials Message'
        />
      </div>
    );
  }

  return (
    <div className='p-xs m-xs grid column w-100' handle={index}>
      <div className='grid justify-between align-center'>
        <h4 className='w-80'>
          {author} - {date}
        </h4>

        <button className='w-5 px-sm success' onClick={toggleEdit}>
          <i className='fas fa-edit' />
        </button>
        <button className='w-5 px-sm error' onClick={() => deleteTestimonial(index)}>
          <i className='fas fa-times' />
        </button>
      </div>
      <p dangerouslySetInnerHTML={{ __html: message }}></p>
    </div>
  );
};

export default Testimonial;
