import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './sagaSlice';

function DisableWidget(props) {
  const [selectedOption, setSelectedOption] = useState('');
  const { featureFlags } = useSelector((state) => state['feature-flags']);
  const activeFeatureFlags = featureFlags.filter((f) => f.active);
  const currentSelection = featureFlags.find((f) => f.id === parseInt(selectedOption));
  const dispatch = useDispatch();

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    dispatch(actions.getFeatureFlags());
  }, []);

  const onSubmit = () => {
    if (!selectedOption) {
      return alert('Hey! Gotta choose a feature!');
    }
    dispatch(actions.disableFeatureFlag({ feature_flag_id: selectedOption }));
  };

  if (!activeFeatureFlags.length) {
    return null;
  }

  return (
    <div className='p-sm box'>
      <h2>Disable Active Feature Flags </h2>
      <h4>Directions</h4>
      <p>
        Select a feature flag below and click the button to set the feature as inactive. Typically
        we will only do this after we've made changes to the code to allow everyone access to this
        feature and we want to clean up our records of which feature flags are still live, however,
        if you'd like to remove <i>anyone's</i> access to a feature, setting a feature flag to
        inactive before we make changes to the code will prevent anyone from being able to access a
        the feature.
      </p>
      <hr />
      <div className='p-b-sm'>
        <label htmlFor='scope-select' className='p-r-sm'>
          Select an active feature flag:
        </label>
        <select
          id='feature-flag-select'
          value={selectedOption}
          onBlur={handleSelectChange}
          onChange={handleSelectChange}
        >
          <option value=''>Choose one</option>
          {activeFeatureFlags.map((f) => (
            <option key={f.id} value={f.id}>
              {f.reserved_name}
            </option>
          ))}
        </select>
        <span className='m-l-sm'>{currentSelection ? currentSelection.description : ''}</span>
      </div>

      <button onClick={onSubmit} className='success w-100'>
        Disable feature flag{' '}
      </button>
    </div>
  );
}

export default DisableWidget;
