/* eslint-disable max-lines-per-function */
import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import Remove from '+/RemoveButton';
import { Link } from 'react-router-dom';
import Status from '+/Status';
import capitalize from 'voca/capitalize';
import TabsContainer from './components/tabs/index.jsx';

export const ViewTherapyGroup = (props) => {
  const {
    current: therapyGroup,
    match = { params: {} },
    readOne,
    delete: deleteTherapyGroup,
    update,
    addProvider,
    removeProvider,
  } = props;
  const { id, tab = 'profile' } = match.params;

  const fetchTherapyGroup = useCallback(() => readOne(id), [id, readOne]);

  useEffect(() => {
    fetchTherapyGroup();
  }, [fetchTherapyGroup]);

  const updateFn = (changeset) =>
    update({
      id,
      changeset,
    });

  const renderTab = () =>
    TabsContainer({
      therapyGroup,
      fetchTherapyGroup,
      updateFn,
      addProvider,
      removeProvider,
    })[tab]();

  const activeIf = (_tab) =>
    _tab === tab ? 'text-primary border-bottom-thick border-primary' : 'text-grey';
  const tabHref = (tab) => `/therapy-groups/view/${id}/${tab}`;

  const renderTabLinks = () => {
    const tabs = Object.keys(TabsContainer()).map((tab) => (
      <Link to={tabHref(tab)} className={`p-sm m-0 mb-0 pointer ${activeIf(tab)}`}>
        {capitalize(tab === 'seo' ? 'SEO' : tab)}
      </Link>
    ));

    return tabs.map((link, i) => (
      <li className='mb-0 pointer list-none' key={i}>
        {link}
      </li>
    ));
  };

  if (!therapyGroup) {
    return null;
  }

  const url = `${process.env.ZENCARE_WEBSITE_URL}/therapy-group/${therapyGroup.slug}`;

  return (
    <div>
      <div className='grid justify-between align-center'>
        <div className='grid align-center w-90'>
          <h3 className='mr-lg'>{therapyGroup.name}</h3>
          <Status
            initialStatus={therapyGroup.status}
            updateFn={updateFn}
            options={['active', 'inactive']}
          />
        </div>
      </div>

      <a target='_blank' rel='noopener noreferrer' href={url}>
        {url}
      </a>

      <nav className='w-100 my-lg border-bottom border-top border-grey-light'>
        <ul className='grid list-none w-100 pb-lg m-0 justify-between'>{renderTabLinks()}</ul>
      </nav>

      {renderTab()}

      <div style={{ float: 'right' }}>
        <Remove
          doubleassert='true'
          className='error'
          name={therapyGroup.name}
          onClick={() => deleteTherapyGroup(therapyGroup.id)}
        />
      </div>
    </div>
  );
};

export default connect((state) => state['therapy-groups'], actions)(ViewTherapyGroup);
