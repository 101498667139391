import React, { useEffect } from 'react';
import { actions } from './sagaSlice';
import { useDispatch, useSelector } from 'react-redux';
import BlogPostCard from './BlogPostCard';
import EditCard from './EditCard';

const HomepageBlogPosts = () => {
  const dispatch = useDispatch();
  const { posts, isCreatingBlogPost } = useSelector((state) => state.homepageBlogPosts);

  useEffect(() => {
    // Fetch blog posts
    dispatch(actions.fetchHomepageBlogPosts());
  }, []);

  return (
    <section className='homepage-blog-posts'>
      <div className='homepage-blog-posts-header'>
        <div>
          <h1>Homepage Blog Posts</h1>
          <p>
            Create and update blog posts that will be featured on the Zencare homepage. Toggle individual blog posts on and off to control whether they are displayed. Blog posts will be featured by
            date created (most recent displayed first).
          </p>
        </div>
        <button className='success' onClick={() => dispatch(actions.toggleIsCreatingBlogPost())}>
          Create post
        </button>
      </div>
      <div className='homepage-blog-posts-grid'>
        {isCreatingBlogPost || !posts.length ? <EditCard /> : null}
        {posts.map((post) => {
          return <BlogPostCard post={post} key={post.id} />;
        })}
      </div>
    </section>
  );
};

export default HomepageBlogPosts;
