import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import RemoveButton from '+/RemoveButton';
import useOrderBy from '+/hooks/useOrderBy';
import Pagination from '+/Pagination';
import utils from '../../utils';
import Column from '+/Column';
import { Link } from 'react-router-dom';

export const List = (props) => {
  const { blacklist, readAll, total, searchBlacklist, delete: deleteBlacklist } = props;

  const { order, orderBy, ordered: orderedBlacklist, handleColumnChange } = useOrderBy(blacklist, 'value');

  useEffect(() => {
    readAll({
      order,
      orderBy,
    });
  }, [order, orderBy, readAll]);

  const handleSearch = ({ target }) => utils.debounce(() => searchBlacklist(target.value));

  return (
    <>
      <div className='grid align-center justify-between'>
        <h1 className='inline-block'>Contact Blocklist</h1>

        <Link to={'/contact-blacklist/add'} className='button primary' title='Add New Blocklist'>
          <i className='fas fa-plus' />
        </Link>
      </div>

      <div className='border border-grey-lightest radius-xs scroll'>
        <input type='text' className='block w-100' placeholder="Filter contact's that are blocklisted..." onKeyUp={handleSearch} />
        <div className='bg-grey-dark text-white grid'>
          <Column title='User ID' field='user_id' width='10' onChange={handleColumnChange} />
          <Column title='Email/Domain' field='value' width='30' onChange={handleColumnChange} />
          <div className='p-md w-10'>
            <b className='mr-sm'>Regex</b>
          </div>
          <div className='p-md w-10'>
            <b className='mr-sm'>Type</b>
          </div>
          <div className='p-md w-10'>
            <b className='mr-sm'>First name</b>
          </div>
          <div className='p-md w-10'>
            <b className='mr-sm'>Last name</b>
          </div>
          <div className='p-md w-30'>
            <b className='mr-sm'>Notes</b>
          </div>
          <div className='p-md mr-sm w-10'>
            <b>Remove</b>
          </div>
        </div>

        {orderedBlacklist.map((blacklist, i) => (
          <div className={`${utils.tableClass(i)} grid align-center`} key={blacklist.id}>
            <div className='p-md w-10'>{blacklist.user_id ? blacklist.user_id : 'N/A'}</div>
            <Link className='w-30' to={`/contact-blacklist/edit/${blacklist.id}`}>
              <div className='p-md'>{blacklist.value}</div>
            </Link>

            <div className='p-md w-10'>{blacklist.regex ? 'Domain' : 'Email'}</div>
            <div className='p-md w-10'>{blacklist.type}</div>
            <div className='p-md w-10'>{blacklist.first_name}</div>
            <div className='p-md w-10'>{blacklist.last_name}</div>

            <div className='p-md w-30'>{blacklist.notes}</div>

            <div className='p-md mr-sm w-10'>
              <RemoveButton className='error' name={blacklist.value} onClick={() => deleteBlacklist(blacklist.id)} />
            </div>
          </div>
        ))}
        <Pagination total={total} fetchFn={(page) => readAll({ page })} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  blacklist: Object.values(state['contact-blacklist'].data || {}),
  total: state['contact-blacklist'].total,
});

export default connect(mapStateToProps, actions)(List);
