import React, { useState } from 'react';
import EditCard from './EditCard';
import DisplayCard from './DisplayCard';

const BlogPostCard = ({ post }) => {
  const [isEditing, setIsEditing] = useState(false);

  return isEditing ? <EditCard post={post} setIsEditing={setIsEditing} /> : <DisplayCard post={post} setIsEditing={setIsEditing} />;
};

export default BlogPostCard;
