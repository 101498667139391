import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './sagaSlice';

function RevokeAccessWidget({ account }) {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState('');
  const { featureFlags } = useSelector((state) => state['feature-flags']);
  const activeFeatureFlags = featureFlags.filter((f) => f.active);
  const activeAccountFeatureFlags = account.feature_flags.filter((f) => f.active);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const onSubmit = () => {
    if (!selectedOption) {
      return alert('Hey! Gotta choose a feature!');
    }
    dispatch(
      actions.disableFeature({
        feature_flag_reserved_name: selectedOption,
        type: 'account',
        account_id: account.id,
      })
    );
  };

  if (!activeFeatureFlags.length) {
    return null;
  }

  return (
    <div className='box m-b-sm'>
      <h4 className='m-0'>Disable Features</h4>
      <p>
        Select a feature from the dropdown and click disable to remove this accounts's access to a
        feature. Active features this account has access to are:{' '}
        {activeAccountFeatureFlags.map((f) => (
          <>
            <code className='p-x-xs m-r-xs'>{f.reserved_name}</code>
          </>
        ))}
      </p>
      <div className='flex align-center'>
        <label htmlFor='scope-select' className='w-20'>
          Select a Feature:
        </label>
        <select
          id='feature-flag-select'
          value={selectedOption}
          onBlur={handleSelectChange}
          onChange={handleSelectChange}
          className='m-x-sm flex-1'
        >
          <option value=''>Choose one</option>
          {activeAccountFeatureFlags.map((f) => (
            <option key={f.id} value={f.reserved_name}>
              {f.reserved_name}
            </option>
          ))}
        </select>
        <button onClick={onSubmit} className='error  w-20'>
          Disable
        </button>
      </div>
    </div>
  );
}

export default RevokeAccessWidget;
