import { crudSlice, noop } from 'saga-slice-helpers';
import { put } from 'redux-saga/effects';
import history from '#/history';

import { api, sagaApi } from '#/apis';
import { paginationReducer } from '#/sagaSlice';

const sagaSliceModule = crudSlice({
  name: 'regions',
  sagaApi,
  reducers: {
    readAllSuccess: paginationReducer,

    addRegion: noop,
    addRegionFail: noop,

    removeRegion: noop,
    removeRegionFail: noop,

    searchRegions: noop,
    searchRegionsSuccess: paginationReducer,
    searchRegionsFail: noop,
  },
  sagas: (A) => ({
    [A.readAll]: {
      *saga({ payload = {} }) {
        const { page = 0, order = 'asc', orderBy = 'name' } = payload;
        window.scrollTo(0, 0);
        yield sagaApi.get(
          `/regions?page=${page}&order=${order}&orderBy=${orderBy}`,
          A.readAllSuccess,
          A.readAllFail
        );
      },
    },
    [A.addRegion]: {
      *saga({ payload }) {
        try {
          const { area, region } = payload;
          yield api.put(`/region/${region}/area/${area}`);
          yield put(A.readOne(region));
        } catch (error) {
          put(A.addRegionFail(error));
        }
      },
    },
    [A.removeRegion]: {
      *saga({ payload }) {
        try {
          const { area, region } = payload;
          yield api.delete(`/region/${region}/area/${area}`);
          yield put(A.readOne(region));
        } catch (error) {
          put(A.removeRegionFail(error));
        }
      },
    },
    [A.createSuccess]: {
      *saga({ payload }) {
        yield history.push(`/regions/view/${payload.id}`);
      },
    },
    [A.deleteSuccess]: {
      *saga() {
        yield history.push('/regions');
      },
    },
    [A.updateSuccess]: {
      *saga({ payload }) {
        yield put(A.readOne(payload.id));
      },
    },
    [A.searchRegions]: {
      *saga({ payload = '' }) {
        try {
          if (payload.length < 1) {
            yield put(A.readAll());
          } else if (payload.length > 0) {
            const { data } = yield api.get(`/regions/search/${payload}`);
            yield put(A.searchRegionsSuccess(data));
          }
        } catch (error) {
          yield put(A.searchRegionsFail(error));
        }
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
