import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Modal from '+/Modal';
import SearchTypeAhead from '+/SearchTypeAhead';
import { connect } from 'react-redux';
import { actions } from '../../../sagaSlice';

export const AddSeatModal = ({
  providers,
  showModal,
  toggleModal,
  toggleShowConfirmAddSeatModal,
  setConfirmAddSeatModalMessage,
  setPendingAddSeatParams,
}) => {
  const [filteredProviders, setFilteredProviders] = useState([]);
  const [selectedProviderId, setSelectedProviderId] = useState(null);
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  const providerListWithDisplayName = providers
    ? providers.map((obj) => ({
        ...obj,
        computedDisplayName: `provider id: ${obj.id} - ${
          obj.company_name ? obj.company_name : `${obj.first_name} ${obj.last_name}`
        })`,
      }))
    : [];

  useEffect(() => {
    if (providers && providers.length && !initialLoadDone) {
      setFilteredProviders(providerListWithDisplayName);
      setInitialLoadDone(true);
    }
  }, [initialLoadDone, providerListWithDisplayName, providers]);

  const searchAccounts = (searchTerm) => {
    // If not loaded, don't try to search.
    if (providerListWithDisplayName) {
      if (searchTerm && searchTerm.length) {
        const result = providerListWithDisplayName.filter((x) =>
          _.includes(x.computedDisplayName.toUpperCase(), searchTerm.toUpperCase())
        );
        setFilteredProviders(result);
      } else {
        setFilteredProviders(providerListWithDisplayName);
      }
    } else {
      setFilteredProviders([]);
    }
  };

  if (showModal) {
    return (
      <Modal title='Choose provider to add the seat for' onDismiss={toggleModal}>
        {filteredProviders && (
          <SearchTypeAhead
            field='computedDisplayName'
            placeholder='Search by first name, last name, company name, or provider id...'
            items={filteredProviders}
            selected={selectedProviderId ? [selectedProviderId] : []}
            searchFn={searchAccounts}
            onSelect={(providerId) => {
              console.log(`Selected provider id ${providerId}`);
              setSelectedProviderId(providerId);
              setPendingAddSeatParams((existingValue) => ({
                ...existingValue,
                provider_id: providerId,
              }));

              const selectedProvider = providers.find((x) => x.id === providerId);
              setConfirmAddSeatModalMessage(
                `provider id: ${selectedProvider.id} - ${
                  selectedProvider.company_name
                    ? selectedProvider.company_name
                    : `${selectedProvider.first_name} ${selectedProvider.last_name}`
                }`
              );
              toggleModal();
              toggleShowConfirmAddSeatModal();
            }}
          />
        )}
      </Modal>
    );
  }

  return null;
};

export default connect((state) => ({}), actions)(AddSeatModal);
