import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import Column from '~/shared/Column';
import useOrderBy from '+/hooks/useOrderBy';
import Pagination from '+/Pagination';
import { tableClass, debounce } from '#';
import { displayDate, capitalizeFirstLetter } from '#/';

// TODO: Right now the sorting works but it removes any sort of filters previously
// applied to the search. I still need to work out a way to maintain search filter when sorting
// that could be a big lift that I have not touched yet

export const List = (props) => {
  const [filterValue, setFilterValue] = useState('');
  const [filterValueIsNumber, setFilterValueIsNumber] = useState(false);
  const { loading, accounts, readAll, total, searchAccounts } = props;
  const { order, orderBy, ordered, handleColumnChange } = useOrderBy(
    accounts,
    'created_at',
    'desc'
  );

  useEffect(() => {
    searchAccounts({
      name: filterValue,
      order,
      orderBy,
    });
  }, [filterValue, order, orderBy, searchAccounts]);

  const handleSearch = ({ target = { value: '' } }) => {
    setFilterValueIsNumber(!isNaN(target.value));
    setFilterValue(target.value);
  };

  return (
    <>
      <h1 className='m-b-0'>Accounts</h1>
      <p className='m-t-0 m-b-sm grey'>Search by account ID, name, or owner</p>

      <div className='border border-grey-lightest radius-xs scroll'>
        <input
          type='text'
          className='block w-100'
          placeholder='Filter accounts...'
          onKeyUp={handleSearch}
        />

        {accounts.length === 0 && filterValueIsNumber && (
          // Shown when failed search by ID
          <div className='box m-t-sm'>
            <h4 className='m-t-0 m-b-sm'>Try a new search!</h4>
            <p className='m-t-0 m-b-0'>
              <span>{filterValue}</span> does not match any account IDs!
            </p>
          </div>
        )}
        {accounts.length === 0 && !filterValueIsNumber && (
          // Shown when failed search by account owner name
          <div className='box m-t-sm'>
            <h4 className='m-t-0 m-b-sm'>Try a new search!</h4>
            <p className='m-t-0 m-b-0'>
              Could not find any account owners named
              <span> {capitalizeFirstLetter(filterValue)}</span>!
            </p>
          </div>
        )}
        {accounts.length > 0 && (
          // Shown when any search is successful
          <>
            <div className='bg-grey-dark text-white grid'>
              <Column title='ID' field='id' width='10' onChange={handleColumnChange} />
              <Column title='Name' field='name' width='40' onChange={handleColumnChange} />
              <Column title='Owner' field='owner_name' width='30' onChange={handleColumnChange} />
              <Column title='Created' field='created_at' width='20' onChange={handleColumnChange} />
            </div>
            {ordered.map((account, i) => (
              <div className={`${tableClass(i)} grid align-center areas`} key={account.id}>
                <div className='p-md w-10'>
                  <Link to={`/accounts/view/${account.id}`}>{account.id}</Link>
                </div>
                <div className='p-md w-40'>
                  <Link to={`/accounts/view/${account.id}`}>{account.name}</Link>
                </div>
                <div className='p-md w-30'>{account.owner_name}</div>
                <div className='p-md w-20'>{displayDate(account.created_at)}</div>
              </div>
            ))}
          </>
        )}
      </div>
      <Pagination
        isHidden={filterValue}
        total={total}
        fetchFn={(page) => readAll({ page, order, orderBy })}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  accounts: Object.values(state.accounts.data || {}),
  //   total: Object.values(state.accounts.total || {}),
  total: state.accounts.total,
  loading: state.accounts.loading,
  foo: 'bar',
});

export default connect(mapStateToProps, actions)(List);
