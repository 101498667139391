import React, { useState, useEffect } from 'react';
import { actions } from '@/providers/sagaSlice';
import { connect } from 'react-redux';
import Modal from '+/Modal';
import TypeAhead from '+/TypeAhead';
import { onlyOnEnter } from '#/';

export const Providers = (props) => {
  const [modal, setModal] = useState(false);
  const { therapyGroup = {}, providers = [], readEvery, addProvider, removeProvider } = props;

  const selectedProviders = therapyGroup && (therapyGroup.providers || []).map((p) => p.id);

  useEffect(() => {
    readEvery();
  }, [readEvery]);

  const viewModal = () => {
    if (modal) {
      return (
        <Modal title='Providers and Practices' onDismiss={() => setModal(false)}>
          <TypeAhead
            field={(item) =>
              item.type === 'individual'
                ? `${item.first_name} ${item.last_name}`
                : item.company_name
            }
            placeholder='Filter Providers and Practices...'
            items={providers}
            selected={selectedProviders}
            onSelect={(provider) =>
              addProvider({
                provider,
                therapyGroup: therapyGroup.id,
              })
            }
          />
        </Modal>
      );
    }
  };

  return (
    <div className='border border-grey-lightest radius-xs p-lg mr-sm mb-sm w-50'>
      {viewModal()}
      <div className='mb-lg grid align-center'>
        <h2>Providers and Practices</h2>
        <div
          tabIndex={0}
          role='menuitem'
          className='primary pointer px-md'
          onClick={() => setModal(true)}
          onKeyDown={onlyOnEnter(() => setModal(true))}
        >
          <i className='fas fa-plus' />
        </div>
      </div>

      <div className='grid wrap'>
        {(therapyGroup.providers || []).map((provider) => {
          const removeProviderFn = () =>
            removeProvider({
              provider: provider.id,
              therapyGroup: therapyGroup.id,
            });

          return (
            <div
              className='grid label primary align-center justify-between mr-xs mb-xs'
              key={provider.id}
            >
              <h5 className='text-white mb-0 mt-0'>
                {provider.type === 'individual'
                  ? `${provider.first_name} ${provider.last_name}`
                  : provider.company_name}
              </h5>
              <div
                tabIndex={0}
                role='menuitem'
                className='inline-block px-md'
                onClick={removeProviderFn}
                onKeyDown={onlyOnEnter(removeProviderFn)}
              >
                <i className='fas fa-times' />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default connect((state) => ({ providers: Object.values(state.providers.data) }), {
  readEvery: actions.readEvery,
})(Providers);
