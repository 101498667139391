import { crudSlice, noop, failReducer, loadingReducer, notLoadingReducer } from 'saga-slice-helpers';
import history from '#/history';
import { sagaApi, api } from '#/apis';
import { takeLatest, put } from 'redux-saga/effects';
import { paginationReducer } from '#/sagaSlice';
import { downloadCSV } from '~/utils/csv';
import { flashSuccess } from '+/flashes/redux';

const sagaSliceModule = crudSlice({
  name: 'accounts',
  sagaApi,
  initialState: {},
  reducers: {
    readAll: noop,
    readAllSuccess: paginationReducer,
    readAllFail: noop,

    searchAccounts: noop,
    searchAccountsSuccess: paginationReducer,
    searchAccountsFail: noop,

    getAccountSummary: noop,
    getAccountSummarySuccess: (state, payload) => {
      state.account = payload[0];
    },
    getAccountSummaryFail: noop,

    exportAccountIntakes: loadingReducer,
    exportAccountIntakesSuccess: (state, payload) => downloadCSV(payload, 'referral_data_ADMIN'),
    exportAccountIntakesFail: failReducer,

    createSpecialRole: loadingReducer,
    createSpecialRoleSuccess: noop,
    createSpecialRoleFail: failReducer,

    deleteSpecialRole: loadingReducer,
    deleteSpecialRoleSuccess: noop,
    deleteSpecialRoleFail: failReducer,

    createPracticeManagementClinicCreator: loadingReducer,
    createPracticeManagementClinicCreatorSuccess: noop,
    createPracticeManagementClinicCreatorFail: failReducer,

    upgradeStandalonePracticeManagementTier: loadingReducer,
    upgradeStandalonePracticeManagementTierSuccess: noop,
    upgradeStandalonePracticeManagementTierFail: failReducer,

    downgradeStandalonePracticeManagementTier: loadingReducer,
    downgradeStandalonePracticeManagementTierSuccess: noop,
    downgradeStandalonePracticeManagementTierFail: failReducer,

    editStandalonePracticeManagementQuantity: loadingReducer,
    editStandalonePracticeManagementQuantitySuccess: noop,
    editStandalonePracticeManagementQuantityFail: failReducer,

    updateAccountNotes: loadingReducer,
    updateAccountNotesSuccess: noop,
    updateAccountNotesFail: failReducer,
  },

  //eslint-disable-next-line
  sagas: (A) => ({
    [A.readAll]: {
      *saga({ payload = {} }) {
        // Destructure query payload
        const { page = 0, order = 'asc', orderBy = 'id', limit = 50 } = payload;
        // Scroll to top of page
        window.scrollTo(0, 0);
        // GET All accounts
        yield sagaApi.get(`/accounts-list?page=${page}&order=${order}&orderBy=${orderBy}&limit=${limit}`, A.readAllSuccess, A.readAllFail);
      },
    },
    [A.searchAccounts]: {
      *saga({ payload = '' }) {
        const { name, page = 1, limit = 10000, order = 'asc', orderBy = 'id' } = payload;

        try {
          if (name.length < 1) {
            yield put(
              A.readAll({
                order,
                orderBy,
              })
            );
          } else if (!isNaN(name)) {
            const url = `/accounts/search/id/${name}?page=${page}&limit=${limit}&orderBy=${orderBy}&order=${order}`;
            const { data } = yield api.get(url);
            yield put(A.searchAccountsSuccess(data));
          } else if (name.length > 0) {
            const url = `/accounts/search/${name}?page=${page}&limit=${limit}`;
            const { data } = yield api.get(url);
            yield put(A.searchAccountsSuccess(data));
          }
        } catch (error) {
          yield put(A.searchAccountsFail(error));
        }
      },
    },
    [A.getAccountSummary]: {
      *saga({ payload }) {
        if (payload.setStatus) {
          yield payload.setStatus('loading');
        }

        yield sagaApi.get(`/accounts/account/${payload.id}`, A.getAccountSummarySuccess, A.getAccountSummaryFail);

        if (payload.setStatus) {
          yield payload.setStatus('idle');
        }
      },
    },
    [A.exportAccountIntakes]: {
      *saga({ payload }) {
        const { account_id } = payload;
        yield sagaApi.get(`/export-intakes/${account_id}/test`, A.exportAccountIntakesSuccess, A.exportAccountIntakesFail);
      },
    },
    [A.createSpecialRole]: {
      *saga({ payload }) {
        let providerId = null;
        providerId = payload.provider_id;
        delete payload.provider_id;
        yield sagaApi.post(`/account/special-role`, payload, A.createSpecialRoleSuccess, A.createSpecialRoleFail);

        // If we're on provider page, refresh it too
        if (providerId) {
          yield put({ type: 'providers/getAccountSummary', payload: { id: providerId } });
        }
      },
    },
    [A.deleteSpecialRole]: {
      *saga({ payload }) {
        let providerId = null;
        providerId = payload.provider_id;
        delete payload.provider_id;

        yield sagaApi.post(`/account/special-role/delete`, payload, A.deleteSpecialRoleSuccess, A.deleteSpecialRoleFail);

        // If we're on provider page, refresh it too
        if (providerId) {
          yield put({ type: 'providers/getAccountSummary', payload: { id: providerId } });
        }
      },
    },
    [A.createSpecialRoleSuccess]: {
      *saga({ payload }) {
        const { account_id, user_id } = payload;
        yield put(A.getAccountSummary({ id: account_id }));
        yield put({ type: 'users/getAccountSummary', payload: { id: user_id } });
        yield put(flashSuccess(`The selected role has been added for user ${user_id}!`, false));
      },
    },
    [A.deleteSpecialRoleSuccess]: {
      *saga({ payload }) {
        const { account_id, user_id } = payload;
        yield put(A.getAccountSummary({ id: account_id }));
        yield put({ type: 'users/getAccountSummary', payload: { id: user_id } });
        yield put(flashSuccess(`The selected role has been revoked for user ${user_id}!`, false));
      },
    },
    [A.createPracticeManagementClinicCreator]: {
      *saga({ payload }) {
        yield sagaApi.post(`/account/practice-management-clinic-creator/create`, payload, A.createPracticeManagementClinicCreatorSuccess, A.createPracticeManagementClinicCreatorFail);
      },
    },
    [A.createPracticeManagementClinicCreatorSuccess]: {
      *saga({ payload }) {
        yield put(flashSuccess('Practice management clinic creator stored successfully!'));
        yield put(A.getAccountSummary({ id: payload.data.account_id }));
      },
    },

    [A.upgradeStandalonePracticeManagementTier]: {
      *saga({ payload }) {
        yield sagaApi.post(
          `/stripe-subscriptions/upgrade-standalone-zpm-tier/${payload.stripe_customer_id}/${payload.stripe_subscription_id}`,
          { new_tier: payload.new_tier },
          A.upgradeStandalonePracticeManagementTierSuccess,
          A.upgradeStandalonePracticeManagementTierFail
        );
      },
    },
    [A.upgradeStandalonePracticeManagementTierSuccess]: {
      *saga() {
        yield put(flashSuccess('Subscription upgraded successfully!'));
        // Reload whole window, not most efficient.
        window.location.reload();
      },
    },

    [A.downgradeStandalonePracticeManagementTier]: {
      *saga({ payload }) {
        yield sagaApi.post(
          `/stripe-subscriptions/downgrade-standalone-zpm-tier/${payload.stripe_customer_id}/${payload.stripe_subscription_id}`,
          { new_tier: payload.new_tier },
          A.downgradeStandalonePracticeManagementTierSuccess,
          A.downgradeStandalonePracticeManagementTierFail
        );
      },
    },
    [A.downgradeStandalonePracticeManagementTierSuccess]: {
      *saga() {
        yield put(flashSuccess('Subscription downgraded successfully!'));
        // Reload whole window, not most efficient.
        window.location.reload();
      },
    },

    [A.editStandalonePracticeManagementQuantity]: {
      *saga({ payload }) {
        yield sagaApi.post(
          `/stripe-subscriptions/edit-standalone-zpm-qty/${payload.stripe_customer_id}/${payload.stripe_subscription_id}`,
          { quantity: payload.quantity },
          A.editStandalonePracticeManagementQuantitySuccess,
          A.editStandalonePracticeManagementQuantityFail
        );
      },
    },
    [A.editStandalonePracticeManagementQuantitySuccess]: {
      *saga() {
        yield put(flashSuccess('(Standalone capable) Practice Management Product seats edited successfully!'));
        // Reload whole window, not most efficient.
        window.location.reload();
      },
    },
    [A.updateAccountNotes]: {
      *saga({ payload }) {
        const { account_id, notes } = payload;
        yield sagaApi.put(`/accounts/notes`, { account_id, notes }, A.updateAccountNotesSuccess, A.updateAccountNotesFail);
        yield put({ type: 'providers/getAccountsList' });
        yield put(A.getAccountSummary({ id: account_id }));
      },
    },
  }),
});

export const { actions } = sagaSliceModule;
export default sagaSliceModule;
