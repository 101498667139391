import React, { useState } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import Modal from '+/Modal';
import EditableCard from '+/forms/EditableCard';
import EditableText from '+/forms/EditableText';

const AddSearchContentButton = ({ addSearchContentOverride, getSearchContentOverrides, searchContentOverrides }) => {
  const [showModal, setShowModal] = useState(false);

  const [url, setUrl] = useState('');
  const [topBeforeMore, setTopBeforeMore] = useState('');
  const [topAfterMore, setTopAfterMore] = useState('');
  const [bottomContent, setBottomContent] = useState('');

  const toggleModal = () => {
    setShowModal((e) => !e);
  };

  const handleButtonClick = () => {
    addSearchContentOverride({
      data: {
        url,
        top_content_before_more: topBeforeMore,
        top_content_after_more: topAfterMore,
        bottom_content: bottomContent,
      },
      callback: () => {
        toggleModal();
        getSearchContentOverrides();
      },
    });
  };

  const renderModal = () => {
    if (showModal) {
      return (
        <Modal modalStyles={{ width: '100%', height: '100%' }} title='Add New Search Content' onDismiss={toggleModal}>
          <EditableText
            label='URL (e.g /test?abc=1):'
            name='url'
            styles='w-100'
            updateFn={(payload) => {
              setUrl(payload.url);
            }}
          />
          {searchContentOverrides.find((x) => x.url === url) ? <p className='text-error'>Error: URL is already in use! Please edit instead!</p> : ''}
          <EditableCard
            label='Top Content: Text before the "More" link'
            name='top_content_before_more'
            styles={'w-100'}
            updateFn={(payload) => {
              setTopBeforeMore(payload.top_content_before_more);
            }}
          />
          <EditableCard
            label='Top Content: Text to show after the "More" link is clicked'
            name='top_content_after_more'
            styles={'w-100'}
            updateFn={(payload) => {
              setTopAfterMore(payload.top_content_after_more);
            }}
          />
          <EditableCard
            label='Bottom Content'
            name='bottom_content'
            styles={'w-100'}
            updateFn={(payload) => {
              setBottomContent(payload.bottom_content);
            }}
          />

          <p>
            {!url || !topBeforeMore || !topAfterMore || !topAfterMore || !bottomContent ? (
              <p>
                <b>Reminder:</b> Did you hit green checkmark to save each field you wanted to add?
              </p>
            ) : (
              ''
            )}
            <p className='text-error'>{url ? '' : 'URL is required'}</p>
            <p>{topBeforeMore ? '' : 'Warning: Nothing set for Top Content: Text before the "More" link'}</p>
            <p>{topAfterMore ? '' : 'Warning: Nothing set for Top Content: Text to show after the "More" link is clicked'}</p>
            <p>{bottomContent ? '' : 'Warning: Nothing set for bottom content.'}</p>
          </p>
          <button className='primary' onClick={handleButtonClick}>
            Add
          </button>
        </Modal>
      );
    }
  };

  return (
    <>
      <div className='m-b-md'>
        <button type='button' className='primary' onClick={() => setShowModal(true)}>
          <i className='fas fa-plus' />
        </button>

        {renderModal()}
      </div>
    </>
  );
};

export default connect((state) => ({ searchContentOverrides: state.searchContent.searchContentOverrides }), actions)(AddSearchContentButton);
