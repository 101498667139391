import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import useOrderBy from '+/hooks/useOrderBy';
import Pagination from '+/Pagination';
import { Link } from 'react-router-dom';
import Column from '+/Column';
import utils from '#/';

export const TherapyGroupsList = (props) => {
  const { therapyGroups, total, searchTherapyGroups, readAll } = props;

  const {
    order,
    orderBy,
    ordered: orderedTherapyGroups,
    handleColumnChange,
  } = useOrderBy(therapyGroups, 'name');

  useEffect(() => {
    readAll({
      order,
      orderBy,
    });
  }, [order, orderBy, readAll]);

  const handleSearch = ({ target }) => utils.debounce(() => searchTherapyGroups(target.value));

  return (
    <>
      <div className='grid align-center justify-between'>
        <h1 className='inline-block'>Therapy Groups</h1>

        <Link to={'/therapy-groups/add'} className='button primary' title='Add New Therapy Group'>
          <i className='fas fa-plus' />
        </Link>
      </div>

      <div className='border border-grey-lightest radius-xs scroll'>
        <input
          type='text'
          className='block w-100'
          placeholder='Filter Therapy Groups...'
          onKeyUp={handleSearch}
        />

        <div className='bg-grey-dark text-white grid justify-between align-center'>
          <Column title='Name' width='50' field='name' onChange={handleColumnChange} />

          <Column title='Created At' width='50' field='created_at' onChange={handleColumnChange} />
        </div>

        {orderedTherapyGroups.map((therapy_group, i) => (
          <div className={`${utils.tableClass(i)} grid align-center`} key={therapy_group.id}>
            <div className='p-md w-50'>
              <Link to={`/therapy-groups/view/${therapy_group.id}`}>{therapy_group.name}</Link>
            </div>

            <div className='p-md w-50'>{utils.displayDate(therapy_group.created_at)}</div>
          </div>
        ))}
        <Pagination total={total} fetchFn={(page) => readAll({ page })} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  therapyGroups: Object.values(state['therapy-groups'].data || {}),
  total: state['therapy-groups'].total,
});

export default connect(mapStateToProps, actions)(TherapyGroupsList);
