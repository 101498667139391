import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '~/components/accounts/sagaSlice';

function AddPracticeManagementClinicCreatorWidget({ account }) {
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    first_name: account?.practice_management_clinic_creator?.first_name || '',
    last_name: account?.practice_management_clinic_creator?.last_name || '',
    email: account?.practice_management_clinic_creator?.email || '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value ? value : null,
    }));
  };

  const validateForm = () => {
    const { first_name, last_name, email } = formValues;

    if (!first_name || !last_name || !email) {
      return false;
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validateForm();

    const payload = { account_id: account.id, ...formValues };

    if (isValid) {
      return dispatch(actions.createPracticeManagementClinicCreator(payload));
    }
  };

  return (
    <div className='box'>
      <h4>Add Practice Management Clinic Creator</h4>
      <table className=' w-100'>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{account?.practice_management_clinic_creator?.first_name || <i>Unset</i>}</td>
            <td>{account?.practice_management_clinic_creator?.last_name || <i>Unset</i>}</td>
            <td>{account?.practice_management_clinic_creator?.email || <i>Unset</i>}</td>
          </tr>
        </tbody>
      </table>
      <form onSubmit={handleSubmit} className='p-0 p-t-xs'>
        <div className='flex column'>
          <label className='m-b-xs w-100'>
            First name:
            <input type='text' name='first_name' className='m-t-xs w-100' value={formValues.first_name} onChange={handleChange} placeholder='Jane' />
          </label>
          <label className='m-b-xs w-100'>
            Last name:
            <input type='text' name='last_name' className='m-t-xs w-100' value={formValues.last_name} onChange={handleChange} placeholder='Doe' />
          </label>
          <label className='m-b-xs w-100'>
            Email:
            <input type='text' name='email' className='m-t-xs w-100' value={formValues.email} onChange={handleChange} placeholder='jane.doe@practice.com' />
          </label>
        </div>
        <button className='primary'>Submit</button>
      </form>
    </div>
  );
}

export default AddPracticeManagementClinicCreatorWidget;
