import React from 'react';

const RemoveButton = (props) => {
  const propClick = props.onClick;
  const { doubleassert = false } = props;

  const assertOne = () => window.confirm(`Are you sure you want to remove "${props.name}"?`);
  const assertTwo = () => window.confirm('Are you REALLY really sure?');

  const onClick = () => {
    if (doubleassert) {
      if (assertOne() && assertTwo()) {
        propClick();
      }
    } else {
      if (assertOne()) {
        propClick();
      }
    }
  };
  const newProps = Object.assign({}, props, { onClick });

  return (
    <button {...newProps}>
      <i className='fas fa-trash' /> {props.children ? props.children : ''}
    </button>
  );
};

export default RemoveButton;
