import DefaultLayout from '##/Default';

import List from './List';
import Add from './Add';

export default [
  {
    path: '/contact-blacklist',
    exact: true,
    layout: DefaultLayout,
    component: List,
    title: 'View All Blocklisted Emails & Domains',
  },
  {
    path: '/contact-blacklist/add',
    exact: true,
    layout: DefaultLayout,
    component: Add,
    title: 'Add New Contact Blocklist',
  },
  {
    path: '/contact-blacklist/edit/:id',
    exact: true,
    layout: DefaultLayout,
    component: Add,
    title: 'Add New Contact Blocklist',
  },
];
