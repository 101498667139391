import React from 'react';
import Modal from '+/Modal';
import { connect } from 'react-redux';
import { actions } from '../../../sagaSlice';

export const ConfirmMoveModal = ({
  confirmMoveModalData,
  providerBeingMoved,
  showModal,
  toggleModal,
  moveProviderToAccount,
  moveProviderToNewAccount,
}) => {
  const submitClicked = () => {
    if (confirmMoveModalData.accountId === null) {
      moveProviderToNewAccount({
        provider_id: providerBeingMoved.id,
      });
    } else {
      moveProviderToAccount({
        account_id: confirmMoveModalData.accountId,
        provider_id: providerBeingMoved.id,
      });
    }
  };

  if (showModal) {
    return (
      <Modal title='Are you sure?' onDismiss={toggleModal}>
        <p>{confirmMoveModalData.message}</p>

        <button className='pill primary md' onClick={submitClicked}>
          Submit
        </button>
      </Modal>
    );
  }

  return null;
};

export default connect(
  (state) => ({
    accountsList: state.providers.accountsList,
  }),
  actions
)(ConfirmMoveModal);
