import React from 'react';
import { Form, Field } from 'react-final-form';
import Input from '+/forms/Input';
import formValidation from './formValidation';

const ProviderForm = (props) => {
  const { type, onSubmit, onCancel } = props;
  const isPractice = type === 'practice';

  return (
    <Form
      onSubmit={onSubmit}
      validate={formValidation(type)}
      subscription={{ invalid: true }}
      render={({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit}>
          <div>
            {isPractice ? (
              <>
                <Field
                  name='company_name'
                  component={Input}
                  divStyles='w-50 inline-block'
                  label='Company Name'
                />
                <Field
                  name='custom_content.tagline'
                  component={Input}
                  divStyles='w-50 inline-block'
                  label='Tagline'
                />
              </>
            ) : (
              <>
                <Field
                  name='first_name'
                  component={Input}
                  divStyles='w-33 inline-block'
                  label='First name'
                />

                <Field
                  name='middle_name'
                  component={Input}
                  divStyles='w-33 inline-block'
                  label='Middle name'
                />

                <Field
                  name='last_name'
                  component={Input}
                  divStyles='w-33 inline-block'
                  label='Last name'
                />
              </>
            )}
            <Field name='slug' component={Input} divStyles='w-33 inline-block' label='Slug' />
            <Field
              name='phone_number'
              component={Input}
              divStyles='w-33 inline-block'
              label='Phone number'
            />
            <Field
              name='custom_content.email'
              component={Input}
              divStyles='w-33 inline-block'
              label='Email'
            />
          </div>

          <div className='grid justify-between field'>
            <button type='button' onClick={onCancel} className='error'>
              Cancel
            </button>

            <button type='submit' disabled={invalid} className='success'>
              Submit
            </button>
          </div>
        </form>
      )}
    />
  );
};

export default ProviderForm;
