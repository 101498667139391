import React from 'react';
const Availability = (props) => {
  const availabilityIcon = (availability) => {
    if (availability) {
      return <i className='fas fa-check text-success' />;
    } else {
      return <i className='fas fa-times text-error' />;
    }
  };

  return (
    <p className='mb-md'>
      {props.title}: {availabilityIcon(props.value)}
    </p>
  );
};

export default Availability;
