import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Modal from '+/Modal';
import SearchTypeAhead from '+/SearchTypeAhead';
import { connect } from 'react-redux';
import { actions } from '../../../sagaSlice';

export const ChangeAccountOwnerModal = ({
  users,
  showModal,
  toggleModal,
  toggleConfirmChangeOwnerModal,
  setConfirmChangeOwnerModalMessage,
  setNewAccountOwnerUserId,
}) => {
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [initialLoadDone, setInitialLoadDone] = useState(false);

  const userListWithDisplayName = users
    ? users.map((obj) => ({
        ...obj,
        computedDisplayName: `user id: ${obj.id} - ${obj.full_name} (${obj.email})`,
      }))
    : [];

  useEffect(() => {
    if (users && users.length && !initialLoadDone) {
      setFilteredUsers(userListWithDisplayName);
      setInitialLoadDone(true);
    }
  }, [initialLoadDone, userListWithDisplayName, users]);

  const searchAccounts = (searchTerm) => {
    // If not loaded, don't try to search.
    if (userListWithDisplayName) {
      if (searchTerm && searchTerm.length) {
        const result = userListWithDisplayName.filter((x) =>
          _.includes(x.computedDisplayName.toUpperCase(), searchTerm.toUpperCase())
        );
        setFilteredUsers(result);
      } else {
        setFilteredUsers(userListWithDisplayName);
      }
    } else {
      setFilteredUsers([]);
    }
  };

  if (showModal) {
    return (
      <Modal title='Choose user to be the new owner' onDismiss={toggleModal}>
        {filteredUsers && (
          <SearchTypeAhead
            field='computedDisplayName'
            placeholder='Search by name, email, or user id...'
            items={filteredUsers}
            selected={selectedUserId ? [selectedUserId] : []}
            searchFn={searchAccounts}
            onSelect={(userId) => {
              console.log(`Selected user id ${userId}`);
              setNewAccountOwnerUserId(userId);
              setSelectedUserId(userId);
              const newOwner = users.find((x) => x.id === userId);
              setConfirmChangeOwnerModalMessage(
                `Are you sure you want to make (user id ${userId}) ${newOwner.full_name} (${newOwner.email}) the owner?`
              );
              toggleModal();
              toggleConfirmChangeOwnerModal();
            }}
          />
        )}
      </Modal>
    );
  }

  return null;
};

export default connect((state) => ({}), actions)(ChangeAccountOwnerModal);
