import React, { useState } from 'react';
import rgx from '#/regex';

const Publications = (props) => {
  const { initialValue, custom_content, updateFn } = props;
  const [publications, setPublication] = useState(initialValue);
  const [newPublication, setNewPublication] = useState('');

  const addPublication = async () => {
    const payload = { custom_content };
    const newPublications = [...publications, newPublication];

    payload.custom_content.publications = newPublications;
    await updateFn(payload);
    setPublication(newPublications);
    setNewPublication('');
  };

  const deletePublication = async (index) => {
    // Turn Array into Object, Remove Element, Then Revert into Array
    let newPublications = { ...publications };
    delete newPublications[index];
    newPublications = Object.values(newPublications);

    setPublication([...newPublications]);
    const payload = { custom_content };
    payload.custom_content.publications = [...newPublications];
    await updateFn(payload);
  };

  return (
    <div className='card cursor-default p-md m-sm'>
      <div className='grid border-bottom py-lg justify-between align-center field'>
        <h3 className='mb-lg'>Publications:</h3>
        <input
          type='text'
          className='w-75'
          placeholder='New Publication Text ...'
          onChange={(e) => setNewPublication(e.target.value)}
          value={newPublication}
        />

        <button
          className='success p-sm ml-md'
          disabled={!rgx.uri.test(newPublication)}
          onClick={addPublication}
        >
          <i className='fas fa-plus' />
        </button>
      </div>

      <div className='scroll-y grid wrap justify-between'>
        {publications.length ? (
          publications.map((publication, index) => (
            <div key={`publication${index}`} className='grid justify-between align-center'>
              <p>{publication}</p>

              <button className='error ml-md' onClick={() => deletePublication(index)}>
                <i className='fas fa-times' />
              </button>
            </div>
          ))
        ) : (
          <div className='grid justify-center w-100'>
            <h5>No Publications yet </h5>
          </div>
        )}
      </div>
    </div>
  );
};

export default Publications;
