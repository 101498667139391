import React from 'react';
import history from '#/history';
import { connect } from 'react-redux';
import { actions } from './sagaSlice';
import { capitalize } from 'voca';
import TagForm from './Form';

export const AddTag = (props) => {
  const { match = { params: {} }, createTag } = props;
  const { plural } = match.params;

  const onCancel = () => history.push(`/${plural}`);

  return (
    <div>
      <h1>Add {capitalize(plural)}</h1>
      <TagForm
        onCancel={onCancel}
        onSubmit={(values) =>
          createTag({
            plural,
            values,
          })
        }
      />
    </div>
  );
};
const mapDispatchToProps = (dispatch, { match = { params: {} } }) => ({
  createTag: (...args) => dispatch(actions[match.params.plural].create(...args)),
});

export default connect(null, mapDispatchToProps)(AddTag);
