import React, { useState } from 'react';
import EditableCard from '+/forms/EditableCard';
import LocationView from '+/locations/View';
import LocationAdd from '+/locations/Add';
import Licenses from '../Licenses';

const LocationTab = (props) => {
  const { provider, fetchFn, updateFn } = props;
  const [addNew, setAddNew] = useState(false);
  const toggleAddition = () => setAddNew((e) => !e);

  if (addNew) {
    return (
      <LocationAdd
        type='providers'
        id={provider.id}
        fetchFn={fetchFn}
        toggleAddition={toggleAddition}
      />
    );
  }

  return (
    <>
      <Licenses providerId={provider.id} />
      <div className='grid align-center justify-between'>
        <h2 className='inline-block'>Locations:</h2>
        <button className='primary pointer p-md' onClick={toggleAddition}>
          <i className='fas fa-plus' />
        </button>
      </div>
      <div className='w-50'>
        <EditableCard
          initialState={provider.custom_content.availability_notes}
          label='Availability Note:'
          name='availability_notes'
          updateFn={updateFn}
          custom_content={provider.custom_content}
        />
      </div>
      <div>
        {(provider.locations || []).map((location, i) => (
          <LocationView
            key={`location#${i}`}
            locationId={location.id}
            isPrimaryLocation={location.id === provider.primary_location_id}
            updateFn={updateFn}
            fetchFn={fetchFn}
            showLicenseInfo={true}
          />
        ))}
      </div>
    </>
  );
};

export default LocationTab;
