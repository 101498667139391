import React, { useEffect, useState } from 'react';
import { actions } from './sagaSlice.js';
import { useDispatch } from 'react-redux';
import Linkify from 'react-linkify';

// Opens Linkify links in new tab -- Usable with all Linkify instances and able to be move to a ./utils.js file
const componentDecorator = (decoratedHref, decoratedText, key) => (
  <a target='blank' href={decoratedHref} key={key}>
    {decoratedText}
  </a>
);

/**
 * An Account Notes widget that allows CSX share account specific knowledge within admin.
 * This Component sits outside of AccountGraph so as to be allows to stand alone at the top of pages.
 *
 * @param {{account: {id: number, notes: string}}} props
 * @returns React.JSX.Element
 */
function AccountNotes({ account }) {
  const dispatch = useDispatch();

  const [value, setValue] = useState(account.notes);
  const [isEditing, setIsEditing] = useState(false);
  const [notes, setNotes] = useState(account.notes);

  useEffect(() => {
    // Reset state values based on network responses
    setValue(account.notes);
    setNotes(account.notes);
  }, [account]);

  function handleSubmit() {
    // Update UI with loading state and values ahead of network response
    setIsEditing(false);
    setNotes(value);
    // Send off network response to update notes
    dispatch(actions.updateAccountNotes({ account_id: account.id, notes: value }));
  }

  function handleButtonClick(e) {
    e.preventDefault();
    if (isEditing) {
      handleSubmit();
    } else {
      setIsEditing(true);
    }
  }

  function handleCancelClick(e) {
    e.preventDefault();
    setValue(account.notes);
    setIsEditing(false);
  }

  function handleChange(e) {
    const { value } = e.target;
    setValue(value);
  }

  return (
    <div className='account-notes box flex column justify-start align-start'>
      <a title={`View Account #${account.id}`} href={`/accounts/view/${account.id}`}>
        Account #{account.id}
      </a>
      {isEditing ? (
        <textarea name='notes' id='notes' cols='30' rows='5' value={value} onChange={handleChange} placeholder={`Enter important notes pertaining to Account #${account.id}`}></textarea>
      ) : notes ? (
        <Linkify componentDecorator={componentDecorator}>
          <p>{notes}</p>
        </Linkify>
      ) : (
        <p className='gray'>Nothing here yet...</p>
      )}
      <div className='button-container'>
        {isEditing && (
          <button className='error hollow' onClick={handleCancelClick}>
            Cancel
          </button>
        )}
        <button className='primary' onClick={handleButtonClick}>
          {isEditing ? 'Save' : 'Edit'}
        </button>
      </div>
    </div>
  );
}

export default AccountNotes;
