import AuthLayout from '##/Auth';
import DefaultLayout from '##/Default';

import Login from './Login';
import Logout from './Logout';
import Confirm from './Confirm';
import RequestReset from './RequestReset';
import ResetPassword from './ResetPassword';
import UpdatePassword from './UpdatePassword';

export default [
  {
    path: '/login',
    exact: true,
    layout: AuthLayout,
    component: Login,
    title: 'Login',
  },
  {
    path: '/logout',
    exact: true,
    component: Logout,
    auth: true,
  },
  {
    path: '/account/confirm/:token',
    layout: AuthLayout,
    component: Confirm,
    title: 'Confirm New Account',
  },
  {
    path: '/password/request',
    exact: true,
    layout: AuthLayout,
    component: RequestReset,
    title: 'Request Password Reset',
  },
  {
    path: '/password/reset/:reset_token',
    exact: true,
    layout: AuthLayout,
    component: ResetPassword,
    title: 'Reset Your Password',
  },
  {
    path: '/password/update',
    exact: true,
    layout: DefaultLayout,
    component: UpdatePassword,
    auth: true,
    title: 'Update Your Password',
  },
];
